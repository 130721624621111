import React from "react";
import { CardWrapper } from "./cardStyle";

const InfoCard = ({
  description,
  description2,
  description3,
  mainValue,
  mainValue2,
  mainValue3,
  cardlin1,
  cardlin2,
  ...rest
}) => {
  return (
    <CardWrapper {...rest}>
      <div className="single_card_container">
        <p className="card_title mb-2 text-center mt-2 w-100">{mainValue}</p>
        <p className="card_description text-center w-100">{description}</p>
      </div>
      {cardlin1 && (
        <span
          className="cardline"
        ></span>
      )}
      <div className="single_card_container">
        <p className="card_title mb-2 text-center mt-2 w-100">{mainValue2}</p>
        <p className="card_description text-center w-100">{description2}</p>
      </div>
      {cardlin2 && (
        <span
          className="cardline"
        ></span>
      )}
      <div className="single_card_container">
        <p className="card_title mb-2 text-center mt-2 w-100">{mainValue3}</p>
        <p className="card_description text-center w-100">{description3}</p>
      </div>
    </CardWrapper>
  );
};

export default InfoCard;

// import React from "react";
// import { CardWrapper } from "./cardStyle";

// const MultiInfoCard = ({
//   topValue,
//   mainValue,
//   mainValue2,
//   mainValue3,
//   positive,
//   info,
//   percentage,
//   description,
//   description2,
//   description3,
//   mainValueSub,
//   mainValueSub2,
//   mainValueSub3,
//   cardlin1,
//   cardlin2,
//   height,
//   onClick,
//   role,
//   width,
// }) => {
//   return (
//     <CardWrapper
//       width={width}
//       height={height}
//       positive={positive}
//       className="card pp"
//     >
//       <div
//         className="card-header d-flex justify-content-end"
//         onClick={onClick}
//         role={role}
//       >
//         {percentage && (
//           <div className="percentage-points">
//             <div className="percentage-points-content">
//               {positive ? (
//                 <i className="fa fa-arrow-up pl-4"></i>
//               ) : (
//                 <i className="fa fa-arrow-down pl-4"></i>
//               )}
//               <span className="percentage-value">{topValue}</span>
//             </div>
//           </div>
//         )}
//         {info && (
//           <div className="info">
//             <i className="fa-sharp fa-light fa-circle-info"></i>
//           </div>
//         )}
//       </div>
//       <div
//         className="card-body card-details mb-1"
//         role={role}
//         onClick={onClick}
//       >
//         <div className="d-flex justify-content-between align-items-center gap-2">
//             <div>
//               <div className="pt-value d-flex justify-content-center">
//                 <span className="p-value mx-1">{mainValue}</span>
//                 {mainValueSub && (
//                   <span className="p-subvalue">{mainValueSub}</span>
//                 )}
//               </div>
//               <p className="pt-titlee d-flex justify-content-center text-center m-0 p-0">
//                 {description}
//               </p>
//             </div>
//           {cardlin1 && (
//             <span
//               className="cardline"
//               style={{
//                 width: "0.1rem",
//                 height: "3.5rem",
//                 backgroundColor: "gray",
//               }}
//             ></span>
//           )}
//             <div>
//               <div className="pt-value d-flex justify-content-center">
//                 <span className="p-value mx-1">{mainValue2}</span>
//                 {mainValueSub2 && (
//                   <span className="p-subvalue">{mainValueSub2}</span>
//                 )}
//               </div>
//               <p className="pt-titlee d-flex justify-content-center text-center m-0 p-0">
//                 {description2}
//               </p>
//             </div>
//           {cardlin2 && (
//             <span
//               className="cardline"
//               style={{
//                 width: "0.1rem",
//                 height: "3.5rem",
//                 backgroundColor: "gray",
//               }}
//             ></span>
//           )}
//             <div>
//               <div className="pt-value d-flex justify-content-center">
//                 <span className="p-value mx-1">{mainValue3}</span>
//                 {mainValueSub3 && (
//                   <span className="p-subvalue">{mainValueSub3}</span>
//                 )}
//               </div>
//               <p className="pt-titlee d-flex justify-content-center text-center m-0 p-0">
//                 {description3}
//               </p>
//             </div>
//         </div>
//       </div>
//       {/* </div> */}
//     </CardWrapper>
//   );
// };

// export default MultiInfoCard;
