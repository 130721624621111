import Badges from "../../Components/Common/Badges";
import CellSelector from "../../Components/Common/CellSelector";
import { colors, convertToTitleCase, formattedPrice } from "../../utils/constants";

export const defaultImage =
  "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aG91c2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60";

export const columns = (loading) => [
  // {
  //   name: "",
  //   grow: 0,
  //   selector: (row) => (
  //     <CellSelector loading={loading}>
  //       <img
  //         data-tag="allowRowEvents"
  //         height="60px"
  //         width="70px"
  //         alt={row?.images?.length ? row?.images[0]?.name : "alt image"}
  //         src={row?.images?.length > 0 ? row?.images[0] : defaultImage}
  //         style={{ borderRadius: "18px", padding: "8px" }}
  //       />
  //     </CellSelector>
  //   ),
  // },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PROPERTY NAME
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row.name ?? "---"}</CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PROPERTY DETAILS
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        <>
          <span className="fw-semibold">{convertToTitleCase(row.type)}</span>{" "}
          <span className="circle_bar"></span> {row.address} {row.city}
        </>
      </CellSelector>
    ),
    wrap: true,
    grow: 2,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PRICE
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading} className={"text-success"}>
        ₦ {formattedPrice(row?.price) ?? "---"}
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        COMPLETION STATUS
      </p>
    ),
    sortable: true,
    wrap: true,
    cell: (row) => (
      <CellSelector loading={loading}>
        {(() => {
          let displayText, tooltipText;
          let borderColor, bgColor, textColor;
          switch (row.completionStatus) {
            case "MOVE_IN_READY":
              displayText = "Move In Ready";
              tooltipText = "The application has not been reviewed yet.";
              borderColor = colors.darkGreen
              bgColor = colors.lightGreen;
              textColor = colors.darkGreen;
              break;
            default:
              displayText = "Under Construction";
              tooltipText = "The customer’s application has been approved";
              borderColor = colors.darkOrange;
              textColor = colors.darkOrange;
              bgColor = colors.yellowLight;
              break;
          }

          return (
            <Badges
              text={displayText}
              title={tooltipText}
              backgroundcolor={bgColor}
              bordercolor={borderColor}
              color={textColor}
            />
          );
        })()}
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        LOCATION
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row?.landmark ?? "---"}</CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        HOUSE TYPE
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {convertToTitleCase(row?.type) ?? "---"}
      </CellSelector>
    ),
  },
];

export const plansColumns = (loading) => [
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PLAN
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row?.name}</CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PROPERTY
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row?.property ?? "---"}</CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        DELIVERY LEVEL
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {convertToTitleCase(row?.deliveryLevel) ?? "---"}
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        DELIVERY PRICE
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        <>₦ {formattedPrice(row?.maxLoanAmount)}</>
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        DOWNPAYMENT
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        <>{row?.equityPercentage ?? 0}%</>
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PERIOD
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        <>{row?.loanTenor} months</>
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        INTEREST RATE
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        <>{row?.interestRate}%</>
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        DATE CREATED
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {new Date(row.dateCreated).toDateString()}
      </CellSelector>
    ),
  },
];
