import { styled } from "styled-components";
import { colors } from "../../../utils/constants";

export const CardWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  height: ${(props) => (props.height ? `${props.height} !important` : "130px")};
  width: ${(props) => (props.width ? `${props.width} !important` : "")};
  box-shadow: 0px 3px 6px #0000001a !important;
  overflow: scroll;
  padding: 0.5rem 1rem;

  .single_card_container {
    flex: 1
  }

  .card_title {
    font-size: 30px;
    font-weight: bold;
    font-family: "Red Hat Display", sans-serif;
  }

  .card_description {
    font-weight: 600;
    font-family: "Red Hat Display", sans-serif;
  }

  .cardline {
    width: 0.1rem;
    height: 3.5rem;
    background-color: #4B4B4B;
    opacity: 0.3
  }
`;
