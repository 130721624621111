import React, { useEffect, useState } from "react";
import usePermission from "../../hooks/usePermissions";
import { useNav } from "../../hooks/useNav";
import Button from "../../Components/Common/Button";
import CustomDropdown from "../../Components/Common/Dropdown";
import InfoCard from "../../Components/Common/InfoCard";
import FormInput from "../../Components/Common/FormInput";
import MultitextInput from "../../Components/Common/MultitextInput";
import {
  customerColums,
  customerData,
  listItem,
} from "./viewingRequestConstant";
import { useNavigate } from "react-router-dom";
import InfoTable from "../../Components/Common/InfoTable";
import Icons from "../../Components/Common/Icons";
import "../ViewingRequests/viewingRequests.css";
import Spinner from "../../Components/Spinner";
import CenteredText from "../../Components/CenteredText";
import useViewing from "./hooks/useViewing";
import Modal from "../../Components/Common/Modal";
import { colors } from "../../utils/constants";
import { convertToTitleCase, formattedPrice } from "../../utils/constants";
import { Dropdown } from "react-bootstrap";
import { HiDotsHorizontal } from "react-icons/hi";
import useConfirmBooking from "./hooks/useConfirmBooking";
import useAcknowledgeBooking from "./hooks/useAcknowledgeBooking";
import useRescheduleBooking from "./hooks/useRescheduleBooking";
import useCancelBooking from "./hooks/useCancelBooking";
import MobileInspectionCardDetails from "./MobileInspectionCardDetails";
import { useMediaQuery } from "react-responsive";
import { houseInspectionTypes } from "../../utils/mockData";
import TableLoader from "../../Components/Common/TableLoader";
import SkeletonLoader from "../../Components/SkeletonLoader";

const ViewingRequests = () => {
  useNav({ mainTitle: "Inspections" });
  const { accessViewViewingRequests } = usePermission();

  const [status, setStatus] = useState("PENDING");
  const navigate = useNavigate();

  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const defaultImage =
    "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aG91c2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60";

  const [rowId, setRowId] = useState();

  const { confirmBooking } = useConfirmBooking(rowId);
  const { acknowledgeBooking } = useAcknowledgeBooking(rowId);
  const { rescheduleBooking } = useRescheduleBooking(rowId);
  const { cancelBooking } = useCancelBooking(rowId);

  const [selectedViewing, setSelectedViewing] = useState();
  const [modalDate, setModalDate] = useState();
  const [modalTime, setModalTime] = useState();
  const [resheduleBooking, setResheduleBooking] = useState();
  const [confirmCancelBooking, setConfirmCancelBooking] = useState(false);
  const [reasonForCancellingBooking, setReasonForCancellingBooking] =
    useState();
  const [descriptionForCancellingBooking, setDescriptionForCancellingBooking] =
    useState();

  const [showViewingModal, setShowViewingModal] = useState(false);
  const [showConfirmBookingModal, setShowConfirmBookingModal] = useState(false);
  const [showResheduleBookingModal, setShowResheduleBookingModal] =
    useState(false);
  const [showMarkAsViewedModal, setShowMarkAsViewedModal] = useState(false);
  const [showCancelBookingModal, setShowCancelBookingModal] = useState(false);

  const handleEditViewing = () => navigate("/house-inspections/edit");
  const handleOnChange = (status) => setStatus(status);

  const [viewingRequestPagination, setViewingRequestPagination] = useState(1);
  const {
    data: viewingRequest,
    isLoading,
    error,
    refetch,
    isRefetching,
  } = useViewing(viewingRequestPagination);

  const [newDate, setNewDate] = useState("");
  const [newTime, setNewTime] = useState("");

  const isFormValid = newDate && newTime;

  const handleAction = (rowId, action) => {
    setRowId(rowId);
    if (viewingRequest?.data) {
      const singleViewing = viewingRequest?.data?.find((x) => x.id === rowId);

      const dateTimeString = singleViewing?.viewingDate;
      const dateObj = new Date(dateTimeString);
      const date = dateObj.toDateString();
      const time = dateObj.toLocaleTimeString();

      setModalTime(time);
      setModalDate(date);
      setSelectedViewing(singleViewing);
    }

    if (action === "viewDetails") {
      setShowViewingModal(true);
    } else if (action === "confirmBooking") {
      setShowConfirmBookingModal(true);
    } else if (action === "rescheduleBooking") {
      setShowResheduleBookingModal(true);
    } else if (action === "markAsViewed") {
      setShowMarkAsViewedModal(true);
    } else {
      setShowCancelBookingModal(true);
    }
  };

  const formatTime = (time) => {
    const [hours, minutes] = time.split(":");
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    return date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
  };

  let dropdownItems = [];

  if (
    selectedViewing?.status === "PENDING" &&
    selectedViewing?.isAgentConfirmed &&
    selectedViewing?.isCustomerConfirmed
  ) {
    dropdownItems = [
      // { label: "Acknowledge Booking", action: "acknowledgeBooking" },
      { label: "Cancel Booking", action: "cancelBooking" },
    ];
  } else if (
    selectedViewing?.status === "HOUSE_INSPECTION_COMPLETED" &&
    selectedViewing?.isAgentConfirmed &&
    selectedViewing?.isCustomerConfirmed
  ) {
    dropdownItems = [{ label: "Cancel Booking", action: "cancelBooking" }];
  } else if (
    selectedViewing?.status === "CUSTOMER_RESCHEDULED" &&
    !selectedViewing?.isAgentConfirmed
  ) {
    dropdownItems = [
      { label: "Confirm Booking", action: "confirmBooking" },
      { label: "Reschedule Booking", action: "rescheduleBooking" },
      { label: "Cancel Booking", action: "cancelBooking" },
    ];
  } else if (
    selectedViewing?.status === "AGENT_RESCHEDULED" &&
    !selectedViewing?.isCustomerConfirmed
  ) {
    dropdownItems = [
      { label: "Reschedule Booking", action: "rescheduleBooking" },
      { label: "Cancel Booking", action: "cancelBooking" },
    ];
  } else if (
    selectedViewing?.status === "VIEWED" &&
    selectedViewing?.isAgentConfirmed &&
    selectedViewing?.isCustomerConfirmed
  ) {
    dropdownItems = [];
  } else if (
    selectedViewing?.status === "AGENT_CANCELLED_BOOKING" ||
    selectedViewing?.status === "CUSTOMER_CANCELLED_BOOKING"
  ) {
    dropdownItems = [];
  } else {
    dropdownItems = [
      { label: "Confirm Booking", action: "confirmBooking" },
      { label: "Reschedule Booking", action: "rescheduleBooking" },
      { label: "Cancel Booking", action: "cancelBooking" },
    ];

    if (selectedViewing?.isAgentConfirmed && selectedViewing?.isCustomerConfirmed) {
      dropdownItems.push({ label: "Mark as Viewed", action: "markAsViewed" });
    }
  }

  useEffect(() => {
    refetch(status);
  }, [status, refetch]);

  useEffect(() => {
    refetch();
  }, [viewingRequestPagination]);

  if (error)
    return (
      <div className="mt-5">
        <CenteredText
          title={
            "A problem happened when retrieving the House Inspection information."
          }
        >
          Please verify your network connection and reload the page.
        </CenteredText>
      </div>
    );
  return (
    <div className="container-fluid">
      {accessViewViewingRequests?.value ? (
        <>
          <div className="d-flex justify-content-end align-items-center gap-3 pb-3 pe-2">
            <div className="mt-3 ">
              <CustomDropdown
                backgroundcolor={"white"}
                bordercolor={colors.darkGreen}
                hoverBorder={""}
                focusBorder={""}
                options={listItem}
                padding={"16px 10px"}
                onChange={(e) => handleOnChange(e.target.value)}
              />
            </div>
            <div className="">
              <Button
                btnText={"Edit Settings"}
                padding={"15px 12px"}
                onBtnClick={handleEditViewing}
              />
            </div>
          </div>

          <div className="">
            <InfoCard
              mainValue={
                isLoading || isRefetching ? (
                  <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
                ) : (
                  viewingRequest?.totalRecords ?? "0"
                )
              }
              description={
                isLoading || isRefetching ? (
                  <SkeletonLoader />
                ) : (
                  "Total Inspections"
                )
              }
            />
          </div>

          {isLoading ? (
            <div className="mt-4">
              <TableLoader />
            </div>
          ) : viewingRequest?.data?.length > 0 ? (
            <div className="mt-2 mb-5">
              {!isMobile ? (
                <InfoTable
                  columns={customerColums(
                    (rowId, action) => handleAction(rowId, action),
                    isRefetching
                  )}
                  dataCollection={viewingRequest?.data}
                  loadingTable={isRefetching}
                  paginationTotalRows={viewingRequest?.totalRecords}
                  onChangePage={(page) => setViewingRequestPagination(page)}
                />
              ) : (
                <>
                  {viewingRequest?.data?.map((request) => (
                    <div key={request.id} className="mb-4">
                      <MobileInspectionCardDetails
                        id={request?.id}
                        title={request?.house?.name}
                        bookingStatus={request?.status}
                        address={request?.house?.address}
                        price={request?.house?.price}
                        date={request?.viewingDate}
                        time={request?.viewingDate}
                        fee={request?.viewingFee}
                        constructionStatus={request?.house?.completionStatus}
                        profileName={request?.customer?.name}
                        image={request?.house?.images?.[0]}
                        profilePhoneNumber={request?.customer?.phoneNumber}
                        isAgentConfirmed={request?.isAgentConfirmed}
                        isCustomerConfirmed={request?.isCustomerConfirmed}
                        handleAction={handleAction}
                      />
                    </div>
                  ))}
                </>
              )}
            </div>
          ) : (
            <div className="mt-5">
              <CenteredText title="You have no house inspections at this time">
                This page shows all house inspections made by customers for your
                properties through any of the available plans in your
                organization or banks you partner with. As long as it is your
                property, you will be able to see all viewings happening on it.
              </CenteredText>
            </div>
          )}
        </>
      ) : (
        <div className="mt-5">
          <CenteredText title={"Unauthorized Access"}>
            You don't have permission to view house inspections. Please contact
            the organization administrator for assistance.
          </CenteredText>
        </div>
      )}

      {/* Viewing Details MODAL */}
      <Modal
        show={showViewingModal}
        closeModal={() => {
          // setSelectedViewing("");
          setShowViewingModal(false);
        }}
        title={"House Inspection Details."}
        closeModalIcon={true}
        width={"100%"}
        height={"100%"}
        crossLine={false}
        titleFontSize={"30px"}
      >
        <div className="px-4">
          <div className="d-flex justify-content-between">
            <div
              className="p-2 mb-3"
              style={{
                border: "1px solid #335F32",
                borderRadius: "8px",
                backgroundColor: "#F8F8F8",
                display: "inline-block",
                width: "10rem",
              }}
            >
              <p
                className="m-0 text-center fw-bold"
                style={{ color: colors.darkGreen }}
              >
                {selectedViewing?.viewingFee ? (
                  <>₦ {formattedPrice(selectedViewing?.viewingFee)}</>
                ) : (
                  "Free"
                )}
              </p>
              <p className="m-0 text-center" style={{ fontSize: "0.7rem" }}>
                INSPECTION COST
              </p>
            </div>

            {!(
              selectedViewing?.status === "VIEWED" &&
              selectedViewing?.isAgentConfirmed &&
              selectedViewing?.isCustomerConfirmed
            ) &&
              !(selectedViewing?.status === "AGENT_CANCELLED_BOOKING") &&
              !(selectedViewing?.status === "CUSTOMER_CANCELLED_BOOKING") && (
                <div className="d-flex justify-content-between">
                  <Dropdown>
                    <Dropdown.Toggle>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          background: "#F0F0F0",
                          borderRadius: "50%",
                          width: "3rem",
                          height: "3rem",
                          padding: "0",
                          margin: "0",
                        }}
                      >
                        <HiDotsHorizontal
                          className="fw-bold"
                          style={{ height: "2.8rem", color: "#707070" }}
                        />
                      </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {dropdownItems.map((item) => (
                        <Dropdown.Item
                          key={item.action}
                          onClick={() =>
                            handleAction(selectedViewing?.id, item.action)
                          }
                          className={
                            item.action === "cancelBooking" ? "text-danger" : ""
                          }
                        >
                          {item.label}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
          </div>

          <div className="row">
            <div className="col-md-6">
              <h4 className="">House Inspection Details</h4>
              <div className="row">
                <div className="col-md-4">
                  <h6
                    className="m-0 mb-1"
                    style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                  >
                    TYPE
                  </h6>
                  <p>{convertToTitleCase(selectedViewing?.type)}</p>
                </div>
                <div className="col-md-4">
                  <h6
                    className="m-0 mb-1"
                    style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                  >
                    AGENT
                  </h6>
                  <p>{selectedViewing?.agent?.name}</p>
                </div>
                <div className="col-md-4">
                  <h6
                    className="m-0 mb-1"
                    style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                  >
                    EMAIL
                  </h6>
                  <p style={{ color: colors.darkGreen, fontWeight: "bold" }}>
                    {selectedViewing?.agent?.email}
                  </p>
                </div>
                <div className="col-md-4">
                  <h6
                    className="m-0 mb-1"
                    style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                  >
                    PHONE NO
                  </h6>
                  <p>{selectedViewing?.agent?.phoneNumber}</p>
                </div>
                <div className="col-md-4">
                  <h6
                    className="m-0 mb-1"
                    style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                  >
                    DATE
                  </h6>
                  <p>{modalDate}</p>
                </div>
                <div className="col-md-4">
                  <h6
                    className="m-0 mb-1"
                    style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                  >
                    TIME
                  </h6>
                  <p>{modalTime}</p>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <h4 className="">Customer Details</h4>
              <div className="row">
                <div className="col-md-4">
                  <h6
                    className="m-0 mb-1"
                    style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                  >
                    CUSTOMER
                  </h6>
                  <p>{selectedViewing?.customer?.name}</p>
                </div>
                <div className="col-md-4">
                  <h6
                    className="m-0 mb-1"
                    style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                  >
                    EMAIL
                  </h6>
                  <p style={{ color: colors.darkGreen, fontWeight: "bold" }}>
                    {selectedViewing?.customer?.email}
                  </p>
                </div>
                <div className="col-md-4">
                  <h6
                    className="m-0 mb-1"
                    style={{ fontSize: "0.8rem", fontWeight: "bold" }}
                  >
                    PHONE NO
                  </h6>
                  <p>{selectedViewing?.customer?.phoneNumber}</p>
                </div>
              </div>
            </div>

            <div className="col-md-6">
              <h4 className="mt-3">Property Details</h4>
              <div className="d-flex gap-2">
                <div>
                  <img
                    data-tag="allowRowEvents"
                    height="110px"
                    width="120px"
                    alt={"alt image"}
                    src={selectedViewing?.house?.images?.[0]}
                    style={{ borderRadius: "5px" }}
                  />
                </div>
                <div className="d-flex flex-column justify-content-between  py-3">
                  <p className="m-0">
                    ₦ {formattedPrice(selectedViewing?.house?.price)}
                  </p>
                  <p className="m-0">{selectedViewing?.house?.address}</p>
                  <p
                    className="m-0 mt-1"
                    style={{ color: colors.darkGreen, fontWeight: "bold" }}
                  >
                    <span className="me-1">
                      <Icons iconName={"underConstruction"} />
                    </span>{" "}
                    {convertToTitleCase(
                      selectedViewing?.house?.completionStatus
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>

      {/* Confirm Booking MODAL */}
      <Modal
        show={showConfirmBookingModal}
        closeModal={() => {
          // setSelectedViewing("");
          setShowConfirmBookingModal(false);
        }}
        title={"Confirm Booking."}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Yes"}
        confirmModal={() => {
          confirmBooking.mutate(null, {
            onSuccess: () => {
              refetch();
              setShowConfirmBookingModal(false);
            },
          });
        }}
        isBtnLoading={confirmBooking.isLoading}
        disabled={confirmBooking.isLoading}
      >
        <p className="text-center">
          Are you sure you want to confirm the inspection for{" "}
          <strong>{selectedViewing?.name ?? "this property"}</strong> located at{" "}
          <strong>{selectedViewing?.house?.address}</strong> which is currently schedule for{" "}
          <strong>{new Date(selectedViewing?.viewingDate).toDateString()}</strong> at{" "}
          <strong>{new Date(selectedViewing?.viewingDate).toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          })}</strong>{" "}
          with <strong>{selectedViewing?.customer?.name}?</strong> This means you’re satisfied
          with the scheduled date and time.
        </p>
      </Modal>

      {/* Reschedule Booking MODAL */}
      <Modal
        show={showResheduleBookingModal}
        closeModal={() => {
          setNewDate("");
          setNewTime("");
          // setSelectedViewing("");
          setResheduleBooking(false);
          setShowResheduleBookingModal(false);
        }}
        title={
          resheduleBooking
            ? "Reschedule Booking Confirmation"
            : "Reschedule Booking."
        }
        confirmButtonLabel={resheduleBooking ? "Yes" : "Reschedule"}
        cancelButtonLabel={"Cancel"}
        confirmModal={() => {
          if (!resheduleBooking) {
            return setResheduleBooking(true);
          }

          const combinedDateTime = new Date(`${newDate}T${newTime}`);

          const resheduleBookingObject = {
            houseViewingId: selectedViewing?.id,
            agentId: selectedViewing?.agentId,
            viewingDate: combinedDateTime,
            customerId: selectedViewing?.customer?.customerId,
            houseId: selectedViewing?.houseId,
            contactNumber: selectedViewing?.contactNumber,
            alternateViewerEmail: selectedViewing?.alternateViewerEmail,
            alternateViewerPhoneNumber:
              selectedViewing?.alternateViewerPhoneNumber,
            alternateViewerName: selectedViewing?.alternateViewerName,
            type: selectedViewing?.type,
            houseViewingFee: selectedViewing?.viewingFee,
          };
          
          rescheduleBooking.mutate(resheduleBookingObject, {
            onSuccess: () => {
              setNewDate(null);
              setNewTime(null);
              refetch();
              setShowResheduleBookingModal(false);
              setResheduleBooking(false);
            },
          });
        }}
        isBtnLoading={rescheduleBooking.isLoading}
        disabled={
          (!resheduleBooking && !isFormValid) || rescheduleBooking.isLoading
        }
      >
        {resheduleBooking ? (
          <p className="text-center">
            Are you sure you want to reschedule the booking for{" "}
            <strong>{selectedViewing?.name ?? "this property"}</strong> located at{" "}
            <strong>{selectedViewing?.house?.address}</strong> which was booked for{" "}
            <strong>{new Date(selectedViewing?.viewingDate).toDateString()}</strong> at{" "}
            <strong>{new Date(selectedViewing?.viewingDate).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}</strong>{" "}
            with <strong>{selectedViewing?.customer?.name}?</strong> to{" "}
            <strong>{new Date(newDate).toDateString()} at {formatTime(newTime)}?</strong>
          </p>
        ) : (
          <div>
            <p>
              Select a new Date and Time for the inspection of{" "}
              {selectedViewing?.name ?? "this property"} located at{" "}
              {selectedViewing?.house?.address}.
            </p>
            <div className="d-flex gap-3">
              <div style={{ width: "10rem" }}>
                <FormInput
                  type={"date"}
                  required
                  labelName={"Date"}
                  placeholder={"Select Date"}
                  value={newDate}
                  onChange={(name, value) => setNewDate(value)}
                />
              </div>
              <div style={{ width: "10rem" }}>
                <FormInput
                  type={"time"}
                  required
                  labelName={"Time"}
                  placeholder={"Select Time"}
                  value={newTime}
                  onChange={(name, value) => setNewTime(value)}
                />
              </div>
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center previousBooking mb-5">
              <p
                className="text-center fw-semibold mb-2"
                style={{ color: colors.green }}
              >
                PREVIOUS BOOKING DETAILS
              </p>
              <div className="d-flex gap-4">
                <p className="m-0">
                  <strong>Date:</strong>{" "}
                  {new Date(selectedViewing?.viewingDate).toDateString()}
                </p>
                <p className="m-0">
                  <strong>Time:</strong>{" "}
                  {new Date(selectedViewing?.viewingDate).toLocaleTimeString(
                    [],
                    { hour: "2-digit", minute: "2-digit" }
                  )}
                </p>
              </div>
            </div>
          </div>
        )}
      </Modal>

      {/* Mark as Viewed MODAL */}
      <Modal
        show={showMarkAsViewedModal}
        closeModal={() => {
          // setSelectedViewing("");
          setShowMarkAsViewedModal(false);
        }}
        title={"Mark as Viewed."}
        confirmButtonLabel={"Yes"}
        cancelButtonLabel={"Cancel"}
        confirmModal={() => {
          acknowledgeBooking.mutate(null, {
            onSuccess: () => setShowMarkAsViewedModal(false),
          });
        }}
        isBtnLoading={acknowledgeBooking.isLoading}
        disabled={acknowledgeBooking.isLoading}
      >
        <p className="text-center">
          By marking this inspection as viewed, you are acknowledging that{" "}
          {selectedViewing?.name ?? "this property"} located at{" "}
          {selectedViewing?.house?.address} has been inspected by{" "}
          {selectedViewing?.customer?.name}? This means you’re satisfied with
          the scheduled date and time.
        </p>
      </Modal>

      {/* Cancel Booking MODAL */}
      <Modal
        show={showCancelBookingModal}
        closeModal={() => {
          // setSelectedViewing(false);
          setConfirmCancelBooking(false);
          setDescriptionForCancellingBooking("");
          setShowCancelBookingModal(false);
        }}
        title={
          confirmCancelBooking
            ? "Cancel Booking Confirmation."
            : "Cancel Booking."
        }
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={confirmCancelBooking ? "Yes" : "Proceed"}
        confirmModal={() => {
          if (!confirmCancelBooking) {
            return setConfirmCancelBooking(true);
          }

          cancelBooking.mutate(
            {
              reason: reasonForCancellingBooking,
              comment: descriptionForCancellingBooking,
            },
            {
              onSuccess: () => {
                refetch();
                setShowCancelBookingModal(false);
                setConfirmCancelBooking(false);
              },
            }
          );
        }}
        isBtnLoading={cancelBooking.isLoading}
        disabled={
          !descriptionForCancellingBooking ||
          !reasonForCancellingBooking ||
          cancelBooking.isLoading
        }
      >
        <>
          {confirmCancelBooking ? (
            <p className="text-center">
              Are you sure you want to cancel your booking for{" "}
              {selectedViewing?.name ?? "this property"} located at{" "}
              {selectedViewing?.house?.address} which you was booked for{" "}
              {new Date(selectedViewing?.viewingDate).toDateString()} at{" "}
              {new Date(selectedViewing?.viewingDate).toLocaleTimeString([], {
                hour: "2-digit",
                minute: "2-digit",
              })}{" "}
              with {selectedViewing?.customer?.name}?
            </p>
          ) : (
            <div>
              <div>
                <CustomDropdown
                  required
                  labelName={"Reason For Cancellation"}
                  placeholder={"Select"}
                  options={houseInspectionTypes}
                  onChange={(e) =>
                    setReasonForCancellingBooking(e.target.value)
                  }
                />
              </div>
              <MultitextInput
                required
                label={"Description"}
                placeholder={
                  "Provide more information on why you are cancelling this bookinig."
                }
                onChange={(e) =>
                  setDescriptionForCancellingBooking(e.target.value)
                }
              />
            </div>
          )}
        </>
      </Modal>
    </div>
  );
};

export default ViewingRequests;
