import React from "react";
import { useNavigate } from "react-router-dom";
import { getImage } from "../../utils/generalUtils";
import defaultHouse from "../../house.svg";
import styled from "styled-components";
import { colors } from "../../utils/constants";

const CardWrapper = styled.div`
  position: relative;
  border: 2px solid
    ${(props) => (props.selected ? colors.primary : "transparent")};
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  overflow: hidden;
  transition: border 0.1s ease-in-out;
  box-shadow: 0px 1px 3px #0000001a !important;

  .estate-card-header {
    position: relative;
    height: 160px;
    overflow: hidden;
  }
`;

const Overlay = styled.div`
  display: ${(props) => (props.selected ? "block" : "none")};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 128, 0, 0.3);
  z-index: 1;
`;

const EstateCard = ({ estate, selected, onSelect }) => {
  const navigate = useNavigate();
  const image = getImage;
  return (
    <CardWrapper
      className="card my-3 bg-light rounded-top"
      selected={selected}
      onClick={() => onSelect(estate.id)}
    >
      <div className="estate-card-header">
        <img
          className="card-img-top w-100 h-100"
          src={image(estate?.images) ?? defaultHouse}
          alt={estate?.name}
        />
        <Overlay selected={selected} />
      </div>

      <div className="card-body text-center">
        <div className="row pr-title">
          <h6>{estate?.name}</h6>
        </div>
        <div className="row pr-desc">
          <p>{estate?.address}</p>
        </div>
        <div className="row pr-hou">
          <span>
            {estate?.houses?.length}{" "}
            {estate?.houses?.length > 1 ? "HOUSES" : "HOUSE"}
          </span>
        </div>
      </div>
    </CardWrapper>
  );
};

export default EstateCard;
