import React, { useEffect, useState } from "react";
// import "../Account/profile.css";
import { useNav } from "../../../hooks/useNav";
import FormInput from "../../../Components/Common/FormInput";
import { colors } from "../../../utils/constants";
import Button from "../../../Components/Common/Button";
import FooterButtons from "../../../Components/Common/FooterButtons";
import Spinner from "../../../Components/Spinner";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import useAuth from "../../../hooks/useAuth";
import useChangePassword from "./hooks/useChangePassword";
import useResetPassword from "./hooks/useResetPassword";
import changePasswordModelSchema from "./models/changePasswordModel";

const Account = () => {
  useNav({ mainTitle: "Password Settings" });
  const { authUser } = useAuth();
  const email = authUser?.user?.email;
  
  const { changePassword, error} = useChangePassword();
  const { resetPassword } = useResetPassword();

  const [isCurrentVisible, setIsCurrentVisible] = useState(false);
  const [isNewVisible, setIsNewVisible] = useState(false);
  const [isConfirmNewVisible, setIsConfirmNewVisible] = useState(false);

  const toggleIsCurrentVisible = () => setIsCurrentVisible(!isCurrentVisible);
  const toggleIsNewVisible = () => setIsNewVisible(!isNewVisible);
  const toggleIsConfirmNewVisible = () =>
    setIsConfirmNewVisible(!isConfirmNewVisible);

  const {
    register,
    // reset,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: authUser?.user,
    resolver: yupResolver(changePasswordModelSchema),
  });

  const onSubmit = (data) => {
    changePassword.mutate(data);
  };

  // const handleResetPassword = () => {
  //   resetPassword.mutate({ email: email });
  // };

  if (error) return "error";

  return (
    <div className="container-fluid">
      <h5 className="mb-4 mt-5"> Change Password</h5>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row col-12">
          <div className="col-md-5">
            <FormInput
              required
              lefticonname="user"
              labelName={"Current Password"}
              type={isCurrentVisible ? "text" : "password"}
              righticonname={isCurrentVisible ? "viewings" : "eyeoff"}
              placeholder={"Enter your current password"}
              name="oldPassword"
              register={register("oldPassword")}
              error={errors?.oldPassword?.message}
              onClick={() => toggleIsCurrentVisible()}
            />
          </div>
        </div>
        <div className="row col-12">
          <div className="col-md-5">
            <FormInput
              required
              lefticonname="password"
              labelName={"New Password"}
              type={isNewVisible ? "text" : "password"}
              righticonname={isNewVisible ? "viewings" : "eyeoff"}
              placeholder={"eg. Paul123#"}
              name="newPassword"
              register={register("newPassword")}
              error={errors?.newPassword?.message}
              onClick={() => toggleIsNewVisible()}
            />
          </div>
          <div className="col-md-5">
            <FormInput
              required
              lefticonname="password"
              labelName={"Confirm Password"}
              type={isConfirmNewVisible ? "text" : "password"}
              righticonname={isConfirmNewVisible ? "viewings" : "eyeoff"}
              placeholder={"eg. Paul123#"}
              name="confirmPassword"
              register={register("confirmPassword")}
              error={errors?.confirmPassword?.message}
              onClick={() => toggleIsConfirmNewVisible()}
            />
          </div>
        </div>

        {/* <hr className="my-4"></hr>

        <h5 className="mb-3"> Reset Password</h5>
        <div className="row col-12 d-flex align-items-end">
          <div className="col-md-5">
            <FormInput
              labelName={"Email"}
              type={"email"}
              required
              lefticonname="envolope"
              value={email}
              name="email"
              register={register("email")}
              disabled
            />
          </div>
          <div className="col-md-2 mb-3">
            <Button
              type={"button"}
              btnText={"Reset"}
              isLoading={resetPassword.isLoading}
              disabled={resetPassword.isLoading}
              onBtnClick={handleResetPassword}
            />
          </div>
        </div> */}
        <FooterButtons
          loading={changePassword.isLoading}
          disabled={changePassword.isLoading}
        />
      </form>
    </div>
  );
};

export default Account;
