import { styled } from "styled-components";
import { colors } from "../../../utils/constants";

export const FormWrapper = styled.div`
  position: relative;
  overflow: hidden;
  width: ${(props) => (props.width ? `${props.width} !important` : "")};
  height: 100%;

  .input-group {
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => (props.color ? `${props.color} !important` : "")};
    height: ${(props) => (props.height ? `${props.height}px` : "53px")};
    border-radius: 100px;
    border: ${(props) =>
      props.bordercolor
      ? `2px solid ${props.bordercolor}`
      : "2px solid transparent"};
    background-color: ${(props) =>
      props.backgroundcolor
      ? `${props.backgroundcolor} !important`
      : colors.grey};
    transition: all 0.25s ease;
    overflow: hidden;
    /* padding-left: 0.8rem; */
    opacity: ${(props) => (props.opacity ? `${props.opacity}` : "")};
  }

  .input-group-error {
    border: 2px solid red !important;
    background-color: white;
  }

  .input-group:focus-within:not(.input-group-error) {
    border: ${(props) =>  props.focusBorder ? `${props.focusBorder} !important` : `1px solid ${colors.darkGreen}`};
    /* border: 1px solid ${colors.darkGreen}; */
    background-color: white;
  }

  .input-group:hover:not(.input-group-error) {
    border: ${(props) =>
      props.hoverBorder
        ? `${props.hoverBorder} !important`
        : `1px solid ${colors.darkGreen}`};
    background-color: white;
    opacity: 1;
  }

  .input-form-dropdown,
  .input-form-dropdown:focus {
    font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "11px")};
    font-family: "Red Hat Display", sans-serif;
    width: 100%;
    border: none !important;
    outline: none !important;
    border-radius: 100px;
    padding: 0 25px !important;
    padding-left: 2rem !important;
    ${(props) => props.lefticonname && "padding-left: 66px !important"};
    opacity: 1;
    color: #4b4b4b;
    height: 100% !important;
    margin-right: 1rem;
    background-color: transparent !important;
  }

  .form-select-placeholder {
    /* font: normal normal normal 1rem "Red Hat Display"; */
    font-style: italic;
    font-size: 12px !important;
    letter-spacing: 0.04px;
    color: #919191;
    opacity: 1;
  }

  .custom-form-label {
    font: normal normal bold 13px "Red Hat Display";
    letter-spacing: 0.04px;
    padding: 0 16px 0 8px;
  }

  .subLabel {
    display: block;
    font-size: 0.9rem;
    padding: 0 16px 0 8px;
  }

  .form_icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${(props) =>
      props.iconBackgroundcolor ? props.iconBackgroundcolor : colors.grey};
    color: ${(props) => (props.iconColor ? props.iconColor : colors.white)};
    font-size: ${(props) => (props.iconSize ? `${props.iconSize}px` : "17px")};
    border-radius: 8px;

    position: absolute;
    left: 0;
  }

  .form_icon span > svg {
    color: #4b4b4b !important;
    fill: #4b4b4b !important;
    // stroke:  #4B4B4B;
    stroke-width: 2px;
  }

  .left-form_icon {
    padding: 0 8px 0 16px;
  }

  .form-error {
    position: absolute;
    left: 0;
    bottom: -1.2rem;

    padding: 0 16px 0 8px;
    font-family: "Red Hat Display";
    font-size: 13px;
    font-weight: 500;
    color: ${colors.red};
  }

  .form-hintText {
    padding: 0 16px 0 8px;
    font-family: "Red Hat Display";
    font-size: 11px;
    font-weight: 500;
    margin: 0;
  }

  .disabled-cursor {
    cursor: not-allowed;
  }
`;
