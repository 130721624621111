import "./bank.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxiosBase from "../../hooks/useAxiosBase";
import Spinner from "../../Components/Spinner";
import { useNav } from "../../hooks/useNav";
import { getSingleImage } from "../../utils/generalUtils";
import { toast } from "react-toastify";
import Icons from "../../Components/Common/Icons";
import useAuth from "../../hooks/useAuth";
import Button from "../../Components/Common/Button";
import { isNullOrEmpty } from "../../utils/constants";
import png from "../../assets/docs/png.png";
import pdf from "../../assets/docs/pdf.png";
import word from "../../assets/docs/doc.png";
import jpg from "../../assets/docs/jpg.png";
import { defaultImage, plansColumns } from "./constants";
import useSendPartnershipRequest from "./hooks/useSendPertnershipRequest";
import Modal from "../../Components/Common/Modal";
import CenteredText from "../../Components/CenteredText";
import useGetSingleBank from "./hooks/useGetSingleBank";
import PlanCard from "../../Components/Common/PlanCard";
import TabGroup from "../../Components/TabGroup";
import LicenseAndCertificationDoc from "../../Components/Common/License&CertificationDoc";
import { convertToTitleCase } from "../../utils/constants";
import InfoTable from "../../Components/Common/InfoTable";
import { columns } from "./constants";
import useGetBankPartnershipTerms from "./hooks/useGetBankPartnershipTerms";
import useGetPartneredPlans from "./hooks/useGetPartneredPlans";
import useGetPartneredEstates from "./hooks/useGetPartneredEstate";
import useGetPartneredProperties from "./hooks/useGetPartneredProperties";
import PropertyCompletionStatus from "../../Components/Common/PropertyCompletionStatus";
import InfoCard from "../../Components/Common/InfoCard";

const BankDetails = () => {
  useNav({
    hasBackButton: true,
  });

  const { authUser } = useAuth();
  const orgID = authUser?.user?.organizationId;
  const orgName = authUser?.user?.organization?.name;

  const [planPagination, setPlanPagination] = useState(1);
  const [propertyPagination, setPropertyPagination] = useState(1);

  const params = useParams();
  const bankId = params?.id;

  const { getRequest } = useAxiosBase();

  const {
    data: getBankCollections,
    isLoading,
    refetch,
    isRefetching,
  } = useGetSingleBank(bankId);

  const [bankOrgID, setBankOrgID] = useState();
  const { sendPartnershipRequest } = useSendPartnershipRequest(bankOrgID);
  const { data: partneredEstates } = useGetPartneredEstates();
  const {
    data: partneredPlans,
    refetch: refetchPlans,
    isRefetching: isRefetchingPlans,
  } = useGetPartneredPlans(planPagination);
  const {
    data: partneredProperties,
    refetch: refetchProperty,
    isRefetching: isRefetchingProperty,
  } = useGetPartneredProperties(propertyPagination);
  const { data: getBankPartnershipTerms, isLoading: loadingPartnershipTerms } =
    useGetBankPartnershipTerms();

  const [bankDetails, setBankDetails] = useState();
  const [bankOrgName, setBankOrgName] = useState();
  const [orgRequestId, setOrgRequestId] = useState();
  const [buttonText, setButtonText] = useState();
  const [declineBtn, setDeclineBtn] = useState(false);
  const [pending, setPending] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showUnpartnerModal, setShowUnpartnerModal] = useState(false);
  const [showRevokeModal, setShowRevokeModal] = useState(false);
  const [showAllPlansModal, setShowAllPlansModal] = useState(false);
  const [showPlanModal, setShowPlanModal] = useState(false);

  const handleBtnClick = () => {
    if (buttonText === "Request Partnership") {
      setShowModal(true);
    } else if (buttonText === "Revoke Partnership") {
      setShowRevokeModal(true);
    } else if (buttonText === "Accept Partnership") {
      setShowAcceptModal(true);
    }
  };

  useEffect(() => {
    refetch();
  }, [bankId]);

  useEffect(() => {
    setBankDetails(getBankCollections);

    if (bankDetails) {
      setBankOrgName(bankDetails?.organization?.name);
      setBankOrgID(bankDetails?.organization?.id);

      if (bankDetails.status === null) {
        setButtonText("Request Partnership");
      } else if (bankDetails.status === "PENDING" && orgID !== bankDetails.id) {
        setButtonText("Revoke Partnership");
      } else if (bankDetails.status === "PENDING" && orgID === bankDetails.id) {
        setDeclineBtn(true);
        setButtonText("Accept Partnership");
      } else if (bankDetails.status === "ACCEPTED") {
        setButtonText("Revoke Partnership");
      } else {
        setButtonText(false);
      }

      if (bankDetails.id) {
        setOrgRequestId(bankDetails.id);
      }
    }
  }, [getBankCollections, bankDetails]);

  const getDocImage = (doc) => {
    if (!isNullOrEmpty(doc?.document)) {
      if (doc?.extension === "png") {
        return png;
      }
      if (doc?.extension === "jpg") return jpg;
      if (doc?.extension === "docx") return word;
      if (doc?.extension === "pdf") return pdf;
    }
  };

  const handlePartnerShipRequest = (requesterId, status) => {
    getRequest({
      url: `developer/partnership/${requesterId}/respond-to-request/${status}`,
    })
      .then((res) => {
        const responseData = res.data?.value;
        if (responseData.statusCode === 200) {
          toast.success(responseData?.message);
          refetch();
          setShowAcceptModal(false);
          setShowDeclineModal(false);
          setShowUnpartnerModal(false);
          setShowRevokeModal(false);
          setPending(false);
        } else {
          toast.error(responseData?.message);
          setPending(false);
        }
        setPending(false);
      })
      .catch((error) => {
        setPending(false);
        toast.error("An error occurred while processing your request.");
      });
  };

  const finished = [
    {
      label: "Fully Finished (Not Furnished)",
      selected: getBankPartnershipTerms?.fullyFinishedNotFurnished,
      onChange: () => {},
    },
    {
      label: "Fully Finished (Furnished)",
      selected: getBankPartnershipTerms?.fullyFinishedFurnished,
      onChange: () => {},
    },
  ];

  const constrction = [
    {
      label: "Semi-Finished",
      selected: getBankPartnershipTerms?.semiFinished,
      onChange: () => {},
    },
    {
      label: "Shell",
      selected: getBankPartnershipTerms?.shell,
      onChange: () => {},
    },
    {
      label: "Carcass",
      selected: getBankPartnershipTerms?.carcass,
      onChange: () => {},
    },
  ];

  useEffect(() => {
    if (planPagination) {
      refetchPlans();
    }
  }, [planPagination]);

  useEffect(() => {
    if (propertyPagination) {
      refetchProperty();
    }
  }, [propertyPagination]);

  if (isLoading || isRefetching)
    return (
      <div style={{ height: "60vh" }}>
        <Spinner loadingText={"Loading Bank Details..."} />
      </div>
    );

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-between align-items-center px-2 mb-2">
        <h3 className="h3 mt-3">{bankDetails?.organization?.name}</h3>
        <div className="d-flex gap-4">
          <div className="">
            <Button
              btnText={buttonText}
              onBtnClick={() => handleBtnClick()}
              disabled={!buttonText}
            />
          </div>
          {declineBtn && (
            <div className="">
              <Button
                btnText={"Decline Partnership"}
                onBtnClick={() => setShowDeclineModal(true)}
              />
            </div>
          )}
        </div>
      </div>
      <TabGroup>
        {/* OVERVIEW TAB */}
        <div label="OVERVIEW">
          <div className="row mb-5">
            <div className="bank-image-details img-fluid d-flex justify-content-start mb-4">
              <img
                src={getSingleImage(bankDetails?.organization?.logo)}
                alt="property"
              />
            </div>

            {bankDetails?.organization?.description && (
              <div className="mb-3">
                <h4 className="h4">Description</h4>
                <p>{bankDetails?.organization?.description}</p>
              </div>
            )}

            <div className="mb-3">
              <h4 className="h4">Contact Details</h4>
              <div className="d-flex gap-4">
                <div className="text-bold">
                  <Icons iconName={"envolope"} className="devIcon" />
                  <a
                    href={`mailto:${bankDetails?.organization?.emailAddress}`}
                    className="devEmail"
                  >
                    <span className="p-1">
                      {bankDetails?.organization?.emailAddress}
                    </span>
                  </a>
                </div>
                <div className="text-bold">
                  <Icons iconName={"phone"} className="devIcon" />
                  <a
                    href={`tel:${bankDetails?.organization?.phoneNumber}`}
                    className="text-decoration-none"
                  >
                    <span className="p-1 ps-2">
                      {bankDetails?.organization?.phoneNumber}
                    </span>
                  </a>
                </div>
              </div>
            </div>

            {/* {bankDetails?.organization?.partnerships?.length && ( */}
            <div className="col-md-12 p-3 pt-4 ">
              <h4 className="h4 mt-1">Bank Relationships</h4>
              <p className="text-success fw-bold">
                WORKS WITH {bankDetails?.organization?.partnerships?.length}{" "}
                PROPERTY DEVELOPERS
              </p>
              {bankDetails?.organization?.partnerships?.length ? (
                bankDetails?.organization?.partnerships?.map((partner) => (
                  <div className="relationships border border-success">
                    <img
                      src={defaultImage}
                      height="60px"
                      width="60px"
                      style={{ borderRadius: "18px", padding: "8px" }}
                    />
                    <p>{partner.name}</p>
                    <div className="mb-1">
                      <Button
                        btnText={"View Developer"}
                        width={"95"}
                        btnTextFont={"12"}
                        // onBtnClick={() => setShowModal(true)}
                        padding={"4px 0"}
                      />
                    </div>
                  </div>
                ))
              ) : (
                <CenteredText title={"No Bank Partnerships"}>
                  {" "}
                  {bankDetails?.organization?.name} currently doesn't have no
                  partnerships with any Bank.
                </CenteredText>
              )}
            </div>
            {/* )} */}

            {/* {bankDetails?.organization?.documents?.length === 0 && ( */}
            <div className="col-md-12 px-3 py-1 m-0">
              <h4 className="h4">Licenses & Certifications</h4>
              <div className="row">
                {bankDetails?.organization?.documents?.length ? (
                  bankDetails?.organization?.documents?.map((doc) => (
                    <div className="col-md-2 mb-3">
                      <LicenseAndCertificationDoc
                        name={convertToTitleCase(doc.optionId)}
                        extension={doc.extension?.toUpperCase()}
                      />
                    </div>
                  ))
                ) : (
                  <CenteredText title={"No Licenses & Certifications"}>
                    {" "}
                    You currently do not have any licenses or certifications
                    with developers. Click the “Request Partnership” button to
                    create a partnership with a developer.
                  </CenteredText>
                )}
              </div>
            </div>
            {/* )} */}
          </div>
        </div>

        {/* PROPERTIES TAB */}
        <div label="PROPERTIES">
          <div className="d-flex align-items-center gap-3 estate_data">
            <div>
              <Icons iconName="estate" />
              <span
                className="fw-semibold"
                style={{ fontFamily: '"Red Hat Display", sans-serif' }}
              >
                {partneredEstates?.totalRecords ?? 0}{" "}
                {partneredEstates?.totalRecords === 1 ? "Estate" : "Estates"}
              </span>
            </div>
            <div className="w-50">
              <Icons iconName="houses" />
              <span
                className="fw-semibold"
                style={{ fontFamily: '"Red Hat Display", sans-serif' }}
              >
                {partneredProperties?.totalRecords ?? 0}{" "}
                {partneredProperties?.totalRecords === 1 ? "House" : "Houses"}
              </span>
            </div>
          </div>

          {isLoading ? (
            <div style={{ height: "35vh" }}>
              <Spinner loadingText={"Loading Properties..."} />{" "}
            </div>
          ) : !partneredProperties?.data?.length ? (
            <div className="mt-5">
              <CenteredText title={"There are no Properties at this time"}>
                {" "}
                When a Banky adds a Property, the information will be displayed
                here.
              </CenteredText>
            </div>
          ) : (
            <div className="col-12">
              <InfoTable
                columns={columns(isRefetchingProperty)}
                dataCollection={partneredProperties?.data}
                pointerOnHover={true}
                highlightOnHover={true}
                loadingTable={isRefetchingProperty}
                paginationTotalRows={partneredProperties?.totalRecords}
                // onChangePage={(page) => setPagination(page)}
                // onRowClicked={(row, event) =>
                //   navigate(`/plans/edit/${row?.id}`)
                // }
              />
            </div>
          )}
        </div>

        {/* PLANS TAB */}
        <div label="PLANS">
          <div className="d-flex gap-4 mb-4 mt-3">
            <div className="">
              <InfoCard
                mainValue={partneredPlans?.totalRecords ?? 0}
                description={"Total Plans"}
              />
            </div>
          </div>
          {isLoading ? (
            <div style={{ height: "35vh" }}>
              <Spinner loadingText={"Loading plans..."} />{" "}
            </div>
          ) : !partneredPlans?.data?.length ? (
            <div className="mt-5">
              <CenteredText title={"There are no Plans at this time"}>
                When a Bank adds a Plan, the information will be displayed here.
              </CenteredText>
            </div>
          ) : (
            <div className="col-12">
              <InfoTable
                columns={plansColumns(isRefetchingPlans)}
                dataCollection={partneredPlans?.data}
                pointerOnHover={true}
                highlightOnHover={true}
                loadingTable={isRefetchingPlans}
                paginationTotalRows={partneredPlans?.totalRecords}
                onChangePage={(page) => setPlanPagination(page)}
                // onRowClicked={(row, event) =>
                //   navigate(`/plans/edit/${row?.id}`)
                // }
              />
            </div>
          )}
        </div>
      </TabGroup>

      {/* REQUEST PARTNERSHIP */}
      <Modal
        title="Bank Partnership Request"
        show={showModal}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Send"}
        closeModalIcon={true}
        width={"100%"}
        height={"100%"}
        crossLine={false}
        titleFontSize={"30px"}
        closeModal={() => setShowModal(false)}
        confirmModal={() =>
          sendPartnershipRequest.mutate(null, {
            onSuccess: () => {
              refetch();
              setShowModal(false);
            },
          })
        }
        isBtnLoading={sendPartnershipRequest.isLoading}
        disabled={sendPartnershipRequest.isLoading}
      >
        {buttonText === "Request Partnership" ? (
          <div className="px-2 px-md-5">
            <p className="text-center w-md-50 m-auto">
              Are you sure you want to work with {bankOrgName}? Working with
              this developer means they will have the ability to sell your
              properties on your mortgage plans.{" "}
              <strong>
                Kindly read through the partnership terms and consideration
                areas below before you proceed to partner with the developer.
              </strong>
            </p>

            {getBankPartnershipTerms ? (
              <>
                <p>
                  Partnership Consideration Areas and Terms for {bankOrgName}.
                </p>
                {loadingPartnershipTerms ? (
                  <Spinner loadingText={"Loading Terms"} />
                ) : (
                  <div className="row">
                    <div className="col-md-3 col-lg-2 p-0">
                      <p className="m-0 fw-bold">PAYMENT PERIOD</p>
                      <p>Not More Than 2 Weeks</p>
                    </div>

                    <div className="col-md-8 col-lg-6">
                      <p className="m-0 fw-bold">PROPERTY COMPLETION STATUS</p>
                      <p>
                        The completion status you want to partner with{" "}
                        {bankOrgName} for their properties on
                      </p>
                      <div className="d-lg-flex gap-4">
                        <div className="w-100 mb-3">
                          <PropertyCompletionStatus
                            title={"MOVE-IN READY"}
                            iconName={"moveInReady"}
                            checkInputs={finished}
                          />
                        </div>
                        <div className="w-100 mb-3">
                          <PropertyCompletionStatus
                            title={"UNDER CONSTRUCTION"}
                            iconName={"underConstruction"}
                            checkInputs={constrction}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6 col-lg-3">
                      <p className="m-0 fw-bold">
                        STANDARD PARTNERHSIP AGREEMENT
                      </p>
                      <div className="col-md-6">
                        <LicenseAndCertificationDoc
                          name={convertToTitleCase(
                            getBankPartnershipTerms?.agreement?.optionId
                          )}
                          extension={getBankPartnershipTerms?.agreement?.extension?.toUpperCase()}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div className="mt-5">
                <CenteredText title="No Partnership Terms Available">
                  <p>
                    Currently, there are no partnership terms available for the
                    bank <strong>{bankOrgName}</strong>. Please check back later
                    or contact your bank representative for more information.
                  </p>
                </CenteredText>
              </div>
            )}
          </div>
        ) : buttonText === "Revoke Partnership" ? (
          <p className="text-center">
            {" "}
            Are you sure you want to Revoke Partnership with {bankOrgName}?{" "}
            <strong>This action can not be undone.</strong>
          </p>
        ) : (
          <p className="text-center">
            {" "}
            Are you sure you want to Accept Partnership with {
              bankOrgName
            } bank?{" "}
            <strong>
              We advice you agree only if you already have a relationship with
              this Bank.
            </strong>
          </p>
        )}
      </Modal>

      {/* ACCEPT PARTNERSHIP */}
      <Modal
        title="Accept Bank Partnership Request"
        show={showAcceptModal}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        closeModal={() => setShowAcceptModal(false)}
        confirmModal={() => {
          setPending(true);
          handlePartnerShipRequest(orgRequestId, "ACCEPTED");
        }}
        isBtnLoading={pending}
        disabled={pending}
      >
        <p className="text-center">
          Are you sure you want to accept the request to partner with
          {bankDetails?.organization?.name ?? "this Bank"}.{" "}
          <strong>
            This will give you access to sell their properties through your
            plans.
          </strong>
        </p>
      </Modal>

      {/* DECLINE PARTNERSHIP */}
      <Modal
        title="Reject Bank Partnership Request"
        show={showDeclineModal}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        closeModal={() => setShowDeclineModal(false)}
        confirmModal={() => {
          setPending(true);
          handlePartnerShipRequest(orgRequestId, "REJECTED");
        }}
        isBtnLoading={pending}
        disabled={pending}
      >
        <p>
          Are you sure you want to decline the request to partner with{" "}
          <strong>{bankDetails?.organization?.name ?? "this Bank"}?</strong>{" "}
          This will give them access to sell your properties.
        </p>
      </Modal>

      {/* UNPARTNER PARTNERSHIP */}
      <Modal
        title="Unpartner with this Bank"
        show={showUnpartnerModal}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        closeModal={() => setShowUnpartnerModal(false)}
        confirmModal={() => {
          setPending(true);
          handlePartnerShipRequest(orgRequestId, "UNPARTNER");
        }}
        isBtnLoading={pending}
        disabled={pending}
      >
        <p>
          Are you sure you want to unpartner with{" "}
          <strong>{bankDetails?.organization?.name}?</strong> This will mean you
          won’t be able to access any of their properties anymmore.
        </p>
      </Modal>

      {/* REVOKE PARTNERSHIP */}
      <Modal
        title="Revoke Partnership"
        show={showRevokeModal}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        closeModal={() => setShowRevokeModal(false)}
        confirmModal={() => {
          setPending(true);
          handlePartnerShipRequest(orgRequestId, "REVOKED");
        }}
        isBtnLoading={pending}
        disabled={pending}
      >
        <p className="text-center">
          Are you sure you want to revoke your partnership request with{" "}
          <strong>{bankDetails?.organization?.name ?? "this Bank"}? </strong>{" "}
          This will mean you won’t be able to access any of their properties
          anymore.
        </p>
      </Modal>

      {/* VIEW ALL PLANS MODAL */}
      <Modal
        title="Plans"
        show={showAllPlansModal}
        width={"100%"}
        height={"100%"}
        titleFont={"Millik"}
        titleFontSize={"30px"}
        crossLine={false}
        closeModalIcon
        closeModal={() => setShowAllPlansModal(false)}
      >
        <p className="text-center">
          Plans available in {orgName}. See if their plans might be desirable
          for your customers.
        </p>
        <div className="row">
          <div className="col-sm-6 col-md-4 mb-5 my-4">
            <PlanCard
              title={"Title"}
              intrestRate={"Intrest Rate"}
              downpayment={"Downpayment"}
              period={"Period"}
              monthlyPayment={"Monthly Payment"}
              intrestValue={"10"}
              downpaymentValue={"20"}
              periodValue={"7"}
              monthleyPaymentValue={"40"}
              width={"100%"}
              onSelectClick={() => setShowPlanModal(true)}
            />
          </div>
          <div className="col-sm-6 col-md-4 mb-5 my-4">
            <PlanCard
              title={"Title"}
              intrestRate={"Intrest Rate"}
              downpayment={"Downpayment"}
              period={"Period"}
              monthlyPayment={"Monthly Payment"}
              intrestValue={"10"}
              downpaymentValue={"20"}
              periodValue={"7"}
              monthleyPaymentValue={"40"}
              width={"100%"}
              onSelectClick={() => setShowPlanModal(true)}
            />
          </div>
          <div className="col-sm-6 col-md-4 mb-5 my-4">
            <PlanCard
              title={"Title"}
              intrestRate={"Intrest Rate"}
              downpayment={"Downpayment"}
              period={"Period"}
              monthlyPayment={"Monthly Payment"}
              intrestValue={"10"}
              downpaymentValue={"20"}
              periodValue={"7"}
              monthleyPaymentValue={"40"}
              width={"100%"}
              onSelectClick={() => setShowPlanModal(true)}
            />
          </div>
          <div className="col-sm-6 col-md-4 mb-5 my-4">
            <PlanCard
              title={"Title"}
              intrestRate={"Intrest Rate"}
              downpayment={"Downpayment"}
              period={"Period"}
              monthlyPayment={"Monthly Payment"}
              intrestValue={"10"}
              downpaymentValue={"20"}
              periodValue={"7"}
              monthleyPaymentValue={"40"}
              width={"100%"}
              onSelectClick={() => setShowPlanModal(true)}
            />
          </div>
          <div className="col-sm-6 col-md-4 mb-5 my-4">
            <PlanCard
              title={"Title"}
              intrestRate={"Intrest Rate"}
              downpayment={"Downpayment"}
              period={"Period"}
              monthlyPayment={"Monthly Payment"}
              intrestValue={"10"}
              downpaymentValue={"20"}
              periodValue={"7"}
              monthleyPaymentValue={"40"}
              width={"100%"}
              onSelectClick={() => setShowPlanModal(true)}
            />
          </div>
          <div className="col-sm-6 col-md-4 mb-5 my-4">
            <PlanCard
              title={"Title"}
              intrestRate={"Intrest Rate"}
              downpayment={"Downpayment"}
              period={"Period"}
              monthlyPayment={"Monthly Payment"}
              intrestValue={"10"}
              downpaymentValue={"20"}
              periodValue={"7"}
              monthleyPaymentValue={"40"}
              width={"100%"}
              onSelectClick={() => setShowPlanModal(true)}
            />
          </div>
        </div>
      </Modal>

      {/* PLAN DETAILS MODAL */}
      <Modal
        title="Smallest Plan"
        subTitle={"By FEDERAL HOUSING AUTHORITY"}
        show={showPlanModal}
        width={"100%"}
        height={"100%"}
        crossLine={false}
        closeModalIcon
        backBtn
        onBtnClick={() => {
          setShowAllPlansModal(true);
          setShowPlanModal(false);
        }}
        closeModal={() => setShowPlanModal(false)}
      >
        <div className="row mt-5 px-4">
          <div className="col-md-4 mb-5">
            <h3> Issuer </h3>
            <p> Federal Housing Authority</p>
          </div>
          <div className="col-md-4 mb-5">
            <h3> Amount </h3>
            <p> N15,000,000</p>
          </div>
          <div className="col-md-4 mb-5">
            <h3> Applicable Fee </h3>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h6> BANK </h6>
                <p className="m-0">N3,000</p>
              </div>
              <div>
                <h6> PROCESSING </h6>
                <p className="m-0">N3,000</p>
              </div>
              <div>
                <h6> ACCOUNT OPENING </h6>
                <p className="m-0">N50,000</p>
              </div>
            </div>
          </div>
          <div className="col-md-4 mb-5">
            <h3> Equity/Downpayment </h3>
            <p> 20%</p>
          </div>
          <div className="col-md-4 mb-5">
            <h3> Tenor </h3>
            <p> 30 Years (360 Payments)</p>
          </div>
          <div className="col-md-4 mb-5">
            <h3> Estimated Processing Time </h3>
            <p> 3 Months</p>
          </div>
          <div className="col-md-4 mb-5">
            <h3> Intrest Rate </h3>
            <p> 50% per annum</p>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BankDetails;
