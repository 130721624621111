import React, { useCallback, useEffect, useMemo, useState } from "react";
import ProgressBar from "@ramonak/react-progress-bar";
import {
  columnData,
  columns,
  displayValidationValue,
  stringDisplayValidation,
} from "../utils/constants";
import DocumentTable from "../../../Components/DocumentTable";
import Reports from "../Reports";
import Modal from "../../../Components/Common/Modal";
import { toast } from "react-toastify";
// import useReviseDocument from "../hooks/useReviseDocument";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { useNavigate } from "react-router-dom";
import MultitextInput from "../../../Components/Common/MultitextInput";
import { colors, convertToTitleCase } from "../../../utils/constants";
import { applicationStatusIndex } from "../utils/applicationStatus";
import RevissionChart from "./RevissionChart";
import { HiOutlineExclamation } from "react-icons/hi";

const DocumentValidation = ({
  appId,
  application,
  documents,
  customer,
  refetch,
  isRefetching,
}) => {
  const { postRequest, getRequest } = useAxiosBase();
  const [name, setName] = useState("");
  const [docUrl, setDocUrl] = useState("");
  const [comment, setComment] = useState();
  const [showModal, setShowModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [validateModalShow, setValidateModalShow] = useState(false);
  const [inValidateModalShow, setInValidateModalShow] = useState(false);
  const [confirmEndRevisionModalShow, setConfirmEndRevisionModalShow] =
    useState(false);
  const [disableBtn, setDisableBtn] = useState(true);
  const [btnIsLoading, setBtnIsLoading] = useState(false);
  const [documentId, setDocumentId] = useState();
  const [revisionId, setRevisionId] = useState();
  const [revisionDoc, setRevisionDoc] = useState();
  const [documentHasRevision, setDocumentHasRevision] = useState();
  const [docName, setDocName] = useState("");
  const [validationIsLoading, setValidationIsLoading] = useState(false);
  const [docValidation, setDocValidation] = useState({
    docId: "",
    validated: false,
  });
  const [customerName, setCustomerName] = useState(customer?.name);

  const [offerLetterRevision, setOfferLetterRevision] = useState();
  const [responseMessage, setResponseMessage] = useState();
  const [responseInvalidateMessage, setResponseInvalidateMessage] = useState();
  // const sendReviseDocument = useReviseDocument();
  const navigate = useNavigate();
  const [handleCloseModal, setHandleCloseModal] = useState(false);
  const [handleCloseInvalidateModal, setHandleCloseInvalidateModal] =
    useState(false);
  //resetInvalidateModal.
  useMemo(() => {
    if (handleCloseModal && !isRefetching) {
      setHandleCloseModal(false);

      setValidateModalShow(false);
      setValidationIsLoading(false);
      setDocValidation({
        docId: documentId,
        validated: !docValidation.validated,
        previous: document?.isValidated,
      });
      setResponseMessage("");
      toast.success(responseMessage, { toastId: "validate" });
    }
  }, [isRefetching]);

  useMemo(() => {
    if (handleCloseInvalidateModal && !isRefetching) {
      setHandleCloseInvalidateModal(false);
      setInValidateModalShow(false);
      setValidationIsLoading(false);

      setDocValidation({
        docId: documentId,
        validated: !docValidation.validated,
        previous: document?.isValidated,
      });
      toast.success(responseInvalidateMessage, { toastId: "Invalidate" });
      setResponseInvalidateMessage("");
    }
  }, [isRefetching]);

  const handleViewDocument = (name, docId, cusName, docUrl) => {
    setName(name);
    setCustomerName(cusName);
    setDocumentId(docId);
    setShowModal(true);
    setDocUrl(docUrl);
  };

  const handleRevise = (appId, docName, applicationDocumentId, revisionId) => {
    setModalShow(true);
    setName(docName);
    setDocumentId(applicationDocumentId);

    if (revisionId) {
      setRevisionId(revisionId);
    } else {
      setRevisionId(null);
    }
  };

  const handleSendRevisions = async (appId, docId) => {
    setDisableBtn(true);
    setBtnIsLoading(true);
    await postRequest({
      url: `/developer/application/${appId}/revise-document/${docId}`,
      data: {
        comment: comment,
        previousRevisionId: revisionId,
      },
    }).then((res) => {
      if (res?.data?.value?.statusCode === 200) {
        toast.success(`Revision has been successfully sent to ${customerName}`);
        refetch();
        setComment("");
        setBtnIsLoading(false);
        return;
      }
      setComment("");
      setBtnIsLoading(false);
      setDisableBtn(false);
      toast.error(res?.data?.value?.message);
    });
  };

  const handleValidate = async (appId, documentId) => {
    setValidationIsLoading(true);
    await getRequest({
      url: `/developer/application/${appId}/validate-document/${documentId}`,
    }).then((res) => {
      if (res?.data?.value?.statusCode === 200) {
        setResponseMessage(res?.data?.value?.message);
        setHandleCloseModal(true);
        setValidateModalShow(false);
        refetch();
        // resetModal();
        return;
      }
      toast.error("Validation Failed!");
      setValidateModalShow(false);
    });
  };

  const handleInValidate = async (appId, documentId) => {
    setValidationIsLoading(true);
    await getRequest({
      url: `/developer/application/${appId}/invalidate-document/${documentId}`,
    }).then((res) => {
      if (res?.data?.value?.statusCode === 200) {
        setResponseInvalidateMessage(res?.data?.value?.message);
        setHandleCloseInvalidateModal(true);
        setInValidateModalShow(false);
        refetch();
        return;
      }
      toast.error("Could not invalidate document, operation failed!");
      setInValidateModalShow(false);
    });
  };

  useEffect(() => {
    if (comment?.length > 10) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  }, [comment]);

  useEffect(() => {
    if (application?.documents) {
      const appDoc = application?.documents?.find(
        (doc) => doc.document.optionId === "OFFER_LETTER_DOCUMENT"
      );

      setOfferLetterRevision(appDoc);
      if (application?.documents?.length > 0) {
        application?.documents?.map((x) => {
          if (x?.isValidated) {
            setDocValidation({
              docId: x?.applicationDocumentId,
              validated: true,
            });
          }
        });
      }
    }
  }, [application]);

  return (
    <div className="pe-0 ps-1 mb-5 mt-4">
      {application?.documents?.length > 0 && (
        <>
          <div className="w-100">
            <div className="p-0 me-0 df">
              <h5>
                {" "}
                {application?.status === "LEGAL_SEARCH" ||
                application?.status === "OFFER_LETTER_REQUEST" ||
                application?.status === "IDENTITY_VERIFICATION" ||
                application?.status === "DOCUMENT_SUBMISSION"
                  ? ""
                  : "Credit &"}{" "}
                Document {application?.status === "PENDING" && "Verification"}
              </h5>
              {application?.status === "LEGAL_SEARCH" ||
              application?.status === "OFFER_LETTER_REQUEST" ||
              application?.status === "IDENTITY_VERIFICATION" ||
              application?.status === "DOCUMENT_SUBMISSION" ? (
                ""
              ) : (
                <div className="col-md-6 progress-bar">
                  <ProgressBar
                    completed={displayValidationValue(documents)}
                    customLabel={stringDisplayValidation(documents)}
                    labelAlignment="center"
                    bgColor="#0A7E32"
                    height="20px"
                    labelSize="12px"
                    transitionDuration="2s"
                    animateOnRender={true}
                    customLabelStyles={{
                      paddingLeft: "40px",
                      fontFamily: '"Red Hat Display", sans-serif',
                    }}
                  />
                  <div className="stats d-flex justify-content-start gap-3 py-2">
                    <span
                      style={{ fontFamily: '"Red Hat Display", sans-serif' }}
                      className="item-value p-0"
                    >
                      {documents?.length} items &bull;{" "}
                    </span>
                    <span
                      style={{ fontFamily: '"Red Hat Display", sans-serif' }}
                      className="item-value p-0"
                    >
                      {
                        documents?.filter((doc) => doc.isValidated === true)
                          ?.length
                      }{" "}
                      validated &bull;
                    </span>
                    <span
                      style={{ fontFamily: '"Red Hat Display", sans-serif' }}
                      className="item-value p-0"
                    >
                      {
                        documents?.filter((docs) => docs?.revisions?.length > 0)
                          ?.length
                      }{" "}
                      sent for revision
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="pe-0 me-0 w-100">
              <>
                {application?.status === "LEGAL_SEARCH" ||
                application?.status === "OFFER_LETTER_REQUEST" ||
                application?.status === "IDENTITY_VERIFICATION" ||
                application?.status === "DOCUMENT_SUBMISSION" ? (
                  ""
                ) : (
                  <DocumentTable
                    application={application}
                    icon={"creditCheck"}
                    documentName={"Credit Check"}
                    isValidated={true}
                    hasButton={false}
                    handleOnClick={() =>
                      handleViewDocument(
                        "Credit Check",
                        null,
                        `${customer?.firstName} ${customer?.lastName}`,
                        `${document?.document?.document}`
                      )
                    }
                  />
                )}

                {documents.map((document) => (
                  <>
                    <DocumentTable
                      application={application}
                      key={document?.applicationDocumentId}
                      documentName={convertToTitleCase(
                        document?.document?.option?.name
                      )}
                      icon={document.icon}
                      hasButton={true}
                      handleOnClick={() =>
                        handleViewDocument(
                          convertToTitleCase(document?.document?.option?.name),
                          document?.applicationDocumentId,
                          `${customer?.firstName} ${customer?.lastName}`,
                          `${document?.document?.document}`
                        )
                      }
                      handleRevise={() => {
                        handleRevise(
                          appId,
                          convertToTitleCase(document?.document?.option?.name),
                          document?.applicationDocumentId,
                          document?.revisions?.id
                        );

                        setDocName(
                          convertToTitleCase(document?.document?.option?.name)
                        );
                        setRevisionDoc(document?.revisions);
                      }}
                      handleViewFeedback={() => setShowFeedbackModal(true)}
                      handleValidate={(hasRevision) => {
                        setValidateModalShow(true);
                        setDocumentHasRevision(hasRevision);
                        setDocumentId(document?.applicationDocumentId);
                        setDocName(
                          convertToTitleCase(document?.document?.option?.name)
                        );
                      }}
                      handleInValidate={() => {
                        setInValidateModalShow(true);
                        setDocumentId(document?.applicationDocumentId);
                        setDocName(
                          convertToTitleCase(document?.document?.option?.name)
                        );
                      }}
                      document={document}
                      setDocName={setDocName}
                      docValidation={docValidation}
                      isValidated={
                        document?.isValidated && docValidation.validated
                      }
                      applicationStatus={application.status}
                      url={document?.document?.document}
                      userName={`${customer?.firstName?.toLowerCase()}_${customer?.lastName?.toLowerCase()}_${document?.document?.name
                        ?.replace(/\s/gm, "_")
                        ?.toLowerCase()}`}
                    />
                  </>
                ))}
              </>
            </div>
          </div>
          <Reports
            view={showModal}
            close={() => setShowModal(false)}
            name={name}
            columns={columns}
            columnData={columnData}
            docuementType={name}
            documentId={documentId}
            appId={appId}
            customerName={customerName}
            docValidation={docValidation}
            docUrl={docUrl}
            application={application}
          />

          <Modal
            title={"Offer Letter Review and Feedback"}
            show={showFeedbackModal}
            closeModal={() => setShowFeedbackModal(false)}
            titleFontSize={"30px"}
            width={"100%"}
            height={"100%"}
            crossLine={false}
            closeModalIcon
            btnHeight={45}
          >
            <p className="text-center m-0 px-3 mt-4">
              {offerLetterRevision?.revisions?.[0]?.comment}
            </p>
          </Modal>

          {/* NEW REVISSION  */}
          <Modal
            title={`Specific Revision - ${customerName}`}
            show={modalShow}
            closeModal={() => {
              setComment("");
              setModalShow(false);
            }}
            cancelButtonLabel={"End Revision"}
            confirmButtonLabel={"Send Revision"}
            btnHeight={45}
            closeModalIcon={true}
            width={"100%"}
            height={"100%"}
            crossLine={false}
            titleFontSize={"30px"}
            cancelModal={() => setConfirmEndRevisionModalShow(true)}
            confirmModal={() => handleSendRevisions(appId, documentId)}
            isBtnLoading={btnIsLoading}
            disabled={disableBtn || btnIsLoading}
          >
            <div className="px-md-4 h-100">
              <div className="d-flex justify-content-center">
                <p className="greenSuccessPill mt-2 mb-3">
                  {revisionDoc?.length}{" "}
                  {revisionDoc?.length > 1 ? "MESSAGES" : "MESSAGE"}
                </p>
              </div>

              <RevissionChart
                docName={docName}
                comment={comment}
                customerName={customerName}
                setComment={setComment}
                revisionDoc={revisionDoc}
                btnIsLoading={btnIsLoading}
              />
            </div>
          </Modal>

          {/* CONFIRM END REVISSION  */}
          <Modal
            show={confirmEndRevisionModalShow}
            title={"End Revision"}
            subTitle={
              <>
                Are you sure you want to end this revision? Ending the revision
                indicates that both you and <strong>{customerName}</strong> have
                reached a consensus and are satisfied to proceed with validating
                their <strong>{docName}</strong>.
              </>
            }
            cancelButtonLabel={"No"}
            confirmButtonLabel={"Yes"}
            closeModal={() => setConfirmEndRevisionModalShow(false)}
            // confirmModal={handleEndRevision}
          />

          <Modal
            show={validateModalShow}
            title={
              <span>
                Validate Document{" "}
                {documentHasRevision === "hasRevision" && (
                  <span style={{ color: "red" }}>
                    <HiOutlineExclamation size={40} />
                  </span>
                )}
              </span>
            }
            subTitle={
              documentHasRevision === "hasRevision" ? (
                "This document is still under revision. Please end the revision before validating the document."
              ) : (
                <>
                  Are you sure you want to validate{" "}
                  <strong>
                    {application?.customer?.name}'s {docName}?
                  </strong>
                </>
              )
            }
            cancelButtonLabel={"Cancel"}
            confirmButtonLabel={"Validate"}
            closeModal={() => setValidateModalShow(false)}
            confirmModal={() => handleValidate(appId, documentId)}
            isBtnLoading={validationIsLoading}
            disabled={
              documentHasRevision === "hasRevision" || validationIsLoading
            }
          />

          <Modal
            show={inValidateModalShow}
            title={"Invalidate Document"}
            subTitle={
              <>
                Are you sure you want to invalidate{" "}
                <strong>
                  {application?.customer?.name}'s {docName}?
                </strong>
              </>
            }
            cancelButtonLabel={"Cancel"}
            confirmButtonLabel={"Invalidate"}
            closeModal={() => setInValidateModalShow(false)}
            confirmModal={() => handleInValidate(appId, documentId)}
            isBtnLoading={validationIsLoading}
            disabled={validationIsLoading}
          />
        </>
      )}
    </div>
  );
};

export default DocumentValidation;
