import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";

export const useGetNotificationConfiguration = (page) => {
    const { getRequest } = useAxiosBase();
  
    return useQuery({
      queryKey: ["get-notification-configuration"],
      queryFn: () =>
        getRequest({ url: `developer/notification-configuration/get-all?pageNumber=${page}` }).then(
          (res) => res?.data?.value
        ),
      staleTime: 50000,
    });
  };
  