import { useQuery } from "@tanstack/react-query";
import useAuth from "./useAuth";
import useAxiosBase from "./useAxiosBase";

const useHouse = (page) => {
  const { authUser } = useAuth();
  const { getRequest } = useAxiosBase();
  const organizationId = authUser?.user?.organizationId;

  return useQuery({
    queryKey: ["devHouses", organizationId],
    queryFn: () =>
      getRequest({
        url: `/developer/${organizationId}/house/get-all?pageNumber=${page}`,
      }).then((res) => res.data?.value?.value),
  });
};

export default useHouse;
