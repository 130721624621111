import CenteredText from "../../../Components/CenteredText";
import Badges from "../../../Components/Common/Badges";
import FooterButtons from "../../../Components/Common/FooterButtons";
import { colors } from "../../../utils/constants";
import { HiDotsVertical } from "react-icons/hi";
import { toApplicationNumber, yearRange } from "../../../utils/generalUtils";
import Dropdown from "react-bootstrap/Dropdown";
import {
  applicationStatus,
  applicationStatusIndex,
} from "../../Applications/utils/applicationStatus";
import { asCurrency } from "../../Applications/utils/constants";
import CreditProfile from "../ReviewData/CreditProfile";
import EmpVerification from "../ReviewData/EmpVerification";
import FinancialCapacity from "../ReviewData/FinancialCapacity";
import StaffRecomendation from "../ReviewData/StaffRecomendation";
import Button from "../../../Components/Common/Button";
import { convertToTitleCase } from "../../../utils/constants";
import CellSelector from "../../../Components/Common/CellSelector";

export const defaultImage =
  "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80";

export const appReviewDummy = (handleOmit, handleButtonClick, loading) => [
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        CUSTOMER
      </p>
    ),
    wrap: true,
    grow: 0.5,
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {row.customer?.firstName} {row.customer?.lastName}
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PROPERTY
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row.house?.name ?? "---"}</CellSelector>
    ),
    wrap: true,
  },
  // {
  //   name: (
  //     <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
  //       PLAN TYPE
  //     </p>
  //   ),
  //   sortable: true,
  //   selector: (row) => (
  //     <CellSelector loading={loading}>{convertToTitleCase(row.mortgagePlan?.type) ?? "---"}</CellSelector>
  //   ),
  //   wrap: true,
  // },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PLAN
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row.mortgagePlan?.name ?? "---"}</CellSelector>
    ),
    wrap: true,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        STATUS
      </p>
    ),
    sortable: true,
    grow: 0.5,
    cell: (row) => (
      <CellSelector loading={loading}>
        {(() => {
          let displayText, tooltipText;
          let borderColor, bgColor, textColor;
          switch (row.applicationStatus) {
            case "UNDER_REVIEW":
              displayText = "Pending";
              tooltipText = "The application has not been reviewed yet.";
              borderColor = colors.darkOrange;
              bgColor = colors.yellowLight;
              textColor = colors.darkOrange;
              break;
            case "REVIEW_REJECTED":
              displayText = "Rejected";
              tooltipText = "The customer’s application was rejected";
              borderColor = colors.danger;
              bgColor = colors.dangerLight;
              textColor = colors.danger;
              break;
            default:
              displayText = "Approved"; // Fallback for any other applicationStatus
              tooltipText = "The customer’s application has been approved";
              borderColor = colors.darkGreen;
              textColor = colors.darkGreen;
              break;
          }

          return (
            <Badges
              text={displayText}
              title={tooltipText}
              backgroundcolor={bgColor}
              bordercolor={borderColor}
              color={textColor}
            />
          );
        })()}
      </CellSelector>
    ),
    wrap: true,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        {" "}
        ACTION{" "}
      </p>
    ),
    sortable: false,
    grow: 0.5,
    center: true,
    cell: (row) => (
      <Dropdown>
        <Dropdown.Toggle id={`dropdown-${row.id}`}>
          <HiDotsVertical className="fw-bold text-dark" />
        </Dropdown.Toggle>
        {!loading && (
          <Dropdown.Menu>
            {row.applicationStatus !== "UNDER_REVIEW" ? (
              <Dropdown.Item
                onClick={() => handleButtonClick(row.applicationId)}
              >
                View Details
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                onClick={() => handleButtonClick(row.applicationId)}
              >
                Review
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        )}
      </Dropdown>
    ),
  },
];

export const reviewTabs = ({
  financeData,
  creditData,
  employmentVerificationData,
  application,
  handleProceedApplication,
  handleRejectApplication,
}) => [
  {
    title: "Financial Capacity",
    label: "FINANCIAL_CAPACITY",
    component: financeData ? (
      <>
        <FinancialCapacity
          financeData={financeData}
          application={application}
        />
        <FooterButtons
          left={"0"}
          bgheight={"250px"}
          height={100}
          cancelLabel={"Reject Application"}
          saveLabel={"Proceed With Application"}
          handleClick={handleProceedApplication}
          cancelBtn={handleRejectApplication}
          disabled={application?.applicationStatus !== "UNDER_REVIEW"}
          disableCancelBtn={application?.applicationStatus !== "UNDER_REVIEW"}
        />
      </>
    ) : (
      <div className="mt-5 text-center">
        <CenteredText
          title={"Bank Statement Not Analyzed"}
          children={
            "We could not analyze the bank statement for this application. This occurs if the uploaded document that is not a bank statement or the applicant is in the diaspora and we couldn't properly analyze their bank statement. Download the bank statement and view if a proper bank statement was uploaded, in which case, you should send a revision to the customer. If it's a diaspora applicant, you'll need to analyze the statement yourself as we look forward to implementing a proper analyzer for international bank statements."
          }
        />
        <FooterButtons
          left={"0"}
          bgheight={"250px"}
          height={100}
          cancelLabel={"Reject Application"}
          saveLabel={"Proceed With Application"}
          handleClick={handleProceedApplication}
          cancelBtn={handleRejectApplication}
          disabled={application?.applicationStatus !== "UNDER_REVIEW"}
          disableCancelBtn={application?.applicationStatus !== "UNDER_REVIEW"}
        />
      </div>
    ),
  },
  {
    title: "Credit & Debt Profile",
    label: "FIRST_CENTRAL",
    component: creditData ? (
      <>
        <CreditProfile creditData={creditData} />{" "}
        <FooterButtons
          left={"0"}
          bgheight={"250px"}
          height={100}
          cancelLabel={"Reject Application"}
          saveLabel={"Proceed With Application"}
          handleClick={handleProceedApplication}
          cancelBtn={handleRejectApplication}
          disabled={application?.applicationStatus !== "UNDER_REVIEW"}
          disableCancelBtn={application?.applicationStatus !== "UNDER_REVIEW"}
        />
      </>
    ) : (
      <div className="mt-5 text-center">
        <CenteredText
          title={"No Credit & Debt Profile Data Available"}
          children={"No Credit & Debt Profile  data available at the moment"}
        />
        <FooterButtons
          left={"0"}
          bgheight={"250px"}
          height={100}
          cancelLabel={"Reject Application"}
          saveLabel={"Proceed With Application"}
          handleClick={handleProceedApplication}
          cancelBtn={handleRejectApplication}
          disabled={application?.applicationStatus !== "UNDER_REVIEW"}
          disableCancelBtn={application?.applicationStatus !== "UNDER_REVIEW"}
        />
      </div>
    ),
  },
  {
    title: "Staff Recommedation",
    label: "EMPLOYMENT_VERIFICATION",
    component: (
      <>
        {" "}
        <EmpVerification application={application} />{" "}
        <FooterButtons
          left={"0"}
          bgheight={"250px"}
          height={100}
          cancelLabel={"Reject Application"}
          saveLabel={"Proceed With Application"}
          handleClick={handleProceedApplication}
          cancelBtn={handleRejectApplication}
          disabled={application?.applicationStatus !== "UNDER_REVIEW"}
          disableCancelBtn={application?.applicationStatus !== "UNDER_REVIEW"}
        />
      </>
    ),
  },
  // {
  //   title: "Staff Recommendation",
  //   label: "STAFF_RECOMMENDATION",
  //   component:
  //     application?.handlingStaffRecommendation !== null ? (
  //       <>
  //         {" "}
  //         <StaffRecomendation recommendation={application} />,
  //         <FooterButtons
  //           position={"absolute"}
  //           bgheight={"250px"}
  //           height={100}
  //           cancelLabel={"Reject Application"}
  //           saveLabel={"Proceed With Application"}
  //           handleClick={handleProceedApplication}
  //           disabled={
  //             application?.status !==
  //             applicationStatus[applicationStatusIndex.UNDER_REVIEW]
  //           }
  //         />
  //       </>
  //     ) : (
  //       <div className="mt-4 text-center">
  //         <CenteredText
  //           title={"No Recommendation"}
  //           children={
  //             "Handling staff has no recommendation for this application"
  //           }
  //         />
  //       </div>
  //     ),
  // },
];

export const creditAndDebitProfileColumn = (viewLoanHistory) => [
  {
    name: <p>SOURCE</p>,
    sortable: true,
    selector: (row) => convertToTitleCase(row.source),
  },
  {
    name: <p>TOTAL BORROWED</p>,
    sortable: true,
    selector: (row) => asCurrency(row.totalBorrowed, "NGN"),
  },
  {
    name: <p>TOTAL OUTSTANDING</p>,
    sortable: true,
    selector: (row) => asCurrency(row.totalOutstanding, "NGN"),
  },
  {
    name: <p>TOTAL LOANS</p>,
    sortable: true,
    selector: (row) => row.totalNoOfLoans,
  },
  {
    name: <p>TOTAL INSTITUTIONS</p>,
    sortable: true,
    selector: (row) => row.totalNoOfInstitutions,
  },
  {
    name: <p>MONTHLY INSTALMENTS</p>,
    sortable: true,
    selector: (row) => asCurrency(row.totalMonthlyInstallment ?? 0, "NGN"),
  },
  {
    name: <p>ACTIVE</p>,
    sortable: true,
    selector: (row) => row.totalNoOfActiveLoans,
  },
  {
    name: <p>CLOSED</p>,
    sortable: true,
    selector: (row) => row.totalNoOfClosedLoans,
  },
  {
    name: <p>BAD DEBTS</p>,
    sortable: true,
    selector: (row) => row.badDebts,
  },
  {
    name: "",
    sortable: true,
    selector: (row) => (
      <p
        className="text-success fw-bold m-0"
        role="button"
        onClick={() => viewLoanHistory(row.source)}
      >
        View Loan History
      </p>
    ),
  },
];

export const creditAndDebitProfileData = [
  {
    id: 1,
    source: "CRC",
    totalBorrowed: "N20,000,000",
    totalOutstanding: "N200,000",
    totalLoans: 14,
    totalInstitutions: 5,
    totalOverdue: "N1,200,000",
    monthlyInstalments: "N380,000",
    active: 6,
    closed: 5,
    badDebts: 0,
  },
  {
    id: 1,
    source: "First Central",
    totalBorrowed: "N20,000,000",
    totalOutstanding: "N200,000",
    totalLoans: 14,
    totalInstitutions: 5,
    totalOverdue: "N1,200,000",
    monthlyInstalments: "N380,000",
    active: 6,
    closed: 5,
    badDebts: 0,
  },
  {
    id: 1,
    source: "Credit Registry",
    totalBorrowed: "N20,000,000",
    totalOutstanding: "N200,000",
    totalLoans: 14,
    totalInstitutions: 5,
    totalOverdue: "N1,200,000",
    monthlyInstalments: "N380,000",
    active: 6,
    closed: 5,
    badDebts: 0,
  },
];
