import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useResetPassword = () => {
  const { postRequest } = useAxiosBase();
  const navigate = useNavigate();

  const resetPassword = useMutation({
    mutationFn: (data) =>
      postRequest({
        url: `/account/reset-password`,
        data: data,
      }),
    onSuccess: (res) => {
      if (res?.data?.value?.statusCode === 200) {
        toast.success(res?.data?.value?.message);
        navigate("/account/login");
      } else {
        toast.error(res?.response?.data?.value?.message || "An error occurred");
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || "An error occurred");
    },
  });

  return { resetPassword };
};

export default useResetPassword;
