import Dashboard from "./Pages/Dashboard";
import MortgageApplication from "./Pages/Applications/BankApplications";
import Properties from "./Pages/Properties";
import CreateEstate from "./Pages/Properties/Estate/createEstate";
import CreateHouse from "./Pages/Properties/House/createHouse";
import Estate from "./Pages/Properties/Estate/index";
import MortgageBanks from "./Pages/Banks";
import Portfolio from "./Pages/PaymentPlans";
import MortgagePlans from "./Pages/Properties/House/HousePlan";
import NHF from "./Pages/Properties/House/HousePlan/NHF";
import CreateMortagagePlan from "./Pages/Properties/House/HousePlan/CreateMortagagePlan";

const Routes = [
  {
    element: <Dashboard />,
    path: "/",
    titleName: "Dashboard",
  },
  {
    path: "/properties",
    element: <Properties />,
    titleName: "Properties",
  },
  {
    element: <MortgageApplication />,
    path: "/mortgage-app",
    titleName: "Mortgage Application",
  },
  {
    element: <MortgageBanks />,
    path: "/mortgage-banks",
    titleName: "Mortgage Banks",
  },
  {
    element: <CreateHouse />,
    path: "/properties/estate/create-house",
    titleName: "Create House",
  },
  {
    element: <CreateEstate />,
    path: "/properties/estate/create-estate",
    titleName: "Create Estate",
  },
  {
    path: "/properties/estate/:id",
    element: <Estate />,
    titleName: "Estate",
  },
  {
    path: "/portfolio",
    element: <Portfolio />,
    titleName: "Portfolio",
  },
  {
    path: "/mortgages/mortgage-plans",
    element: <MortgagePlans />,
    titleName: "Mortgage Plans",
  },
  {
    path: "/mortgages/mortgage-plans/NHF",
    element: <NHF />,
    titleName: "Mortgage Plans - NHF",
  },
  {
    path: "/mortgages/mortgage-plans/create",
    element: <CreateMortagagePlan />,
    titleName: "Mortgage Plan details",
  },
];

export default Routes;
