import React, { useEffect, useState } from "react";
import { useNav } from "../../../hooks/useNav";
import FormInput from "../../../Components/Common/FormInput";
import { colors } from "../../../utils/constants";
import Button from "../../../Components/Common/Button";
import useGetRemitaPaymentConfiguration from "./hooks/useGetRemitaPaymentConfiguration";
import useCreateConfiguration from "./hooks/useCreate";
import { useForm } from "react-hook-form";
import { model } from "./model";
import { yupResolver } from "@hookform/resolvers/yup";

const Payments = () => {
  useNav({ mainTitle: "Payments", subTitle: "Setup how Giddaa will pay you." });

  const { data: getPaymentConfiguration } = useGetRemitaPaymentConfiguration();
  const { createRemitaConfiguration } = useCreateConfiguration();

  const [commisionOnDownpayment, setCommisionOnDownpayment] = useState();
  const [commisionOnApplicableFess, setCommisionOnApplicableFess] = useState();
  const [commisionOnRepayment, setCommisionOnRepayment] = useState();

  const [collectionApiKeyData, setCollectionApiKeyData] = useState();
  const [merchantIdData, setMerchantIdData] = useState();
  const [applicableFessIdData, setApplicableFessIdData] = useState();
  const [downpaymentIdData, setDownpaymentIdData] = useState();
  const [repaymentIdData, setRepaymentIdData] = useState();

  // INPUT CONFIRMATION STATES
  const [confirmCollectionApiKeyData, setConfirmCollectionApiKeyData] =
    useState();
  const [confirmMerchantIdData, setConfirmMerchantIdData] = useState();
  const [confirmApplicableFessIdData, setConfirmApplicableFessIdData] =
    useState();
  const [confirmDownpaymentIdData, setConfirmDownpaymentIdData] = useState();
  const [confirmRepaymentIdData, setConfirmRepaymentIdData] = useState();

  // SHOW CONFIRMATION FIELD STATE
  const [showConfirmCollectionApiKeyData, setShowConfirmCollectionApiKeyData] = useState(false);

  // DATA HAS BEEN UPDATED SUCCESSFULLY
  const [collectionApiKeyDataIsUpdated, setCollectionApiKeyDataIsUpdated] =
    useState(false);
  const [merchantIdDataIsUpdated, setMerchantIdDataIsUpdated] = useState(false);
  const [applicableFessIdDataIsUpdated, setApplicableFessIdDataIsUpdated] =
    useState(false);
  const [downpaymentIdDataIsUpdated, setDownpaymentIdDataIsUpdated] =
    useState(false);
  const [repaymentIdDataIsUpdated, setRepaymentIdDataIsUpdated] =
    useState(false);

  // TOGGLE VISIBLE INPUT FILED
  const [isVisibleCollectionApiKey, setIsVisibleCollectionApiKey] =
    useState(false);
  const [isVisibleMerchantId, setIsVisibleMerchantId] = useState(false);
  const [isVisibleApplicableFessId, setIsVisibleApplicableFessId] =
    useState(false);
  const [isVisibleDownpaymentId, setIsVisibleDownpaymentId] = useState(false);
  const [isVisibleRepaymentId, setIsVisibleRepaymentId] = useState(false);

  const [confirmVisibleCollectionApiKey, setConfirmVisibleCollectionApiKey] =
    useState(false);
  const [confirmVisibleMerchantId, setConfirmVisibleMerchantId] =
    useState(false);
  const [confirmVisibleApplicableFessId, setConfirmVisibleApplicableFessId] =
    useState(false);
  const [confirmVisibleDownpaymentId, setConfirmVisibleDownpaymentId] =
    useState(false);
  const [confirmVisibleRepaymentId, setConfirmVisibleRepaymentId] =
    useState(false);

  useEffect(() => {
    if (getPaymentConfiguration) {
      const getCommisionOnDownpaymentData = getPaymentConfiguration.find(
        (data) => data.identifier === "EQUITY_PERCENTAGE"
      );
      const getCommisionOnApplicableFessData = getPaymentConfiguration.find(
        (data) => data.identifier === "APPLICABLE_FEE_PERCENTAGE"
      );
      const getCommisionOnRepaymentData = getPaymentConfiguration.find(
        (data) => data.identifier === "REPAYMENT_PERCENTAGE"
      );

      setCommisionOnDownpayment(getCommisionOnDownpaymentData);
      setCommisionOnApplicableFess(getCommisionOnApplicableFessData);
      setCommisionOnRepayment(getCommisionOnRepaymentData);

      const getCollectionApiKeyData = getPaymentConfiguration.find(
        (data) => data.identifier === "COLLECTION_API_KEY"
      );
      const getMerchantIdData = getPaymentConfiguration.find(
        (data) => data.identifier === "MERCHANT_ID"
      );
      const getCommissionOnDownpaymentData = getPaymentConfiguration.find(
        (data) => data.identifier === "EQUITY_PERCENTAGE"
      );
      const commissionOnRepaymentData = getPaymentConfiguration.find(
        (data) => data.identifier === "REPAYMENT_PERCENTAGE"
      );
      const commissionOnApplicableFeesData = getPaymentConfiguration.find(
        (data) => data.identifier === "APPLICABLE_FEE_PERCENTAGE"
      );
      const getApplicableFeesIdData = getPaymentConfiguration.find(
        (data) => data.identifier === "APPLICABLE_FEE_SERVICE_TYPE_ID"
      );
      const getDownpaymentIdData = getPaymentConfiguration.find(
        (data) => data.identifier === "EQUITY_SERVICE_TYPE_ID"
      );
      const getRepaymentIdData = getPaymentConfiguration.find(
        (data) => data.identifier === "REPAYMENT_SERVICE_TYPE_ID"
      );

      setCollectionApiKeyData(getCollectionApiKeyData);
      setMerchantIdData(getMerchantIdData);
      setApplicableFessIdData(getApplicableFeesIdData);
      setDownpaymentIdData(getDownpaymentIdData);
      setRepaymentIdData(getRepaymentIdData);
    }
  }, [getPaymentConfiguration]);

  return (
    <form className="ps-1 pe-2 mb-5">
      <div className="row me-0">
        <div className="col-sm-6 col-md-3 pe-0 position-relative mb-3">
          <FormInput
            labelName={"COLLECTION API KEY"}
            placeholder={"Enter Collection API key"}
            value={collectionApiKeyData?.value}
            type={isVisibleCollectionApiKey ? "text" : "password"}
            righticonname={isVisibleCollectionApiKey ? "viewings" : "eyeoff"}
            onClick={() =>
              setIsVisibleCollectionApiKey(!isVisibleCollectionApiKey)
            }
            onFocus={() => setShowConfirmCollectionApiKeyData(true)}
            onBlur={() => setShowConfirmCollectionApiKeyData(false)}
            onChange={(name, value) => {
              setCollectionApiKeyData({
                ...collectionApiKeyData,
                value: value,
              });
              setCollectionApiKeyDataIsUpdated(false);
            }}
          />
          {collectionApiKeyData?.value && (
            <div className="">
              <FormInput
                labelName={" Confirm Collection API key"}
                placeholder={"Confirm Collection API key"}
                value={confirmCollectionApiKeyData}
                type={confirmVisibleCollectionApiKey ? "text" : "password"}
                righticonname={
                  confirmVisibleCollectionApiKey ? "viewings" : "eyeoff"
                }
                onClick={() =>
                  setConfirmVisibleCollectionApiKey(
                    !confirmVisibleCollectionApiKey
                  )
                }
                onChange={(name, value) => {
                  setConfirmCollectionApiKeyData(value);
                  setCollectionApiKeyDataIsUpdated(false);
                }}
              />
            </div>
          )}
          {collectionApiKeyData?.value === confirmCollectionApiKeyData && (
            <div className="ms-2">
              {collectionApiKeyDataIsUpdated ? (
                <p
                  className="fw-bold m-0 position-absolute"
                  style={{ color: colors.darkGreen, bottom: "-0.5rem" }}
                >
                  Submitted
                </p>
              ) : (
                <Button
                  type={"button"}
                  btnText={"Submit"}
                  width={"5.5rem"}
                  height={"2rem"}
                  isLoading={createRemitaConfiguration.isLoading}
                  disabled={createRemitaConfiguration.isLoading}
                  padding={"0 10px"}
                  borderWidth={"4px"}
                  spinnerWidth={"1rem"}
                  spinnerHeight={"1rem"}
                  onBtnClick={() =>
                    createRemitaConfiguration.mutate(collectionApiKeyData, {
                      onSuccess: () => {
                        setCollectionApiKeyDataIsUpdated(true);
                      },
                    })
                  }
                />
              )}
            </div>
          )}
        </div>

        <div className="col-sm-6 col-md-3 pe-0 position-relative mb-3">
          <FormInput
            labelName={"MERCHANT ID"}
            placeholder={"Enter Merchant ID"}
            value={merchantIdData?.value}
            type={isVisibleMerchantId ? "text" : "password"}
            righticonname={isVisibleMerchantId ? "viewings" : "eyeoff"}
            onClick={() => setIsVisibleMerchantId(!isVisibleMerchantId)}
            onChange={(name, value) => {
              setMerchantIdData({
                ...merchantIdData,
                value: value,
              });
              setMerchantIdDataIsUpdated(false);
            }}
          />
          {merchantIdData?.value && (
            <div className="">
              <FormInput
                labelName={" Confirm  Merchant ID"}
                placeholder={"Confirm  Merchant ID"}
                value={confirmMerchantIdData}
                type={confirmVisibleMerchantId ? "text" : "password"}
                righticonname={confirmVisibleMerchantId ? "viewings" : "eyeoff"}
                onClick={() =>
                  setConfirmVisibleMerchantId(!confirmVisibleMerchantId)
                }
                onChange={(name, value) => {
                  setConfirmMerchantIdData(value);
                  setMerchantIdDataIsUpdated(false);
                }}
              />
              {merchantIdData?.value === confirmMerchantIdData && (
                <div className="ms-2">
                  {merchantIdDataIsUpdated ? (
                    <p
                      className="fw-bold m-0 position-absolute"
                      style={{ color: colors.darkGreen, bottom: "-0.5rem" }}
                    >
                      Submitted
                    </p>
                  ) : (
                    <Button
                      type={"button"}
                      btnText={"Submit"}
                      width={"5.5rem"}
                      height={"2rem"}
                      isLoading={createRemitaConfiguration.isLoading}
                      disabled={createRemitaConfiguration.isLoading}
                      padding={"0 10px"}
                      borderWidth={"4px"}
                      spinnerWidth={"1rem"}
                      spinnerHeight={"1rem"}
                      onBtnClick={() =>
                        createRemitaConfiguration.mutate(merchantIdData, {
                          onSuccess: () => {
                            setMerchantIdDataIsUpdated(true);
                          },
                        })
                      }
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      <div
        className="d-md-flex align-items-center border rounded mt-3 overflow-hidden"
        style={{ backgroundColor: "#F0F0F0" }}
      >
        <h5 className="col-md-2 p-4 h-100" style={{ color: colors.darkGreen }}>
          {" "}
          Commission & Payouts{" "}
        </h5>
        <div className="row p-4 bg-white">
          <div className="position-relative col-md-4">
            <FormInput
              labelName={"Commission On Downpayment (%)"}
              placeholder={"Enter Downpayment Commission"}
              value={commisionOnDownpayment?.value ? `${commisionOnDownpayment?.value}%` : "0%"}
              disabled
            />
            <p
              className="position-absolute m-0"
              style={{
                bottom: "-0.5rem",
                color: colors.darkGreen,
                fontSize: "0.8rem",
              }}
            >
              Giddaa will earn{" "}
              {commisionOnDownpayment?.value
                ? 100 - parseFloat(commisionOnDownpayment?.value)
                : 100}
              % of every downpayment
            </p>
          </div>
          <div className="position-relative col-md-4">
            <FormInput
              labelName={"Commission On Applicable Fees (%)"}
              placeholder={"Enter Applicable Fees Commission"}
              value={commisionOnApplicableFess?.value ? `${commisionOnApplicableFess?.value}%` : "0%"}
              disabled
            />
            <p
              className="position-absolute m-0"
              style={{
                bottom: "-0.5rem",
                color: colors.darkGreen,
                fontSize: "0.8rem",
              }}
            >
              Giddaa will earn{" "}
              {commisionOnApplicableFess?.value
                ? 100 - parseFloat(commisionOnApplicableFess?.value)
                : 100}
              % of every downpayment
            </p>
          </div>
          <div className="position-relative col-md-4">
            <FormInput
              labelName={"Commission On Repayment (%)"}
              placeholder={"Enter Repayment Commission"}
              value={commisionOnRepayment?.value ? `${commisionOnRepayment?.value}%` : "0%"}
              disabled
            />
            <p
              className="position-absolute m-0"
              style={{
                bottom: "-0.5rem",
                color: colors.darkGreen,
                fontSize: "0.8rem",
              }}
            >
              Giddaa will earn{" "}
              {commisionOnRepayment?.value
                ? 100 - parseFloat(commisionOnRepayment?.value)
                : 100}
              % of every repayment
            </p>
          </div>
        </div>
      </div>

      <div
        className="d-md-flex align-items-center border rounded mt-4 overflow-hidden"
        style={{ backgroundColor: "#F0F0F0" }}
      >
        <h5 className="col-md-2 p-4" style={{ color: colors.darkGreen }}>
          Service Type IDs
        </h5>
        <div className="row p-4 bg-white">
          <div className="position-relative col-md-4 mb-3">
            <FormInput
              labelName={" Downpayment ID"}
              placeholder={"Enter Downpayment ID"}
              value={downpaymentIdData?.value}
              type={isVisibleDownpaymentId ? "text" : "password"}
              righticonname={isVisibleDownpaymentId ? "viewings" : "eyeoff"}
              onClick={() =>
                setIsVisibleDownpaymentId(!isVisibleDownpaymentId)
              }
              onChange={(name, value) => {
                setDownpaymentIdData({
                  ...downpaymentIdData,
                  value: value,
                });
                setDownpaymentIdDataIsUpdated(false);
              }}
            />
            {downpaymentIdData?.value && (
              <div className="">
                <FormInput
                  labelName={" Confirm Downpayment ID"}
                  placeholder={"Confirm Downpayment ID"}
                  value={confirmDownpaymentIdData}
                  type={confirmVisibleDownpaymentId ? "text" : "password"}
                  righticonname={
                    confirmVisibleDownpaymentId ? "viewings" : "eyeoff"
                  }
                  onClick={() =>
                    setConfirmVisibleDownpaymentId(!confirmVisibleDownpaymentId)
                  }
                  onChange={(name, value) => {
                    setConfirmDownpaymentIdData(value);
                    setDownpaymentIdDataIsUpdated(false);
                  }}
                />
                {downpaymentIdData?.value === confirmDownpaymentIdData && (
                  <div className="ms-2">
                    {downpaymentIdDataIsUpdated ? (
                      <p
                        className="fw-bold m-0 position-absolute"
                        style={{ color: colors.darkGreen, bottom: "-0.5rem" }}
                      >
                        Submitted
                      </p>
                    ) : (
                      <Button
                        type={"button"}
                        btnText={"Submit"}
                        width={"5.5rem"}
                        height={"2rem"}
                        isLoading={createRemitaConfiguration.isLoading}
                        disabled={createRemitaConfiguration.isLoading}
                        padding={"0 10px"}
                        borderWidth={"4px"}
                        spinnerWidth={"1rem"}
                        spinnerHeight={"1rem"}
                        onBtnClick={() =>
                          createRemitaConfiguration.mutate(downpaymentIdData, {
                            onSuccess: () => {
                              setDownpaymentIdDataIsUpdated(true);
                            },
                          })
                        }
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="position-relative col-md-4 mb-3">
            <FormInput
              labelName={" Applicable Fees ID"}
              placeholder={"Enter Applicable Fees ID"}
              value={applicableFessIdData?.value}
              type={isVisibleApplicableFessId ? "text" : "password"}
              righticonname={isVisibleApplicableFessId ? "viewings" : "eyeoff"}
              onClick={() =>
                setIsVisibleApplicableFessId(!isVisibleApplicableFessId)
              }
              onChange={(name, value) => {
                setApplicableFessIdData({
                  ...applicableFessIdData,
                  value: value,
                });
                setApplicableFessIdDataIsUpdated(false);
              }}
            />
            {applicableFessIdData?.value && (
              <div className="">
                <FormInput
                  labelName={" Confirm Applicable Fees ID"}
                  placeholder={"Confirm Applicable Fees ID"}
                  value={confirmApplicableFessIdData}
                  type={confirmVisibleApplicableFessId ? "text" : "password"}
                  righticonname={
                    confirmVisibleApplicableFessId ? "viewings" : "eyeoff"
                  }
                  onClick={() =>
                    setConfirmVisibleApplicableFessId(!confirmVisibleApplicableFessId)
                  }
                  onChange={(name, value) => {
                    setConfirmApplicableFessIdData(value);
                    setApplicableFessIdDataIsUpdated(false);
                  }}
                />
                {applicableFessIdData?.value ===
                  confirmApplicableFessIdData && (
                  <div className="ms-2">
                    {applicableFessIdDataIsUpdated ? (
                      <p
                        className="fw-bold m-0 position-absolute"
                        style={{ color: colors.darkGreen, bottom: "-0.5rem" }}
                      >
                        Submitted
                      </p>
                    ) : (
                      <Button
                        type={"button"}
                        btnText={"Submit"}
                        width={"5.5rem"}
                        height={"2rem"}
                        isLoading={createRemitaConfiguration.isLoading}
                        disabled={createRemitaConfiguration.isLoading}
                        padding={"0 10px"}
                        borderWidth={"4px"}
                        spinnerWidth={"1rem"}
                        spinnerHeight={"1rem"}
                        onBtnClick={() =>
                          createRemitaConfiguration.mutate(
                            applicableFessIdData,
                            {
                              onSuccess: () => {
                                setApplicableFessIdDataIsUpdated(true);
                              },
                            }
                          )
                        }
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>

          <div className="position-relative col-md-4 mb-3">
            <FormInput
              labelName={" Repayment ID"}
              placeholder={"Enter Repayment ID"}
              value={repaymentIdData?.value}
              type={isVisibleRepaymentId ? "text" : "password"}
              righticonname={isVisibleRepaymentId ? "viewings" : "eyeoff"}
              onClick={() =>
                setIsVisibleRepaymentId(!isVisibleRepaymentId)
              }
              onChange={(name, value) => {
                setRepaymentIdData({
                  ...repaymentIdData,
                  value: value,
                });
                setRepaymentIdDataIsUpdated(false);
              }}
            />
            {repaymentIdData?.value && (
              <div className="">
                <FormInput
                  labelName={" Confirm Repayment ID"}
                  placeholder={"Confirm Repayment ID"}
                  value={confirmRepaymentIdData}
                  type={confirmVisibleRepaymentId ? "text" : "password"}
                  righticonname={
                    confirmVisibleRepaymentId ? "viewings" : "eyeoff"
                  }
                  onClick={() =>
                    setConfirmVisibleRepaymentId(!confirmVisibleRepaymentId)
                  }
                  onChange={(name, value) => {
                    setConfirmRepaymentIdData(value);
                    setRepaymentIdDataIsUpdated(false);
                  }}
                />
                {repaymentIdData?.value === confirmRepaymentIdData && (
                  <div className="ms-2">
                    {repaymentIdDataIsUpdated ? (
                      <p
                        className="fw-bold m-0 position-absolute"
                        style={{ color: colors.darkGreen, bottom: "-0.5rem" }}
                      >
                        Submitted
                      </p>
                    ) : (
                      <Button
                        type={"button"}
                        btnText={"Submit"}
                        width={"5.5rem"}
                        height={"2rem"}
                        isLoading={createRemitaConfiguration.isLoading}
                        disabled={createRemitaConfiguration.isLoading}
                        padding={"0 10px"}
                        borderWidth={"4px"}
                        spinnerWidth={"1rem"}
                        spinnerHeight={"1rem"}
                        onBtnClick={() =>
                          createRemitaConfiguration.mutate(repaymentIdData, {
                            onSuccess: () => {
                              setRepaymentIdDataIsUpdated(true);
                            },
                          })
                        }
                      />
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <p className="my-4">
        Service types indicate the different possible payments a customer can
        make to you. These service types are created on the Remita Platform and
        inputted into your Giddaa dashboard in the field above. Each of these
        payments will have a unique identification. This unique identifier is
        called an ID. Through this ID, we are able to identify your organziation
        and the customer is able to successfully and directly pay you.
      </p>
    </form>
  );
};

export default Payments;
