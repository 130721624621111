import React, { useEffect, useRef } from "react";
import Button from "../Button";
import { colors } from "../../../utils/constants";
import { ModalWrapper } from "./style";
import ReactDOM from "react-dom";
import Icons from "../Icons";

const Modal = ({
  show = false,
  closeModal,
  title,
  subTitle,
  cancelModal,
  confirmModal,
  cancelButtonLabel,
  confirmButtonLabel,
  children,
  width,
  height,
  btnWidth,
  btnHeight,
  wide = false,
  component,
  isBtnLoading,
  disabled,
  closeModalIcon,
  crossLine,
  outSideToClose = true,
  cancelBtn,
  backBtn,
  onBtnClick,
  ...rest
}) => {
  const ref = useRef();
  const closeModalWindow = () => {
    if (cancelBtn && outSideToClose === false) {
      cancelBtn();
      return;
    }
    closeModal(false);
  };

  const clickOutside = (e) => {
    if (!ref.current.contains(e.target)) {
      closeModalWindow();
    }
  };

  return ReactDOM.createPortal(
    <ModalWrapper
      width={width}
      height={height}
      $wide={wide}
      {...rest}
      onClick={clickOutside}
    >
      <div className={show ? "modal fade show" : "modal fade"}>
        <div
          className={`mmodal-dialog modal-dialog-scrollable ${
            wide ? "modal-fullscreen" : "modal-dialog-centered"
          }`}
        >
          <div className="modal-content" ref={ref}>
            <div className="modal-body">
              <div className="row position-realative">
                {backBtn && (
                  <div className="col-1 modal-back-btn g-0">
                    <Button
                      lefticonname={"leftArrow"}
                      btnText={"Back"}
                      onBtnClick={onBtnClick}
                      borderradius={"10"}
                      width={"6rem"}
                      padding={"8px 2px"}
                      iconcolor={colors.primary}
                      btntextcolor={colors.primary}
                      backgroundcolor={colors.white}
                      iconcolorHover={colors.hover}
                      colorHover={colors.hover}
                      borderHover={colors.hover}
                      backgroundHover={colors.white}
                    />
                  </div>
                )}
                <div className="col-12">
                  {component ? (
                    component
                  ) : (
                    <h2 className="text-center title">{title}</h2>
                  )}
                </div>
                {closeModalIcon && (
                  <div
                    className="col-1 close-btn g-0"
                    onClick={() => closeModal(false)}
                  >
                    <Icons iconName={"close"} />
                  </div>
                )}
              </div>
              <hr className="my-3"></hr>
              {subTitle && (
                <h6 className="text-center subtitle mb-3"> {subTitle}</h6>
              )}
              {children && <div className="children" style={{height: "90%"}}>{children}</div>}
            </div>
            <div className="modal-footer d-flex justify-content-center align-items-center gap-4 mt-2">
              {cancelButtonLabel && (
                <Button
                  type={"button"}
                  btnText={cancelButtonLabel}
                  onBtnClick={cancelModal ?? closeModalWindow}
                  width={btnWidth}
                  height={btnHeight}
                  btntextcolor={colors.primary}
                  backgroundcolor={colors.white}
                  colorHover={colors.hover}
                  borderHover={colors.hover}
                  backgroundHover={colors.white}
                />
              )}
              {confirmButtonLabel && (
                <Button
                  type={"button"}
                  btnText={confirmButtonLabel}
                  onBtnClick={confirmModal}
                  isLoading={isBtnLoading}
                  disabled={disabled}
                  width={btnWidth}
                  height={btnHeight}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalWrapper>,
    document.getElementById("portal")
  );
};

export default Modal;
