import Button from "../../../Components/Common/Button";
import Icons from "../../../Components/Common/Icons";
import { colors } from "../../../utils/constants";
import { HiDotsVertical } from "react-icons/hi";
import Dropdown from "react-bootstrap/Dropdown";
import { convertToTitleCase } from "../../../utils/constants";
import CellSelector from "../../../Components/Common/CellSelector";

export const defaultImage =
  "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aG91c2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60";

export const teamColumns = (handleAction, loading) => [
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        TEAM MEMBER
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {
          <>
            {row.isAViewingAgent && (
              <div className="">
                <span
                  className="badge btn-sm py-1 mb-1"
                  style={{
                    color: colors.darkGreen,
                    backgroundColor: colors.lightGreen,
                    borderRadius: "100px",
                  }}
                >
                  Inspection Agent
                </span>
              </div>
            )}
            <>
              {row.firstName === "pending" ? (
                <p
                  className="badge btn-sm py-1 mb-1"
                  style={{
                    color: colors.darkOrange,
                    backgroundColor: colors.orange,
                    borderRadius: "100px",
                  }}
                >
                  Pending
                </p>
              ) : (
                row.name
              )}
            </>
          </>
        }
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        {" "}
        EMAIL{" "}
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        <>
          {row.isContactStaff && (
            <div className="">
              <span
                className="badge btn-sm py-1 mb-1"
                style={{
                  color: colors.darkGreen,
                  backgroundColor: colors.lightGreen,
                  borderRadius: "100px",
                }}
              >
                Contact Staff
              </span>
            </div>
          )}
        </>
        {row.email || "--"}
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        {" "}
        ROLE{" "}
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {convertToTitleCase(row.applicationRole.name) || "--"}
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        {" "}
        PHONE NUMBER{" "}
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {row.firstName === "pending" ? (
          <p
            className="badge btn-sm py-1 mb-1"
            style={{
              color: colors.darkOrange,
              backgroundColor: colors.orange,
              borderRadius: "100px",
            }}
          >
            Pending
          </p>
        ) : (
          row.phoneNumber
        )}
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        {" "}
        STATUS{" "}
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {row.firstName === "pending" ? (
          <p
            className="badge btn-sm py-1 mb-1"
            style={{
              color: colors.darkOrange,
              backgroundColor: colors.orange,

              borderRadius: "100px",
            }}
          >
            Yet to Join
          </p>
        ) : (
          <p
            className="badge btn-sm py-1 mb-1"
            style={{
              color: colors.darkGreen,
              backgroundColor: colors.lightGreen,
              borderRadius: "100px",
            }}
          >
            Joined
          </p>
        )}
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        {" "}
        ACTION{" "}
      </p>
    ),
    sortable: false,
    grow: 0.5,
    center: true,
    cell: (row) => (
      <Dropdown>
        <Dropdown.Toggle id={`dropdown-${row.id}`}>
          <HiDotsVertical className="fw-bold text-dark" />
        </Dropdown.Toggle>

        {!loading && (
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => handleAction(row.id, "changeRole")}>
              Change Role
            </Dropdown.Item>

            {row?.firstName !== "pending" &&
              (row.isAViewingAgent ? (
                <Dropdown.Item onClick={() => handleAction(row.id, "remove")}>
                  Remove as Inspection agent
                </Dropdown.Item>
              ) : (
                <Dropdown.Item onClick={() => handleAction(row.id, "add")}>
                  Add as Inspection agent
                </Dropdown.Item>
              ))}

            {row?.firstName !== "pending" && (
              <Dropdown.Item onClick={() => handleAction(row.id, "staff")}>
                Add as Contact Staff
              </Dropdown.Item>
            )}

            <Dropdown.Item
              className="text-danger"
              onClick={() => handleAction(row.id, "delete")}
            >
              Delete
            </Dropdown.Item>
          </Dropdown.Menu>
        )}
      </Dropdown>
    ),
  },
];
