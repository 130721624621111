import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useDeleteConstructionUpdate = (houseId, constructionId) => {
  const { deleteRequest } = useAxiosBase();

  const deleteConstructionUpdate = useMutation({
    mutationKey: ["delete-construction", constructionId],
    mutationFn: (data) =>
      deleteRequest({
        url: `/developer/house/${houseId}/construction-update/${constructionId}/delete`,
        data: data,
      }),
      onSuccess: (res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success("Deleted successfully!");
        } else {
          toast.error(res?.response?.data?.value?.message || "An error occurred");
        }
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "An error occurred");
      },
  });

  return { deleteConstructionUpdate };
};

export default useDeleteConstructionUpdate;

