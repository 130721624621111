import { toApplicationNumber } from "../../utils/generalUtils";
import {
  colors,
  convertToTitleCase,
  formattedPrice,
} from "../../utils/constants";
import { Dropdown } from "react-bootstrap";
import { HiDotsVertical } from "react-icons/hi";
import { asCurrency } from "../Applications/utils/constants";
import CellSelector from "../../Components/Common/CellSelector";

const defaultImage =
  "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aG91c2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60";

export const customerColums = (loading) => [
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        CUSTOMER
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {row.customer?.firstName} {row.customer?.lastName}
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PROPERTY
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {/* <>
          <span className="fw-semibold">
            {convertToTitleCase(row?.house?.type)}
          </span>{" "}
          <span className="circle_bar"></span> {row?.house?.address} {row.city}
        </> */}
        {row.house?.name ?? "---"}
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PLAN TYPE
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row.mortgagePlan?.name}</CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        HANDLING STAFF
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {row.handler?.firstName} {row.handler?.lastName}
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        CONVERSION PERIOD
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row.conversionPeriod}</CellSelector>
    ),
  },
];

export const successfulTransactionsColumn = (handleButtonClick, loading) => [
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        ID
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {toApplicationNumber(row.application?.applicationId)}
      </CellSelector>
    ),
    grow: 0.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        CUSTOMER
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {row.customer?.firstName} {row.customer?.lastName}
      </CellSelector>
    ),
    grow: 1.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        TOTAL PAID
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        <>
          ₦{formattedPrice(row.amount)}
          <p
            className="m-0"
            style={{ fontSize: "11px", color: colors.darkGreen }}
          >
            {" "}
            ({(row.amount / row.amount) * 100}%)
          </p>
        </>
      </CellSelector>
    ),
    wrap: true,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        EARNINGS
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        <>
          ₦{formattedPrice(row.organizationAmount)}
          <p
            className="m-0"
            style={{ fontSize: "11px", color: colors.darkGreen }}
          >
            {" "}
            ({(row.organizationAmount / row.amount) * 100}%)
          </p>
        </>
      </CellSelector>
    ),
    wrap: true,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        GIDDAA
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        <>
          ₦{formattedPrice(row.giddaaAmount)}
          <p
            className="m-0"
            style={{ fontSize: "11px", color: colors.darkGreen }}
          >
            {" "}
            ({(row.giddaaAmount / row.amount) * 100}%)
          </p>
        </>
      </CellSelector>
    ),
    wrap: true,
    grow: 1.2,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        TYPE
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {convertToTitleCase(row.transactionType)}
      </CellSelector>
    ),
    wrap: true,
    grow: 1,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PROPERTY
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row.house?.name ?? "---"}</CellSelector>
    ),
    wrap: true,
    grow: 2.1,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PLAN
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {row.mortgagePlan?.name ?? "---"}
      </CellSelector>
    ),
    wrap: true,
    grow: 1.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PAYMENT DATE
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {row.dueDate ? new Date(row.dueDate).toDateString() : "--"}
      </CellSelector>
    ),
    wrap: true,
    grow: 1.5,
  },
  {
    name: <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}></p>,
    sortable: false,
    grow: 0.5,
    center: true,
    cell: (row) => (
      <Dropdown>
        <Dropdown.Toggle
          id={`dropdown-${row.id}`}
        >
          <HiDotsVertical className="fw-bold text-dark" />
        </Dropdown.Toggle>

        {!loading && (
          <Dropdown.Menu>
            {row.status >= "REVIEW_APPROVED" ||
            row?.status === "REVIEW_REJECTED" ? (
              <Dropdown.Item
                onClick={() => handleButtonClick(row.applicationId)}
              >
                View Receipt
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                onClick={() => handleButtonClick(row.applicationId)}
              >
                Download Receipt
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        )}
      </Dropdown>
    ),
  },
];

export const expectedTransactionsColumn = (handleOmit, handleButtonClick) => [
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        ID
      </p>
    ),
    sortable: true,
    selector: (row) => `${toApplicationNumber(row.application?.applicationId)}`,
    grow: 0.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        CUSTOMER
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => `${row.customer?.firstName} ${row.customer?.lastName}`,
    grow: 1.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        AMOUNT DUE
      </p>
    ),
    sortable: true,
    selector: (row) => (row.amount ? asCurrency(row.amount, "NGN") : "---"),
    wrap: true,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        TYPE
      </p>
    ),
    sortable: true,
    selector: (row) => convertToTitleCase(row.transactionType) ?? "---",
    wrap: true,
    grow: 1.2,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PROPERTY
      </p>
    ),
    sortable: true,
    // selector: (row) => `${row.house?.cityName}, ${row.house?.stateName}`,
    selector: (row) => row.house?.name ?? "---",
    wrap: true,
    grow: 2.1,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        NEXT PAYMENT
      </p>
    ),
    sortable: true,
    selector: (row) =>
      row.dueDate ? new Date(row.dueDate).toDateString() : "--",
    wrap: true,
    grow: 1.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PAYMENT TRACKER
      </p>
    ),
    sortable: true,
    selector: (row) => row.paymentTracker,
    wrap: true,
    grow: 1.5,
  },
  {
    name: <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}></p>,
    sortable: false,
    grow: 0.5,
    center: true,
    cell: (row) => (
      <Dropdown>
        <Dropdown.Toggle
          id={`dropdown-${row.id}`}
        >
          <HiDotsVertical className="fw-bold text-dark" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item onClick={() => handleButtonClick(row.applicationId)}>
            View Repayment Schedule
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
];

export const customerData = [
  {
    id: 1234567843,
    images: [{ name: "name", base64: defaultImage }],
    customerName: "Joseph Kalu",
    property: "Bungalow - No 23 Joseph Waku Street, Gwarimpa, Abuja.",
    mortgageType: "NHF",
    handlingStaff: "Obinna Nnamani",
    conversionPeriod: "20 Days",
  },
  {
    id: 6890781283,
    images: [{ name: "name", base64: defaultImage }],
    customerName: "James Ngutor",
    property: "-",
    mortgageType: "Diaspora",
    handlingStaff: "Christie Stuart-Little",
    conversionPeriod: "5 Days",
  },

  {
    id: 9885571235,
    images: [{ name: "name", base64: defaultImage }],
    customerName: "Sarah Hash",
    property:
      "Semi Detached Bungalow - 13, Aminu Kano Crescent, Wuse 2, Abuja.",
    mortgageType: "-",
    handlingStaff: "John Nana - Koffi",
    conversionPeriod: "40 Days",
  },
  {
    id: 1234567843,
    images: [{ name: "name", base64: defaultImage }],
    customerName: "Martin Lawrence",
    property: "Bungalow - No 23 Joseph Waku Street, Gwarimpa, Abuja.",
    mortgageType: "NHF",
    handlingStaff: "Obinna Nnamani",
    conversionPeriod: "20 Days",
  },
];

export const repaymentColumn = (handleButtonClick) => [
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        MONTH
      </p>
    ),
    sortable: true,
    center: true,
    grow: 1.1,
    selector: (row) => {
      let pillComponent = null;

      if (row.isPaid) {
        pillComponent = <p className="m-0 greenSuccessPill">Paid</p>;
      } else if (!row.isPaid && new Date(row.dueDate) < new Date()) {
        pillComponent = <p className="m-0 redDangerPill">Missed</p>;
      } else if (!row.isPaid && new Date(row.dueDate) > new Date()) {
        pillComponent = <p className="m-0 yellowPendingPill">Upcoming</p>;
      }

      return (
        <>
          {pillComponent}
          <p className="m-0">{row.month}</p>
        </>
      );
    },
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        DUE DATE
      </p>
    ),
    sortable: true,
    selector: (row) =>
      row.dueDate ? new Date(row.dueDate).toDateString() : "--",
    wrap: true,
    grow: 1.2,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        START BALANCE
      </p>
    ),
    sortable: true,
    selector: (row) => `₦ ${formattedPrice(row.beginningBalance.toFixed())}`,
    wrap: true,
    grow: 1.2,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        MONTHLY PAYMENT
      </p>
    ),
    sortable: true,
    selector: (row) => `₦ ${formattedPrice(row.pmt.toFixed())}`,
    wrap: true,
    grow: 2.1,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        MONTHLY INTEREST
      </p>
    ),
    sortable: true,
    selector: (row) => `₦ ${formattedPrice(row.ipmt.toFixed())}`,
    wrap: true,
    grow: 1.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        MONTHLY PRINCIPAL
      </p>
    ),
    sortable: true,
    selector: (row) => `₦ ${formattedPrice(row.ppmt.toFixed())}`,
    wrap: true,
    grow: 1.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        ENDING BALANCE
      </p>
    ),
    sortable: true,
    selector: (row) => `₦ ${formattedPrice(row.endingBalance.toFixed())}`,
    wrap: true,
    grow: 1.5,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        ACTIONS
      </p>
    ),
    sortable: false,
    grow: 0.5,
    center: true,
    cell: (row) => (
      <Dropdown>
        <Dropdown.Toggle
          id={`dropdown-${row.id}`}
        >
          <HiDotsVertical className="fw-bold text-dark" />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {!row.isPaid && new Date(row.dueDate) < new Date() && (
            <Dropdown.Item
              onClick={() => handleButtonClick(row.transactionId, "warn")} >
              Send Warning
            </Dropdown.Item>
          )}

          {!row.isPaid && new Date(row.dueDate) > new Date() && (
            <Dropdown.Item
              onClick={() => handleButtonClick(row.transactionId, "remind")} >
              Remind Customer
            </Dropdown.Item>
          )}
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
];
