import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";

const useGetSingleBank = (bankId) => {
  const { getRequest } = useAxiosBase();
  return useQuery({
    queryKey: ["singleBank"],
    queryFn: () =>
      getRequest({
        url: `developer/partnership/get-organization/${bankId}`,
      }).then((res) => res.data?.value),
    staleTime: 50000,
  });
};
export default useGetSingleBank;