import React, { useState, useEffect } from "react";
import FormInput from "../../Components/Common/FormInput";
import Dropdown from "../../Components/Common/Dropdown";
import Button from "../../Components/Common/Button";
import { colors } from "../../utils/constants";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ImageWrapper,
  LandingContainer,
} from "../../Components/Common/StyledComponents/style";
import image from "../../assets/images/signup.jpg";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import modelSchema from "./models/inviteTeamMemberModel";
import VerifyingLoader from "../../Components/Common/VerifyingLoader";
import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../hooks/useAxiosBase";
import useCompleteRegistration from "./hooks/useCompleteRegistration";
import useAuth from "../../hooks/useAuth";

const InviteTeamMember = () => {
  const { authUser } = useAuth();
  const orgName = authUser?.user?.organization?.name;

  const location = useLocation();
  const key = new URLSearchParams(location.search).get("key");
  const code = new URLSearchParams(location.search).get("code");
  const email = new URLSearchParams(location.search).get("email");
  const { postRequest } = useAxiosBase();
  const navigate = useNavigate();

  const { completeRegistration, isLoading } = useCompleteRegistration();

  const [showVerifying, setShowVerifying] = useState(true);

  const [organizationName, setOrganizationName] = useState();
  const [applicationUserId, setApplicationUserId] = useState();
  const [organizationId, setOrganizationId] = useState();
  const [roleId, setRoleId] = useState();

  const verifyTeamMember = useMutation({
    mutationFn: (data) =>
      postRequest({
        url: "/account/verify-link",
        data: data,
      }).then((res) => {
        if ([400, 401, 413, 404].includes(res?.response?.data?.statusCode)) {
          toast.error("Unauthorized");
          setTimeout(() => navigate("/account/login"), 1000);
          return;
        }
        if (res?.data?.value?.statusCode === 200) {
          setRoleId(res.data?.value?.value?.roleId);
          setApplicationUserId(res.data?.value?.value?.id);
          setOrganizationId(res.data?.value?.value?.organizationId);
          setOrganizationName(res.data?.value?.value?.organizationName);
          setShowVerifying(false);
          return;
        }
        return res.data;
      }),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(modelSchema) });

  const onSubmit = (data) => {
    const dob = new Date(data.dateOfBirth);
    const formattedDateOfBirth = dob.toLocaleDateString("en-US", {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
    });

    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    const hasBirthdayOccurred =
      today.getMonth() > dob.getMonth() ||
      (today.getMonth() === dob.getMonth() && today.getDate() >= dob.getDate());

    if (!hasBirthdayOccurred) {
      age--;
    }

    data.age = age;
    data.roleId = roleId;
    data.dateOfBirth = formattedDateOfBirth;
    data.organizationId = organizationId;
    data.applicationUserId = applicationUserId;
    data.gender = data.gender.charAt(0).toUpperCase() + data.gender.slice(1);

    completeRegistration.mutate(data);
  };

  const [isVisible, setIsVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const togglePassword = () => {
    setIsVisible(!isVisible);
  };
  const toggleConfirmPassword = () => {
    setConfirmVisible(!confirmVisible);
  };

  useEffect(() => {
    document.title = `Giddaa - ${orgName} - Complete Registration`;
  }, []);

  useEffect(() => {
    if (key && code && email)
      verifyTeamMember.mutate({
        key: key,
        code: code,
        emailAddress: email,
      });
  }, []);

  useEffect(() => {
    document.title = `Giddaa - ${orgName} - Complete Registration`;
  }, []);

  return (
    <LandingContainer>
      {showVerifying && <VerifyingLoader title={"Verifying Link"} />}
      <div className="container-fluid op">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-1 ps-0 ms-0 d-none d-md-flex img-container">
            <ImageWrapper>
              <img className="img-fluid" src={image} alt="imaget" />
            </ImageWrapper>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 d-md-flex d-sm-flex align-items-sm-center ps-sm-0 ps-md-0 justify-content-md-center pe-md-0 pe-ms-0 form-container">
            <div className="row d-flex flex-column py-md-3 ps-md-2 pe-md-4 p-s-0">
              <div className="d-flex flex-column flex-start pe-md-5 pe-md-0">
                <img
                  src="https://s3.amazonaws.com/appforest_uf/f1675031132769x885606220423525500/GiddaaLogoGreen.svg"
                  alt="logo"
                  width={110}
                  height={90}
                />
                <h1 className="pt-3 text-md-start text-sm-center">
                  {organizationName
                    ? organizationName
                    : "Welcome to Giddaa for developers"}
                </h1>
                <p className="">
                  👋🏼 Hello. You were invited to {organizationName ?? '" "'} on
                  Giddaa. Create your account to join your organization. Giddaa
                  helps developers sell properties to Nigerians at home and
                  abroad.
                </p>
              </div>
              <div className="form-wrapper py-2 pe-md-3 mb-4">
                <form onSubmit={handleSubmit(onSubmit)} className="row">
                  <div className="col-md-6">
                    <FormInput
                      required
                      labelName={"First Name"}
                      placeholder={"eg. Paul"}
                      lefticonname={"user"}
                      register={register("firstName")}
                      name="firstName"
                      error={errors?.firstName?.message}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormInput
                      required
                      labelName={"Last Name"}
                      placeholder={"eg. Obuba"}
                      lefticonname={"user"}
                      name="lastName"
                      register={register("lastName")}
                      error={errors?.lastName?.message}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormInput
                      required
                      type={"email"}
                      labelName={"Email"}
                      value={email}
                      lefticonname={"envolope"}
                      name="email"
                      register={register("email")}
                      error={errors?.email?.message}
                      placeholder={"eg. paul@gmail.com"}
                      hoverBackgroundcolor={colors.grey}
                      readOnly
                    />
                  </div>
                  <div className="col-md-6">
                    <FormInput
                      type={"tel"}
                      labelName={"Phone Number"}
                      lefticonname={"phone"}
                      name="phoneNumber"
                      register={register("phoneNumber")}
                      error={errors?.phoneNumber?.message}
                      placeholder={"eg. 09014513409"}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormInput
                      required
                      type={"date"}
                      labelName={"Date of birth"}
                      lefticonname={"envolope"}
                      name="dateOfBirth"
                      register={register("dateOfBirth")}
                      error={errors?.dateOfBirth?.message}
                    />
                  </div>
                  <div className="col-md-6">
                    <Dropdown
                      type={"text"}
                      required
                      labelName={"Gender"}
                      lefticonname={"phone"}
                      options={[
                        {
                          id: "Male",
                          name: "Male",
                        },
                        {
                          id: "Female",
                          name: "Female",
                        },
                      ]}
                      placeholder={"eg. male"}
                      name="gender"
                      register={register("gender")}
                      error={errors?.gender?.message}
                      onChange={(e) => setValue("gender", e.target.value)}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormInput
                      required
                      labelName={"Password"}
                      lefticonname={"password"}
                      name="password"
                      register={register("password")}
                      error={errors?.password?.message}
                      type={isVisible ? "text" : "password"}
                      righticonname={isVisible ? "viewings" : "eyeoff"}
                      placeholder={"Paul1234$"}
                      onClick={() => togglePassword()}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormInput
                      required
                      labelName={"Confrim Password"}
                      lefticonname={"password"}
                      register={register("confirmPassword")}
                      error={errors?.confirmPassword?.message}
                      type={confirmVisible ? "text" : "password"}
                      righticonname={confirmVisible ? "viewings" : "eyeoff"}
                      placeholder={"Paul1234$"}
                      onClick={() => toggleConfirmPassword()}
                    />
                  </div>
                  <div className="col-12 d-flex justify-content-center mt-3">
                    <Button
                      type={"submit"}
                      btnText={"Create Account"}
                      isLoading={completeRegistration.isLoading}
                      disabled={completeRegistration.isLoading}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </LandingContainer>
  );
};

export default InviteTeamMember;
