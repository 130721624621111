import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useAddContactStaff = (applicationUserId, status) => {
  const { getRequest } = useAxiosBase();

  const addContactStaff = useMutation({
    mutationKey: ["contact-staff", applicationUserId],
    mutationFn: (data) =>
      getRequest({
        url: `/developer/user/${applicationUserId}/update-as-contact-staff?status=${status}`,
        data: data,
      }),
      onSuccess: (res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success(res?.data?.value?.message);
        } else {
          toast.error(res?.response?.data?.value?.message || "An error occurred");
        }
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "An error occurred");
      },
  });

  return { addContactStaff };
};

export default useAddContactStaff;