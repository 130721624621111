import React from "react";
import "./index.css";
import { colors } from "../../../../../utils/constants";
import CheckInput from "../../../../../Components/Common/CheckInput";

const PlanAccessibilityCard = ({
  name,
  label,
  checked,
  handler,
  title,
  description,
}) => {
  return (
    <div className="card_container">
      <div className="d-flex align-items-center gap-2 px-3 py-2">
        <div className="">
          <CheckInput
            name={name}
            checked={checked}
            checkTick={true}
            label={label}
            width={"23px"}
            height={"23px"}
            bcolor={"#0000001A"}
            bordercolor={"#CECECE"}
            onChange={(e) => handler(e)}
          />
        </div>
        <p className="card_title m-0" style={{ color: colors.darkGreen }}>
          {title}
        </p>
      </div>
      <p className="card_description">{description}</p>
    </div>
  );
};

export default PlanAccessibilityCard;
