import styled from "styled-components";
import { colors } from "../../../utils/constants";

export const WizzardContainer = styled.div`
  margin: 0px auto;
  padding: 20px;
  max-width: 100% !important;
  max-height: 100%;
  min-height: 100vh;
  /* border: 5px solid red; */

  .content {
    margin: 10px;
  }
  position: relative;
`;

export const WizzardHeader = styled.div`
  border: 1px solid green;
  border-radius: 50px;
  background: white;
  right: 0;
  overflow: hidden;
  max-width: 65% !important;
  margin: auto;

  .title-header {
    color: ${colors.darkGreen};
    font-size: 15px;
    font-family: "Millik", sans-serif;
    font-weight: 300;
    text-align: center;
    padding-top: 15px;
  }

  .title-subheader {
    font-size: 13px;
    font-family: "Red Hat Display", sans-serif;
    font-weight: 500;
    color: ${colors.darkGray};
  }

  .active {
    background: ${colors.darkGreen} !important;
    color: ${colors.white + " !important"};
  }

  .active > .title-header {
    color: ${colors.white + " !important"};
  }

  .active > .title-subheader {
    color: ${colors.white + " !important"};
  }
`;

export const WizzardFooter = styled.div`
  background: ${colors.grey};
  position: fixed;
  width: 100%;
  /* height: 17vh; */
  bottom: 0;
  right: 0;
  padding: 1.5rem;
  z-index: 5;
`;

export const WizzardBody = styled.div`
  margin: 18px auto;
  margin-bottom: 8rem;
  .sub-title {
    color: ${colors.darkGreen};
  }
  .color-picker {
    width: 127px;
    height: 100%;
  }
  .bank-list-container {
    height: 60%;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .bank_img {
    width: 70px;
    height: 60px;
    border-radius: 10px;
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  }

  .bank-name {
    font-size: 16px;
    font-family: "Red Hat Display", sans-serif;
    font-weight: bold;
    text-align: center;
  }

  // .form-content {
  //   padding-bottom: 30px;
  // }

  .bank-radio {
    width: 22px;
    height: 22px;
  }

  .bank-list-wrapper,
  .con {
    border-bottom: 0.5px solid #eae8e8c6;
  }

  .doc_upload_label,
  .required {
    font-size: 14px;
    font-weight: 900;
    font-family: "Red Hat Display", sans-serif;
  }

  .doc_upload_label > .required {
    color: ${colors.red};
  }
  .doc_uploaded {
    font-size: 14px;
    font-family: "Red Hat Display", sans-serif;
    color: ${colors.darkGrey};
  }

  .file_upload {
    display: none;
  }

  //Select Input
  .form_select {
    width: ${(props) =>
      props.width ? `${props.width}px` : "-webkit-fill-available"};
    height: ${(props) => (props.height ? `${props.height}px` : "60px")};
    border: 1px solid gray;
    border-radius: 100px;
    font-size: 16px;
    font-weight: 500;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 999;
  }

  .form_label {
    font: normal normal bold 16px "Red Hat Display";
  }

  .company_logo_wrapper {
    span {
      color: ${colors.green};
      font-size: 14px;
      font-weight: 500;
      font-family: "Red Hat Display", sans-serif;
      padding: 3px 0 0 8px;
      cursor: pointer;
    }
    input {
      display: none;
    }
  }

  .company_logo {
    display: flex;
    justify-content: center;
    align-items: center;
    border: ${`1px solid ${colors.lightGrey}`};
    border-radius: 50%;
    width: 92px;
    height: 92px;

    img {
      width: 90px;
      height: 90px;
      border-radius: 50%;
    }

    svg {
      width: 40px;
      height: 40px;
      fill: ${colors.veryLightGrey};
    }

    cursor: pointer;
  }

  .color-pallete {
    height: 88px;
    input {
      padding: 5px;
    }
  }

  .form-error {
    position: absolute;
    left: 0;
    bottom: -1.5rem;

    padding: 0 16px 0 8px;
    font-family: "Red Hat Display";
    font-size: 13px;
    font-weight: 500;
    color: ${colors.red};
  }
`;
