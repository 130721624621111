import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useDeleteTeam = (teamMemberId) => {
  const { deleteRequest } = useAxiosBase();

  const deleteTeamMember = useMutation({
    mutationKey: ["update-team", teamMemberId],
    mutationFn: (data) =>
      deleteRequest({
        url: `/developer/user/${teamMemberId}/delete`,
        data: data,
      }).then((res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success("Updated Successfully!");
        }
        if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
          toast.error(res?.data?.value?.message);
        }
      }),
    onSuccess: (res, res1) => {
      if (res?.data?.value?.value?.statusCode === 200) {
        toast.success("Updated Successfully!");
      }
      if ([400, 404, 500].includes(res?.data?.value?.value?.statusCode)) {
        toast.error(res?.data?.value?.value?.message);
      }
    },
    onError: (error) => {
      toast.error(error);
    },
  });

  return { deleteTeamMember };
};

export default useDeleteTeam;
