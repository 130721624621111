import React from "react";
import Button from "../Button";
import { colors } from "../../../utils/constants";
import { Wrapper } from "./style";
import LargeLoader from "../LargeLoader";

const FooterButtons = ({
  loading,
  saveLabel,
  cancelLabel,
  saveBtn,
  cancelBtn,
  className,
  handleClick,
  disabled = false,
  disableCancelBtn = false,
  largeLoader = false,
  ...rest
}) => {
  return (
    <>
    {largeLoader && <LargeLoader />}
      <Wrapper {...rest}>
        <div className="footerButtons">
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="p-3">
              <Button
                btnText={cancelLabel ?? "Cancel"}
                type="button"
                onBtnClick={cancelBtn}
                btntextcolor={colors.primary}
                backgroundcolor={colors.white}
                colorHover={colors.hover}
                borderHover={colors.hover}
                backgroundHover={colors.white}
                disabled={disableCancelBtn}
              />
            </div>
            <div className="p-3">
              <Button
                btnText={saveLabel ?? "Save"}
                onBtnClick={handleClick}
                type="submit"
                isLoading={loading}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
};

export default FooterButtons;
