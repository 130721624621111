import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import useAuth from "../../../hooks/useAuth";

const useGetBankPartnershipTerms = () => {
  const { getRequest } = useAxiosBase();
  const { authUser } = useAuth();
  const organizationId = authUser?.user?.organizationId;

  return useQuery({
    queryKey: ["mortgageBanks"],
    queryFn: () =>
      getRequest({
        url: `/public/organization/${organizationId}/partnership/get-terms`,
      }).then((res) => res.data?.value?.value),
    staleTime: 50000,
  });
};
export default useGetBankPartnershipTerms;