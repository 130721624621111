import React from "react";
import { FormWrapper } from "./style";
import Icons from "../Icons";
import { convertToTitleCase } from "../../../utils/constants";

const Dropdown = ({
  labelName,
  required,
  options,
  size,
  value,
  placeholder,
  onChange,
  icon,
  watch,
  error,
  hintText,
  subLabel,
  register,
  disabled = false,
  isLoading = false,
  ...rest
}) => {
  const filteredOptions = options?.filter((option) => option.name !== value);

  return (
    <FormWrapper {...rest}>
      {labelName && (
        <label className="form-label custom-form-label" htmlFor={labelName}>
          {labelName} {required && <span className="required">*</span>}
        </label>
      )}

      {subLabel && (
        <label className="subLabel" htmlFor={subLabel}>
          {subLabel}
        </label>
      )}

        <div className={`${error && "input-group-error"} input-group mb-1`}>
          {rest.lefticonname && (
            <span className="form_icon left-form_icon">
              <Icons iconName={rest.lefticonname} />
            </span>
          )}
          <select
            required={required}
            className={`${
              isLoading
                ? "input-group form-select form-control-lg custom-form-lg border-start-0 focus-ring focus-ring-success border border-gray text-secondary fs-6"
                : "input-group form-select form-control-lg custom-form-lg border-start-0 focus-ring focus-ring-success border border-gray"
            } ${disabled ? "disabled-cursor" : ""}`}
            value={value}
            placeholder={placeholder}
            onChange={onChange}
            disabled={disabled}
            {...register}
          >
            {isLoading ? (
              <option value="" className="spinner-grow">
                Loading...
              </option>
            ) : (
              <>
                {placeholder && !value && (
                  <option
                    value=""
                    className="form-select-placeholder"
                    disabled
                    selected
                  >
                    {placeholder}
                  </option>
                )}
                {value && (
                  <option value={value} selected>
                    {value}
                  </option>
                )}
                {filteredOptions?.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.name}
                  </option>
                ))}
              </>
            )}
          </select>
        </div>
        {error ? (
          <div className="form-error">{error}</div>
        ) : (
          hintText && <div className="form-hintText">{hintText}</div>
        )}
    </FormWrapper>
  );
};

export default Dropdown;
