import React from "react";
import "./nhf.css";
import { useNav } from "../../../../../hooks/useNav";

const NHF = () => {
  useNav({ rightBtnClick: "", hasBackButton: true, checkBox: true });
  return (
    <div className="container-fluid mortgage-plans">
      <div className="row mb-4">
        <div className="col-lg-6 col-md-6 col-sm-4">
          <h1>Mortgage Plans - NHF</h1>
        </div>
      </div>
      <div className="row mt-2">
        <h3>Description</h3>
        <p className="text-wrap">
          The Nigerian Housing Fund (N.H.F) loan is a popular housing loan given
          to Nigerians. It is available to all employed and self-employed
          Nigerians. The loan shall be for the purpose of building, purchasing
          or renovating a residential accommodation. The maximum given to
          individuals is N50,000,000 (revised amount as of May 2023). The loan
          is paid back over a period of 30 years.
        </p>
      </div>
      <div className="row mt-2 span-wrap">
        <div className="col-3 d-flex flex-column">
          <span className="span-title">Maximum Tenor (In Months)</span>
          <span className="span-text">360 Months (30 Years)</span>
        </div>
        <div className="col-3 d-flex flex-column">
          <span className="span-title">Minimum Downpayment</span>
          <span className="span-text">10%</span>
        </div>
        <div className="col-3 d-flex flex-column">
          <span className="span-title">Max Amount</span>
          <span className="span-text">N50,000,000</span>
        </div>
        <div className="col-3 d-flex flex-column">
          <span className="span-title">Interest Rate</span>
          <span className="span-text">6%</span>
        </div>
      </div>
      <hr className="mt-4 hr" />

      <div className="row mt-2 span-wrap">
        <div className="col-3 d-flex flex-column">
          <span className="span-head">Processing Fees</span>
          <span className="span-head-text">1. Fee Name - N250,000</span>
        </div>
      </div>
      <hr className="mt-4 hr" />

      <div className="row mt-2 span-wrap">
        <div className="col-3 d-flex flex-column">
          <span className="span-head">Required Documents</span>
          <span className="span-head-text">1. Employment Letter</span>
          <span className="span-head-text">2. Government ID</span>
        </div>
      </div>
      <hr className="mt-4 hr" />

      <div className="row mt-2 span-wrap">
        <div className="col-6 d-flex flex-column">
          <h4>Who Can Apply</h4>
          <ul>
            <li>
              <span className="span-head-text">
                {" "}
                Any Nigerian above the age of 18
              </span>
            </li>
            <li>
              <span className="span-head-text">
                {" "}
                Applicant must be a contributor to the National Housing Fund for
                a minimum period of six months.
              </span>
            </li>
            <li>
              <span className="span-head-text">
                {" "}
                Applicant must have satisfactory evidence of regular flow of
                income to guarantee loan repayment.
              </span>
            </li>
          </ul>
        </div>
      </div>
      <hr className="mt-4 hr" />

      <div className="row mt-2 span-wrap">
        <div className="col-6 d-flex flex-column">
          <h4>Average Processing Time</h4>
          <span className="span-head-text">
            The average processing time for this load if all documents are
            provided is 3 months
          </span>
        </div>
      </div>
      <hr className="mt-4 hr" />

      <div className="row mt-2 span-wrap">
        <div className="col-6 d-flex flex-column">
          <h4>How to Apply for NHF</h4>
          <div className="steps">
            <span className="steps-head">STEP 1</span>
            <span className="steps-text">
              Log in to the Gidda web application
            </span>
          </div>
          <div className="steps">
            <span className="steps-head">STEP 2</span>
            <span className="steps-text">Find a property of your choice</span>
          </div>
          <div className="steps">
            <span className="steps-head">STEP 3</span>
            <span className="steps-text">
              Click the "Apply Now" button to apply for the property
            </span>
          </div>
          <div className="steps">
            <span className="steps-head">STEP 4</span>
            <span className="steps-text">
              Select the bank you want to process your mortgage through
            </span>
          </div>
          <div className="steps">
            <span className="steps-head">STEP 5</span>
            <span className="steps-text">
              Book a viewing of the property with an agent, then acknowledge
              having viewed the property
            </span>
          </div>
          <div className="steps">
            <span className="steps-head">STEP 6</span>
            <span className="steps-text">
              Submit the necessary documents. See docs below
            </span>
          </div>
          <div className="steps">
            <span className="steps-head">STEP 7</span>
            <span className="steps-text">
              Pay the primary bank account opening & service charges.
            </span>
          </div>
        </div>
      </div>
      <hr className="mt-4 hr" />
    </div>
  );
};

export default NHF;
