import React, { useEffect } from "react";
import { LargeLoaderWrapper } from "./style";

const LargeLoader = ({ ...rest }) => {
  useEffect(() => {
    // Smooth scroll to the top of the page when the component mounts
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <LargeLoaderWrapper className={rest.className}>
      {/* <div className="large-loader"></div> */}
    </LargeLoaderWrapper>
  );
};

export default LargeLoader;
