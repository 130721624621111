export const getBase64Image = (file) => {
  return new Promise((resolve, reject) => {
    const filereader = new FileReader();
    filereader.readAsDataURL(file);
    filereader.onload = () => {
      resolve(filereader.result.slice(filereader.result.search(",") + 1));
    };
    filereader.onerror = (error) => {
      reject(error);
    };
  });
};

export const fileExtension = (file) => {
  return file.name.split(".").pop();
};
