import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";

const useGetDeveloperDocuments = () => {
  const { getRequest } = useAxiosBase();

  return useQuery({
    queryKey: ["get-documents"],
    queryFn: () =>
      getRequest({ url: `/developer/get-documents` }).then(
        (res) => res.data?.value?.value
      ),
    staleTime: 5000,
  });
};

export default useGetDeveloperDocuments;
