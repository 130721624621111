import React, { useContext } from "react";
import "../Navbar/index.css";
import { useToggler } from "../../Context/AppContext";
import NavContext from "../../Context/NavContext";
import { colors } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import FormInput from "../Common/FormInput";
import Button from "../Common/Button";
import Icons from "../Common/Icons";

const Navbar = () => {
  const { navInfo } = useContext(NavContext);
  const { setAuthUser } = useAuth();

  const navigate = useNavigate();

  const toggler = useToggler();

  return (
    <div className="navbar-wrapper p-0  ps-3 pe-2 m-0">
      <nav className="row navbar-expand navbar-dark navbar-custom mt-4 p-0 ps-3">
        <div className="col-9 col-md-8 d-flex align-items-center mt-2 p-0">
          {/* <<========= MOBILE NAVE TOGGLER STARTS =========>> */}
          <div className="col-1 hamburger" onClick={toggler}>
            <button
              className="btn btn-link btn-sm order-1 order-lg-0 me-lg-0 text-black"
              style={{
                marginBottom: "0px",
                fontSize: "20px",
                background: "transparent",
              }}
              id="sidebarToggle"
              href="#!"
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>

          {/* <<========= MOBILE NAVE TOGGLER ENDS =========>> */}

          <div className="mb-0 main-title">
            {navInfo?.hasBackButton || navInfo?.leftBtnClick ? (
              <>
                {navInfo?.hasBackButton && (
                  <Button
                    lefticonname={"leftArrow"}
                    btnText={"Back"}
                    onBtnClick={() => navigate(-1)}
                    borderradius={"10"}
                    width={"6rem"}
                    padding={"8px 2px"}
                    iconcolor={colors.primary}
                    btntextcolor={colors.primary}
                    backgroundcolor={colors.white}
                    iconcolorHover={colors.hover}
                    colorHover={colors.hover}
                    borderHover={colors.hover}
                    backgroundHover={colors.white}
                  />
                )}
                {navInfo?.leftBtnClick && (
                  <Button
                    lefticonname={"leftArrow"}
                    btnText={"Back"}
                    onBtnClick={navInfo.leftBtnClick}
                    borderradius={"10"}
                    width={"6rem"}
                    padding={"8px 2px"}
                    iconcolor={colors.primary}
                    btntextcolor={colors.primary}
                    backgroundcolor={colors.white}
                    iconcolorHover={colors.hover}
                    colorHover={colors.hover}
                    borderHover={colors.hover}
                    backgroundHover={colors.white}
                  />
                )}
              </>
            ) : (
              <h1 className="header-name w-100">{navInfo?.mainTitle}</h1>
            )}
            {navInfo?.isProp && (
              <div className="d-none d-md-inline-block mx-auto ml-auto input-group my-2">
                <div className="d-flex gap-3 estate_data">
                  <div className="d-flex align-items-center">
                    <Icons iconName={"estate"} />
                    <span
                      className="fw-semibold pt-2"
                      style={{ fontFamily: '"Red Hat Display", sans-serif' }}
                    >
                      {navInfo?.estates ?? 0} Estates
                    </span>
                  </div>
                  <div className="d-flex align-items-center">
                    <Icons iconName={"houses"} />{" "}
                    <span
                      className="fw-semibold pt-2"
                      style={{ fontFamily: '"Red Hat Display", sans-serif' }}
                    >
                      {navInfo?.houses ?? 0} Houses
                    </span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* <<========= SEARCH FORM STARS =========>> */}
        <div className="col-0 col-md-4 me-4 d-flex p-0">
          <form className="d-none d-md-inline-block my-auto input-group mt-2">
            <FormInput
              type="text"
              placeholder="Search..."
              lefticonname="search"
              height={45}
              onChange={() => {}}
              iconColor={colors.primary}
            />
          </form>
        </div>

        {/* <<========= NOTIFICATION SEC STARTS =========>> */}
        {/* <div className="col-3 col-md-1 mb-2">
          <ul className="navbar-nav d-flex justify-content-center">
            <li className="nav-item dropdown">
              <div className="nav-link-bell">
                <a
                  href="#!"
                  className="custom-icon custom-icon-bell"
                  id="navbarDropdown"
                  // href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <i className="fa-regular fa-bell"></i>
              
                  <span className="custom-icon-bell-count">2</span>
                </a>
              </div>
              <ul
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <a className="dropdown-item" href="#!">
                    Settings
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" href="#!">
                    Activity Log
                  </a>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>
                <li>
                  <span
                    className="dropdown-item"
                    onClick={() => {
                      sessionStorage.removeItem("user");
                      setAuthUser(null);
                      navigate("/account/login");
                    }}
                  >
                    Logout
                  </span>
                </li>
              </ul>
            </li>
          </ul>
        </div> */}
      </nav>
      <div className="">
        {(navInfo?.hasBackButton || navInfo?.leftBtnClick) && (
          <h1
            className="header-name w-100"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {navInfo?.mainTitle}
          </h1>
        )}
        {navInfo?.subTitle && (
          <p className="sub-title col-9 col-md-6 d-flex align-items-center">
            {navInfo?.subTitle}
          </p>
        )}
      </div>
    </div>
  );
};

export default Navbar;
