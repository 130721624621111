// import { useMutation } from "@tanstack/react-query";
// import useAxiosBase from "../../../hooks/useAxiosBase";
// import useAuth from "../../../hooks/useAuth";

// const useSendPartnershipRequest = (developerId) => {
//   const { getRequest } = useAxiosBase();
//   const { authUser } = useAuth();
//   const organizationId = authUser?.user?.organizationId ?? "";

//   return useMutation({
//     mutationKey: ["partnership-request", organizationId],
//     mutationFn: () =>
//       getRequest({
//         url: `/bank/${organizationId}/send-developer-request/${developerId}`,
//       }).then((res) => res.data?.value),
//     staleTime: 50000,
//   });
// };
// export default useSendPartnershipRequest;

import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import useAuth from "../../../hooks/useAuth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useSendPartnershipRequest = (bankOrgID) => {
  const { getRequest } = useAxiosBase();
  const navigate = useNavigate()

  const sendPartnershipRequest = useMutation({
    mutationKey: ["partnership-request"],
    mutationFn: (data) =>
      getRequest({
        url: `/developer/partnership/send-request/${bankOrgID}`,
        data: data,
      }).then((res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success(res?.data?.value?.message);
          navigate("/banks")
        }
        if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
          toast.error(res?.data?.value?.message);
        }
      }),
    onSuccess: (res, res1) => {
      if (res?.data?.value?.value?.statusCode === 200) {
        toast.success(res?.data?.value?.message);
      }
      if ([400, 404, 500].includes(res?.data?.value?.value?.statusCode)) {
        toast.error(res?.data?.value?.value?.message);
      }
    },
    onError: (error) => {
      toast.error(error);
    },
  });

  return { sendPartnershipRequest };
};

export default useSendPartnershipRequest;
