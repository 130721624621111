import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useResetPassword = () => {
  const { postRequest } = useAxiosBase();

  const resetPassword = useMutation({
    mutationFn: (data) =>
      postRequest({
        url: `/account/generate-reset-password-link`,
        data: data,
      }).then((res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success("A Reset link has been sent to your email address.");
        } else {
          toast.error("Email address was not found");
        }
        // if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
        //   toast.error(res?.data?.value?.message);
        //   console.log("the love of man")
        // }
      }),
    onSuccess: (res, res1) => {
      if (res?.data?.value?.statusCode === 200) {
        toast.success("A Reset link has been sent to your email address.");
      }

      if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
        toast.error(res?.data?.value?.message);
      }
    },
    onError: (error) => {
      toast.error(error);
    },
  });

  return { resetPassword };
};

export default useResetPassword;
