import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";
import useAuth from "../../../../hooks/useAuth";

const useEditProfile = ({ appUserId }) => {
  const { putRequest } = useAxiosBase();
  const { authUser, setAuthUser } = useAuth();


  const update = useMutation({
    mutationKey: ["user-profile", appUserId],
    mutationFn: (data) =>
      putRequest({
        url: `/account/${appUserId}/update-profile`,
        data: data,
      }).then((res) => {
        if (res?.data?.value?.statusCode === 200) {
          const updatedUser = {
            ...authUser,
            user: {
              ...authUser.user,
              firstName: res?.data?.value?.value.firstName,
              lastName: res?.data?.value?.value.lastName,
              bio: res?.data?.value?.value.bio,
              title: res?.data?.value?.value.title,
              designation: res?.data?.value?.value.designation,
              age: res?.data?.value?.value.age,
              dateOfBirth: res?.data?.value?.value.dateOfBirth,
              gender: res?.data?.value?.value.gender,
              phoneNumber: res?.data?.value?.value.phoneNumber,
              profilePicture: res?.data?.value?.value.profilePicture,
              name: `${res?.data?.value?.value.firstName} ${res?.data?.value?.value.lastName}`,
            },
          };

          setAuthUser(updatedUser);

          // Update session storage
          sessionStorage.setItem("user", JSON.stringify(updatedUser));
          toast.success(" Profile Updated Successfully!");
        }
        if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
          toast.error(res?.data?.value?.message);
        }
      }),
    onSuccess: (res, res1) => {
      if (res?.data?.value?.value?.statusCode === 200) {
        const updatedUser = {
          ...authUser,
          user: {
            ...authUser.user,
            firstName: res?.data?.value?.value.firstName,
            lastName: res?.data?.value?.value.lastName,
            name: `${res?.data?.value?.value.firstName} ${res?.data?.value?.value.lastName}`,
          },
        };

        setAuthUser(updatedUser);

        // Update session storage
        sessionStorage.setItem("user", JSON.stringify(updatedUser));
        toast.success("Updated Successfully!");
      }
      if ([400, 404, 500].includes(res?.data?.value?.value?.statusCode)) {
        toast.error(res?.data?.value?.value?.message);
      }
    },
    onError: (error) => {
      toast.error(error);
    },
  });

  return { update };
};

export default useEditProfile;
