import React, { useEffect, useState } from "react";
import InfoTable from "../../Components/Common/InfoTable";
import Modal from "../../Components/Common/Modal";
import InfoCard from "../../Components/Common/InfoCard";
import { useNavigate } from "react-router-dom";
import { colors, months, years } from "../../utils/constants";
import Spinner from "../../Components/Spinner";
import { useNav } from "../../hooks/useNav";
import { appReviewDummy, reviewTabs } from "./utils/constant";
import { useGetApplicationReview } from "./hooks/useGetApplicationReview";
import { toast } from "react-toastify";
import useAxiosBase from "../../hooks/useAxiosBase";
import CenteredText from "../../Components/CenteredText";
import DocumentHeader from "../Applications/DocumentHeader";
import MultitextInput from "../../Components/Common/MultitextInput";
import FormInput from "../../Components/Common/FormInput";
import { convertToTitleCase } from "../../utils/constants";
import TableLoader from "../../Components/Common/TableLoader";
import SkeletonLoader from "../../Components/SkeletonLoader";

const ApplicationReview = () => {
  const { getRequest, postRequest } = useAxiosBase();
  const [page, setPage] = useState(0);
  const [showApprovalModal, setShowApprovalModal] = useState(false);
  const [showRejectionModal, setShowRejectionModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [omit, setOmit] = useState(true);
  const [appId, setAppId] = useState();
  const [showModal, setShowModal] = useState(false);
  const [applicationId, setApplicationId] = useState();
  const [application, setApplication] = useState();
  const [applicationPending, setApplicationPending] = useState(false);
  const [financePending, setFinancePending] = useState(false);
  const [financeData, setFinanceData] = useState();
  const [creditPending, setCreditPending] = useState(false);
  const [pending, setPending] = useState(false);
  const [creditData, setCreditData] = useState();
  const [employmentVerification, setEmploymentVerification] = useState(false);
  const [applicationPendingReview, setApplicationPendingReview] = useState(0);
  const [approvedAppliction, setApprovedAppliction] = useState(0);
  const [unHandledApplications, setUnHandledApplications] = useState(0);
  const [reviewComment, setReviewComment] = useState();
  const [reviewCommentDescription, setReviewCommentDescription] = useState();
  const [reviewRatio, setReviewRatio] = useState();
  const [employmentVerificationData, setEmploymentVerificationData] =
    useState();
  const [applicationStatus, setApplicationStatus] = useState();

  useNav({
    mainTitle: "Application Review",
    subTitle: "Applications that require your attention to move forward.",
  });

  const navigate = useNavigate();

  const [pagination, setPagination] = useState(1);
  const [loadingTable, setLoadingTable] = useState(false);
  const {
    data: applicationReview,
    isLoading,
    refetch,
    isRefetching,
  } = useGetApplicationReview(pagination);

  const fetchFinancialCapacity = (appId) => {
    setFinancePending(true);
    getRequest({
      url: `/developer/application/${appId}/review/get-financial-capacity`,
    }).then((res) => {
      if (res?.data?.value?.statusCode === 200) {
        setFinanceData(res?.data?.value?.value);
      }
      setFinancePending(false);
    });
  };

  const fetchCreditCapacity = (appId) => {
    setCreditPending(true);
    getRequest({
      url: `/developer/application/${appId}/review/get-analyzed-credit-report`,
    }).then((res) => {
      if (res?.data?.value?.statusCode === 200) {
        setCreditData(res?.data?.value?.value?.score);
      }
      setCreditPending(false);
    });
  };

  const fetchEmploymentVerification = (appId) => {
    setEmploymentVerification(true);
    getRequest({
      url: `/developer/application/${appId}/review/get-employee-verification`,
    }).then((res) => {
      if (res?.data?.value?.statusCode === 200) {
        setEmploymentVerificationData(res?.data?.value?.value);
      }
      setEmploymentVerification(false);
    });
  };

  const handleTabChange = (index) => {
    setPage(index);
    setPageNumber(pageNumber + index);
    switch (index) {
      case 0:
        fetchFinancialCapacity(appId);
        break;
      case 1:
        fetchCreditCapacity(appId);
        break;
      // case 2:
      //   fetchEmploymentVerification(appId);
      //   break;
      default:
        break;
    }
  };

  //PLEASE OPTIMIZE THIS FUNCTION
  const approveReview = async ({ status }) => {
    setPending(true);
    await postRequest({
      url: `/developer/application/${appId}/perform-review/${status}`,
      data: {
        Comment: `APPROVED`,
      },
    }).then((res) => {
      if (res.data?.value?.statusCode === 200) {
        toast.success(res.data?.value?.message);
        setTimeout(() => {
          refetch();
          setShowModal(false);
          setShowApprovalModal(false);
          setPageNumber(0);
        }, 2000);
        setPending(false);
        return;
      }
      toast.error(res.data?.value?.message);
      setPending(false);
    });
  };

  //PLEASE OPTIMIZE THIS FUNCTION
  const reviewApplication = async ({ status }) => {
    if (!reviewComment) {
      toast.error("Please provide a reason for rejecting this application.");
      return;
    }

    if (!reviewCommentDescription) {
      toast.error(
        "Please provide a detailed description as to why you are rejecting this application."
      );
      return;
    }

    setPending(true);
    if (reviewComment) {
      await postRequest({
        url: `/developer/application/${appId}/perform-review/${status}`,
        data: {
          Comment: `REASON: ${reviewComment}. DESCRIPTION: ${reviewCommentDescription}`,
        },
      }).then((res) => {
        if (res.data?.value?.statusCode === 200) {
          refetch();
          toast.success(res.data?.value?.message);
          setShowRejectionModal(false);
          setShowModal(false);
          setPending(false);

          return;
        }
        toast.error(res.data?.value?.message);
        setPending(false);
      });
      return;
    }
  };

  const getSelectedApplication = (applicationId) => {
    if (applicationReview) {
      const selectedApp = applicationReview?.data?.find(
        (app) => app.applicationId === applicationId
      );
      if (selectedApp) {
        setApplication(selectedApp);
      }
    }
  };

  const handleProceedApplication = () => setShowApprovalModal(true);
  const handleRejectApplication = () => {
    setShowRejectionModal(true);
  };

  const numberOfApplications = (applications) => {
    if (applications?.length > 0) {
      if (applications?.length > 1) {
        return `${applications?.length}`;
      }
      return `${applications?.length}`;
    }
    return "0";
  };

  const handleApplicationBtnClick = (rowId) => {
    setAppId(rowId);
    getSelectedApplication(rowId);
    setApplicationId(rowId);
    fetchFinancialCapacity(rowId);
    setShowModal(true);
  };

  const handleRowClicked = (row) => {
    if (row?.applicationStatus === "UNDER_REVIEW") {
      return toast("Click the action menu to review this application");
    }
  };

  useEffect(() => {
    if (applicationId) {
      fetchFinancialCapacity(applicationId);
      fetchCreditCapacity(applicationId);
      fetchEmploymentVerification(applicationId);
    }
  }, [applicationId]);

  useEffect(() => {
    if (applicationReview?.data?.length > 0) {
      setApplicationPendingReview(
        applicationReview?.data?.filter(
          (x) => x?.applicationStatus === "UNDER_REVIEW"
        )?.length || 0
      );

      setUnHandledApplications(
        applicationReview?.data?.filter((x) => x?.handler?.handlerId === null)
          ?.length || 0
      );

      const rejectedAppliction =
        applicationReview?.data?.filter(
          (x) => x?.applicationStatus === "REVIEW_REJECTED"
        )?.length || 0;

      const approvedAppliction =
        applicationReview?.data?.map(
          (x) =>
            x?.applicationStatus !== "REVIEW_REJECTED" ||
            x?.applicationStatus !== "UNDER_REVIEW"
        )?.length || 0;

      if (approvedAppliction) {
        setApprovedAppliction(approvedAppliction);
      }

      if (rejectedAppliction === 0 && approvedAppliction === 0) {
        setReviewRatio(0);
      } else {
        setReviewRatio(
          (applicationReview?.data?.length / approvedAppliction) * 100
        );
      }

      if (application?.status === "REVIEW_REJECTED") {
        setApplicationStatus("was Rejected");
      } else if (application?.status === "UNDER_REVIEW") {
        setApplicationStatus("is Currently Under Review");
      } else {
        setApplicationStatus("was Approved");
      }
    }
  }, [applicationReview?.data, application]);

  useEffect(() => {
    setLoadingTable(isRefetching);
  }, [isRefetching]);

  useEffect(() => {
    if (pagination) {
      refetch();
    }
  }, [pagination]);

  return (
    <div className="px-2">
      <div className="d-flex gap-4 mt-4">
        <div className="">
          <InfoCard
            mainValue={
              isLoading || isRefetching ? (
                <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
              ) : (
                applicationReview?.totalRecords ?? 0
              )
            }
            description={
              isLoading || isRefetching ? (
                <SkeletonLoader />
              ) : (
                "Total Applications"
              )
            }
          />
        </div>
        <div className="">
          <InfoCard
            mainValue={
              isLoading || isRefetching ? (
                <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
              ) : (
                applicationPendingReview
              )
            }
            description={
              isLoading || isRefetching ? (
                <SkeletonLoader />
              ) : (
                "Applications not yet handled"
              )
            }
          />
        </div>
        <div className="">
          <InfoCard
            mainValue={
              isLoading || isRefetching ? (
                <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
              ) : (
                reviewRatio ? `${reviewRatio}%` : "0%"
              )
            }
            description={
              isLoading || isRefetching ? (
                <SkeletonLoader />
              ) : (
                "Application Approval Ratio"
              )
            }
          />
        </div>
      </div>

      <div className="mb-4">
        {isLoading ? (
          <div className="mt-4">
            <TableLoader />
          </div>
        ) : !applicationReview?.data?.length ? (
          <div className="mt-5">
            <CenteredText title={"No Data"}>
              There are currently no applications to review.
            </CenteredText>
          </div>
        ) : (
          <div className="mt-2">
            <InfoTable
              columns={appReviewDummy(
                omit,
                handleApplicationBtnClick,
                isRefetching
              )}
              dataCollection={applicationReview?.data}
              pointerOnHover={true}
              highlightOnHover={true}
              loadingTable={loadingTable}
              paginationTotalRows={applicationReview?.totalRecords}
              onChangePage={(page) => setPagination(page)}
              onRowClicked={handleRowClicked}
            />
          </div>
        )}
      </div>

      <Modal
        show={showModal}
        cancelButtonLabel={
          financeData?.length > 0 ||
          creditData?.length > 0 ||
          employmentVerificationData?.length > 0
            ? "Reject Application"
            : ""
        }
        confirmButtonLabel={
          financeData?.length > 0 ||
          creditData?.length > 0 ||
          employmentVerificationData?.length > 0
            ? "Proceed with Application"
            : ""
        }
        closeModal={() => setShowModal(false)}
        cancelBtn={() => setShowRejectionModal(true)}
        confirmModal={() => setShowApprovalModal(true)}
        width={"100%"}
        height={"100%"}
        crossLine={false}
        component={
          application && (
            <DocumentHeader
              leftTitle={
                <div
                  className="p-3"
                  style={{
                    border: "1px solid #335F32",
                    color: "#335F32",
                    borderRadius: "8px",
                    backgroundColor: "#F8F8F8",
                    display: "inline-block",
                  }}
                >
                  <p className="text-center m-0 fw-bold">{`APPLIED FOR ${application?.mortgagePlan?.name?.toUpperCase()}`}</p>
                </div>
              }
              title={
                <div style={{ color: colors.darkGreen }}>
                  {application?.customer?.firstName}{" "}
                  {application?.customer?.lastName}- Affordability & Risk Review
                </div>
              }
              subTitle={
                <>
                  <p style={{ fontSize: "10px" }}>
                    {" "}
                    REVIEWED BY{" "}
                    {`${application?.handler?.firstName?.toUpperCase()} ${application?.handler?.lastName?.toUpperCase()}` ??
                      ""}{" "}
                    <span className="circle_bar"> </span> THE APPLICATION{" "}
                    {applicationStatus?.toUpperCase()}
                  </p>
                  <p
                    className="m-0"
                    style={{
                      border: "1px solid #335F32",
                      backgroundColor: "#DFFFDE",
                      color: "#335F32",
                      fontSize: "13px",
                      borderRadius: "20px",
                      display: "inline-block",
                      padding: "3px 10px",
                    }}
                  >
                    {convertToTitleCase(application?.applicationStatus)}
                  </p>
                </>
              }
              applicationId={appId}
            />
          )
        }
        closeModalIcon={true}
        // outSideToClose={false}
      >
        {application ? (
          <div className="row m-0 mt-4">
            <div className="tab d-flex justify-content-center">
              {reviewTabs({ financeData }).map((tab, index) => (
                <div
                  style={{ width: window.innerWidth >= 992 ? "20%" : "100%" }}
                  className={`tab-item border text-center p-3 ${
                    index === 0 ? "round-start" : ""
                  } ${
                    index === reviewTabs({ financeData })?.length - 1
                      ? "round-end"
                      : ""
                  } ${page === index ? "bg-active" : "bg-white"}`}
                  onClick={() => handleTabChange(index)}
                  key={tab.label}
                >
                  <p
                    className={`tab-item-link ${
                      page === index ? "bg-active" : ""
                    }`}
                  >
                    {tab.title}
                  </p>
                </div>
              ))}
            </div>

            {financePending ||
            creditPending ||
            employmentVerification ||
            applicationPending ? (
              <div className="mt-5 pt-5">
                <Spinner />
              </div>
            ) : (
              <div className="ps-3 pe-2">
                {
                  reviewTabs({
                    financeData,
                    creditData,
                    employmentVerificationData,
                    application,
                    handleProceedApplication,
                    handleRejectApplication,
                  })[page].component
                }
              </div>
            )}
          </div>
        ) : (
          <div className="mt-5">
            <Spinner />
          </div>
        )}
      </Modal>

      <Modal
        show={showApprovalModal}
        title={"Proceed With Application"}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        btnHeight={55}
        closeModal={() => {
          setPage(0);
          setPageNumber(0);
          setShowApprovalModal(false);
          // approveReview({ status: "REJECTED" });
        }}
        confirmModal={() => approveReview({ status: "APPROVED" })}
        isBtnLoading={pending}
        disabled={pending}
      >
        <p className="text-center ps-4 pe-4 text-wrap mb-0">
          Are you sure you want to proceed with the application? This will mean
          that you have approved their application and would want them to pay
          necessary fees.
        </p>
      </Modal>

      <Modal
        title={"Application Rejection"}
        show={showRejectionModal}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Submit"}
        btnHeight={55}
        width={"100%"}
        height={"100%"}
        titleFontSize={"30px"}
        closeModalIcon={true}
        crossLine={false}
        closeModal={() => {
          setShowRejectionModal(false);
        }}
        confirmModal={() => reviewApplication({ status: "REJECTED" })}
        isBtnLoading={pending}
        disabled={pending}
      >
        <div className="mx-3">
          <FormInput
            required
            labelName={"Reason for rejection"}
            placeholder={"Why are you rejecting this applicatiion?"}
            onChange={(name, value) => setReviewComment(value)}
          />
          <MultitextInput
            label={"Description"}
            required
            row={8}
            placeholder={`Provide a more detailed information on why you are rejecting the application.`}
            onChange={(e) => setReviewCommentDescription(e?.target?.value)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ApplicationReview;
