import React, { useEffect, useState } from "react";
import { useNav } from "../../../hooks/useNav";
import Button from "../../../Components/Common/Button";
import Spinner from "../../../Components/Spinner";
import { columns } from "./constat";
import Modal from "../../../Components/Common/Modal";
import CenteredText from "../../../Components/CenteredText";
import InfoTable from "../../../Components/Common/InfoTable";
import Dropdown from "../../../Components/Common/Dropdown";
import FormInput from "../../../Components/Common/FormInput";
import InfoCard from "../../../Components/Common/InfoCard";
import { notificationTypes } from "../../../utils/mockData";
import { useGetNotificationUsers } from "./hooks/useGetNotificationUsers";
import { useGetNotificationConfiguration } from "./hooks/useGetNotificationConfiguration";
import useCreateNotificationConfiguration from "./hooks/useCreateNotificationConfiguration";
import useUpdateNotificationConfiguration from "./hooks/useUpdateNotificationConfiguration";
import useDeleteNotificationConfiguration from "./hooks/useDeleteNotificationConfiguration";
import CheckInput from "../../../Components/Common/CheckInput";
import { colors } from "../../../utils/constants";
import { toast } from "react-toastify";
import { convertToTitleCase } from "../../../utils/constants";
import TableLoader from "../../../Components/Common/TableLoader";
import SkeletonLoader from "../../../Components/SkeletonLoader";

const NotificationConfiguration = () => {
  useNav({
    mainTitle: "Notification Settings",
    subTitle: "Manage your notification preferences and settings",
  });

  const [rowId, setRowId] = useState();
  const [pagination, setPagination] = useState(1);
  const [notificationUsers, setNotificationUsers] = useState([]);
  const [selectedNotificationUsers, setSelectedNotificationUsers] = useState(
    []
  );
  const [newNotificationUsers, setNewNotificationUsers] = useState([]);
  const [removeNotificationUsers, setRemoveNotificationUsers] = useState([]);
  const [notificationTypesToDisplay, setNotificationTypesToDisplay] =
    useState(notificationTypes);
  const [notificationObject, setNotificationObject] = useState({
    type: null,
    emailEnabled: true,
    textMessageEnabled: false,
    inAppNotificationEnabled: false,
    notificationUsers: [],
    removeNotificationUsers: [],
  });

  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data: getNotificationUsers } = useGetNotificationUsers(1);

  const {
    data: getNotificationConfiguration,
    isLoading,
    refetch,
    isRefetching,
  } = useGetNotificationConfiguration(pagination);

  const { createNotificationConfiguration } =
    useCreateNotificationConfiguration();
  const { updateNotificationConfiguration } =
    useUpdateNotificationConfiguration(rowId);
  const { deleteNotificationConfiguration } =
    useDeleteNotificationConfiguration(rowId);

  const handleAction = (rowId, action) => {
    setRowId(rowId);
    if (action === "edit") {
      const selectedRole = getNotificationConfiguration?.data?.find(
        (role) => role.id === rowId
      );

      const userIds = [
        ...new Set(selectedRole.notificationUsers.map((user) => user.id)),
      ];

      setSelectedNotificationUsers(userIds);
      setNotificationObject((prev) => ({
        ...prev,
        type: selectedRole.type,
        notificationUsers: userIds,
      }));

      setShowEditModal(true);
    } else {
      setShowDeleteModal(true);
    }
  };

  const handleCheckChange = (userId) => {
    setNotificationUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === userId ? { ...user, isChecked: !user.isChecked } : user
      )
    );

    setNotificationObject((prev) => {
      const isSelected = prev.notificationUsers.includes(userId);
      const updatedUsers = isSelected
        ? prev.notificationUsers.filter((id) => id !== userId)
        : [...prev.notificationUsers, userId];

      return {
        ...prev,
        notificationUsers: updatedUsers,
      };
    });
  };

  const handleUpdateNotificationUsers = (userId) => {
    const isSelected = selectedNotificationUsers.includes(userId);
    const isNewlySelected = newNotificationUsers.includes(userId);

    if (isSelected) {
      // User is already in the selected list, add to removeNotificationUsers
      setRemoveNotificationUsers((prev) =>
        prev.includes(userId)
          ? prev.filter((id) => id !== userId)
          : [...prev, userId]
      );
      setNewNotificationUsers((prev) => prev.filter((id) => id !== userId));
    } else if (isNewlySelected) {
      // User was newly selected, but now unchecked
      setNewNotificationUsers((prev) => prev.filter((id) => id !== userId));
    } else {
      // User is newly selected
      setNewNotificationUsers((prev) => {
        if (!prev.includes(userId)) {
          return [...prev, userId];
        }
        return prev;
      });
      setRemoveNotificationUsers((prev) => prev.filter((id) => id !== userId));
    }

    setNotificationObject((prev) => {
      const isSelected = prev.notificationUsers.includes(userId);
      const updatedUsers = isSelected
        ? prev.notificationUsers.filter((id) => id !== userId)
        : [...prev.notificationUsers, userId];

      return {
        ...prev,
        notificationUsers: updatedUsers,
      };
    });
  };

  useEffect(() => {
    if (getNotificationUsers?.data) {
      const users = getNotificationUsers?.data?.map((user) => ({
        id: user?.id,
        isChecked: false,
        name: `${user?.name} - ${user?.applicationRole?.name}`,
      }));
      setNotificationUsers(users);
    }
  }, [getNotificationUsers?.data]);

  useEffect(() => {
    if (getNotificationConfiguration?.data) {
      const configuredTypes = getNotificationConfiguration.data.map(
        (config) => config.type
      );
      const filteredNotificationTypes = notificationTypes.filter(
        (type) => !configuredTypes.includes(type.id)
      );
      setNotificationTypesToDisplay(filteredNotificationTypes);
    }
  }, [getNotificationConfiguration?.data]);

  useEffect(() => {
    if (pagination) {
      refetch();
    }
  }, [pagination]);

  return (
    <div className="px-2">
      <div className="d-flex justify-content-end gap-3 pt-2 pb-3">
        <Button btnText={"Create"} onBtnClick={() => setShowModal(true)} />
      </div>

      <div className="">
        <InfoCard
          mainValue={
            isLoading || isRefetching ? (
              <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
            ) : (
              getNotificationConfiguration?.totalRecords ?? "0"
            )
          }
          description={
            isLoading || isRefetching ? (
              <SkeletonLoader />
            ) : (
              "Total Configured Notifications"
            )
          }
        />
      </div>

      <div className="mb-4">
        {isLoading ? (
          <div className="mt-4">
            <TableLoader />
          </div>
        ) : getNotificationConfiguration?.data?.length > 0 ? (
          <div className="mt-2">
            <InfoTable
              columns={columns(
                (rowId, action) => handleAction(rowId, action),
                isRefetching
              )}
              dataCollection={getNotificationConfiguration?.data}
              pointerOnHover={true}
              highlightOnHover={true}
              loadingTable={isRefetching}
              paginationTotalRows={getNotificationConfiguration?.totalRecords}
              onChangePage={(page) => setPagination(page)}
              // onRowClicked={(row, event) => navigate(`/plans/${row.id}`)}
            />
          </div>
        ) : (
          <div className="mt-5">
            <CenteredText
              title={"You have no Notification Configuration at this time"}
            >
              To begin accepting applications and selling your properties online
              to customers, click the create plan button above to create a
              purchase plan.
            </CenteredText>
          </div>
        )}
      </div>

      {/* CREATE MODAL  */}
      <Modal
        show={showModal}
        title={"Create Notification Configuration"}
        width={"100%"}
        height={"100%"}
        closeModalIcon
        titleFontSize={"30px"}
        crossLine={false}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Save"}
        closeModal={() => {
          setNotificationObject((prev) => ({
            ...prev,
            notificationUsers: [],
          }));

          setNotificationUsers((prev) =>
            prev.map((user) => ({ ...user, isChecked: false }))
          );

          setShowModal(false);
        }}
        confirmModal={() => {
          if (notificationObject?.notificationUsers?.length === 0) {
            return toast.error(
              "Please select at least one user to receive notifications."
            );
          }
          createNotificationConfiguration.mutate(notificationObject, {
            onSuccess: () => {
              refetch();
              setNotificationObject((prev) => ({
                ...prev,
                type: null,
                notificationUsers: [],
              }));
              setShowModal(false);
            },
          });
        }}
        isBtnLoading={createNotificationConfiguration.isLoading}
        disabled={
          createNotificationConfiguration.isLoading || !notificationObject.type
        }
      >
        <div className="px-3">
          <div>
            <Dropdown
              required
              labelName={"Notification Type"}
              placeholder={"Select"}
              options={notificationTypesToDisplay}
              onChange={(e) => {
                setNotificationObject((prev) => ({
                  ...prev,
                  type: e.target.value,
                }));
              }}
            />
          </div>
          <p
            className="ps-1 mt-3"
            style={{ fontSize: "1rem", fontWeight: "bold" }}
          >
            Select Users to Receive Notifications{" "}
            <sapn className="required">*</sapn>
          </p>
          <div className="row">
            {notificationUsers?.length > 0 &&
              notificationUsers?.map((user, index) => (
                <div className="col-sm-6 col-md-3" key={user.id}>
                  <div
                    className="border-bottom border-bottom-1 mb-3 pb-3"
                    key={index}
                  >
                    <CheckInput
                      name={user.name}
                      label={user.name}
                      checked={user.isChecked}
                      fontSize={"0.9rem"}
                      width={"23px"}
                      height={"23px"}
                      bcolor={colors.darkGray}
                      bordercolor={colors.grey}
                      margin={"0.3rem 0 0 0.2rem"}
                      checkTick
                      onChange={() => handleCheckChange(user.id)}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Modal>

      {/* EDIT MODAL */}
      <Modal
        show={showEditModal}
        title={"Update Notification Users"}
        width={"100%"}
        height={"100%"}
        closeModalIcon
        titleFontSize={"30px"}
        crossLine={false}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Update"}
        closeModal={() => {
          setNewNotificationUsers([]);
          setRemoveNotificationUsers([]);
          setNotificationObject((prev) => ({
            ...prev,
            notificationUsers: [],
          }));

          setNotificationUsers((prev) =>
            prev.map((user) => ({ ...user, isChecked: false }))
          );

          setShowEditModal(false);
        }}
        confirmModal={() => {
          if (notificationObject?.notificationUsers?.length === 0) {
            return toast.error(
              "Please select at least one user to receive notifications."
            );
          }

          const updatedNotificationUsers = {
            type: null,
            emailEnabled: true,
            textMessageEnabled: false,
            inAppNotificationEnabled: false,
            notificationUsers:
              newNotificationUsers?.length > 0 ? newNotificationUsers : null,
            removeNotificationUsers:
              removeNotificationUsers?.length > 0
                ? removeNotificationUsers
                : null,
          };

          updateNotificationConfiguration.mutate(updatedNotificationUsers, {
            onSuccess: () => {
              refetch();
              setNotificationObject((prev) => ({
                ...prev,
                type: null,
                notificationUsers: [],
              }));
              setShowEditModal(false);
            },
          });
        }}
        isBtnLoading={updateNotificationConfiguration.isLoading}
        disabled={
          updateNotificationConfiguration.isLoading || !notificationObject.type
        }
      >
        <div className="px-4">
          <div>
            <FormInput
              required
              labelName={"Notification Type"}
              value={convertToTitleCase(notificationObject?.type)}
              disabled
            />
          </div>
          <p
            className="ps-1 mt-3"
            style={{ fontSize: "1rem", fontWeight: "bold" }}
          >
            Select Users to Receive Notifications{" "}
            <sapn className="required">*</sapn>
          </p>
          <div className="row">
            {notificationUsers?.length > 0 &&
              notificationUsers?.map((user, index) => (
                <div className="col-sm-6 col-md-3" key={user.id}>
                  <div
                    className="border-bottom border-bottom-1 mb-3 pb-3"
                    key={index}
                  >
                    <CheckInput
                      checkTick
                      name={user.name}
                      label={user.name}
                      fontSize={"0.9rem"}
                      width={"23px"}
                      height={"23px"}
                      bcolor={colors.darkGray}
                      bordercolor={colors.grey}
                      margin={"0.3rem 0 0 0.2rem"}
                      checked={notificationObject.notificationUsers.includes(
                        user.id
                      )}
                      onChange={() => handleUpdateNotificationUsers(user.id)}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </Modal>

      {/* DELETE MODAL */}
      <Modal
        show={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        title={`Delete this Notification Configuration`}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Delete"}
        confirmModal={() =>
          deleteNotificationConfiguration?.mutate(null, {
            onSuccess: () => {
              refetch();
              setShowDeleteModal(false);
            },
          })
        }
        isBtnLoading={deleteNotificationConfiguration.isLoading}
        disabled={deleteNotificationConfiguration.isLoading}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <p>
                Are you sure you want to delete?{" "}
                <strong className="text-danger">
                  This action can't be undone
                </strong>
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default NotificationConfiguration;
