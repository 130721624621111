import React, { useContext, useEffect, useState } from "react";
import {
  colors,
  formattedPrice,
  getNumber,
  isNullOrEmpty,
  onlyNumbers,
  convertToTitleCase,
  removeUnderscore,
} from "../../../../utils/constants.js";
import {
  planTypes,
  bankTypes,
  interestTypes,
  processingFees,
  deliveryLevelsLists,
  completionRateLists,
} from "../../../../utils/mockData.js";
import Button from "../../../../Components/Common/Button/index.jsx";
import CheckInput from "../../../../Components/Common/CheckInput/index.jsx";
import FormInput from "../../../../Components/Common/FormInput/index.jsx";
import FooterButtons from "../../../../Components/Common/FooterButtons/index.js";
import Icons from "../../../../Components/Common/Icons/index.jsx";
import MultitextInput from "../../../../Components/Common/MultitextInput/index.jsx";
import { useForm } from "react-hook-form";
import { model } from "./model";
import { toast } from "react-toastify";
import useAuth from "../../../../hooks/useAuth.js";
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import useCreatePlans from "./hooks/useCreatePlans";
import useUpdatePlans from "./hooks/useUpdatePlans";
import CenteredText from "../../../../Components/CenteredText/index.jsx";
import Dropdown from "../../../../Components/Common/Dropdown/index.jsx";
import PlanTypeContext from "../../../../Context/PlanTypeContext.jsx";
import PlanAccessibilityCard from "./PlanAccessibilityCard/index.jsx";
import CustomTextEditor from "../../../../Components/Common/CustomRichTextEditor";

const MortgagePlanForm = ({ plan }) => {
  const { authUser } = useAuth();
  const navigate = useNavigate();

  const { planType, interestType, setPlanType, setInterestType } =
    useContext(PlanTypeContext);

  const params = useParams();
  const houseId = params?.houseId;

  const [planId, setPlanId] = useState();
  const { createPlans, isLoading } = useCreatePlans(houseId);
  const { updatePlans } = useUpdatePlans(planId, houseId);

  // const [isLoading, setIsLoading ] = useState(false);

  const [processingForm, setProcessingForm] = useState([]);
  const [documentForm, setDocumentForm] = useState([]);
  const [planTypeName, setPlanTypeName] = useState("");
  const [loanTenorError, setLoanTenorError] = useState("");
  const [planDetails, setPlanDetails] = useState({});
  const [disable, setDisable] = useState(false);

  const [bankName, setBankName] = useState("");
  const [selectedBank, setSelectedBank] = useState(null);
  const [selectedDeliveryLevel, setSelectedDeliveryLevel] = useState(null);
  const [deliveryLevelName, setDeliveryLevelName] = useState("");
  const [selectedCompletionRate, setSelectedCompletionRate] = useState(null);
  const [completionRateName, setCompletionRateName] = useState("");
  const [selectedInterestType, setSelectedInterestType] = useState(null);
  const [interestTypeName, setInterestTypeName] = useState("");
  const [eligibilityCriteria, setEligibilityCriteria] = useState(null);
  const [disableEligibilityCriteria, setDisableEligibilityCriteria] =
    useState(false);

  const [defaultCheckableDocuments, setDefaultCheckableDocuments] = useState([
    {
      id: "BANK_STATEMENT",
      name: "Bank Statements",
      label: "Bank Statements",
      isRequired: true,
      checked: true,
      description:
        "Recent bank statements for the last six months to demonstrate financial stability and capacity to repay the loan with bank stamp.",
    },
    {
      id: "EMPLOYMENT_LETTER",
      name: "Employment Letter",
      label: "Employment Letter",
      isRequired: true,
      checked: true,
      description: "",
    },
    {
      id: "CREDIT_REPORT",
      name: "Credit Report",
      label: "Credit Report (For Diaspora Customers)",
      isRequired: true,
      checked: true,
      description: "",
    },
  ]);

  const [showNHFDoc, setShowNHFDoc] = useState(false);
  const [checkableNHFDocuments, setCheckableNHFDocuments] = useState([
    {
      id: "NHF_APPLICATION_FORM",
      name: "Application Form",
      label: "Application Form",
      isRequired: true,
      checked: true,
      description: "Completed NHF loan application form.",
    },
    {
      id: "IDENTITY_PROOF",
      name: "Identity Proof",
      label: "Identity Proof",
      isRequired: true,
      checked: true,
      description:
        "Valid government-issued identification (e.g., national ID card, passport, driver’s license).",
    },
    {
      id: "PROOF_OF_NHF_CONTRIBUTION",
      name: "Proof of NHF Contribution",
      label: "Proof of NHF Contribution",
      isRequired: true,
      checked: true,
      description:
        "Six-month statement of contribution from the NHF internet banking portal.",
    },
    {
      id: "EMPLOYMENT_DETAILS",
      name: "Employment Details",
      label: "Employment Details",
      isRequired: true,
      checked: true,
      description:
        "For salaried employees, a letter from the employer confirming employment status and salary.",
    },
    {
      id: "BUSINESS_DETAILS",
      name: "Business Details",
      label: "Business Details",
      isRequired: false,
      checked: false,
      description:
        "For self-employed individuals, proof of business registration and financial statements.",
    },
    {
      id: "THREE_MONTHS_PAYSLIP",
      name: "3 Months Payslip",
      label: "3 Months Payslip",
      isRequired: true,
      checked: true,
      description: "Three months pay slips preceding the date of application.",
    },
    {
      id: "PROPERTY_DOCUMENT",
      name: "Property Documents",
      label: "Property Documents",
      isRequired: false,
      checked: false,
      description: "Photocopy of Title Documents of the Property.",
    },
    {
      id: "LETTER_OF_INTRODUCTION_FROM_EMPLOYER",
      name: "Letter of Introduction from Employer",
      label: "Letter of Introduction from Employer",
      isRequired: false,
      checked: false,
      description: "A letter from the employer introducing the applicant.",
    },
    {
      id: "LETTER_OF_UNDERTAKING_NOT_TO_ACCESS_NHF_LOAN",
      name: "Letter of Undertaking Not to Access NHF Loan",
      label: "Letter of Undertaking Not to Access NHF Loan",
      isRequired: false,
      checked: false,
      description:
        "A letter of undertaking stating that the applicant has not previously accessed an NHF loan.",
    },
    {
      id: "BIRTH_CERTIFICATE_OR_SOWRN_AGE_DECLARATION",
      name: "Birth Certificate/Sworn Age Declaration",
      label: "Birth Certificate/Sworn Age Declaration",
      isRequired: true,
      checked: true,
      description:
        "A copy of the applicant’s birth certificate or a sworn age declaration.",
    },
    {
      id: "VALUATION_REPORT",
      name: "Valuation Report",
      label: "Valuation Report",
      isRequired: false,
      checked: false,
      description:
        "A valuation report from a registered estate valuer for the property intended for purchase or renovation.",
    },
    {
      id: "PASSPORT_PHOTOGRAPHS",
      name: "Passport Photographs",
      label: "Passport Photographs",
      isRequired: true,
      checked: true,
      description: "Two recent passport-sized photographs of the applicant.",
    },
    {
      id: "TAX_CLEARANCE_CERTIFICATE",
      name: "Tax Clearance Certificate",
      label: "Tax Clearance Certificate",
      isRequired: false,
      checked: false,
      description:
        "A valid tax clearance certificate showing the applicant’s tax compliance status.",
    },
  ]);

  const [showFMBDoc, setShowFMBDoc] = useState(false);
  const [checkableFMBDocuments, setCheckableFMBDocuments] = useState([
    {
      id: "RENT_TO_OWN_APPLICATION_FORM",
      name: "Application Form",
      label: "Application Form",
      isRequired: false,
      checked: false,
      description: "Filled out completely.",
    },
    {
      id: "PASSPORT_PHOTOGRAPHS",
      name: "Passport Photographs",
      label: "Passport Photographs",
      isRequired: true,
      checked: true,
      description: "Two passport photographs with a white background.",
    },
    {
      id: "ALLOCATION_LETTER",
      name: "Allocation Letter",
      label: "Allocation Letter",
      isRequired: true,
      checked: true,
      description: "Signed by the applicant.",
    },
    {
      id: "LETTER_OF_APPOINTMENT_OR_EMPLOYMENT",
      name: "Letter of Appointment/Employment",
      label: "Letter of Appointment/Employment",
      isRequired: true,
      checked: true,
      description:
        "Official document from your employer stating your position and start date.",
    },
    {
      id: "LETTER_OF_CONFIRMATION_OF_APPOINTMENT_OR_EMPLOYMENT",
      name: "Letter of Confirmation of Appointment/Employment",
      label: "Letter of Confirmation of Appointment/Employment",
      isRequired: true,
      checked: true,
      description:
        "Document confirming your employment status, usually provided after probation.",
    },
    {
      id: "IDENTITY_PROOF",
      name: "Identity Proof",
      label: "Identity Proof",
      isRequired: true,
      checked: true,
      description:
        "Valid government-issued identification (e.g., national ID card, passport, driver’s license).",
    },
    {
      id: "OFFICE_ID",
      name: "Office ID",
      label: "Office ID",
      isRequired: true,
      checked: true,
      description:
        "Identification card issued by your employer to verify your employment.",
    },
    {
      id: "PROOF_OF_NHF_CONTRIBUTION",
      name: "Proof of NHF Contribution: ",
      label: "Proof of NHF Contribution: ",
      isRequired: true,
      checked: true,
      description:
        "Six-month statement of contribution from the NHF internet banking portal.",
    },
    {
      id: "THREE_MONTHS_PAYSLIP",
      name: "Three Months Payslip",
      label: "Three Months Payslip",
      isRequired: true,
      checked: true,
      description: "Three months pay slips preceding the date of application.",
    },
    {
      id: "BVN_PRINT_OUT",
      name: "BVN Print Out",
      label: "BVN Print Out",
      isRequired: true,
      checked: true,
      description: "Printout with photograph. ",
    },
    {
      id: "UTILITY_BILL",
      name: "Utility Bill",
      label: "Utility Bill",
      isRequired: false,
      checked: false,
      description:
        "Most recent utility bill (required for business owners or self-employed applicants).",
    },
    {
      id: "MOST_RECENT_SIX_MONTHS_REGISTERED_BUSINESS_ADDITIONAL_INCOME_STATEMENT_OF_ACCOUNT",
      name: "Most Recent Six Months Registered Business Additional Income Statement of Account",
      label:
        "Most Recent Six Months Registered Business Additional Income Statement of Account",
      isRequired: false,
      checked: false,
      description:
        "Where applicable, only for agricultural businesses for civil servants with bank stamp.",
    },
    {
      id: "SWORN_AFFIDAVIT",
      name: "Sworn Affidavit",
      label: "Sworn Affidavit",
      isRequired: false,
      checked: false,
      description:
        "To continue to pay/make NHF Contribution (required for business owners or self-employed applicants).",
    },
    {
      id: "BANK_CERTIFIED_PAYMENT_MANDATE",
      name: "Bank Certified Payment Mandate: ",
      label: "Bank Certified Payment Mandate: ",
      isRequired: false,
      checked: false,
      description:
        "Standing order to continue to pay NHF Contribution until retirement (required for business owners or self-employed applicants).",
    },
    {
      id: "CERTIFICATE_OF_BUSINESS_REGISTRATION",
      name: "Certificate of Business Registration",
      label: "Certificate of Business Registration",
      isRequired: false,
      checked: false,
      description:
        "Including business owner’s details (required for business owners or self-employed applicants).",
    },
    {
      id: "GUARANTORS",
      name: "Guarantors",
      label: "Guarantors",
      isRequired: false,
      checked: false,
      description:
        "Preferably civil servants (this will be required later, for business owners or self-employed applicants).",
    },
    {
      id: "SWORN_AFFIDAVIT_TO_SUPPORT_SPOUSE",
      name: "Sworn Affidavit to Support Spouse",
      label: "Sworn Affidavit to Support Spouse",
      isRequired: false,
      checked: false,
      description: "Provided by the supporting spouse.",
    },
    {
      id: "MOST_RECENT_THREE_MONTHS_PAY_SLIP",
      name: "Most Recent 3 Months Pay Slips (Supporting Spouse)",
      label: "Most Recent 3 Months Pay Slips (Supporting Spouse)",
      isRequired: false,
      checked: false,
      description:
        "Only for the supporting spouse who wishes to support with salary.",
    },
    {
      id: "MOST_RECENT_SIX_MONTHS_STATEMENT_OF_ACCOUNT",
      name: "Most Recent 6 Months Statement of Account",
      label: "Most Recent 6 Months Statement of Account",
      isRequired: false,
      checked: false,
      description: "Including salary or registered business, with bank stamp.",
    },
  ]);

  const NHFANDRentToOwnCriteria = `
  <ol>
    <li>Age: Applicants must be at least 18 years old.</li>
    <li>Income: Applicants must have a verifiable source of income.</li>
    <li>NHF Registration: Applicants must be registered contributors to the NHF for a minimum period, usually six months, before applying for the loan. (<a href="https://ibank.fmbn.gov.ng/Account/AgentRegistration">Click to Register to the Fund</a>)</li>
    <li>Employment Status:
      <ol>
        <li>For Salaried Employees: Must be in permanent employment with an organization that deducts and remits contributions to the NHF.</li>
        <li>For Self-Employed Individuals: Must have evidence of regular income and contributions to the NHF.</li>
      </ol>
    </li>
    <li>Contribution: Applicants must be consistent in their monthly contributions to the NHF (2.5% of monthly income for salaried employees or a stipulated amount for self-employed individuals).</li>
    <li>Loan Purpose: The loan must be used strictly for building, purchasing, or renovating residential property.</li>
    <li>Creditworthiness: Applicants must have a good credit history, free from outstanding or defaulted loans.</li>
    <li>Loan Tenure: The maximum loan tenure is typically 30 years, but it may vary based on the applicant's age and the loan amount.</li>
    <li>Down Payment: Equity contribution based on the loan amount is as follows:
      <ol>
        <li>N 6,000,000.00 to 50,000,000.00 - 10% equity</li>
        <li>N 5,000,000.00 or less - 0% equity</li>
      </ol>
    </li>
    <li>Maximum Loan Amount: The Federal Mortgage Bank of Nigeria (FMBN) provides a maximum loan amount of ₦50,000,000. However, the maximum amount an applicant can get is based on their contribution:
      <ol>
        <li>If you contribute between ₦0 - ₦10,000, the maximum loan amount you can get is ₦15,000,000.</li>
        <li>If you contribute between ₦10,001 - ₦25,000, the maximum loan amount you can get is ₦25,000,000.</li>
        <li>If you contribute above ₦25,000, you can get a maximum loan amount of ₦50,000,000.</li>
      </ol>
    </li>
  </ol>
`;

  const {
    register,
    reset,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
  } = useForm({ resolver: yupResolver(model) });
  const [isFormInitialized, setIsFormInitialized] = useState(false);

  const [planAccessibilities, setPlanAccessibilities] = useState({
    INDIVIDUALS: [
      {
        title: "Government Workers",
        description:
          "These are federal or state government workers, people with government jobs.",
        name: "governmentWorkers",
      },
      {
        title: "Private Workers",
        description:
          "These are workers that are employed in a private organization.",
        name: "privateWorkers",
      },
      {
        title: "Self Employed",
        description:
          "These are people who own their own business. Examples include business owners & entrepreneurs, content creators, freelancers, etc.",
        name: "selfEmployed",
      },
    ],
    MARRIED_COPOULES: [
      {
        title: "Government Workers",
        description:
          "These are federal or state government workers, people with government jobs.",
        name: "governmentWorkers",
      },
      {
        title: "Private Workers",
        description:
          "These are workers that are employed in a private organization.",
        name: "privateWorkers",
      },
      {
        title: "Self Employed",
        description:
          "These are people who own their own business. Examples include business owners & entrepreneurs, content creators, freelancers, etc.",
        name: "selfEmployed",
      },
    ],
    PARENTS_AND_CHILDERN: [
      {
        title: "Government Workers",
        description:
          "These are federal or state government workers, people with government jobs.",
        name: "governmentWorkers",
      },
      {
        title: "Private Workers",
        description:
          "These are workers that are employed in a private organization.",
        name: "privateWorkers",
      },
      {
        title: "Self Employed",
        description:
          "These are people who own their own business. Examples include business owners & entrepreneurs, content creators, freelancers, etc.",
        name: "selfEmployed",
      },
    ],
    SIBLINGS: [
      {
        title: "Government Workers",
        description:
          "These are federal or state government workers, people with government jobs.",
        name: "governmentWorkers",
      },
      {
        title: "Private Workers",
        description:
          "These are workers that are employed in a private organization.",
        name: "privateWorkers",
      },
      {
        title: "Self Employed",
        description:
          "These are people who own their own business. Examples include business owners & entrepreneurs, content creators, freelancers, etc.",
        name: "selfEmployed",
      },
    ],
    CORPORATES_COMPANIES: [
      {
        title: "Government Workers",
        description:
          "These are federal or state government workers, people with government jobs.",
        name: "governmentWorkers",
      },
      {
        title: "Private Workers",
        description:
          "These are workers that are employed in a private organization.",
        name: "privateWorkers",
      },
      {
        title: "Self Employed",
        description:
          "These are people who own their own business. Examples include business owners & entrepreneurs, content creators, freelancers, etc.",
        name: "selfEmployed",
      },
    ],
    FRIENDS_AND_BUSINESS_PARTNERS: [
      {
        title: "Government Workers",
        description:
          "These are federal or state government workers, people with government jobs.",
        name: "governmentWorkers",
      },
      {
        title: "Private Workers",
        description:
          "These are workers that are employed in a private organization.",
        name: "privateWorkers",
      },
      {
        title: "Self Employed",
        description:
          "These are people who own their own business. Examples include business owners & entrepreneurs, content creators, freelancers, etc.",
        name: "selfEmployed",
      },
    ],
    FACTIONAL_OWNERS: [
      {
        title: "Government Workers",
        description:
          "These are federal or state government workers, people with government jobs.",
        name: "governmentWorkers",
      },
      {
        title: "Private Workers",
        description:
          "These are workers that are employed in a private organization.",
        name: "privateWorkers",
      },
      {
        title: "Self Employed",
        description:
          "These are people who own their own business. Examples include business owners & entrepreneurs, content creators, freelancers, etc.",
        name: "selfEmployed",
      },
    ],
  });

  const initializeSelectedCheckboxes = (planAccessibilities) => {
    const initialState = {};
    Object.keys(planAccessibilities)?.forEach((category) => {
      initialState[category] = {};
      planAccessibilities[category]?.forEach((item) => {
        initialState[category][item.name] = false;
      });
    });
    return initialState;
  };

  const [selectedCheckboxes, setSelectedCheckboxes] = useState(
    initializeSelectedCheckboxes(planAccessibilities)
  );

  const handleCheckboxChange = (name, category) => {
    setSelectedCheckboxes((prevSelected) => ({
      ...prevSelected,
      [category]: {
        ...prevSelected[category],
        [name]: !prevSelected[category]?.[name],
      },
    }));
  };

  const handleSelectAllChange = (category) => {
    const allSelected = planAccessibilities[category]?.every(
      (item) => selectedCheckboxes[category]?.[item.name]
    );
    const newSelected = planAccessibilities[category].reduce((acc, item) => {
      acc[item.name] = !allSelected;
      return acc;
    }, {});
    setSelectedCheckboxes((prevSelected) => ({
      ...prevSelected,
      [category]: newSelected,
    }));
  };

  const onSubmit = (data) => {
    let checkedDocuments = defaultCheckableDocuments
      .filter((doc) => doc.checked)
      .map((doc) => ({
        id: doc.id,
        name: doc.name,
        description: doc.description,
        isRequired: doc.isRequired,
      }));

    if (showNHFDoc) {
      const checkedNHFDocuments = checkableNHFDocuments
        .filter((doc) => doc.checked)
        .map((doc) => ({
          id: doc.id,
          name: doc.name,
          description: doc.description,
          isRequired: doc.isRequired,
        }));

      checkedDocuments = checkedDocuments.concat(checkedNHFDocuments);
    }

    if (showFMBDoc) {
      const checkedFMBDocuments = checkableFMBDocuments
        .filter((doc) => doc.checked)
        .map((doc) => ({
          id: doc.id,
          name: doc.name,
          description: doc.description,
          isRequired: doc.isRequired,
        }));

      checkedDocuments = checkedDocuments.concat(checkedFMBDocuments);
    }

    if (documentForm?.length > 0) {
      const docForm = documentForm.map((doc) => ({
        name: doc.name,
        description: doc.description,
        isRequired: doc.isRequired,
      }));

      checkedDocuments = checkedDocuments.concat(docForm);
    }

    if (!data?.numberOfMissedPayments) {
      data.numberOfMissedPayments = 0;
    } else if (typeof data?.numberOfMissedPayments === "string") {
      data.numberOfMissedPayments = parseFloat(
        data.numberOfMissedPayments.replace(/,/g, "")
      );
    }

    data["organizationId"] = authUser?.user?.organizationId;

    data = {
      ...data,
      maxLoanAmount: data?.maxLoanAmount
        ? getNumber(data?.maxLoanAmount)
        : null,
      deliveryLevelPrice: data?.deliveryLevelPrice
        ? getNumber(data?.deliveryLevelPrice)
        : null,
      mortgagePeriod: data?.mortgagePeriod
        ? getNumber(data?.mortgagePeriod)
        : null,
      maxAge: data?.maxAge ? getNumber(data?.maxAge) : null,
      interestRate: data?.interestRate ? getNumber(data?.interestRate) : null,
      loanTenor: data?.loanTenor ? getNumber(data?.loanTenor) : null,
      equityPercentage: data?.equityPercentage
        ? getNumber(data?.equityPercentage)
        : null,
      processingPeriod: data?.processingPeriod
        ? getNumber(data?.processingPeriod)
        : null,
      defaultFee: data?.defaultFee ? getNumber(data?.defaultFee) : null,
      gracePeriod: data?.gracePeriod ? getNumber(data?.gracePeriod) : null,
      numberOfMissedPayments: data?.numberOfMissedPayments,
    };

    // Convert processingForm.amount to numbers
    const processedProcessingForm = processingForm?.map((item) => {
      let amount = item?.amount;

      if (typeof amount === "string") {
        amount = parseFloat(amount.replace(/,/g, ""));
      } else if (typeof amount === "number") {
        amount = parseFloat(amount);
      } else {
        amount = 0;
      }

      return {
        ...item,
        amount: amount,
      };
    });
    if (isNullOrEmpty(data?.id)) {
      const formData = {
        ownerType: "DEVELOPER",
        bankName: selectedBank,
        type: planType,
        interestType: selectedInterestType,
        name: data.name,
        description: data.description,
        deliveryLevelPrice: Number(data.deliveryLevelPrice),
        deliveryDescription: data.deliveryDescription,
        occupancyPolicy: data.occupancyPolicy,
        deliveryLevel: selectedDeliveryLevel,
        completionRate: selectedCompletionRate,
        eligibilityCriteria: eligibilityCriteria,
        numberOfMissedPayments: data?.numberOfMissedPayments ?? 0,
        propertyRecoveryTerms: data.propertyRecoveryTerms,
        mortgagePeriod: Number(data.processingPeriod),
        access: selectedCheckboxes ? JSON.stringify(selectedCheckboxes) : null,
        maxLoanAmount: Number(data.maxLoanAmount),
        maxAge: Number(data.maxAge),
        interestRate: Number(data.interestRate),
        loanTenor: Number(data.loanTenor),
        equityPercentage: Number(data.equityPercentage),
        organizationId: authUser?.user?.organizationId,
        processingPeriod: Number(data.processingPeriod),
        defaultFee: Number(data?.defaultFee),
        gracePeriod: Number(data?.gracePeriod),
        applicableFees: processedProcessingForm,
        houses: [houseId] ?? null,
        documents: checkedDocuments,
        repaymentMethods: [],
      };
      // console.log("sumitedformData", formData);
      createPlans.mutate(formData);
    } else {
      data["id"] = plan.id;
      const formData = {
        ownerType: "DEVELOPER",
        bankName: selectedBank,
        type: planType,
        interestType: selectedInterestType,
        name: data.name,
        // name: null,
        description: data.description,
        deliveryLevelPrice: Number(data.deliveryLevelPrice),
        deliveryDescription: data.deliveryDescription,
        propertyRecoveryTerms: data.propertyRecoveryTerms,
        occupancyPolicy: data.occupancyPolicy,
        deliveryLevel: selectedDeliveryLevel,
        completionRate: selectedCompletionRate,
        eligibilityCriteria: eligibilityCriteria,
        numberOfMissedPayments: data?.numberOfMissedPayments ?? 0,
        mortgagePeriod: Number(data.processingPeriod),
        access: selectedCheckboxes ? JSON.stringify(selectedCheckboxes) : null,
        maxAge: Number(data.maxAge),
        maxLoanAmount: Number(data.maxLoanAmount),
        interestRate: Number(data.interestRate),
        loanTenor: Number(data.loanTenor),
        equityPercentage: Number(data.equityPercentage),
        defaultFee: Number(data.defaultFee),
        gracePeriod: Number(data.gracePeriod),
        organizationId: authUser?.user?.organizationId,
        processingPeriod: Number(data.processingPeriod),
        applicableFees: processedProcessingForm,
        houses: [houseId] ?? null,
        documents: checkedDocuments,
        repaymentMethods: [],
      };

      // console.log("update", formData);
      updatePlans.mutate(formData);
    }
  };

  const handleAddDynamicForm = () => {
    if (processingForm?.length && processingForm[0].name === "") {
      toast.error(`Please enter a fee name!`);
      return;
    }
    if (processingForm?.length && processingForm[0].amount === 0) {
      toast.error(`Please enter the fee amount!`);
      return;
    }
    let newForm = {
      name: "",
      amount: 0,
      type: "FIXED",
    };
    setProcessingForm([newForm, ...processingForm]);
  };

  const handleChangeEventDynamicForm = (name, value, index) => {
    let dynamicInputData = [...processingForm];

    if (name === "amount" && dynamicInputData[index].type === "PERCENTAGE") {
      if (!onlyNumbers(value) || value < 1 || value > 100) {
        dynamicInputData[index].error =
          "Percentage must be a number between 1 and 100";
        dynamicInputData[index][name] = "";
      } else {
        dynamicInputData[index].error = "";
        dynamicInputData[index][name] = value;
      }
    } else {
      dynamicInputData[index][name] = value;
      dynamicInputData[index].error = "";
    }

    setProcessingForm(dynamicInputData);
  };

  const handleFeeTypeChange = (value, index) => {
    let dynamicInputData = [...processingForm];
    dynamicInputData[index]["type"] = value;
    dynamicInputData[index]["amount"] = 0; // Reset amount on fee type change
    dynamicInputData[index]["error"] = ""; // Reset error on fee type change
    setProcessingForm(dynamicInputData);
  };

  const handleRemoveFeature = (index) => {
    let newData = [...processingForm];
    newData.splice(index, 1);
    setProcessingForm(newData);
  };

  const handleAddDocumentForm = () => {
    if (documentForm.length && documentForm[0].name === "") {
      toast.error(`Please enter a document name`);
      return;
    }
    if (documentForm.length && documentForm[0].description === "") {
      toast.error(
        `Please enter a description for this document${documentForm.length}!`
      );
      return;
    }
    let newForm = {
      name: "",
      description: "",
      isRequired: false,
    };
    setDocumentForm([newForm, ...documentForm]);
  };

  const handleChangeEventDocumentForm = (name, value, index) => {
    let dynamicInputData = [...documentForm];
    if (name === "isRequired") {
      dynamicInputData[index][name] = !dynamicInputData[index][name];
    } else {
      dynamicInputData[index][name] = value;
    }
    setDocumentForm(dynamicInputData);
  };

  const handleRemoveDocumentForm = (index) => {
    let newData = [...documentForm];
    newData.splice(index, 1);
    setDocumentForm(newData);
  };

  const handleSelectInterestType = (e) => {
    let id = e;

    if (id) {
      const interestName = interestTypes.find((x) => x.id === id).name;
      setInterestTypeName(interestName);
      setSelectedInterestType(id);
    }
  };

  const handleSelectPlanType = (e) => {
    let id = e;
    reset();

    if (id) {
      const planTypeName = planTypes.find((x) => x.id === id).name;
      setPlanTypeName(planTypeName);
      setPlanType(id);
    } else {
      return;
    }

    if (removeUnderscore(id) === "OUTRIGHTPLAN") {
      setValue("equityPercentage", "100");
      setValue("loanTenor", null);
    }

    if (removeUnderscore(id) === "RENTTOOWN") {
      setDisable(true);
      setShowFMBDoc(true);
      setValue("interestRate", "7");
      setValue("loanTenor", "360");
      setValue("maxAge", "60");
      setValue("maxLoanAmount", "50,000,000");
      setInterestTypeName("Compound Interest");
      setSelectedInterestType("COMPOUND_INTEREST");
      setEligibilityCriteria(NHFANDRentToOwnCriteria);
      setDisableEligibilityCriteria(true);
    }

    if (removeUnderscore(id) !== "RENTTOOWN") {
      setShowFMBDoc(false);
    }
    if (removeUnderscore(id) === "HELPTOOWN") {
      setDisable(true);
      setShowFMBDoc(true);
      setValue("interestRate", "13.91");
      setValue("loanTenor", "20");
      setValue("maxAge", "60");
      setValue("maxLoanAmount", "35,000,000");
      setInterestTypeName("Compound Interest");
      setSelectedInterestType("COMPOUND_INTEREST");
      setEligibilityCriteria(NHFANDRentToOwnCriteria);
      setDisableEligibilityCriteria(true);
    }

    if (removeUnderscore(id) !== "HELPTOOWN") {
      setShowFMBDoc(false);
    }

    if (removeUnderscore(id) === "NHFPLAN") {
      setDisable(true);
      setShowNHFDoc(true);
      setValue("interestRate", "6");
      setValue("loanTenor", "360");
      setValue("maxAge", "60");
      setValue("maxLoanAmount", "50,000,000");
      setInterestTypeName("Compound Interest");
      setSelectedInterestType("COMPOUND_INTEREST");
      setEligibilityCriteria(NHFANDRentToOwnCriteria);
      setDisableEligibilityCriteria(true);
    }

    if (removeUnderscore(id) !== "NHFPLAN") {
      setShowNHFDoc(false);
    }

    if (removeUnderscore(id) === "MORTGAGEPLAN") {
      setValue("maxAge", "60");
    }

    if (removeUnderscore(id) !== "MORTGAGEPLAN") {
      setDisable(false);
      setBankName(null);
      setSelectedBank(null);
    }

    if (
      removeUnderscore(id) !== "NHFPLAN" &&
      removeUnderscore(id) !== "RENTTOOWN" &&
      removeUnderscore(id) !== "MORTGAGEPLAN"
    ) {
      setInterestTypeName(null);
      setSelectedInterestType(null);
    }

    if (
      removeUnderscore(id) !== "NHFPLAN" &&
      removeUnderscore(id) !== "RENTTOOWN"
    ) {
      setEligibilityCriteria(null);
      setDisableEligibilityCriteria(false);
    }
  };

  const handleDocCheck = (docName) => {
    const updatedDefaultDocs = defaultCheckableDocuments.map((doc) =>
      doc.name === docName ? { ...doc, checked: !doc.checked } : doc
    );

    const updatedNHFDocs = checkableNHFDocuments.map((doc) =>
      doc.name === docName ? { ...doc, checked: !doc.checked } : doc
    );

    const updatedFMBDocs = checkableFMBDocuments.map((doc) =>
      doc.name === docName ? { ...doc, checked: !doc.checked } : doc
    );

    setCheckableNHFDocuments(updatedNHFDocs);
    setCheckableFMBDocuments(updatedFMBDocs);
    setDefaultCheckableDocuments(updatedDefaultDocs);
  };

  useEffect(() => {
    if (!isFormInitialized) {
      setIsFormInitialized(true);
    }
  }, [isDirty]);

  useEffect(() => {
    if (isFormInitialized) {
      if (interestType) {
        handleSelectInterestType(interestType);
      }

      if (planType) {
        handleSelectPlanType(planType);
      }
    }
  }, [isFormInitialized]);

  useEffect(() => {
    if (plan) {
      setPlanId(plan.id);
      const newPlanDetails = {
        name: plan.name,
        maxAge: plan.maxAge,
        maxLoanAmount: formattedPrice(plan.maxLoanAmount),
        deliveryLevelPrice: formattedPrice(plan.deliveryLevelPrice),
        interestRate: plan.interestRate < 1 ? "0%" : `${plan.interestRate}%`,
        mortgagePeriod: `${plan.mortgagePeriod}`,
        loanTenor: `${plan.loanTenor} Months`,
        processingPeriod: `${plan.processingPeriod} Days`,
        gracePeriod: `${plan.gracePeriod} Days`,
        numberOfMissedPayments: plan.numberOfMissedPayments,
        access: plan.access,
        equityPercentage: `${plan.equityPercentage}%`,
        defaultFee: formattedPrice(plan.defaultFee),
        description: plan.description,
        deliveryDescription: plan.deliveryDescription,
      };

      if (plan.access) {
        const parsedAccess = JSON.parse(plan.access);
        setSelectedCheckboxes(parsedAccess);
      }
      setEligibilityCriteria(plan.eligibilityCriteria);
      setPlanDetails(newPlanDetails);
      setProcessingForm(plan.applicableFees);

      if (
        removeUnderscore(plan.type) === "RENTTOOWN" ||
        removeUnderscore(plan.type) === "HELPTOOWN"
      ) {
        setShowFMBDoc(true);
      }

      if (removeUnderscore(plan.type) === "NHFPLAN") {
        setShowNHFDoc(true);
      }

      const planName = planTypes.find((x) => x.id === plan.type)?.name;
      setPlanTypeName(planName);
      setPlanType(plan.type);

      const interestName = interestTypes.find(
        (x) => x.id === plan.interestType
      )?.name;
      setInterestTypeName(interestName);
      setSelectedInterestType(plan.interestType);

      const deliveryLevelName = deliveryLevelsLists.find(
        (x) => x.id === plan.deliveryLevel
      )?.name;
      setDeliveryLevelName(deliveryLevelName);
      setSelectedDeliveryLevel(plan.deliveryLevel);

      const completionRateName = completionRateLists.find(
        (x) => x.id === plan.completionRate
      )?.name;
      setCompletionRateName(completionRateName);
      setSelectedCompletionRate(plan.completionRate);

      const bankName = bankTypes.find((x) => x.id === plan.bankName)?.name;
      setBankName(bankName);
      setSelectedBank(plan.bankName);

      const nhfDocumentIds = checkableNHFDocuments?.map((doc) => doc.id);
      const fmbDocumentIds = checkableFMBDocuments?.map((doc) => doc.id);
      const defaultDocumentIds = defaultCheckableDocuments?.map(
        (doc) => doc.id
      );

      const docForm = plan?.requiredDocuments
        ?.filter(
          (doc) =>
            !nhfDocumentIds.includes(doc.optionId) &&
            !fmbDocumentIds.includes(doc.optionId) &&
            !defaultDocumentIds.includes(doc.optionId)
        )
        .map((doc) => ({
          name: doc.option?.name,
          isRequired: doc.option?.isRequired,
          description: doc.option?.description,
        }));

      // Update NHF Documents to be checked if they are required by the plan
      const updatedNHFDocuments = checkableNHFDocuments.map((doc) => ({
        ...doc,
        checked: plan.requiredDocuments.some(
          (requiredDoc) => requiredDoc.optionId === doc.id
        ),
      }));

      // Update FMB Documents to be checked if they are required by the plan
      const updatedFMBDocuments = checkableFMBDocuments.map((doc) => ({
        ...doc,
        checked: plan.requiredDocuments.some(
          (requiredDoc) => requiredDoc.optionId === doc.id
        ),
      }));

      setCheckableNHFDocuments(updatedNHFDocuments);
      setCheckableFMBDocuments(updatedFMBDocuments);
      setDocumentForm(docForm);
    }
  }, [plan]);

  useEffect(() => {
    reset(planDetails, processingForm, documentForm);
  }, [planDetails, reset]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {plan && (
        <input
          {...register("id")}
          value={plan.id}
          style={{ display: "none" }}
        />
      )}

      <div className="h4 mt-4">Plan Details</div>
      <div className="row">
        {removeUnderscore(planType) === "MORTGAGEPLAN" && (
          <div className="col-sm-6 col-md-4 mb-2">
            <Dropdown
              required
              labelName={"Bank Name"}
              placeholder={"Select"}
              value={bankName}
              options={bankTypes}
              onChange={(e) => {
                let id = e.target.value;

                if (id) {
                  const bankName = bankTypes.find((x) => x.id === id).name;
                  setBankName(bankName);
                  setSelectedBank(id);
                }
              }}
            />
          </div>
        )}

        <div className="col-sm-6 col-md-4 mb-2">
          <Dropdown
            labelName={"Plan Type"}
            required={true}
            value={planTypeName}
            placeholder={"Select"}
            options={planTypes}
            disabled={plan?.id}
            onChange={(e) => handleSelectPlanType(e?.target?.value)}
          />
        </div>

        <div className="col-sm-6 col-md-4 mb-2">
          <FormInput
            labelName={"Plan Name"}
            placeholder={"Disapora Track plan"}
            required
            register={register("name")}
            error={errors?.name?.message}
          />
        </div>

        {removeUnderscore(planType) === "MORTGAGEPLAN" && (
          <div className="col-sm-6 col-md-4 mb-2">
            <Dropdown
              labelName={"Interest Type"}
              required={true}
              value={interestTypeName}
              placeholder={"Select"}
              options={interestTypes}
              onChange={(e) => handleSelectInterestType(e?.target?.value)}
              disabled={disable}
            />
          </div>
        )}

        {(removeUnderscore(planType) === "NHFPLAN" ||
          removeUnderscore(planType) === "MORTGAGEPLAN" ||
          removeUnderscore(planType) === "HELPTOOWN" ||
          removeUnderscore(planType) === "RENTTOOWN") && (
          <div className="col-sm-6 col-md-4 mb-2">
            <FormInput
              labelName={"Interest Rate (%)"}
              required
              placeholder={"Figures are in percentage"}
              register={register("interestRate")}
              hintText={"Enter only numbers. E.g 10, not 10%"}
              error={errors?.interestRate?.message}
              disabled={
                removeUnderscore(planType) !== "MORTGAGEPLAN" && !disable
              }
            />
          </div>
        )}

        {(removeUnderscore(planType) === "NHFPLAN" ||
          removeUnderscore(planType) === "MORTGAGEPLAN" ||
          removeUnderscore(planType) === "HELPTOOWN" ||
          removeUnderscore(planType) === "RENTTOOWN") && (
          <div
            className="col-sm-6 col-md-4 mb-2"
            onBlur={(e) =>
              setValue(
                "maxLoanAmount",
                !onlyNumbers(e.target.value)
                  ? formattedPrice(plan?.maxLoanAmount)
                  : formattedPrice(e.target.value)
              )
            }
          >
            <FormInput
              labelName={"Max Loan Amount"}
              required
              placeholder={"Enter max loan amount"}
              register={register("maxLoanAmount")}
              error={errors?.maxLoanAmount?.message}
              disabled={
                removeUnderscore(planType) !== "MORTGAGEPLAN" && !disable
              }
            />
          </div>
        )}

        {removeUnderscore(planType) !== "OUTRIGHTPLAN" && (
          <div
            className="col-sm-6 col-md-4 mb-2"
            onBlur={(e) => {
              if (removeUnderscore(planType) === "MORTGAGEPLAN") {
                if (e.target.value < 12) {
                  setValue("loanTenor", "");
                  setLoanTenorError(
                    "Minimum loan tenor for Mortgage Plan is 12 months"
                  );
                  return;
                }
                setLoanTenorError("");
                setValue("loanTenor", e.target.value);
              }
            }}
          >
            <FormInput
              labelName={"Payment Period (In Months)"}
              required
              placeholder={"Figures are in months"}
              register={register("loanTenor")}
              hintText={"Enter only numbers. E.g 24, not 24 Months"}
              error={loanTenorError}
              disabled={
                removeUnderscore(planType) !== "MORTGAGEPLAN" &&
                removeUnderscore(planType) !== "PAYMENTPLAN" &&
                !disable
              }
            />
          </div>
        )}

        {removeUnderscore(planType) !== "OUTRIGHTPLAN" && (
          <div className="col-sm-6 col-md-4 mb-2">
            <FormInput
              labelName={"Minimum Downpayment (%)"}
              placeholder={"Figures are in percentage"}
              required
              register={register("equityPercentage")}
              hintText={"Enter only numbers. E.g 10, not 10%"}
              error={errors?.equityPercentage?.message}
            />
          </div>
        )}

        <div className="col-sm-6 col-md-4 mb-2">
          <FormInput
            labelName={"Application Processing Time (in days)"}
            placeholder={"How many days?"}
            required
            register={register("processingPeriod")}
            hintText={"Enter only numbers. E.g 10, not 10 days"}
            error={errors?.processingPeriod?.message}
          />
        </div>

        {removeUnderscore(planType) !== "OUTRIGHTPLAN" && (
          <div className="col-sm-6 col-md-4 mb-4">
            <FormInput
              labelName={"Monthly Default Fee (%)"}
              placeholder={"Figures are in percentage"}
              register={register("defaultFee")}
              hintText={"Enter only numbers. E.g 10, not 10%"}
              error={errors?.defaultFee?.message}
            />
          </div>
        )}

        {removeUnderscore(planType) !== "OUTRIGHTPLAN" && (
          <div className="col-sm-6 col-md-4 mb-4">
            <FormInput
              labelName={"Grace Period (in days)"}
              placeholder={"How many day?"}
              register={register("gracePeriod")}
              hintText={"Enter only numbers. E.g 10, not 10 days"}
              error={errors?.gracePeriod?.message}
            />
          </div>
        )}

        {(removeUnderscore(planType) === "RENTTOOWN" ||
          removeUnderscore(planType) === "HELPTOOWN" ||
          removeUnderscore(planType) === "MORTGAGEPLAN" ||
          removeUnderscore(planType) === "NHFPLAN") && (
          <div className="col-sm-6 col-md-4 mb-4">
            <FormInput
              labelName={"Max Age (Years)"}
              required
              placeholder={"Maximum age limit in years"}
              register={register("maxAge")}
              hintText={"Enter only numbers. E.g 10, not 10 Years"}
              error={errors?.maxAge?.message}
              disabled={
                removeUnderscore(planType) === "MORTGAGEPLAN"
                  ? disable
                  : !disable
              }
            />
          </div>
        )}

        {removeUnderscore(planType) !== "OUTRIGHTPLAN" && (
          <div
            className="col-sm-6 col-md-4 mb-4"
            onBlur={(e) =>
              setValue(
                "numberOfMissedPayments",
                !onlyNumbers(e.target.value)
                  ? formattedPrice(plan?.numberOfMissedPayments)
                  : formattedPrice(e.target.value)
              )
            }
          >
            <FormInput
              labelName={"Maximum Missed Payments Before Property Recovery"}
              placeholder={"Payments missed?"}
              register={register("numberOfMissedPayments")}
              hintText={
                "How many payments can a customer miss before you recover your property"
              }
              error={errors?.numberOfMissedPayments?.message}
            />
          </div>
        )}

        <div
          className="col-sm-6 col-md-4 mb-4"
          onBlur={(e) =>
            setValue(
              "deliveryLevelPrice",
              !onlyNumbers(e.target.value)
                ? formattedPrice(plan?.deliveryLevelPrice)
                : formattedPrice(e.target.value)
            )
          }
        >
          <FormInput
            labelName={"Delivery Level Price"}
            required
            placeholder={"Enter the delivery level price"}
            register={register("deliveryLevelPrice")}
            error={errors?.deliveryLevelPrice?.message}
          />
        </div>

        <div className="col-sm-6 col-md-4 mb-4 position-relative">
          <Dropdown
            labelName={"Delivery Level"}
            required={true}
            placeholder={"Select"}
            value={deliveryLevelName}
            options={deliveryLevelsLists}
            hintText={
              "At what level of development do you want to sell houses on this plan?"
            }
            onChange={(e) => {
              let id = e.target.value;
              if (id) {
                const deliveryLevelName = deliveryLevelsLists.find(
                  (x) => x.id === id
                ).name;
                setDeliveryLevelName(deliveryLevelName);
                setSelectedDeliveryLevel(id);
              }
            }}
          />
        </div>

        <div className="col-sm-6 col-md-4 mb-4">
          <Dropdown
            labelName={"Current Completion Rate"}
            placeholder={"Select"}
            value={completionRateName}
            options={completionRateLists}
            hintText={
              "What’s the completion rate of the property at this delivery level?"
            }
            onChange={(e) => {
              let id = e.target.value;
              if (id) {
                const completionRateName = completionRateLists.find(
                  (x) => x.id === id
                ).name;
                setCompletionRateName(completionRateName);
                setSelectedCompletionRate(id);
              }
            }}
          />
        </div>

        <div className="col-sm-6 col-md-4 mb-4">
          <MultitextInput
            required
            label={"Delivery Level Description"}
            placeholder={"Describe the delivery Level"}
            register={register("deliveryDescription")}
            error={errors?.deliveryDescription?.message}
          />
        </div>

        {(removeUnderscore(planType) === "NHFPLAN" ||
          removeUnderscore(planType) === "MORTGAGEPLAN" ||
          removeUnderscore(planType) === "HELPTOOWN" ||
          removeUnderscore(planType) === "RENTTOOWN") && (
          <div className="col-sm-6 col-md-4 mb-4">
            <MultitextInput
              label={"Description"}
              placeholder={"Describe the plan to customer"}
              register={register("description")}
              error={errors?.description?.message}
            />
          </div>
        )}

        <div className="col-sm-6 col-md-4 mb-4">
          <MultitextInput
            label={"Occupancy Policy"}
            placeholder={"Please describe your occupancy policy in detail"}
            register={register("occupancyPolicy")}
            error={errors?.occupancyPolicy?.message}
          />
        </div>

        {removeUnderscore(planType) !== "OUTRIGHTPLAN" && (
          <div className="col-sm-6 col-md-4 mb-4">
            <MultitextInput
              label={"Missed Payment Policy"}
              placeholder={"What's your policy on missed payments"}
              register={register("propertyRecoveryTerms")}
              error={errors?.propertyRecoveryTerms?.message}
            />
          </div>
        )}

        <div className="col-md-12 mt-3">
          <CustomTextEditor
            height={"15rem"}
            labelName={"Eligibility Criteria"}
            value={eligibilityCriteria}
            placeholder={"eg. Enter Eligibility Criteria"}
            onChange={(e) => setEligibilityCriteria(e)}
            readOnly={disableEligibilityCriteria}
          />
        </div>
      </div>

      <hr className="hr mt-3" />

      <div className="mt-4  pb-3 pt-2">
        <div className="h4 mt-4">Plan Accessibility</div>
        <p>
          What kind of people can access this plan?{" "}
          <strong>You can select multiple.</strong>
        </p>

        {/* Individuals  */}
        <div>
          <div>
            <div className="d-flex align-items-center justify-content-end gap-2 my-3">
              <p className="m-0">Select All</p>
              <div className="">
                <CheckInput
                  name={`selectAll_${"INDIVIDUALS"}`}
                  checked={planAccessibilities["INDIVIDUALS"]?.every(
                    (item) => selectedCheckboxes["INDIVIDUALS"]?.[item.name]
                  )}
                  checkTick={true}
                  width={"23px"}
                  height={"23px"}
                  bcolor={"#0000001A"}
                  bordercolor={"#CECECE"}
                  onChange={() => handleSelectAllChange("INDIVIDUALS")}
                />
              </div>
            </div>
            <div
              className="plan_scroll_container pb-4 m-0"
              style={{ borderBottom: "1px solid gainsboro" }}
            >
              <div className="">
                <h5 style={{ color: colors.darkGreen }}>Individuals</h5>
                <p>
                  Only one person can apply to own the property.{" "}
                  <strong>
                    Select the different employment types that can apply.
                  </strong>
                </p>
              </div>

              {planAccessibilities?.INDIVIDUALS?.map((item, index) => (
                <div key={index} className="">
                  <PlanAccessibilityCard
                    title={item.title}
                    description={item.description}
                    name={item.name}
                    checked={
                      selectedCheckboxes["INDIVIDUALS"]?.[item.name] || false
                    }
                    handler={() =>
                      handleCheckboxChange(item.name, "INDIVIDUALS")
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Married Couples & Commmon Law Partners  */}
        <div>
          <div>
            <div className="d-flex align-items-center justify-content-end gap-2 my-3 mt-5">
              <p className="m-0">Select All</p>
              <div className="">
                <CheckInput
                  name={`selectAll_${"MARRIED_COPOULES"}`}
                  checked={planAccessibilities["MARRIED_COPOULES"]?.every(
                    (item) =>
                      selectedCheckboxes["MARRIED_COPOULES"]?.[item.name]
                  )}
                  checkTick={true}
                  width={"23px"}
                  height={"23px"}
                  bcolor={"#0000001A"}
                  bordercolor={"#CECECE"}
                  onChange={() => handleSelectAllChange("MARRIED_COPOULES")}
                />
              </div>
            </div>
            <div
              className="plan_scroll_container pb-4 m-0"
              style={{ borderBottom: "1px solid gainsboro" }}
            >
              <div className="">
                <h5 style={{ color: colors.darkGreen }}>
                  Married Couples & Commmon Law Partners
                </h5>
                <p>
                  These are people who have been partnered by law.{" "}
                  <strong>
                    Select the different employment types that can apply.
                  </strong>
                </p>
              </div>
              {planAccessibilities?.MARRIED_COPOULES?.map((item, index) => (
                <div key={index} className="">
                  <PlanAccessibilityCard
                    title={item.title}
                    description={item.description}
                    name={item.name}
                    checked={
                      selectedCheckboxes["MARRIED_COPOULES"]?.[item.name] ||
                      false
                    }
                    handler={() =>
                      handleCheckboxChange(item.name, "MARRIED_COPOULES")
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Parents & Their Children  */}
        <div>
          <div>
            <div className="d-flex align-items-center justify-content-end gap-2 my-3 mt-5">
              <p className="m-0">Select All</p>
              <div className="">
                <CheckInput
                  name={`selectAll_${"PARENTS_AND_CHILDERN"}`}
                  checked={planAccessibilities["PARENTS_AND_CHILDERN"]?.every(
                    (item) =>
                      selectedCheckboxes["PARENTS_AND_CHILDERN"]?.[item.name]
                  )}
                  checkTick={true}
                  width={"23px"}
                  height={"23px"}
                  bcolor={"#0000001A"}
                  bordercolor={"#CECECE"}
                  onChange={() => handleSelectAllChange("PARENTS_AND_CHILDERN")}
                />
              </div>
            </div>
            <div
              className="plan_scroll_container pb-4 m-0"
              style={{ borderBottom: "1px solid gainsboro" }}
            >
              <div className="">
                <h5 style={{ color: colors.darkGreen }}>
                  Parents & Their Children
                </h5>
                <p>
                  Parents and their children will be able to apply.{" "}
                  <strong>
                    Select the different employment types that can apply.
                  </strong>
                </p>
              </div>
              {planAccessibilities?.PARENTS_AND_CHILDERN?.map((item, index) => (
                <div key={index} className="">
                  <PlanAccessibilityCard
                    title={item.title}
                    description={item.description}
                    name={item.name}
                    checked={
                      selectedCheckboxes["PARENTS_AND_CHILDERN"]?.[item.name] ||
                      false
                    }
                    handler={() =>
                      handleCheckboxChange(item.name, "PARENTS_AND_CHILDERN")
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Siblings  */}
        <div>
          <div>
            <div className="d-flex align-items-center justify-content-end gap-2 my-3 mt-5">
              <p className="m-0">Select All</p>
              <div className="">
                <CheckInput
                  name={`selectAll_${"SIBLINGS"}`}
                  checked={planAccessibilities["SIBLINGS"]?.every(
                    (item) => selectedCheckboxes["SIBLINGS"]?.[item.name]
                  )}
                  checkTick={true}
                  width={"23px"}
                  height={"23px"}
                  bcolor={"#0000001A"}
                  bordercolor={"#CECECE"}
                  onChange={() => handleSelectAllChange("SIBLINGS")}
                />
              </div>
            </div>
            <div
              className="plan_scroll_container pb-4 m-0"
              style={{ borderBottom: "1px solid gainsboro" }}
            >
              <div className="">
                <h5 style={{ color: colors.darkGreen }}>Siblings</h5>
                <p>
                  Brothers and or sisters related by blood will be able to
                  apply.{" "}
                  <strong>
                    Select the different employment types that can apply.
                  </strong>
                </p>
              </div>
              {planAccessibilities?.SIBLINGS?.map((item, index) => (
                <div key={index} className="">
                  <PlanAccessibilityCard
                    title={item.title}
                    description={item.description}
                    name={item.name}
                    checked={
                      selectedCheckboxes["SIBLINGS"]?.[item.name] || false
                    }
                    handler={() => handleCheckboxChange(item.name, "SIBLINGS")}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Corporates & Companies  */}
        <div>
          <div>
            <div className="d-flex align-items-center justify-content-end gap-2 my-3 mt-5">
              <p className="m-0">Select All</p>
              <div className="">
                <CheckInput
                  name={`selectAll_${"CORPORATES_COMPANIES"}`}
                  checked={planAccessibilities["CORPORATES_COMPANIES"]?.every(
                    (item) =>
                      selectedCheckboxes["CORPORATES_COMPANIES"]?.[item.name]
                  )}
                  checkTick={true}
                  width={"23px"}
                  height={"23px"}
                  bcolor={"#0000001A"}
                  bordercolor={"#CECECE"}
                  onChange={() => handleSelectAllChange("CORPORATES_COMPANIES")}
                />
              </div>
            </div>
            <div
              className="plan_scroll_container pb-4 m-0"
              style={{ borderBottom: "1px solid gainsboro" }}
            >
              <div className="">
                <h5 style={{ color: colors.darkGreen }}>
                  Corporates & Companies
                </h5>
                <p>
                  Registered corporate entities and businesses can apply own
                  your properties.{" "}
                  <strong>
                    Select the different employment types that can apply.
                  </strong>
                </p>
              </div>
              {planAccessibilities?.CORPORATES_COMPANIES?.map((item, index) => (
                <div key={index} className="">
                  <PlanAccessibilityCard
                    title={item.title}
                    description={item.description}
                    name={item.name}
                    checked={
                      selectedCheckboxes["CORPORATES_COMPANIES"]?.[item.name] ||
                      false
                    }
                    handler={() =>
                      handleCheckboxChange(item.name, "CORPORATES_COMPANIES")
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Friends & Business Partners  */}
        <div>
          <div>
            <div className="d-flex align-items-center justify-content-end gap-2 my-3 mt-5">
              <p className="m-0">Select All</p>
              <div className="">
                <CheckInput
                  name={`selectAll_${"FRIENDS_AND_BUSINESS_PARTNERS"}`}
                  checked={planAccessibilities[
                    "FRIENDS_AND_BUSINESS_PARTNERS"
                  ]?.every(
                    (item) =>
                      selectedCheckboxes["FRIENDS_AND_BUSINESS_PARTNERS"]?.[
                        item.name
                      ]
                  )}
                  checkTick={true}
                  width={"23px"}
                  height={"23px"}
                  bcolor={"#0000001A"}
                  bordercolor={"#CECECE"}
                  onChange={() =>
                    handleSelectAllChange("FRIENDS_AND_BUSINESS_PARTNERS")
                  }
                />
              </div>
            </div>
            <div
              className="plan_scroll_container pb-4 m-0"
              style={{ borderBottom: "1px solid gainsboro" }}
            >
              <div className="">
                <h5 style={{ color: colors.darkGreen }}>
                  Friends & Business Partners
                </h5>
                <p>
                  Individuals with a business relationship and/or personal
                  relationship.{" "}
                  <strong>
                    Select the different employment types that can apply.
                  </strong>
                </p>
              </div>
              {planAccessibilities?.FRIENDS_AND_BUSINESS_PARTNERS?.map(
                (item, index) => (
                  <div key={index} className="">
                    <PlanAccessibilityCard
                      title={item.title}
                      description={item.description}
                      name={item.name}
                      checked={
                        selectedCheckboxes["FRIENDS_AND_BUSINESS_PARTNERS"]?.[
                          item.name
                        ] || false
                      }
                      handler={() =>
                        handleCheckboxChange(
                          item.name,
                          "FRIENDS_AND_BUSINESS_PARTNERS"
                        )
                      }
                    />
                  </div>
                )
              )}
            </div>
          </div>
        </div>

        {/* Fractional Owners.  */}
        <div>
          <div>
            <div className="d-flex align-items-center justify-content-end gap-2 my-3 mt-5">
              <p className="m-0">Select All</p>
              <div className="">
                <CheckInput
                  name={`selectAll_${"FACTIONAL_OWNERS"}`}
                  checked={planAccessibilities["FACTIONAL_OWNERS"]?.every(
                    (item) =>
                      selectedCheckboxes["FACTIONAL_OWNERS"]?.[item.name]
                  )}
                  checkTick={true}
                  width={"23px"}
                  height={"23px"}
                  bcolor={"#0000001A"}
                  bordercolor={"#CECECE"}
                  onChange={() => handleSelectAllChange("FACTIONAL_OWNERS")}
                />
              </div>
            </div>
            <div
              className="plan_scroll_container pb-4 m-0"
              style={{ borderBottom: "1px solid gainsboro" }}
            >
              <div className="">
                <h5 style={{ color: colors.darkGreen }}>Fractional Owners.</h5>
                <p>
                  Many people who want to own a small portion of the property
                  and earn passive income from the property. These people may
                  not know themselves.{" "}
                  <strong>
                    Select the different employment types that can apply.
                  </strong>
                </p>
              </div>
              {planAccessibilities?.FACTIONAL_OWNERS?.map((item, index) => (
                <div key={index} className="">
                  <PlanAccessibilityCard
                    title={item.title}
                    description={item.description}
                    name={item.name}
                    checked={
                      selectedCheckboxes["FACTIONAL_OWNERS"]?.[item.name] ||
                      false
                    }
                    handler={() =>
                      handleCheckboxChange(item.name, "FACTIONAL_OWNERS")
                    }
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <hr className="hr mt-3" />

      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center processing-fees py-3">
          <div className="h4">Processing Fees</div>
          <div className="d-flex justify-content-end">
            <Button
              lefticonname={"plus"}
              btnText={"Add Fee"}
              type="button"
              iconcolor={colors.primary}
              btntextcolor={colors.primary}
              backgroundcolor={colors.white}
              iconcolorHover={colors.hover}
              colorHover={colors.hover}
              borderHover={colors.hover}
              backgroundHover={colors.white}
              onBtnClick={handleAddDynamicForm}
            />
          </div>
        </div>

        {processingForm.length > 0 ? (
          processingForm?.map((input, index) => (
            <div className="row mt-3" key={index}>
              <div className="row mb-2">
                <span>
                  <Button
                    btnText={<Icons iconName={"delete"} />}
                    bordercolor={colors.black}
                    borderHover={colors.red}
                    backgroundcolor={colors.white}
                    backgroundHover={colors.dangerLight}
                    colorHover={colors.danger}
                    color={colors.danger}
                    iconcolorHover={colors.danger}
                    padding={"0"}
                    bordersize={2}
                    btntextcolor={colors.red}
                    width={"40px"}
                    height={"43px"}
                    type={"button"}
                    onBtnClick={() => handleRemoveFeature(index)}
                  />
                </span>
              </div>
              <div className="row">
                <div className="col-sm-6 col-md-4">
                  <FormInput
                    labelName={"Fee Name"}
                    placeholder={"Processing"}
                    required
                    value={input.name}
                    name={"name"}
                    onChange={(name, value) =>
                      handleChangeEventDynamicForm(name, value, index)
                    }
                  />
                </div>
                <div className="col-sm-6 col-md-4 mb-2">
                  <Dropdown
                    labelName={"Fee Type (Fixed or Percentage)"}
                    required={true}
                    value={convertToTitleCase(input.type)}
                    placeholder={"Select"}
                    options={processingFees}
                    onChange={(e) => handleFeeTypeChange(e.target.value, index)}
                  />
                </div>
                {input.type === "PERCENTAGE" ? (
                  <div className="col-sm-6 col-md-4 mb-4">
                    <FormInput
                      left={"-7rem"}
                      inputDescription
                      inputDescriptionText={
                        "Percentage of property price means the fee will be calculated as a percentage of the property price. For example, if a house costs N100 million, and the fee is 1%, the customer will be paying 1% of the property price (N1 million)."
                      }
                      labelName={"Amount (%)"}
                      placeholder={"Figures are in percentage (eg. 10%)"}
                      required
                      error={input.error}
                      value={input.amount}
                      name={"amount"}
                      onChange={(name, value) =>
                        handleChangeEventDynamicForm(name, value, index)
                      }
                    />
                  </div>
                ) : (
                  <div className="col-sm-6 col-md-4">
                    <FormInput
                      left={"-5rem"}
                      inputDescription
                      inputDescriptionText={
                        "A fixed amount means you will be charging a flat fee. For example, you will charge N50,000 Naira, rather than a percentage of the property price."
                      }
                      labelName={"Amount"}
                      placeholder={"N250,000"}
                      required
                      onBlur={(e) => formattedPrice(e.target.value)}
                      value={formattedPrice(input.amount)}
                      name={"amount"}
                      onChange={(name, value) =>
                        handleChangeEventDynamicForm(name, value, index)
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div>
            <CenteredText title={"Processing Fees"}>
              There are currently no processing fees for this plan. If there are
              any fees you collect from your customers, click the add fee button
              above to input such fees.
            </CenteredText>
          </div>
        )}
      </div>

      <hr className="hr mt-4" />

      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center processing-fees py-3">
          <div className="h4">Required Documents</div>
          <div className="d-flex justify-content-end">
            <Button
              lefticonname={"plus"}
              btnText={"Add Doc"}
              type="button"
              iconcolor={colors.primary}
              btntextcolor={colors.primary}
              backgroundcolor={colors.white}
              iconcolorHover={colors.hover}
              colorHover={colors.hover}
              borderHover={colors.hover}
              backgroundHover={colors.white}
              onBtnClick={handleAddDocumentForm}
            />
          </div>
        </div>

        {documentForm.length > 0 ? (
          documentForm.map((input, index) => (
            <div className="row mt-4" key={index}>
              <div className="row mb-2">
                <span>
                  <Button
                    btnText={<Icons iconName={"delete"} />}
                    bordercolor={colors.black}
                    borderHover={colors.red}
                    backgroundcolor={colors.white}
                    backgroundHover={colors.dangerLight}
                    colorHover={colors.danger}
                    color={colors.danger}
                    iconcolorHover={colors.danger}
                    padding={"0"}
                    bordersize={2}
                    btntextcolor={colors.red}
                    width={"40px"}
                    height={"43px"}
                    type={"button"}
                    onBtnClick={() => handleRemoveDocumentForm(index)}
                  />
                </span>
              </div>
              <div className="row">
                <div className="col-sm-6 col-md-5">
                  <FormInput
                    labelName={"Document Name"}
                    placeholder={"Document Name"}
                    required
                    value={input.name}
                    name={`name`}
                    onChange={(name, value) =>
                      handleChangeEventDocumentForm(name, value, index)
                    }
                  />
                </div>
                <div className="col-sm-6 col-md-5">
                  <MultitextInput
                    label={"How would you describe this document?"}
                    placeholder={"Document Description"}
                    name={`description`}
                    required
                    value={input.description}
                    onChange={(e) =>
                      handleChangeEventDocumentForm(
                        e.target.name,
                        e?.target?.value,
                        index
                      )
                    }
                  />
                </div>
                <div className="col-sm-6 col-md-2 pt-1">
                  <label className="custom-form-label">Is Required</label>
                  <CheckInput
                    name={"isRequired"}
                    checkTick={true}
                    checked={input.isRequired}
                    width={"23px"}
                    height={"23px"}
                    bcolor={"#0000001A"}
                    bordercolor={"#CECECE"}
                    register={register("isRequired")}
                    onChange={(e) =>
                      handleChangeEventDocumentForm(
                        "isRequired",
                        e.target.checked,
                        index
                      )
                    }
                  />
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="">
            <CenteredText title={"Document Submission"}>
              There are currently no documents to be submitted for this plan. If
              there are any documents you require your customers to submit,
              click the add doc button above to receive documents from your
              customers. By default, we ask for bank statements and employment
              letters.
            </CenteredText>
          </div>
        )}
        <div className="row m-0 mt-4">
          {defaultCheckableDocuments?.map((doc) => (
            <div
              key={doc.name}
              className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
            >
              <p className="m-0 fw-bold">
                {doc.label}{" "}
                {doc.isRequired && <span className="required">*</span>}
              </p>
              <CheckInput
                name={doc.name}
                checked={doc.checked}
                checkTick
                width={"23px"}
                height={"23px"}
                bcolor={"#0000001A"}
                bordercolor={"#CECECE"}
                onChange={() => {
                  handleDocCheck(doc.name);
                }}
              />
            </div>
          ))}

          {showNHFDoc
            ? checkableNHFDocuments?.map((doc) => (
                <div
                  key={doc.name}
                  className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
                >
                  <p className="m-0 fw-bold">
                    {doc.label}{" "}
                    {doc.isRequired && <span className="required">*</span>}
                  </p>
                  <CheckInput
                    name={doc.name}
                    checked={doc.checked}
                    checkTick
                    width={"23px"}
                    height={"23px"}
                    bcolor={"#0000001A"}
                    bordercolor={"#CECECE"}
                    onChange={() => {
                      handleDocCheck(doc.name);
                    }}
                  />
                </div>
              ))
            : null}

          {showFMBDoc
            ? checkableFMBDocuments?.map((doc) => (
                <div
                  key={doc.name}
                  className="col-6 col-sm-4 col-md-3 col-lg-2 my-3"
                >
                  <p className="m-0 fw-bold">
                    {doc.label}{" "}
                    {doc.isRequired && <span className="required">*</span>}
                  </p>
                  <CheckInput
                    name={doc.name}
                    checked={doc.checked}
                    checkTick
                    width={"23px"}
                    height={"23px"}
                    bcolor={"#0000001A"}
                    bordercolor={"#CECECE"}
                    onChange={() => {
                      handleDocCheck(doc.name);
                    }}
                  />
                </div>
              ))
            : null}
        </div>
      </div>

      {/* <hr className="hr mt-4" /> */}

      {/* <div className="mt-4">
        <div className="processing-fees row">
          <div className="h4">Repayment Methods</div>
        </div>
        <div className="row mt-3 mb-5">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="col-md-3">
                  <CheckInput
                    name={"REMITA_PAYMENT"}
                    checkTick={true}
                    label={"Remita Payment"}
                    width={"23px"}
                    height={"23px"}
                    bcolor={colors.darkGray}
                    bordercolor={colors.grey}
                    checked={true}
                    disabled={true}
                    register={register("REMITA_PAYMENT")}
                    // onChange={(e) => handleOnCheckRepayment(e)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {plan?.id ? (
        <FooterButtons
          saveLabel={"Update"}
          cancelBtn={() => {
            setPlanType("");
            setInterestType("");
            navigate(`/properties/house/plans/${houseId}`);
          }}
          loading={updatePlans.isLoading}
          disabled={updatePlans.isLoading}
          largeLoader={updatePlans.isLoading}
        />
      ) : (
        <FooterButtons
          saveLabel={"Create"}
          cancelBtn={() => {
            setPlanType("");
            setInterestType("");
            navigate(`/properties/house/plans/${houseId}`);
          }}
          loading={createPlans.isLoading}
          disabled={createPlans.isLoading}
          largeLoader={createPlans.isLoading}
        />
      )}
    </form>
  );
};

export default MortgagePlanForm;
