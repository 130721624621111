import CellSelector from "../../Components/Common/CellSelector";
import { convertToTitleCase, formattedPrice } from "../../utils/constants";

export const plansColumns = (loading) => [
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        TYPE
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>{convertToTitleCase(row?.type)}</CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        NAME
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row?.name}</CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        DELIVERY LEVEL
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {row?.deliveryLevel ? convertToTitleCase(row?.deliveryLevel) : "---"}
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        DELIVERY PRICE
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        <>₦ {formattedPrice(row?.deliveryLevelPrice)}</>
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        DATE CREATED
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {new Date(row.dateCreated).toDateString()}
      </CellSelector>
    ),
  },
];
