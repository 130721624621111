import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useEndApplication = (applicationId) => {
  const { putRequest } = useAxiosBase();

  const endApplication = useMutation({
    mutationFn: (data) =>
      putRequest({
        url: `/developer/application/${applicationId}/end-application`,
        data: data,
      }),
    onSuccess: (res) => {
      if (res?.data?.value?.statusCode === 200) {
        toast.success("You have successfully ended this application.")
      } else {
        toast.error(res?.response?.data?.value?.message || "An error occurred");
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || "An error occurred");
    },
  });

  return { endApplication };
};

export default useEndApplication;

