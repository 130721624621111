import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";

const useGetBankPartnership = (page) => {
  const { getRequest } = useAxiosBase();

  return useQuery({
    queryKey: ["banks"],
    queryFn: () =>
      getRequest({
        url: `/developer/partnership/get-all?pageNumber=${page}`,
      }).then((res) => res.data?.value),
    staleTime: 50000,
  });
};
export default useGetBankPartnership;