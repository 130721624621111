import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import useAuth from "../../../../hooks/useAuth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useUploadImages = (estateId) => {
  const { postRequest } = useAxiosBase();
  const navigate = useNavigate();

  const uploadingEstateImages = useMutation({
    mutationFn: (data) =>
      postRequest({
        url: `developer/estate/${estateId}/upload-document`,
        data: data,
      }).then((res) => {
        if (res?.data?.value?.statusCode === 200) {
          return;
        }
        if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
          toast.error(res?.data?.value?.message);
        }
      }),
    onSuccess: (res, res1) => {
      if (res?.data?.value?.statusCode === 200) {
      }
      if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
        toast.error(res?.data?.value?.message);
      }
    },
    onError: (error) => {
      toast.error(error);
    },
  });

  return { uploadingEstateImages };
};

export default useUploadImages;
