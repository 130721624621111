import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useUpdateEstate = (estateId) => {
  const { putRequest } = useAxiosBase();
  const navigate = useNavigate();

  const updateEstate = useMutation({
    mutationKey: ["update-estate", estateId],
    mutationFn: (data) =>
      putRequest({
        url: `developer/estate/${estateId}/update`,
        data: data,
      }),
      onSuccess: (res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success(res?.data?.value?.message);
          navigate("/properties", { state: { refresh: true } });
        } else {
          toast.error(res?.response?.data?.value?.message || "An error occurred");
        }
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "An error occurred");
      },
  });

  return { updateEstate };
};

export default useUpdateEstate;
