import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetAllPlans } from "./hooks/useGetAllPlans";
import Spinner from "../../Components/Spinner";
import CenteredText from "../../Components/CenteredText";
import {
  colors,
  convertToTitleCase,
  formattedPrice,
} from "../../utils/constants";
import { useNav } from "../../hooks/useNav";
import DocumentAndFeeCard from "./DocumentAndFeeCard";

const SinglePlanDetails = () => {
  const navigate = useNavigate();

  const params = useParams();
  const planId = params?.id;

  const [singlePlan, setSinglePlan] = useState();
  const [showFullDeliveryDescription, setShowFullDeliveryDescription] =
    useState(false);
  const [showFullPlanDescription, setShowFullPlanDescription] = useState(false);
  const [showFullAccessDescription, setShowFullAccessDescription] =
    useState(false);
  const [showFullPolicyDescription, setShowFullPolicyDescription] =
    useState(false);

  const { data: plans, isLoading, refetch, isRefetching } = useGetAllPlans();

  useNav({
    mainTitle: singlePlan?.name,
    // subTitle:
    //   "This shows the different types of plans your company accepts from customers. You can also create custom plans solely for your development company.",
    hasBackButton: true,
  });

  useEffect(() => {
    if (plans) {
      const getSinglePlan = plans?.data.find((plan) => plan.id === planId);
      setSinglePlan(getSinglePlan);
    }
  }, [plans, planId]);

  if (isLoading)
    return (
      <div style={{ height: "60vh" }}>
        {" "}
        <Spinner loadingText={"Loading Plan Details..."} />{" "}
      </div>
    );
  return (
    <div className="mb-5 ps-2">
      <div className="h4 mt-4">Plan Details</div>
      <div className="row">
        <div className="col-sm-6 col-md-4 mb-4">
          <p className="mb-2" style={{ fontSize: "16px", fontWeight: "bold" }}>
            Plan Name
          </p>
          <p> {singlePlan?.name ?? "---"}</p>
        </div>
        <div className="col-sm-6 col-md-4 mb-4">
          <p className="mb-2" style={{ fontSize: "16px", fontWeight: "bold" }}>
            Property
          </p>
          <p> {singlePlan?.houseName ?? "---"}</p>
        </div>
        {singlePlan?.maxLoanAmount && (
          <div className="col-sm-6 col-md-4 mb-4">
            <p
              className="mb-2"
              style={{ fontSize: "16px", fontWeight: "bold" }}
            >
              Delivery Level Price
            </p>
            <p> ₦{formattedPrice(singlePlan?.maxLoanAmount)}</p>
          </div>
        )}

        {singlePlan?.interestRate && (
          <div className="col-sm-6 col-md-4 mb-4">
            <p
              className="mb-2"
              style={{ fontSize: "16px", fontWeight: "bold" }}
            >
              Interest Rate (%){" "}
            </p>
            <p> {singlePlan?.interestRate}%</p>
          </div>
        )}

        {singlePlan?.loanTenor ? (
          <div className="col-sm-6 col-md-4 mb-4">
            <p
              className="mb-2"
              style={{ fontSize: "16px", fontWeight: "bold" }}
            >
              Maximum Tenor (In Months)
            </p>
            <p>{singlePlan?.loanTenor > 1 ? "Months" : "Month"}</p>
          </div>
        ) : null}

        {singlePlan?.equityPercentage && (
          <div className="col-sm-6 col-md-4 mb-4">
            <p
              className="mb-2"
              style={{ fontSize: "16px", fontWeight: "bold" }}
            >
              Minimum Downpayment (%){" "}
            </p>
            <p> {singlePlan?.equityPercentage}%</p>
          </div>
        )}

        {singlePlan?.processingPeriod && (
          <div className="col-sm-6 col-md-4 mb-4">
            <p
              className="mb-2"
              style={{ fontSize: "16px", fontWeight: "bold" }}
            >
              Processing Time (in days){" "}
            </p>
            <p>{singlePlan?.processingPeriod > 1 ? "Days" : "Day"}</p>
          </div>
        )}

        {singlePlan?.defaultFee ? (
          <div className="col-sm-6 col-md-4 mb-4">
            <p
              className="mb-2"
              style={{ fontSize: "16px", fontWeight: "bold" }}
            >
              Default Fee (%)
            </p>
            <p> {formattedPrice(singlePlan?.defaultFee)}%</p>
          </div>
        ) : null}

        {singlePlan?.gracePeriod ? (
          <div className="col-sm-6 col-md-4 mb-4">
            <p
              className="mb-2"
              style={{ fontSize: "16px", fontWeight: "bold" }}
            >
              Grace Period (in days)
            </p>
            <p>{singlePlan?.gracePeriod > 1 ? "Days" : "Day"}</p>
          </div>
        ) : null}

        {singlePlan?.numberOfMissedPayments ? (
          <div className="col-sm-6 col-md-4 mb-4">
            <p
              className="mb-2"
              style={{ fontSize: "16px", fontWeight: "bold" }}
            >
              Number of Missed Payments
            </p>
            <p> {singlePlan?.numberOfMissedPayments}</p>
          </div>
        ) : null}

        {singlePlan?.deliveryLevel && (
          <div className="col-sm-6 col-md-4 mb-4">
            <p
              className="mb-2"
              style={{ fontSize: "16px", fontWeight: "bold" }}
            >
              Delivery Level
            </p>
            <p>{convertToTitleCase(singlePlan?.deliveryLevel)}</p>
          </div>
        )}

        <div className="row d-flex justify-content-between ps-0 m-0">
          {singlePlan?.deliveryDescription && (
            <div className="col-md-4 mb-5">
              <p
                className="mb-2"
                style={{ fontSize: "16px", fontWeight: "bold" }}
              >
                Delivery Level Description
              </p>
              {showFullDeliveryDescription ? (
                <p>{singlePlan?.deliveryDescription}</p>
              ) : (
                <p className="mb-1">
                  {singlePlan?.deliveryDescription?.substring(0, 150) ??
                    "No description was provided"}
                </p>
              )}
              {singlePlan?.deliveryDescription?.length > 150 && (
                <p
                  className="fw-bold"
                  style={{ cursor: "pointer", color: colors.darkGreen }}
                  onClick={() =>
                    setShowFullDeliveryDescription(!showFullDeliveryDescription)
                  }
                >
                  {showFullDeliveryDescription ? "Show Less" : "Read More"}
                </p>
              )}
            </div>
          )}

          {singlePlan?.description && (
            <div className="col-md-4 mb-5">
              <p
                className="mb-2"
                style={{ fontSize: "16px", fontWeight: "bold" }}
              >
                Describe this Plan
              </p>
              {showFullPlanDescription ? (
                <p>{singlePlan?.description}</p>
              ) : (
                <p className="mb-1">
                  {singlePlan?.description?.substring(0, 150) ??
                    "No description was provided"}
                </p>
              )}
              {singlePlan?.description?.length > 150 && (
                <p
                  className="fw-bold"
                  style={{ cursor: "pointer", color: colors.darkGreen }}
                  onClick={() =>
                    setShowFullPlanDescription(!showFullPlanDescription)
                  }
                >
                  {showFullPlanDescription ? "Show Less" : "Read More"}
                </p>
              )}
            </div>
          )}

          {singlePlan?.access && (
            <div className="col-md-4">
              <p
                className="mb-2"
                style={{ fontSize: "16px", fontWeight: "bold" }}
              >
                Who Can Access This Plan?
              </p>
              {showFullAccessDescription ? (
                <p>{singlePlan?.access}</p>
              ) : (
                <p className="mb-1">
                  {singlePlan?.access?.substring(0, 150) ??
                    "No description was provided"}
                </p>
              )}
              {singlePlan?.access?.length > 150 && (
                <p
                  className="fw-bold"
                  style={{ cursor: "pointer", color: colors.darkGreen }}
                  onClick={() =>
                    setShowFullAccessDescription(!showFullAccessDescription)
                  }
                >
                  {showFullAccessDescription ? "Show Less" : "Read More"}
                </p>
              )}
            </div>
          )}

          {singlePlan?.propertyRecoveryTerms && (
            <div className="col-md-4 mb-5">
              <p
                className="mb-2"
                style={{ fontSize: "16px", fontWeight: "bold" }}
              >
                Missed Payment Policy
              </p>
              {showFullPolicyDescription ? (
                <p>{singlePlan?.propertyRecoveryTerms}</p>
              ) : (
                <p className="mb-1">
                  {singlePlan?.propertyRecoveryTerms?.substring(0, 150) ??
                    "No missed payment policy was provided"}
                </p>
              )}
              {singlePlan?.propertyRecoveryTerms?.length > 150 && (
                <p
                  className="fw-bold"
                  style={{ cursor: "pointer", color: colors.darkGreen }}
                  onClick={() =>
                    setShowFullPolicyDescription(!showFullPolicyDescription)
                  }
                >
                  {showFullPolicyDescription ? "Show Less" : "Read More"}
                </p>
              )}
            </div>
          )}
        </div>
      </div>

      <div className="h4 mt-4">Processing Fees</div>
      <div className="row">
        {singlePlan?.applicableFees?.length > 0 ? (
          singlePlan?.applicableFees?.map((fee) => (
            <div className="col-md-3 mb-3">
              <DocumentAndFeeCard
                pillColor={"greenSuccessPill"}
                name={fee?.name}
                pill={fee?.type === "FIXED" ? `₦${formattedPrice(fee?.amount)}` : `${fee?.amount}%`}
                isRequired
              />
            </div>
          ))
        ) : (
          <div className="my-4">
            <CenteredText title={"No Fees Required"}>
              <p className="text-center m-0">
                No Application Fee has been added to this plan.
              </p>
            </CenteredText>
          </div>
        )}
      </div>

      <div className="h4 mt-4">Documents</div>
      <div className="row">
        {singlePlan?.requiredDocuments?.length > 0 ? (
          singlePlan?.requiredDocuments?.map((doc) => (
            <div className="col-md-3 mb-3">
              <DocumentAndFeeCard
                pill={["Required"]}
                name={doc?.option?.name}
                isRequired={doc?.option?.isRequired}
              />
            </div>
          ))
        ) : (
          <div className="my-4">
            <CenteredText title={"No Documents at the moment"}>
              <p className="text-center m-0">
                No Documents has been added to this plan.
              </p>
            </CenteredText>
          </div>
        )}
      </div>
    </div>
  );
};

export default SinglePlanDetails;
