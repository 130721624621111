import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useRescheduleBooking = (houseViewingId) => {
  const { putRequest } = useAxiosBase();

  const rescheduleBooking = useMutation({
    mutationKey: ["reschedule-booking", houseViewingId],
    mutationFn: (data) =>
      putRequest({
        url: `developer/house-viewing/${houseViewingId}/update`,
        data: data,
      }),
      onSuccess: (res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success(res?.data?.value?.message);
        } else {
          toast.error(res?.response?.data?.value?.message || "An error occurred");
        }
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "An error occurred");
      },
  });

  return { rescheduleBooking };
};

export default useRescheduleBooking;
