import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../../../hooks/useAxiosBase";

const useGetPlans = (houseId, page) => {
  const { getRequest } = useAxiosBase();

  return useQuery({
    queryKey: ["house-plans"],
    queryFn: () =>
      getRequest({ url: `/developer/house/${houseId}/get-mortgage-plans?pageNumber=${page}` }).then(
        (res) => res.data.value?.value
      ),
    staleTime: 5000,
  });
};

export default useGetPlans;