import { styled } from "styled-components";

export const CheckInputWrapper = styled.div`
  display: flex;
  /* align-items: center; */
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;

  .form-check-input {
    position: relative;
    border-radius: ${(props) => props.borderradius ?? "0.25em"};
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    border-color: ${(props) => props.bordercolor ?? "transparent"};
    appearance: none;
  }

  .form-check-input:checked::before {
    content: ${(props) => (props.checkTick ? "''" : "none")};
    position: absolute;
    top: 40%;
    left: 50%;
    line-height: 0.9rem;
    transform: translate(-50%, -50%) rotate(45deg);
    width: ${(props) => (props.tickWidth ? `${props.tickWidth}` : "0.5rem")};
    height: ${(props) => (props.tickHeight ? `${props.tickHeight} !important` : "0.9rem")};
    border: solid white;
    border-width: ${(props) => (props.tickBorderWidth ? `${props.tickBorderWidth} !important` : "0 3px 3px 0")};
  }

  .form-check-input:checked {
    background: rgb(43, 79, 43) !important;
    border-color: rgb(43, 79, 43);
  }

  .form-check-label {
    color: ${(props) => (props.labelColor ? `${props.labelColor} !important` : "")};
    cursor: pointer;
    padding: 0 4px !important;
    height: 0 !important;
    font-size: ${(props) => `${props.fontSize} !important` ?? "14px"};
    font-weight: bold;
    font-family: "Red Hat Display", sans-serif;
    background-color: rebeccapurple !important;
    margin-top: ${(props) => (props.labelMT ? `${props.labelMT} !important` : "0.2rem")};
    margin: ${(props) => (props.margin ? `${props.margin} !important` : "")};
  }
`;
