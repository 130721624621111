import React, { useState, useEffect } from "react";
import { useNav } from "../../../hooks/useNav";
import { bankAppcolumns } from "../../../utils/constants";
import InfoTable from "../../../Components/Common/InfoTable";
import InfoCard from "../../../Components/Common/InfoCard";
import useGetBankApplications from "../hooks/useGetBankApplications";
import { applicationColumns, applicationColumnsData } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import Modal from "../../../Components/Common/Modal";
import Spinner from "../../../Components/Spinner";
import CenteredText from "../../../Components/CenteredText";
import { toast } from "react-toastify";
import TableLoader from "../../../Components/Common/TableLoader";
import SkeletonLoader from "../../../Components/SkeletonLoader";

const BankApplication = () => {
  useNav({
    mainTitle: "Partner Bank Applications",
    subTitle:
      "These are applications made by customers for properties through banks you've partnered with.",
  });
  const navigate = useNavigate();
  const [pagination, setPagination] = useState(1);

  const {
    data: applications,
    isLoading,
    refetch,
    isRefetching,
  } = useGetBankApplications(pagination);

  useEffect(() => {
    if (pagination) {
      refetch();
    }
  }, [pagination]);

  return (
    <div className="px-2">
      <div className="col-lg-4 col-md-3 col-sm-2 ps-1">
        <InfoCard
          mainValue={
            isLoading || isRefetching ? (
              <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
            ) : (
              applications?.totalRecords ?? "0"
            )
          }
          description={
            isLoading || isRefetching ? (
              <SkeletonLoader />
            ) : (
              "Total Applications"
            )
          }
        />
      </div>

      <div className="mb-4">
        {isLoading ? (
          <div className="mt-4">
            <TableLoader />
          </div>
        ) : !applications?.data?.length ? (
          <div className="mt-5">
            <CenteredText title={"No Applications"}>
              There are no applications at this time
            </CenteredText>
          </div>
        ) : (
          <div className="ps-1 pe-1 mt-2">
            <InfoTable
              columns={bankAppcolumns(isRefetching)}
              dataCollection={applications?.data}
              rightTextAsButton={true}
              pointerOnHover={true}
              highlightOnHover={true}
              loadingTable={isRefetching}
              paginationTotalRows={applications?.totalRecords}
              onChangePage={(page) => setPagination(page)}
              onRowClicked={(row) =>
                navigate(
                  `/application/bank-applications/single-partner-application/${row.applicationId}`
                )
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default BankApplication;
