import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import useAuth from "../../../hooks/useAuth";

const useGetPartneredPlans = (page) => {
  const { getRequest } = useAxiosBase();
  const { authUser } = useAuth();
  const organizationId = authUser?.user?.organizationId;

  return useQuery({
    queryKey: ["Partnered-Plans", organizationId],
    queryFn: () =>
      getRequest({
        url: `/public/organization/${organizationId}/developer/get-mortgage-plans?pageNumber=${page}`,
      }).then((res) => res.data?.value?.value),
    staleTime: 50000,
  });
};
export default useGetPartneredPlans;