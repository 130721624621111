import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useSubmitLoanTerms = (applicationId) => {
  const { postRequest } = useAxiosBase();

  const submitLoanTerms = useMutation({
    mutationFn: (data) =>
    postRequest({
        url: `developer/application/${applicationId}/submit-terms`,
        data: data,
      }).then((res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success("Sent Successfully!");
        }
        if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
          toast.error(res?.data?.value?.message);
        }
      }),
    onSuccess: (res, res1) => {
      if (res?.data?.value?.statusCode === 200) {
        toast.success("Sent Successfully!");
      }
      if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
        toast.error(res?.data?.value?.message);
      }
    },
    onError: (error) => {
      toast.error(error);
    },
  });

  return { submitLoanTerms };
};

export default useSubmitLoanTerms;