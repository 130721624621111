import React, { useEffect, useState } from "react";
import usePermission from "../../../hooks/usePermissions";
import { useNav } from "../../../hooks/useNav";
import { useNavigate } from "react-router-dom";
import { colors, convertToTitleCase } from "../../../utils/constants";
import { AiOutlineInfoCircle } from "react-icons/ai";
import THeader from "./Table/THeader";
import TSubHeader from "./Table/TSubHeader";
import TRow from "./Table/TRow";
import TDivider from "./Table/TDivider";
import { permissions, roleData } from "./constant";
import useGetRoles from "./hooks/useGetRoles";
import useCreateRoles from "./hooks/useCreateRoles";
import useUpdateRolePermissions from "./hooks/useUpdateRolePermissions";
import useDeleteRole from "./hooks/useDeleteRoles";
import Button from "../../../Components/Common/Button";
import Modal from "../../../Components/Common/Modal";
import MultitextInput from "../../../Components/Common/MultitextInput";
import FormInput from "../../../Components/Common/FormInput";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useAuth from "../../../hooks/useAuth";
import permissionsSchema from "./models/permissions";
import { PiCrownSimpleBold } from "react-icons/pi";
import FooterButtons from "../../../Components/Common/FooterButtons";
import InfoTable from "../../../Components/Common/InfoTable";
import InfoCard from "../../../Components/Common/InfoCard";
import Spinner from "../../../Components/Spinner";
import CenteredText from "../../../Components/CenteredText";
import { toast } from "react-toastify";
import { HiDotsVertical } from "react-icons/hi";
import { Dropdown } from "react-bootstrap";
import CellSelector from "../../../Components/Common/CellSelector";
import TableLoader from "../../../Components/Common/TableLoader";
import SkeletonLoader from "../../../Components/SkeletonLoader";

const PermissionsAndRoles = () => {
  useNav({
    mainTitle: "Roles & Permissions",
    subTitle:
      "View and manage roles and permissions within your organization. Define user access levels, create custom roles, and ensure secure collaboration across your team.",
  });

  const {
    accessViewRole,
    accessCreateRole,
    accessUpdateRole,
    accessDeleteRole,
  } = usePermission();

  const navigate = useNavigate();
  const { authUser } = useAuth();
  const organizationId = authUser?.user?.organization?.id;

  const [initialPermissionData, setInitialPermissionData] = useState([]);
  const [createPermissionData, setCreatePermissionData] = useState([]);
  const [permissionData, setPermissionData] = useState([]);

  const [roleName, setRoleName] = useState("");
  const [roleId, setRoleId] = useState("");
  const [pagination, setPagination] = useState(1);
  const {
    data: roleData,
    refetch: refetchRoles,
    isRefetching,
    isLoading,
    error,
  } = useGetRoles(pagination);

  const { createRole } = useCreateRoles();
  const { updateRolePermissions } = useUpdateRolePermissions(roleId);
  const { deleteRole } = useDeleteRole(roleId);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [groupSelections, setGroupSelections] = useState({});

  // HANDLE CREATE ORGANIZATION ROLE
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ resolver: yupResolver(permissionsSchema) });

  const onSubmitCreate = (data) => {
    const hasPermissions = createPermissionData.some((permission) =>
      permission.rules.some((rule) => rule.value)
    );

    if (!hasPermissions) {
      return toast.error(
        "Please add at least one permission before creating the role."
      );
    }

    data.id = "string";
    data.organizationId = organizationId;
    data.access = JSON.stringify(createPermissionData);
    // console.log("data", data);

    createRole.mutate(data, {
      onSuccess: () => {
        refetchRoles();
        setShowCreateModal(false);
        setPermissionData([]);
        setValue("description", null);
        setValue("name", null);
        resetCreatePermissionChecks();
      },
    });
  };

  const resetCreatePermissionChecks = () => {
    const resetData = initialPermissionData.map((permission) => ({
      ...permission,
      rules: permission.rules.map((rule) => ({ ...rule, value: false })),
    }));
    setCreatePermissionData(resetData);

    // RESET ALL THE TOGGLE ALL
    setGroupSelections((prevGroupSelections) => {
      const updatedGroupSelections = { ...prevGroupSelections };
      for (const key in updatedGroupSelections) {
        if (updatedGroupSelections.hasOwnProperty(key)) {
          updatedGroupSelections[key] = false;
        }
      }
      return updatedGroupSelections;
    });
  };

  const handleCreatePermissionChecked = (index1, index) => {
    const updatedPermissionData = [...createPermissionData];

    updatedPermissionData[index1].rules[index].value =
      !updatedPermissionData[index1].rules[index]?.value;

    setCreatePermissionData(updatedPermissionData);

    // Deselect toggleAll if not all checkboxes are checked
    const allChecked = updatedPermissionData[index1].rules.every(
      (rule) => rule.value
    );
    setGroupSelections((prev) => ({ ...prev, [index1]: allChecked }));
  };

  // HANDLE EDIT ROLE PERMISSIONS
  const {
    register: editRegister,
    handleSubmit: handleEditSubmit,
    reset: reset2,
    formState: { errors: errors2 },
  } = useForm({ resolver: yupResolver(permissionsSchema) });

  const onSubmitEdit = (data) => {
    const hasPermissions = permissionData?.access?.some((permission) =>
      permission.rules.some((rule) => rule.value)
    );

    if (!hasPermissions) {
      return toast.error("Please add at least one permission to this role.");
    }
    data.access = JSON.stringify(permissionData.access);

    updateRolePermissions.mutate(data, {
      onSuccess: () => {
        refetchRoles();
        setShowEditModal(false);
      },
    });
  };

  const hadnleEditRole = () => {
    const selectedRole = roleData?.data?.find((role) => role.id === roleId);
    const parsedAccess = JSON.parse(selectedRole.access);

    setPermissionData({ ...selectedRole, access: parsedAccess });

    reset2(selectedRole);
    setShowEditModal(true);
  };

  const handleRowId = (rowId) => {
    const selectedRole = roleData?.data.find((role) => role.id === rowId);

    setRoleName(selectedRole?.name);
    setRoleId(rowId);
  };

  const handlePermissionChecked = (value, index1, index) => {
    setPermissionData((prevPermissionData) => {
      const updatedPermissionData = { ...prevPermissionData };

      if (updatedPermissionData && updatedPermissionData.access) {
        updatedPermissionData.access[index1].rules[index].value = value;
        return { ...updatedPermissionData };
      }

      return { ...prevPermissionData };
    });

    // Deselect toggleAll if not all checkboxes are checked
    const allChecked = permissionData.access[index1].rules.every(
      (rule) => rule.value
    );
    setGroupSelections((prev) => ({ ...prev, [index1]: allChecked }));
  };

  const handleToggleAll = (index1) => {
    const allChecked = !groupSelections[index1];
    setGroupSelections((prev) => ({ ...prev, [index1]: allChecked }));

    setPermissionData((prevPermissionData) => {
      const updatedPermissionData = { ...prevPermissionData };

      if (updatedPermissionData && updatedPermissionData.access) {
        updatedPermissionData.access[index1].rules =
          updatedPermissionData.access[index1].rules.map((rule) => ({
            ...rule,
            value: allChecked,
          }));

        return { ...updatedPermissionData };
      }

      return { ...prevPermissionData };
    });

    setCreatePermissionData((prevCreatePermissionData) => {
      const updatedCreatePermissionData = [...prevCreatePermissionData];

      updatedCreatePermissionData[index1].rules = updatedCreatePermissionData[
        index1
      ].rules.map((rule) => ({
        ...rule,
        value: allChecked,
      }));

      return updatedCreatePermissionData;
    });
  };

  // RLOLES COLUMN
  const roleColumns = (
    handleRowId,
    showEditModal,
    showDeleteModal,
    loading
  ) => {
    const columns = [
      {
        name: <p className="fw-bold p-0 m-0"> ROLE</p>,
        sortable: true,
        selector: (row) => (
          <CellSelector loading={loading}>{row.name || "--"}</CellSelector>
        ),
      },
      {
        name: <p className="fw-bold p-0 m-0"> DESCRIPTION </p>,
        grow: 1.5,
        wrap: true,
        sortable: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            {row.description || "--"}
          </CellSelector>
        ),
      },
      {
        name: <p className="fw-bold p-0 m-0"> DATE CREATED </p>,
        sortable: true,
        selector: (row) => (
          <CellSelector loading={loading}>
            {row?.dateCreated ? new Date(row.dateCreated).toDateString() : "--"}
          </CellSelector>
        ),
      },
    ];

    if (accessUpdateRole?.value || accessDeleteRole?.value) {
      columns.push({
        name: <p className="fw-bold p-0 m-0"> ACTION </p>,
        sortable: false,
        grow: 0.5,
        center: true,
        cell: (row) => (
          <Dropdown>
            <Dropdown.Toggle id={`dropdown-${row.id}`}>
              <HiDotsVertical
                onClick={() => handleRowId(row.id)}
                className="fw-bold text-dark"
              />
            </Dropdown.Toggle>

            {!loading && (
              <Dropdown.Menu>
                <Dropdown.Item onClick={showEditModal}>Edit</Dropdown.Item>
                <Dropdown.Item
                  onClick={showDeleteModal}
                  className="text-danger"
                >
                  Delete
                </Dropdown.Item>
              </Dropdown.Menu>
            )}
          </Dropdown>
        ),
      });
    }

    return columns;
  };

  useEffect(() => {
    const initialCreatePermissionData = permissions.map((permission) => ({
      ...permission,
      rules: permission.rules.map((rule) => ({ ...rule, value: false })),
    }));

    setCreatePermissionData(initialCreatePermissionData);
    setInitialPermissionData(initialCreatePermissionData);
  }, []);

  useEffect(() => {
    if (pagination) {
      refetchRoles();
    }
  }, [pagination]);

  return (
    <div className="container-fluid">
      {accessViewRole?.value ? (
        <>
          {accessCreateRole?.value && (
            <div className="d-flex justify-content-end mt-3">
              <Button
                btnText={"Create Role"}
                onBtnClick={() => setShowCreateModal(true)}
              />
            </div>
          )}
          <div>
            <InfoCard
              mainValue={
                isLoading || isRefetching ? (
                  <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
                ) : (
                  roleData?.totalRecords ?? "0"
                )
              }
              description={
                isLoading || isRefetching ? <SkeletonLoader /> : "Total Roles"
              }
            />
          </div>

          <div>
            {isLoading ? (
              <div className="mt-4">
                <TableLoader />
              </div>
            ) : roleData?.data?.length > 0 ? (
              <div className="mt-2">
                <InfoTable
                  columns={roleColumns(
                    (rowId) => handleRowId(rowId),
                    () => hadnleEditRole(),
                    () => setShowDeleteModal(true),
                    isRefetching
                  )}
                  dataCollection={roleData?.data}
                  pointerOnHover={true}
                  highlightOnHover={true}
                  loadingTable={isRefetching}
                  paginationTotalRows={roleData?.totalRecords}
                  onChangePage={(page) => setPagination(page)}
                  onRowClicked={(row, e) =>
                    navigate(`/settings/permission-and-roles/${row.id}`)
                  }
                />
              </div>
            ) : (
              <>
                <CenteredText title={"You have no created roles at this time"}>
                  Create and assign possession to a certain role, or check your
                  network connection.
                </CenteredText>
              </>
            )}
          </div>
        </>
      ) : (
        <div className="mt-5">
          <CenteredText title={"Unauthorized Access"}>
            You don't have permission to view organization roles. Please contact
            the organization administrator for assistance.
          </CenteredText>
        </div>
      )}

      {/* CREATE MODAL */}
      <Modal
        show={showCreateModal}
        title={"Create Role"}
        confirmModal={handleSubmit(onSubmitCreate)}
        confirmButtonLabel={"Create"}
        cancelButtonLabel={"Cancel"}
        closeModalIcon={true}
        width={"100%"}
        height={"100%"}
        crossLine={false}
        isBtnLoading={createRole.isLoading}
        disabled={createRole.isLoading}
        closeModal={() => {
          setShowCreateModal(false);
          setValue("description", null);
          setValue("name", null);
          resetCreatePermissionChecks();
        }}
        titleColor={colors.darkGreen}
        titleFont={"Millik"}
        titleFontSize={"30px"}
      >
        <div className="container-fulid px-4">
          <div>
            <FormInput
              required
              placeholder={"Name"}
              labelName={"Name"}
              name="name"
              error={errors?.name?.message}
              register={register("name")}
            />
          </div>
          <div className="mb-4">
            <MultitextInput
              required
              placeholder={"Description"}
              label={"Description"}
              name="description"
              error={errors?.description?.message}
              register={register("description")}
            />
          </div>

          <table className="table">
            {createPermissionData?.map((permission, index1) => (
              <div key={index1} className="">
                <TSubHeader
                  title={permission.groupName}
                  allChecked={!!groupSelections[index1]}
                  handleToggleAll={() => handleToggleAll(index1)}
                />

                <div className="d-flex flex-wrap">
                  {permission?.rules?.map((rule, index) => (
                    <div key={index} className="col-12 col-sm-6 col-md-3">
                      <TRow
                        label={rule.name}
                        name={rule.name}
                        checked={rule.value}
                        handler={(e) =>
                          handleCreatePermissionChecked(index1, index)
                        }
                      />
                    </div>
                  ))}
                </div>

                <TDivider />
              </div>
            ))}
          </table>
        </div>
      </Modal>

      {/* EDIT MODAL */}
      <Modal
        show={showEditModal}
        closeModal={() => setShowEditModal(false)}
        title={"Edit Role Permissons"}
        confirmModal={handleEditSubmit(onSubmitEdit)}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Update"}
        closeModalIcon={true}
        width={"100%"}
        height={"100%"}
        crossLine={false}
        isBtnLoading={updateRolePermissions.isLoading}
        disabled={updateRolePermissions.isLoading}
        titleColor={colors.darkGreen}
        titleFont={"Millik"}
        titleFontSize={"30px"}
      >
        <div className="container-fulid px-4">
          <div>
            <FormInput
              required
              placeholder={"eg. ADMIN_ROLE"}
              labelName={"Name"}
              name="name"
              error={errors2?.name?.message}
              register={editRegister("name")}
            />
          </div>

          <div className="mb-4">
            <MultitextInput
              label={"Description"}
              name={"description"}
              error={errors2?.description?.message}
              register={editRegister("description")}
              placeholder={
                "eg. We are a realty firm located in Abuja and has developed over 23 estate all around Nigeria."
              }
            />
          </div>

          <table className="table">
            {permissionData?.access?.map((permission, index1) => (
              <div key={index1} className="">
                <TSubHeader
                  title={permission.groupName}
                  allChecked={!!groupSelections[index1]}
                  handleToggleAll={() => handleToggleAll(index1)}
                />

                <div className="d-flex flex-wrap">
                  {permission?.rules?.map((rule, index) => (
                    <div key={index} className="col-12 col-sm-6 col-md-3">
                      <TRow
                        label={rule.name}
                        name={rule.name}
                        checked={rule.value}
                        handler={(e) =>
                          handlePermissionChecked(
                            e?.target?.checked,
                            index1,
                            index
                          )
                        }
                      />
                    </div>
                  ))}
                </div>

                <TDivider />
              </div>
            ))}
          </table>
        </div>
      </Modal>

      {/* DELETE MODAL */}
      <Modal
        show={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        title={`Delete Role`}
        isBtnLoading={deleteRole.isLoading}
        disabled={deleteRole.isLoading}
        confirmModal={() =>
          deleteRole.mutate(null, {
            onSuccess: () => {
              refetchRoles();
              setShowDeleteModal(false);
            },
          })
        }
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Delete"}
        backgroundcolor={colors.red}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 text-center">
              <p>
                Are you sure you want to delete <strong> {roleName}.</strong>{" "}
                <strong className="text-danger">
                  This action cannot be undone.
                </strong>
              </p>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PermissionsAndRoles;
