import { styled } from "styled-components";
import { colors } from "../../../utils/constants";

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;

export const LandingContainer = styled.div`
  p {
    font-family: "Red Hat Display", sans-serif;
    font-weight: 500;
  }

  svg {
    width: 25px;
  }

  h1 {
    color: ${colors.darkGreen};
  }
  
  .img-container {
    overflow: hidden;
    position: fixed;
    height: 100%;
  }

  .form-container {
    /* overflow: hidden; */
    position: absolute;
    top: 0;
    right: 0;
  }
  hr {
    color: ${colors.darkGreen};
    width: 25%;
    height: 1%;
  }
`;
