import React, { useState, useEffect } from "react";
import "../Signup/signup.css";
import FormInput from "../../Components/Common/FormInput";
import Button from "../../Components/Common/Button";
import { colors } from "../../utils/constants";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ImageWrapper,
  LandingContainer,
} from "../../Components/Common/StyledComponents/style";
import image from "../../assets/images/signup.jpg";
import { useSignupContext } from "../../Context/SignUpContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import modelSchema from "../Signup/models/signupModel";

const Developer = () => {

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(modelSchema) });

  const navigate = useNavigate();
  const { developerAdminData, setDeveloperAdminData } = useSignupContext();

  const handleLogin = () => navigate("/account/login");

  const onSubmit = (data) => {
    setDeveloperAdminData(data);
    reset();
    toast.success(
      <>
        <h5 className="m-0 text-center">Welcome to Giddaa</h5>
        <p className="text-center">
          Hello {data?.firstname || developerAdminData?.firstname}, welcome to
          Giddaa.
        </p>
      </>
    );
    sessionStorage.setItem("adminUser", JSON.stringify(developerAdminData));
    navigate("/account/onboarding");
  };

  const [isVisible, setIsVisible] = useState(false);
  const [confirmVisible, setConfirmVisible] = useState(false);
  const togglePassword = () => {
    setIsVisible(!isVisible);
  };
  const toggleConfirmPassword = () => {
    setConfirmVisible(!confirmVisible);
  };

  useEffect(() => {
    document.title = `Giddaa - Signup`;
  }, []);

  return (
    <LandingContainer>
      <div className="container-fluid op">
        <div className="row">
          <div className="col-md-6 d-none d-md-flex img-container p-0">
            <ImageWrapper>
              <img className="img-fluid" src={image} alt="image" />
            </ImageWrapper>
          </div>
          <div className="postion-relative form-container col-md-6 d-flex flex-column justify-content-center p-3 p-md-4">
            <img
              src="https://s3.amazonaws.com/appforest_uf/f1675031132769x885606220423525500/GiddaaLogoGreen.svg"
              alt="logo"
              width={90}
              height={70}
            />

            <div className="form-wrapper w-100">
              <h1 className="mt-5 text-md-start">
                Welcome to Giddaa for property developers
              </h1>
              <p className="">
                Start selling your properties available on payment plans to
                Nigerian's at home and <strong>abroad</strong>
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="d-md-flex justify-content-between gap-4">
                  <div className="w-100 w-md-50">
                    <FormInput
                      required
                      labelName={"First Name"}
                      placeholder={"eg. Paul"}
                      lefticonname={"user"}
                      register={register("firstname")}
                      name="firstname"
                      error={errors?.firstname?.message}
                      // value={developerAdminData?.firstname}
                      onChange={(name, value) =>
                        setDeveloperAdminData({
                          ...developerAdminData,
                          firstname: value,
                        })
                      }
                    />
                  </div>
                  <div className="w-100 w-md-50">
                    <FormInput
                      required
                      labelName={"Last Name"}
                      placeholder={"eg. Obuba"}
                      lefticonname={"user"}
                      name="lastname"
                      register={register("lastname")}
                      error={errors?.lastname?.message}
                      // value={developerAdminData?.lastname}
                      onChange={(name, value) =>
                        setDeveloperAdminData({
                          ...developerAdminData,
                          lastname: value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="d-md-flex justify-content-between gap-4">
                  <div className="w-100 w-md-50">
                    <FormInput
                      required
                      type={"email"}
                      labelName={"Email"}
                      lefticonname={"envolope"}
                      name="email"
                      register={register("email")}
                      error={errors?.email?.message}
                      placeholder={"eg. paul@gmail.com"}
                      // value={developerAdminData?.email}
                      onChange={(name, value) =>
                        setDeveloperAdminData({
                          ...developerAdminData,
                          email: value,
                        })
                      }
                    />
                  </div>
                  <div className="w-100 w-md-50">
                    <FormInput
                      maxlength="11"
                      labelName={"Phone Number"}
                      lefticonname={"phone"}
                      name="phoneNumber"
                      register={register("phoneNumber")}
                      error={errors?.phoneNumber?.message}
                      placeholder={"eg. 09014513409"}
                      // value={developerAdminData?.phoneNumber}
                      onChange={(name, value) =>
                        setDeveloperAdminData({
                          ...developerAdminData,
                          phoneNumber: value,
                        })
                      }
                    />
                  </div>
                </div>

                <div className="col-12">
                  <FormInput
                    required
                    labelName={"Password"}
                    lefticonname={"password"}
                    name="password"
                    register={register("password")}
                    error={errors?.password?.message}
                    type={isVisible ? "text" : "password"}
                    righticonname={isVisible ? "viewings" : "eyeoff"}
                    placeholder={"Paul1234$"}
                    onClick={() => togglePassword()}
                    // value={developerAdminData?.password}
                    onChange={(name, value) =>
                      setDeveloperAdminData({
                        ...developerAdminData,
                        password: value,
                      })
                    }
                  />
                </div>
                <div className="col-12">
                  <FormInput
                    required
                    labelName={"Confrim Password"}
                    lefticonname={"password"}
                    register={register("confirmPassword")}
                    error={errors?.confirmPassword?.message}
                    type={confirmVisible ? "text" : "password"}
                    righticonname={confirmVisible ? "viewings" : "eyeoff"}
                    placeholder={"Paul1234$"}
                    onClick={() => toggleConfirmPassword()}
                  />
                </div>
                <div className="col-12 d-flex justify-content-center mt-3">
                  <Button type={"submit"} btnText={"Sign up"} />
                </div>
                <div className="mt-3 text-center">
                  <p>
                    Already have an account?{" "}
                    <strong
                      className="anchor"
                      role="button"
                      onClick={handleLogin}
                    >
                      Login
                    </strong>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </LandingContainer>
  );
};

export default Developer;
