import React, { useState, useEffect } from "react";
import {
  WizzardBody,
  WizzardContainer,
  WizzardFooter,
  WizzardHeader,
} from "./style";
import Button from "../../../Components/Common/Button";
import { colors } from "../../../utils/constants";
import Spinner from "../../../Components/Spinner";
import organization, {
  validateCompanyDetails,
  // validateMortageBank,
  validateDocumentSubmission,
} from "../../../models/organization";
import { useMutation, useQuery } from "@tanstack/react-query";
import { widgetType } from "./constants";
import { useSignupContext } from "../../../Context/SignUpContext";
import { toast } from "react-toastify";
import useUploadDocuments from "../hooks/useUploadDocuments";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { useNavigate } from "react-router-dom";
import { AiOutlineConsoleSql } from "react-icons/ai";

const Wizzard = ({ type }) => {
  const { postRequest, putRequest } = useAxiosBase();
  const navigate = useNavigate();

  const [page, setPage] = useState(0);
  const { developerAdminData, setDeveloperAdminData } = useSignupContext();
  const [formData, setFormData] = useState(organization);
  const [formErrors, setFormErrors] = useState();
  const [responseID, setResponseID] = useState();
  const [organizationType, setOrganizationType] = useState();
  const { uploadingDocuments } = useUploadDocuments(
    responseID,
    organizationType
  );

  const [isLoading, setIsLoading] = useState(false);

  // CREATE ACCOUNT
  const createAccount = useMutation(async (data) => {
    setIsLoading(true);
    const res = await postRequest({
      url: "/public/organization/register",
      data: data,
    });
    if ([400, 401, 413, 500].includes(res?.response?.data?.statusCode)) {
      toast.error(res?.response?.data?.value?.message);
      setIsLoading(false);
      return;
    }
    if (res?.data?.value?.statusCode === 200) {
      toast.success(res?.data?.value?.message);
      setResponseID(res?.data?.value?.value?.id);
      setOrganizationType(res?.data?.value?.value?.organizationTypeId);
      setIsLoading(false);
      return;
    }
    setIsLoading(false);
    return res.data;
  });

  const handleNextClick = async () => {
    if (page === widgetType?.length - 1) {
      let storedData = JSON.parse(sessionStorage.getItem("adminUser"));

      const data = {
        name: formData.companyName,
        tin: formData.tin,
        cac: formData.cac,
        address: formData.companyAddress,
        emailAddress: formData.companyEmail,
        description: formData.companyDescription,
        phoneNumber: formData.companyPhoneNumber,
        bankName: formData.bankName,
        bankCode: formData.bankCode,
        accountNumber: formData.companyBankAccountNumber,
        state: formData.stateId,
        cityId: formData.cityId,
        logo: formData.companyLogo,
        logoBase64: formData.logoBase64,
        featuredImage: formData.featuredImage,
        brandColorCode: formData.companyBrandColor,
        yearsInOperation: formData.companyYearsInExistence,
        documents: [],
        invitationRequests: formData.invitationRequests,
        adminName:
          `${developerAdminData?.firstname} ${developerAdminData?.lastname}` ??
          storedData.firstname + " " + storedData.lastname,
        adminEmail: developerAdminData?.email ?? storedData.email,
        adminPassword: developerAdminData?.password ?? storedData.password,
        adminPhoneNumber:
          developerAdminData?.phoneNumber ?? storedData.phoneNumber,
        propertyViewingAmount: formData.agentCharge,
        organizationTypeId: "GIDDAA_DEVELOPER_ORGANIZATION_TYPE",
      };

      createAccount.mutate(data, {
        onSuccess: () => {
          setTimeout(
            () => navigate("/account/login", { state: { refresh: true } }),
            1000
          );
        },
      });
      setDeveloperAdminData({});

      return;
    }

    if (page === 0) {
      if (validateCompanyDetails(formData, setFormErrors, formErrors)) {
        setPage(page + 1);
      } else {
        return;
      }
    }

    if (page === 1) {
      setPage(page + 1);
    }

    // if (page === 1) {
    //   if (validateMortageBank(formData, setFormErrors, formErrors)) {
    //     setPage(page + 1);
    //   } else {
    //     return;
    //   }
    // }
    if (page === 2) {
      if (validateDocumentSubmission(formData, setFormErrors, formErrors)) {
        setPage(page + 1);
      } else {
        return;
      }
    }

    //validate current form.
    //  if true: increment step
    // if false: validation message.
    // setPage(page + 1);
  };

  const SetActive = (index) => {
    return index === page
      ? "col-md-3 d-flex justify-content-between align-items-center flex-column px-3 active"
      : "col-md-3 d-flex justify-content-between align-items-center flex-column px-3";
  };

  const handleUploadDocumentFunction = () => {
    if (responseID) {
      formData.documents?.map((documents) => {
        uploadingDocuments.mutate(documents, {
          onSuccess: () => {
            setIsLoading(false);
          },
        });
      });
    }
  };

  useEffect(() => {
    handleUploadDocumentFunction();
  }, [responseID]);

  const finalOption = isLoading ? <Spinner width={"1.3rem"} height={"1.3rem"} borderWidth={"4px"} /> : "Done";
  return (
    <WizzardContainer>
      <div className="content">
        <WizzardHeader className="d-lg-flex justify-content-between mb-5">
          {widgetType.map((widget, index) => (
            <div className={SetActive(index)} key={`${widget.subTitle}`}>
              <h4 className="title-header">
                <span>{widget.title}</span>
              </h4>
              <p className="title-subheader">
                <span>{widget.subTitle}</span>
              </p>
            </div>
          ))}
        </WizzardHeader>

        <div className="row body_ mx-md-3">
          <WizzardBody>
            {widgetType[page]?.component(
              formData,
              setFormData,
              formErrors,
              setFormErrors
            )}
          </WizzardBody>
        </div>

        <WizzardFooter className="d-flex justify-content-center align-items-center gap-3 gap-md-5 ">
          {page > 0 && (
            <Button
              lefticonname="leftArrow"
              btnText={widgetType[page - 1]?.title}
              disabled={isLoading}
              iconcolor={colors.primary}
              btntextcolor={colors.primary}
              backgroundcolor={colors.white}
              iconcolorHover={colors.hover}
              colorHover={colors.hover}
              borderHover={colors.hover}
              backgroundHover={colors.white}
              onBtnClick={() => setPage((currentPage) => currentPage - 1)}
            />
          )}

          <Button
            righticonname={page !== widgetType?.length - 1 && "rightArrow"}
            btnText={
              page === widgetType?.length - 1
                ? finalOption
                : widgetType[page + 1]?.title
            }
            onBtnClick={handleNextClick}
            disabled={isLoading}
            iconcolor={colors.white}
          />
        </WizzardFooter>
      </div>
    </WizzardContainer>
  );
};

export default Wizzard;
