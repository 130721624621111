import React, { useEffect, useState } from "react";
import FormInput from "../../../Components/Common/FormInput";
import Dropdown from "../../../Components/Common/Dropdown";
import { colors } from "../../../utils/constants";

const PropertyViewing = ({ data, setData }) => {

  const [propertyViewing, setPropertyViewing] = useState(false);

  const handleSelectOption = (value) => {
    if (value === "Yes") {
      setPropertyViewing(true)
    } else {
      setPropertyViewing(false)
      setData({
        ...data,
        agentCharge: "",
      })
    }
  };

  const options = [
    {
      id: "No",
      name: "No",
    },
    {
      id: "Yes",
      name: "Yes",
    },
  ]

  useEffect(() => {
    document.title = `Giddaa - Viewing Settings`;
  }, []);

  return (
    <>
      <div className="row d-flex justify-content-center w-100 text-center">
        <h1 className="h2 sub-title">Control Property Viewings</h1>
        <h6 className="sub-title_2">
          Let prospective customers know how they should handle property
          viewings with agents
        </h6>
      </div>

      <div className="row mt-3 d-flex flex-column align-items-center justify-content-center col-md-12">
        <div className="col-6 mx-auto ">
          <Dropdown
            labelName={" Do your agents charge for property viewings?"}
            options={options}
            onChange={(e) => handleSelectOption(e.target.value)}
            classname="mx-auto"
          />
        </div>

        {propertyViewing ? (
          <div className="col-md-6 mx-auto pb-4">
            <div className="col-md-12">
              <FormInput
                required
                type={"number"}
                labelName={"How Much Do they Charge"}
                placeholder="What do agents charge per viewing?"
                value={data.agentCharge}
                onChange={(name, value) => {
                  const charge = parseFloat(value);
                  setData({
                    ...data,
                    agentCharge: isNaN(charge) ? "" : charge,
                  });
                }}
              />
            </div>
          </div>
        ): null}
      </div>







      {/* <div className="row mt-3 d-flex flex-column align-items-center justify-content-center">
        <div className="col-md-6 custom-select">
          <label
            htmlFor="property-viewing-options"
            className="form-label form_label"
          >
            Do your agents charge for property viewings?{" "}
            <span className="required">*</span>
          </label>
          <button  style={{ position: 'relative', zIndex: 2 }}
            className="form-select-md form_select"
            role="combobox"
            aria-labelledby="select button"
            aria-haspopup="listbox"
            aria-expanded={dropdownVisible}
            aria-controls="select-dropdown"
            onClick={toggleDropdown}
          >
            <span className="selected-value">
              {propertyViewing}
            </span>
            <span className="arrow"></span>
          </button>
          {dropdownVisible && (
            <ul
              className="select-dropdown"
              role="listbox"
              id="select-dropdown"
              style={{ position: 'relative', zIndex: 10 }}
            >
              <li role="option" onClick={() => handleSelectOption('Yes')}>
                <input
                  type="radio"
                  id="yes"
                  name="yes"
                />
                <label htmlFor="yes">Yes</label>
              </li>
              <li role="option" onClick={() => handleSelectOption('No')}>
                <input
                  type="radio"
                  id="no"
                  name="no"
                />
                <label htmlFor="no">No</label>
              </li>
            </ul>
          )}
        </div>
        {propertyViewing === "Yes" && (
          <div className="col-md-6 mx-auto pt-4 pb-4 px-5">
            <div className="col-md-12">
                <FormInput
                  required
                  type={"number"}
                  labelName={"How Much Do they Charge"}
                  placeholder="What do agents charge per viewing?"
                  value={data.agentCharge}
                  backgroundcolor={colors.white}
                  onChange={(name, value) => {
                    const charge = parseFloat(value);
                    setData({
                      ...data,
                      agentCharge: isNaN(charge) ? "" : charge,
                    });
                  }}
                />
            </div>
          </div>
        )}
      </div> */}
    </>
  );
};

export default PropertyViewing;
