import Button from "../../Components/Common/Button";
import { colors } from "../../utils/constants";
import { HiDotsVertical } from "react-icons/hi";
import Dropdown from "react-bootstrap/Dropdown";

const defaultImage =
  "https://images.unsplash.com/photo-1600585154526-990dced4db0d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fEhvdXNlc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60";

export const OfferLetterColumns = (handleOmit, handleButtonClick) => [
  {
    name: <p className=" fw-bolder text-uppercase p-0 m-0">Customer</p>,
    sortable: true,
    grow: handleOmit ? 1 : 2,
    selector: (row) => row.application?.customer?.name,
  },
  {
    name: <p className=" fw-bolder text-uppercase p-0 m-0">Property</p>,
    sortable: true,
    selector: (row) => row.application?.house?.address,
    wrap: true,
  },
  {
    name: <p className=" fw-bolder text-uppercase p-0 m-0">Estate</p>,
    sortable: true,
    selector: (row) => row.application?.house?.estate,
    wrap: true,
  },
  {
    name: <p className=" fw-bolder text-uppercase p-0 m-0">PLAN TYPE</p>,
    sortable: true,
    selector: (row) => row.application?.mortgagePlan?.name,
    wrap: true,
  },
  {
    name: <p className="fw-bolder text-uppercase p-0 m-0"> Bank</p>,
    sortable: true,
    selector: (row) => row.application?.house?.address,
    wrap: true,
    grow: 1,
  },
  {
    name: <p className=" fw-bolder text-uppercase p-0 m-0">REQUEST DATE</p>,
    sortable: true,
    selector: (row) => row?.dateCreated ? new Date(row.dateCreated).toDateString() : "--",
    omit: handleOmit,
  },
  {
    name: <p className=" fw-bolder text-uppercase p-0 m-0">SUBMITTED DATE</p>,
    sortable: true,
    selector: (row) => {
      return row?.dateSubmitted ? new Date(row.dateSubmitted).toDateString() : "--";
    },
    omit: handleOmit,
  },
  {
    name: <p className=" fw-bolder text-uppercase p-0 m-0"> STATUS</p>,
    sortable: true,
    selector: (row) => row.application?.status,
    conditionalCellStyles: [
      {
        when: (row) => row.status === "NotSent",
        style: {
          color: colors.red,
          fontWeight: 700,
        },
      },
      {
        when: (row) => row.status === "Sent",
        style: {
          color: colors.green,
          fontWeight: 700,
        },
      },
    ],
    wrap: true,
    grow: handleOmit ? 0.5 : 2,
  },
  {
    name: <p className=" fw-bolder text-uppercase p-0 m-0">ACTION</p>,
    sortable: true,
    // grow: handleOmit ? 0.5 : 1,
    grow: 0.5,
    center: true,
    selector: (row) => (
      <Dropdown>
        <Dropdown.Toggle
          id={`dropdown-${row.id}`}
          style={{ background: "transparent", border: "none" }}
        >
          <HiDotsVertical className="fw-bold text-dark" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="position-fixed">
          <Dropdown.Item href="#/action-1">
            {row?.status === "NotSent" ? (
              <p className="p-0 m-0" onClick={() => handleButtonClick(row.id)}>SEND</p>
            ) : (
              <p className="p-0 m-0">DOWNLOAD</p>
            )}
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
];

export const OfferLetterColumnsData = [
  {
    nhfNumber: "12345678909",
    mortgagePlanId: "84b236cb-d598-4e2b-a814-ec702dbcd410",
    mortgageBanks: "Premium Mortgage Bank",
    mortgagePlan: {
      name: "NHF",
      description: "An awesome plan",
      type: "NHF",
      mortgagePeriod: 12,
      access: "Anybody",
      maxLoanAmount: 50000.0,
      interestRate: 12.0,
      loanTenor: 12,
      equityPercentage: 0.0,
      organizationId: "c7ecc866-ca52-42a6-b84e-9281877a789c",
      organization: null,
      processingPeriod: 30,
      amortization: 0.0,
      applicableFees: [],
      requiredDocuments: [],
      repaymentMethods: [],
      id: "84b236cb-d598-4e2b-a814-ec702dbcd410",
      createdBy:
        '{"Id":"8750c76e-36ce-44ff-bb0c-13a10c2354ee","Name":" ","Action":"Admin Create Mortgage Plan"}',
      dateCreated: "2023-08-02T23:55:22.922434+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    contactNumber: null,
    houseId: "ad563919-e42e-45c3-b7ef-21e9c4e492f2",
    house: {
      city: null,
      address: "St Marvin Street",
      landmark: "The popes statue",
      units: 5,
      estate: null,
      price: 35000000.0,
      longitude: 0.0,
      latitude: 0.0,
      description: "An awesome house",
      features: [],
      images: null,
      organizationId: "c7ecc866-ca52-42a6-b84e-9281877a789c",
      organization: null,
      houseMortgagePlans: [],
      id: "ad563919-e42e-45c3-b7ef-21e9c4e492f2",
      createdBy:
        '{"Id":"8750c76e-36ce-44ff-bb0c-13a10c2354ee","Name":" ","Action":"Admin Create House"}',
      dateCreated: "2023-08-02T23:49:45.312126+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    customerId: "ceb75dff-94db-4459-b4d7-a010597c6258",
    customer: {
      name: "Lionel Messi",
      firstName: "Lionel",
      lastName: "Messi",
      email: "test2@gmail.com",
      phoneNumber: "08106884376",
      address: null,
      organizationId: "giddaa_organization",
      organization: null,
      verificationCode: "isdpPUpOIvYosF",
      roleId: "CUSTOMER_ROLE",
      applicationRole: null,
      profilePicture:
        "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
      withInNigeria: true,
      age: null,
      income: null,
      cityId: null,
      city: null,
      affordability: null,
      lastLoginDate: "2023-07-23T15:09:16.433835+00:00",
      isActive: true,
      isDeleted: false,
      id: "ceb75dff-94db-4459-b4d7-a010597c6258",
      createdBy:
        '{"Id":"system","Name":"SYSTEM","Action":"Customer registration"}',
      dateCreated: "2023-07-23T15:09:16.433807+00:00",
      dateLastModified: "0001-01-01T00:00:00",
      lastModifiedBy: null,
    },
    houseReview: {
      applicationUserId: "ceb75dff-94db-4459-b4d7-a010597c6258",
      applicationUser: null,
      applicationId: "aa0ab1da-f5f3-47ee-b7e4-55a8d694ceb2",
      houseId: "ad563919-e42e-45c3-b7ef-21e9c4e492f2",
      house: null,
      star: 5,
      comment: "I loved the whole house.",
      id: "97d9c304-6828-4333-87dd-155d43319c36",
      createdBy:
        '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Creates Review"}',
      dateCreated: "2023-08-03T02:12:41.956345+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    viewingComplete: false,
    proofOfPaymentReferenceNumber: null,
    downPayment: null,
    loanAmount: null,
    withInNigeria: true,
    countryId: "NIGERIA",
    houseViewing: {
      applicationId: "aa0ab1da-f5f3-47ee-b7e4-55a8d694ceb2",
      contactNumber: "090230219321",
      agentId: "489c6da4-1c7c-432d-ab0a-212ce007d9b3",
      agent: {
        name: "Kayne West",
        firstName: "Kayne",
        lastName: "West",
        email: "kayne.west@gmail.com",
        phoneNumber: "+234090129012",
        address: null,
        organizationId: "giddaa_organization",
        organization: null,
        verificationCode: "WoDzcKX4eM7XqSB",
        roleId: "VIEWING_AGENT_ROLE",
        applicationRole: null,
        profilePicture: defaultImage,
        withInNigeria: true,
        age: null,
        income: null,
        cityId: null,
        city: null,
        affordability: null,
        lastLoginDate: "2023-08-03T00:49:47.530228+00:00",
        isActive: true,
        isDeleted: false,
        id: "489c6da4-1c7c-432d-ab0a-212ce007d9b3",
        createdBy:
          '{"Id":"8750c76e-36ce-44ff-bb0c-13a10c2354ee","Name":"James Bond","Action":"Create Admin User"}',
        dateCreated: "2023-08-03T00:49:47.526006+00:00",
        dateLastModified: "0001-01-01T00:00:00",
        lastModifiedBy: null,
      },
      viewingDate: "2023-07-27T11:06:27.556377+00:00",
      customerId: "ceb75dff-94db-4459-b4d7-a010597c6258",
      customer: null,
      houseId: "ad563919-e42e-45c3-b7ef-21e9c4e492f2",
      house: null,
      status: 0,
      id: "ece7eeb2-9bf8-47ab-92b1-35bfd4264f2f",
      createdBy:
        '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Creates House Viewing"}',
      dateCreated: "2023-08-03T01:50:45.669459+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    interestPercentage: 0,
    documents: [],
    readyToPay: null,
    status: "Sent",
    stage: 1,
    interestDate: null,
    dateViewed: "0001-01-01T00:00:00",
    alternateViewerName: "Meck Aaron",
    alternateViewerEmail: "meck@gmail.com",
    alternateViewerPhoneNumber: "0329023",
    id: "aa0ab1da-f5f3-47ee-b7e4-55a8d694ceb2",
    createdBy:
      '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Creates An Application"}',
    dateCreated: "2023-08-03T02:44:01.684133+00:00",
    dateLastModified: "2023-08-03T01:46:47.268438+00:00",
    lastModifiedBy:
      '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Updates Application"}',
  },
  {
    nhfNumber: "123456789092",
    mortgagePlanId: "84b236bb-d598-4e2b-a814-ec702dbcd410",
    mortgageBanks: "Premium Mortgage Bank",
    mortgagePlan: {
      name: "Plan A",
      description: "An awesome plan",
      type: "DEVELOPER_PLAN",
      mortgagePeriod: 12,
      access: "Anybody",
      maxLoanAmount: 50000.0,
      interestRate: 12.0,
      loanTenor: 12,
      equityPercentage: 0.0,
      organizationId: "c7ecc866-ca52-42a6-b84e-9281877a789c",
      organization: null,
      processingPeriod: 30,
      amortization: 0.0,
      applicableFees: [],
      requiredDocuments: [],
      repaymentMethods: [],
      id: "84b236cb-d598-4e2b-a814-ec702dbcd410",
      createdBy:
        '{"Id":"8750c76e-36ce-44ff-bb0c-13a10c2354ee","Name":" ","Action":"Admin Create Mortgage Plan"}',
      dateCreated: "2023-08-02T23:55:22.922434+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    contactNumber: null,
    houseId: "ad563919-f42e-45c3-b7ef-21e9c4e492f2",
    house: {
      city: null,
      address: "St Marvin Street",
      landmark: "The popes statue",
      units: 5,
      estate: null,
      price: 35000000.0,
      longitude: 0.0,
      latitude: 0.0,
      description: "An awesome house",
      features: [],
      images: null,
      organizationId: "c7ecc866-ca52-42a6-b84e-9281877a789c",
      organization: null,
      houseMortgagePlans: [],
      id: "ad563919-e42e-45c3-b7ef-21e9c4e492f2",
      createdBy:
        '{"Id":"8750c76e-36ce-44ff-bb0c-13a10c2354ee","Name":" ","Action":"Admin Create House"}',
      dateCreated: "2023-08-02T23:49:45.312126+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    customerId: "ceb75dff-94db-4459-b4d7-a010597c6258",
    customer: {
      name: "Sam Curr",
      firstName: "Lionel",
      lastName: "Messi",
      email: "test2@gmail.com",
      phoneNumber: "08106884376",
      address: null,
      organizationId: "giddaa_organization",
      organization: null,
      verificationCode: "isdpPUpOIvYosF",
      roleId: "CUSTOMER_ROLE",
      applicationRole: null,
      profilePicture:
        "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80",
      withInNigeria: true,
      age: null,
      income: null,
      cityId: null,
      city: null,
      affordability: null,
      lastLoginDate: "2023-07-23T15:09:16.433835+00:00",
      isActive: true,
      isDeleted: false,
      id: "ceb75dff-94db-4459-b4d7-a010597c6258",
      createdBy:
        '{"Id":"system","Name":"SYSTEM","Action":"Customer registration"}',
      dateCreated: "2023-07-23T15:09:16.433807+00:00",
      dateLastModified: "0001-01-01T00:00:00",
      lastModifiedBy: null,
    },
    houseReview: {
      applicationUserId: "ceb75dff-94db-4459-b4d7-a010597c6258",
      applicationUser: null,
      applicationId: "aa0ab1da-f5f3-47ee-b7e4-55a8d694ceb2",
      houseId: "ad563919-e42e-45c3-b7ef-21e9c4e492f2",
      house: null,
      star: 5,
      comment: "I loved the whole house.",
      id: "97d9c304-6828-4333-87dd-155d43319c36",
      createdBy:
        '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Creates Review"}',
      dateCreated: "2023-08-03T02:12:41.956345+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    viewingComplete: false,
    proofOfPaymentReferenceNumber: null,
    downPayment: null,
    loanAmount: null,
    withInNigeria: true,
    countryId: "NIGERIA",
    houseViewing: {
      applicationId: "ab1ab1da-f5f3-47ee-b7e4-55a8d694ceb2",
      contactNumber: "090230219321",
      agentId: "489c6da4-1c7c-432d-ab0a-212ce007d9b3",
      agent: {
        name: "Kayne West",
        firstName: "Kayne",
        lastName: "West",
        email: "kayne.west@gmail.com",
        phoneNumber: "+234090129012",
        address: null,
        organizationId: "giddaa_organization",
        organization: null,
        verificationCode: "WoDzcKX4eM7XqSB",
        roleId: "VIEWING_AGENT_ROLE",
        applicationRole: null,
        profilePicture: defaultImage,
        withInNigeria: true,
        age: null,
        income: null,
        cityId: null,
        city: null,
        affordability: null,
        lastLoginDate: "2023-08-03T00:49:47.530228+00:00",
        isActive: true,
        isDeleted: false,
        id: "489c6da4-1c7c-432d-ab0a-212ce007d9b3",
        createdBy:
          '{"Id":"8750c76e-36ce-44ff-bb0c-13a10c2354ee","Name":"James Bond","Action":"Create Admin User"}',
        dateCreated: "2023-08-03T00:49:47.526006+00:00",
        dateLastModified: "0001-01-01T00:00:00",
        lastModifiedBy: null,
      },
      viewingDate: "2023-07-27T11:06:27.556377+00:00",
      customerId: "ceb75dff-94db-4459-b4d7-a010597c6258",
      customer: null,
      houseId: "ad563919-e42e-45c3-b7ef-21e9c4e492f2",
      house: null,
      status: 0,
      id: "ece7eeb2-9bf8-47ab-92b1-35bfd4264f2f",
      createdBy:
        '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Creates House Viewing"}',
      dateCreated: "2023-08-03T01:50:45.669459+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    interestPercentage: 0,
    documents: [],
    readyToPay: null,
    status: "NotSent",
    stage: 2,
    interestDate: null,
    dateViewed: "0001-01-01T00:00:00",
    alternateViewerName: "Meck Aaron",
    alternateViewerEmail: "meck@gmail.com",
    alternateViewerPhoneNumber: "0329023",
    id: "ab1ab1da-f5f3-47ee-b7e4-55a8d694ceb2",
    createdBy:
      '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Creates An Application"}',
    dateCreated: "2023-08-03T02:44:01.684133+00:00",
    dateLastModified: "2023-08-03T01:46:47.268438+00:00",
    lastModifiedBy:
      '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Updates Application"}',
  },
  {
    nhfNumber: "123456789093",
    mortgagePlanId: "84b236bb-d598-4e2b-a814-ec702dbcd529",
    mortgageBanks: "Premium Mortgage Bank",
    mortgagePlan: {
      name: "Plan A",
      description: "An awesome plan",
      type: "BANK_PLAN",
      mortgagePeriod: 12,
      access: "Anybody",
      maxLoanAmount: 50000.0,
      interestRate: 12.0,
      loanTenor: 12,
      equityPercentage: 0.0,
      organizationId: "c7ecc866-ca52-42a6-b84e-9281877a789c",
      organization: null,
      processingPeriod: 30,
      amortization: 0.0,
      applicableFees: [],
      requiredDocuments: [],
      repaymentMethods: [],
      id: "93b236cb-d598-4e2b-a814-ec702dbcd410",
      createdBy:
        '{"Id":"8750c76e-36ce-44ff-bb0c-13a10c2354ee","Name":" ","Action":"Admin Create Mortgage Plan"}',
      dateCreated: "2023-08-02T23:55:22.922434+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    contactNumber: null,
    houseId: "ao573919-f42e-45c3-b7ef-21e9c4e492f2",
    house: {
      city: null,
      address: "St Marvin Street",
      landmark: "The popes statue",
      units: 5,
      estate: null,
      price: 35000000.0,
      longitude: 0.0,
      latitude: 0.0,
      description: "An awesome house",
      features: [],
      images: null,
      organizationId: "c7ecc866-ca52-42a6-b84e-9281877a789c",
      organization: null,
      houseMortgagePlans: [],
      id: "ad563919-e42e-45c3-b7ef-21e9c4e492f2",
      createdBy:
        '{"Id":"8750c76e-36ce-44ff-bb0c-13a10c2354ee","Name":" ","Action":"Admin Create House"}',
      dateCreated: "2023-08-02T23:49:45.312126+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    customerId: "ceb75dff-94db-4459-b4d7-a010597c6258",
    customer: {
      name: "Ethan Hawke",
      firstName: "Ethan",
      lastName: "Hawke",
      email: "test2@gmail.com",
      phoneNumber: "08106884376",
      address: null,
      organizationId: "giddaa_organization",
      organization: null,
      verificationCode: "isdpPUpOIvYosF",
      roleId: "CUSTOMER_ROLE",
      applicationRole: null,
      profilePicture:
        "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      withInNigeria: true,
      age: null,
      income: null,
      cityId: null,
      city: null,
      affordability: null,
      lastLoginDate: "2023-07-23T15:09:16.433835+00:00",
      isActive: true,
      isDeleted: false,
      id: "pob75dff-94db-4459-b4d7-a010597c6258",
      createdBy:
        '{"Id":"system","Name":"SYSTEM","Action":"Customer registration"}',
      dateCreated: "2023-07-23T15:09:16.433807+00:00",
      dateLastModified: "0001-01-01T00:00:00",
      lastModifiedBy: null,
    },
    houseReview: {
      applicationUserId: "ceb75dff-94db-4459-b4d7-a010597c6258",
      applicationUser: null,
      applicationId: "aa0ab1da-f5f3-47ee-b7e4-55a8d694ceb2",
      houseId: "ad563919-e42e-45c3-b7ef-21e9c4e492f2",
      house: null,
      star: 5,
      comment: "I loved the whole house.",
      id: "97d9c304-6828-4333-87dd-155d43319c36",
      createdBy:
        '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Creates Review"}',
      dateCreated: "2023-08-03T02:12:41.956345+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    viewingComplete: false,
    proofOfPaymentReferenceNumber: null,
    downPayment: null,
    loanAmount: null,
    withInNigeria: true,
    countryId: "NIGERIA",
    houseViewing: {
      applicationId: "yy0ab1da-f5f3-47ee-b7e4-55a8d694ceb2",
      contactNumber: "090230219321",
      agentId: "489c6da4-1c7c-432d-ab0a-212ce007d9b3",
      agent: {
        name: "Kayne West",
        firstName: "Kayne",
        lastName: "West",
        email: "kayne.west@gmail.com",
        phoneNumber: "+234090129012",
        address: null,
        organizationId: "giddaa_organization",
        organization: null,
        verificationCode: "WoDzcKX4eM7XqSB",
        roleId: "VIEWING_AGENT_ROLE",
        applicationRole: null,
        profilePicture: defaultImage,
        withInNigeria: true,
        age: null,
        income: null,
        cityId: null,
        city: null,
        affordability: null,
        lastLoginDate: "2023-08-03T00:49:47.530228+00:00",
        isActive: true,
        isDeleted: false,
        id: "489c6da4-1c7c-432d-ab0a-212ce007d9b3",
        createdBy:
          '{"Id":"8750c76e-36ce-44ff-bb0c-13a10c2354ee","Name":"James Bond","Action":"Create Admin User"}',
        dateCreated: "2023-08-03T00:49:47.526006+00:00",
        dateLastModified: "0001-01-01T00:00:00",
        lastModifiedBy: null,
      },
      viewingDate: "2023-07-27T11:06:27.556377+00:00",
      customerId: "ceb75dff-94db-4459-b4d7-a010597c6258",
      customer: null,
      houseId: "ad563919-e42e-45c3-b7ef-21e9c4e492f2",
      house: null,
      status: 0,
      id: "ece7eeb2-9bf8-47ab-92b1-35bfd4264f2f",
      createdBy:
        '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Creates House Viewing"}',
      dateCreated: "2023-08-03T01:50:45.669459+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    interestPercentage: 0,
    documents: [],
    readyToPay: null,
    status: "Sent",
    stage: 2,
    interestDate: null,
    dateViewed: "0001-01-01T00:00:00",
    alternateViewerName: "Meck Aaron",
    alternateViewerEmail: "meck@gmail.com",
    alternateViewerPhoneNumber: "0329023",
    id: "yy0ab1da-f5f3-47ee-b7e4-55a8d694ceb2",
    createdBy:
      '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Creates An Application"}',
    dateCreated: "2023-08-03T02:44:01.684133+00:00",
    dateLastModified: "2023-08-03T01:46:47.268438+00:00",
    lastModifiedBy:
      '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Updates Application"}',
  },
];
