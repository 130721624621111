import { styled } from "styled-components";
import { colors } from "../../../utils/constants";

export const Wrapper = styled.div`
  width: ${(props) => (props.width ? `${props.width}px` : "")};
  position: relative;
  z-index: 2;
  cursor: pointer;
  .select-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${(props) => (props.height ? `${props.height}px` : "60px")};
    border: ${(props) => props.bordercolor ? `2px solid ${props.bordercolor}` : `2px solid ${colors.darkGreen}`};
    border-radius: 100px;
    padding-right: 4px;
    padding-left: 14px;
    font-size: 14px;
    background-color: ${(props) => (props.backgroundcolor ? `${props.backgroundcolor} !important` : "white")};
    transition: all 0.25s ease;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    position: relative;
    z-index: 1;
  }

  .select-btn:hover {
    background-color: ${(props) => (props.hover ? "white !important" : "")};
    border: ${(props) => (props.hover ? `2px solid ${colors.grey}` : `2px solid ${colors.darkGreen}`)};
    // color: ${colors.darkGreen};
  }

  .custom-form-label {
    font-size: 14px;
    font: normal normal bold 13px "Red Hat Display";
    letter-spacing: 0.04px;
    padding-left: 10px;
  }

  .custom-form-label > .required {
    color: red;
  }

  .selected-value {
    font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : "14px")};
    font-weight: bold;
    font-family: "Red Hat Display", sans-serif;
    color: ${colors.darkGreen} !important;
  }

  .select-placeholder {
    font: normal normal normal 1rem "Red Hat Display";
    letter-spacing: 0.04px;
    color: #919191;
    opacity: 1;
  }

  .form_icon {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center; 
    background: ${(props) =>
      props.iconBackgroundcolor ? props.iconBackgroundcolor : colors.grey};
    color: ${(props) => (props.iconColor ? props.iconColor : colors.white)};
    font-size: ${(props) => (props.iconSize ? `${props.iconSize}px` : "24px")};
    padding: 10px; 
    border-top-left-radius: 100px;
    border-bottom-left-radius: 100px;
  }

  /* .form_icon span > svg {
    color: #4B4B4B !important;
    fill: ${colors.darkGreen} !important;
    // stroke: ${colors.darkGreen};;
    stroke-width: 2px;
  } */


  .select-btn > span > svg {
    transform: ${(props) => (props.show ? "rotate(90deg)" : "rotate(-90deg)")};
    stroke-width: 2px !important;
    stroke: ${colors.darkGreen};
    fill: ${colors.darkGreen};
  }

  .content {
    display: ${(props) => (props.show ? "flex" : "none")};
    justify-content: space-between;
    flex-direction: row;
    padding: 25px 13px 0px 13px;
    border: 1px solid red;
    // margin-top: -36px;
    background: ${colors.white};
    border: 1px solid #d8d8d8;
    border-radius: 8px;
    border-top: none;
    position: absolute;
    top: 3rem;
    right: 0;
    left: 0;
    max-height: 20rem;
    overflow-y: auto;
    // z-index: 999;
  }

  .circle {
    display: flex;
    border: ${`1px solid ${colors.gray}`};
    border-radius: 50%;
    width: 24px;
    height: 24px;
  }

  .options {
    width: 100%;
    padding: 0 auto;
    margin-top: 0;
    // z-index: 999;
  }

  .options > hr {
    color: #0808081f;
  }

  .options p {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    border-radius: 8px;
  }
`;
