import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";

const useGetApplicationSubscribers = (page) => {
  const { getRequest } = useAxiosBase();
  return useQuery({
    queryKey: ["all-completed-applications"],
    queryFn: () =>
      getRequest({
        url: `developer/application/get-all-completed?pageNumber=${page}`,
      }).then((res) => res.data?.value?.value),
    staleTime: 50000,
  });
};
export default useGetApplicationSubscribers;