import React from "react";
import useAxiosBase from "./useAxiosBase";
import { useQuery } from "@tanstack/react-query";

const useGetAllBanks = () => {
  const { getRequest } = useAxiosBase();

  return useQuery({
    queryKey: ["all-banks"],
    queryFn: () =>
      getRequest({ url: "/public/organization/bank/get-all" }).then((res) => {
        return res?.data?.value?.value?.data;
      }),
  });
};

export default useGetAllBanks;
