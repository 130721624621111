import React, { useState } from "react";
import { months, mtAppcolumns, mtAppdata, years } from "../../utils/constants";
import { useNav } from "../../hooks/useNav";
import CustomDropdown from "../../Components/Common/CustomDropdown";
import InfoCard from "../../Components/Common/InfoCard";
import InfoTable from "../../Components/Common/InfoTable";
import Modal from "../../Components/Common/Modal/index";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { OfferLetterColumns, OfferLetterColumnsData } from "./constants";
import UploadDocument from "../../Components/Common/UploadDocument";
import { useRef } from "react";
import useSaveSingleImage from "../../hooks/useSaveSingleImage";
import useGetTitleDoc from "./hooks/useGetPropertyDoc";
import CenteredText from "../../Components/CenteredText";
import Spinner from "../../Components/Spinner";

const TitleDocumentList = () => {
  const [omit, setOmit] = useState(true);
  const [appId, setAppId] = useState();
  const [customerData, setCustomerData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [spanText, setSpanText] = useState("Show All Fields");
  useNav({
    hasBackButton: true,
    mainTitle: "Title Documents",
    subTitle:
      "This shows a list of all Propery document requests made by prospective customers for different mortgages and their application status",
  });

  const { data: titleDoc, isLoading } = useGetTitleDoc();

  // const { data, isLoading } = useGetApplications();
  // const {
  //   data: appData,
  //   refetch,
  //   isLoading: loadingApp,
  //   isRefetching: isRefeatching,
  // } = useHandleApplication(appId);

  const handleSpanClick = () => {
    setSpanText(!omit ? "Show All Fields" : "Hide Fields");
    setOmit(!omit);
  };

  const handleApplicationBtnClick = (rowId) => {
    setAppId(rowId);
    setShowModal(true);
  };

  const handleApplication = () => {
  };
  const docRef = useRef();
  const { imageToDisplay, imageObject, handleChange } = useSaveSingleImage({
    optionsId: "ESTATE_IMAGE",
  });

  const handleRowClicked = (row, e) => {
    setCustomerData(row);

    setShowModal(true);
  };

  return (
    <div className="container-fluid">
      <div className="d-flex justify-content-end gap-3 pt-2 pb-3">
        <div>
          <CustomDropdown
            listItem={months}
            defaultItem={months[new Date().getMonth()].value}
            width={150}
          />
        </div>
        <div>
          <CustomDropdown
            listItem={years()}
            defaultItem={years()[0].value}
            width={140}
          />
        </div>
      </div>
      <div className="col-lg-3 col-md-3 col-sm-4">
        <InfoCard
          positive={true}
          topValue={"15%"}
          percentage={true}
          mainValue={"3 Requests"}
        />
      </div>

      {isLoading ? (
        <Spinner />
      ) : titleDoc?.length > 0 ? (
        <div className="row mt-2">
          <div className="col-12">
            <InfoTable
              columns={OfferLetterColumns(omit, handleApplicationBtnClick)}
              dataCollection={titleDoc}
              rightTextAsButton={true}
              spanText={spanText}
              handleSpanTextClick={handleSpanClick}
              pointerOnHover={true}
              highlightOnHover={true}
              onRowClicked={(row, e) => handleRowClicked(row, e)}
            />
          </div>
        </div>
      ) : (
        <>
          <CenteredText title={"No Title Documents Found"}>
            It looks like there are currently no title documents available.
            Reload the page or check your network connection.
          </CenteredText>
        </>
      )}

      <Modal
        show={showModal}
        title={"Send Property Document"}
        subTitle={`You are about to send ${customerData?.customer?.name}'s Propery Document for Bungalow - No 12 ${customerData?.house?.address}, Abuja.`}
        confirmButtonLabel={"Send"}
        closeModal={() => setShowModal(false)}
        confirmModal={handleApplication}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-6">
              <UploadDocument
                title={"Upload Propery Document"}
                docRef={docRef}
                docObject={imageObject}
                handleChange={handleChange}
              />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TitleDocumentList;
