// import { useMutation, useQuery } from "@tanstack/react-query";
// import useAxiosBase from "../../../hooks/useAxiosBase";
// import useAuth from "../../../hooks/useAuth";
// import { toast } from "react-toastify";

// const useGetOfferLetter = () => {
//   const { getRequest, postRequest } = useAxiosBase();
//   const { authUser } = useAuth();
//   const organizationId = authUser?.user?.organizationId;

//   const getOfferLetter = useQuery({
//     queryKey: ["devOfferLetter"],
//     queryFn: () =>
//       getRequest({ url: "/developer/offer-letter-request/get-all" }).then(
//         (res) => res.data.value?.value
//       ),
//     staleTime: 5000,
//   });

//   const sendProspectReminder = useMutation({
//     mutationFn: (prospectId) =>
//       postRequest({
//         url: `/developer/${organizationId}/prospect/${prospectId}/send-reminder`,
//       }),
//     onSuccess: (data) => {
//       toast.success("Reminder sent to " + data);
//     },
//   });

//   return { getOfferLetter, sendProspectReminder };
// };

// export default useGetOfferLetter;

import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";

const useGetOfferLetter = () => {
  const { getRequest } = useAxiosBase();

  return useQuery({
    queryKey: ["getOffer-Letter"],
    queryFn: () =>
      getRequest({ url: "/developer/offer-letter-request/get-all" }).then(
        (res) => res.data?.value
      ),
    staleTime: 5000,
  });
};

export default useGetOfferLetter;