import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";

export const useGetApplicationReview = (page) => {
  const { getRequest } = useAxiosBase();

  return useQuery({
    queryKey: ["application-review"],
    queryFn: () =>
      getRequest({ url: `/developer/applications-under-review/get-all?pageNumber=${page}` }).then(
        (res) => res?.data?.value?.value
      ),
    staleTime: 50000,
  });
};
