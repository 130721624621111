import React from "react";
import { PlanTypeWrapper } from "./style";
import { colors } from "../../../utils/constants";
import CheckInput from "../CheckInput";
import Icons from "../Icons";

const PlanTypeCard = ({title, iconName, description, onChange, onClick, selected, ...rest}) => {
  return (
    <PlanTypeWrapper {...rest} className="select_plan_type_container" onClick={onClick}>
      <div className="select_plan_type_header">
        <span>
          <Icons iconName={iconName} />
        </span>
        <h4 className="plan_type_title m-0 mt-1 px-2">{title}</h4>
        <div className="select_plan_type_checkbox">
          <CheckInput
            width={"1.5rem"}
            height={"1.5rem"}
            borderradius={"50%"}
            bordercolor={colors.darkGray}
            onChange={(e) => onChange(e)}
            checked={selected}
          />
        </div>
      </div>
      <p className="px-3 py-2">{description}</p>
    </PlanTypeWrapper>
  );
};

export default PlanTypeCard;
