import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const  useSendForReview = () => {
  const { postRequest } = useAxiosBase();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: (data) =>
      postRequest({
        url: `developer/application/${data.appId}/send-for-review`,
        data: {
          comment: data.Comment,
        },
      }).then((res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success(res?.data?.value?.message);
          // setTimeout(() => navigate("/application-review"), 2500);
          return;
        }
        toast.error(res?.data?.value?.message);
      }),
    enabled: false,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
};

export default useSendForReview;
