import React, { useContext } from "react";
import { createContext, useState } from "react";

export const SignUpContext = createContext({
  developerAdminData: {},
  setDeveloperAdminData: (value) => {},
});

export const SignUpContextProvider = ({ children }) => {
  const [developerAdminData, setDeveloperAdminData] = useState({});

  return (
    <SignUpContext.Provider
      value={{ developerAdminData, setDeveloperAdminData }}
    >
      {children}
    </SignUpContext.Provider>
  );
};

export const useSignupContext = () => useContext(SignUpContext);
