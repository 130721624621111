import "./App.css";
import MainLayout from "./Components/MainLayout";
import { AppContextProvider } from "./Context/AppContext";
import Routes from "./routes";
import { NavContextProvider } from "./Context/NavContext";
import SideNav from "./Components/SideNav";
import "react-toastify/dist/ReactToastify.css";
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-quill/dist/quill.snow.css';

function App() {
  return (
    <>
      <AppContextProvider>
        <NavContextProvider>
          <div className="mainAppWrapper">
            <SideNav />
            <MainLayout pages={Routes} />
          </div>
        </NavContextProvider>
      </AppContextProvider>
    </>
  );
}

export default App;
