export const LEGAL_SEARCH = 0;
export const OFFER_LETTER_REQUEST = 1;
export const IDENTITY_VERIFICATION = 2;
export const DOCUMENT_SUBMISSION = 2;
export const PENDING = 3;
export const UNDER_REVIEW = 4;
export const REVIEW_REJECTED = 4;
export const REVIEW_APPROVED = 5;
export const OFFER_TERMS = 5;
export const TERMS_ACCEPTED = 5;
export const MORTGAGE_TERMS_UPLOADED = 5;
export const UPLOADED_OFFER = 5;
export const UPLOADED_OFFER_ACCEPTED = 6;
export const PENDING_FEE_PAYMENT = 7;
export const PENDING_EQUITY_PAYMENT = 7;
export const PENDING_BALANCE_PAYMENT = 7;
export const COMPLETED = 8;

export const applicationStatus = {
  [LEGAL_SEARCH]: "LEGAL_SEARCH",
  [OFFER_LETTER_REQUEST]: "OFFER_LETTER_REQUEST",
  [IDENTITY_VERIFICATION]: "IDENTITY_VERIFICATION",
  [DOCUMENT_SUBMISSION]: "DOCUMENT_SUBMISSION",
  [PENDING]: "PENDING",
  [UNDER_REVIEW]: "UNDER_REVIEW",
  [REVIEW_REJECTED]: "REVIEW_REJECTED",
  [REVIEW_APPROVED]: "REVIEW_APPROVED",
  [OFFER_TERMS]: "OFFER_TERMS",
  [TERMS_ACCEPTED]: "TERMS_ACCEPTED",
  [MORTGAGE_TERMS_UPLOADED]: "MORTGAGE_TERMS_UPLOADED",
  [UPLOADED_OFFER]: "UPLOADED_OFFER",
  [UPLOADED_OFFER_ACCEPTED]: "UPLOADED_OFFER_ACCEPTED",
  [PENDING_FEE_PAYMENT]: "PENDING_FEE_PAYMENT",
  [PENDING_EQUITY_PAYMENT]: "PENDING_EQUITY_PAYMENT",
  [PENDING_BALANCE_PAYMENT]: "PENDING_BALANCE_PAYMENT",
  [COMPLETED]: "COMPLETED",
};

export const applicationStatusIndex = {
  LEGAL_SEARCH: LEGAL_SEARCH,
  OFFER_LETTER_REQUEST: OFFER_LETTER_REQUEST,
  IDENTITY_VERIFICATION: IDENTITY_VERIFICATION,
  DOCUMENT_SUBMISSION: DOCUMENT_SUBMISSION,
  PENDING: PENDING,
  UNDER_REVIEW: UNDER_REVIEW,
  REVIEW_REJECTED: REVIEW_REJECTED,
  REVIEW_APPROVED: REVIEW_APPROVED,
  OFFER_TERMS: OFFER_TERMS,
  TERMS_ACCEPTED: TERMS_ACCEPTED,
  MORTGAGE_TERMS_UPLOADED: MORTGAGE_TERMS_UPLOADED,
  UPLOADED_OFFER: UPLOADED_OFFER,
  UPLOADED_OFFER_ACCEPTED: UPLOADED_OFFER_ACCEPTED,
  PENDING_FEE_PAYMENT: PENDING_FEE_PAYMENT,
  PENDING_EQUITY_PAYMENT: PENDING_EQUITY_PAYMENT,
  PENDING_BALANCE_PAYMENT: PENDING_BALANCE_PAYMENT,
  COMPLETED: COMPLETED,
};