import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useSendBVNRequest = (applicationId, customerName) => {
  const { getRequest } = useAxiosBase();

  const sendBVNRequest = useMutation({
    mutationFn: (data) =>
        getRequest({
        url: `developer/application/${applicationId}/request-bvn-verification`,
        data: data,
      }),
      onSuccess: (res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success(`A request has been sent to ${customerName} to provide their BVN.`);
        } else {
          toast.error(res?.response?.data?.value?.message || "An error occurred");
        }
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "An error occurred");
      },
    });

  return { sendBVNRequest };
};

export default useSendBVNRequest;
