import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";

const useGetTitleDoc = () => {
  const { getRequest } = useAxiosBase();

  return useQuery({
    queryKey: ["get-TitleDoc"],
    queryFn: () =>
      getRequest({ url: "/developer/offer-letter-request/get-all" }).then(
        (res) => res.data?.value
      ),
    staleTime: 5000,
  });
};

export default useGetTitleDoc;