import React from "react";
import { CardWrapper } from "./style";
import AffordabilityPill from "../AffordabilityTag";

const PlanCard = ({
  title,
  intrestRate,
  downpayment,
  period,
  monthlyPayment,
  intrestValue,
  downpaymentValue,
  periodValue,
  monthleyPaymentValue,
  onSelectClick,
  ...rest
}) => {
  return (
    <CardWrapper {...rest}>
      <p className="title">{title}</p>
      <div className="plan_details mb-2">
        {intrestRate && (
          <div>
            <p className="detail_title m-0"> {intrestRate}</p>
            <p className="detail_text m-0"> {intrestValue ?? 0}%</p>
          </div>
        )}
        <span className="side_bar"></span>
        {downpayment && (
          <div>
            <p className="detail_title m-0"> {downpayment} </p>
            <p className="detail_text m-0"> {downpaymentValue ?? 0}% </p>
          </div>
        )}
        <span className="side_bar"></span>
        {period && (
          <div>
            <p className="detail_title m-0"> {period}</p>
            <p className="detail_text m-0"> {periodValue ?? 0} Months</p>
          </div>
        )}
        <span className="side_bar"></span>
        {monthlyPayment && (
          <div>
            <p className="detail_title m-0"> {monthlyPayment}</p>
            <p className="detail_text m-0"> ₦{monthleyPaymentValue ?? 0}</p>
          </div>
        )}
      </div>
      <div className="orgName_affordability">
        <div>
          <img />
          <p> Frist by grneal hostpiatl </p>
        </div>
        <AffordabilityPill text={"Affordable"} />
      </div>
      <span className="select_plan" onClick={onSelectClick}> SELECT </span>
    </CardWrapper>
  );
};

export default PlanCard;
