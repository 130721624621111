import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useCreateConstructionUpdate = (houseId) => {
  const { postRequest } = useAxiosBase();

  const createConstructionUpdate = useMutation({
    mutationKey: ["create-construction", houseId],
    mutationFn: (data) =>
      postRequest({
        url: `/developer/house/${houseId}/construction-update/create`,
        data: data,
      }),
    onSuccess: (res) => {
      if (res?.data?.value?.statusCode === 200) {
        toast.success("Created Successfully!");
        return;
      } else {
        toast.error(res?.response?.data?.value?.message || "An error occurred");
      }
    }
  });

  return { createConstructionUpdate };
};

export default useCreateConstructionUpdate;
