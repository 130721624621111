import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const useCreatePlans = (houseId) => {
  const { postRequest } = useAxiosBase();
  const navigate = useNavigate();

  const createPlans = useMutation({
    mutationFn: (data) =>
      postRequest({
        url: "/developer/mortgage-plan/create",
        data: data,
      }),
      onSuccess: (res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success("Created Successfully!");
          navigate(`/properties/house/plans/${houseId}`);
        } else {
          toast.error(res?.response?.data?.value?.message || "An error occurred");
        }
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "An error occurred");
      },
  });

  return { createPlans };
};

export default useCreatePlans;
