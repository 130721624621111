import { Dropdown } from "react-bootstrap";
import { colors, convertToTitleCase } from "../../utils/constants";
import { HiDotsVertical } from "react-icons/hi";

export const HOUSETYPE = "House Type";

export const sliderSettings = {
  infinite: false,
  speed: 500,
  slidesToShow: 4,
  slidesToScroll: 1,

  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        infinite: true,
        dots: true,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export function addDays(date, days) {
  const result = new Date(date);
  return days === -1
    ? result.setDate(result.getFullYear())
    : result.setDate(result.getDate() + days);
}

export const getHouseType = (features) => {
  let result;
  features?.map((feature) => {
    if (feature?.name?.includes(HOUSETYPE)) {
      result = feature?.icon?.toUpperCase();
    }
  });
  return result;
};

const defaultImage =
  "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aG91c2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60";

export const houseColumns = (
  viewProperty,
  handlePlan,
) => [
  {
    name: "",
    grow: 0,
    cell: (row) => (
      <img
        data-tag="allowRowEvents"
        height="60px"
        width="70px"
        alt={row?.images?.length ? row?.images[0]?.name : "alt image"}
        src={row?.images?.length > 0 ? row?.images[0] : defaultImage}
        style={{ borderRadius: "18px", padding: "8px" }}
      />
    ),
  },
  {
    name: <p className="fw-bold p-0 m-0" style={{fontSize: "12px"}}>PROPERTY</p>,
    sortable: true,
    selector: (row) => <><span className="fw-semibold">{convertToTitleCase(row.type)}</span> <span className="circle_bar"></span> {row.address} {row.city}</>,
      wrap: true,
      grow: 2,
  },
  {
    name: <p className="fw-bold p-0 m-0" style={{fontSize: "12px"}}>PRICE</p>,
    sortable: true,
    selector: (row) => `₦ ${row.price?.toLocaleString()}`,
  },
  {
    name: <p className="fw-bold p-0 m-0" style={{fontSize: "12px"}}>ESTATE</p>,
    sortable: true,
    selector: (row) => row.estateName ?? "None Selected",
  },
  {
    name: <p className="fw-bold p-0 m-0" style={{fontSize: "12px"}}>PLAN</p>,
    sortable: true,
    grow: 0.5,
    selector: (row) => row.houseMortgagePlans?.length,
  },
  // {
  //   name: <p className="fw-bold p-0 m-0" style={{fontSize: "12px"}}>House Type</p>,
  //   sortable: true,
  //   selector: (row) => convertToTitleCase(row.type),
  // },
  {
    name: <p className="fw-bold p-0 m-0" style={{fontSize: "12px"}}>UNITES LEFT</p>,
    sortable: true,
    grow: 0.6,
    center: true,
    selector: (row) => row.units,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        {" "}
        ACTION{" "}
      </p>
    ),
    sortable: false,
    grow: 0.5,
    center: true,
    selector: (row) => (
      <Dropdown>
        <Dropdown.Toggle
          id={`dropdown-${row.id}`}
          style={{ background: "transparent", border: "none" }}
        >
          <HiDotsVertical className="fw-bold text-dark" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="position-fixed">
          <Dropdown.Item
            onClick={() => viewProperty(row.id)}
            style={{
              fontFamily: "'Red Hat Display', sans-serif",
              padding: "10px",
              borderBottom: `1px solid ${colors.grey}`,
            }}
          >
            Edit
          </Dropdown.Item>
          <Dropdown.Item
            onClick={() => handlePlan(row.id)}
            style={{
              fontFamily: "'Red Hat Display', sans-serif",
              padding: "10px",
              borderBottom: `1px solid ${colors.grey}`,
            }}
          >
            Delete
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    ),
  },
];