import { createContext, useState } from "react";

const PlanTypeContext = createContext({
  planType: "",
  interestType: "",
  setPlanType: (value) => {},
  setInterestType: (value) => {},
});

export const PlanTypeContextProvider = ({ children }) => {
  const [planType, setPlanType] = useState(""); 
  const [interestType, setInterestType] = useState("");
  return (
    <PlanTypeContext.Provider value={{ planType, interestType, setPlanType, setInterestType }}>
      {children}
    </PlanTypeContext.Provider>
  );
};

export default PlanTypeContext;