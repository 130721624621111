import { styled } from "styled-components";
import { colors } from "../../../utils/constants";

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid green;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #0000001a;
  font-family: "Red Hat Display", sans-serif;
  /* height: ${(props) =>
    props.height ? `${props.height}px !important` : "auto"}; */
  width: ${(props) => (props.width ? `${props.width} !important` : "450px")};
  overflow: hidden;

  .title {
    text-align: center;
    font-size: 17px;
    color: ${(props) =>
      props.color ? `${props.color} !important` : colors.darkGreen};
    font-weight: 800;
    font-family: "Red Hat Display", sans-serif;
    padding-top: 0.5rem !important;
  }

  .plan_details,
  .orgName_affordability {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
  }

  .detail_title {
    color: ${colors.gray};
  }

  .detail_text {
    font-weight: bold;
    color: black;
  }

  .side_bar {
    width: 0.1rem;
    height: 2rem;
    padding: 0;
    margin: 0;
    background-color: ${colors.grey};
  }

  .select_plan {
    text-align: center;
    font-weight: 700;
    width: 100%;
    color: white;
    background-color: ${colors.darkGreen};
    margin: auto;
    padding: 8px;
    cursor: pointer;
  }
`;
