// import { styled } from "styled-components";

// export const SpinnerWrapper = styled.div`
//   width: ${(props) => (props.width ? `${props.width}rem` : "3rem")};
//   height: ${(props) => (props.height ? `${props.height}rem` : "3rem")};
//   margin-top: ${(props) =>
//     props.margin_top ? `${props.margin_top}px` : "auto"};
//   margin-bottom: ${(props) =>
//     props.margin_bottom ? `${props.margin_bottom}px` : "auto"};
//   margin-left: ${(props) =>
//     props.margin_left ? `${props.margin_left}px` : "auto"};
//   margin-right: ${(props) =>
//     props.margin_tight ? `${props.margin_right}px` : "auto"};
// `;


import { keyframes } from "styled-components";
import styled from "styled-components";

// Keyframe animation for loader
const loaderAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const SpinnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Red Hat Display", sans-serif;
  height: 100%;

  .verifyingLoader {
    border-radius: 50%;
    border: ${(props) =>  props.borderWidth ? `${props.borderWidth} solid #f3f3f3` : "7px solid #f3f3f3"};
    border-top: ${(props) =>  props.borderWidth ? `${props.borderWidth} solid green` : "7px solid green"};
    width: ${(props) =>  props.width ? props.width : "50px"};
    height: ${(props) =>  props.height ? props.height : "50px"};
    animation: ${loaderAnimation} 2s linear infinite; /* Apply animation */
  }
 `;