import React, { useContext, useEffect, useState } from "react";
import NavContext from "../Context/NavContext";
import useAuth from "./useAuth";

export const useNav = ({
  mainTitle,
  isHome,
  isProp,
  hasBackButton,
  showNav,
  nav,
  subTitle,
  leftBtnTxt,
  rightBtnTxt,
  leftBtnClick,
  rightBtnClick,
  checkBox,
  leftButtonDelete,
  leftBtnDelete,
  houses,
  estates,
}) => {
  const { navInfo, setNavInfo } = useContext(NavContext);
  const { authUser } = useAuth();
  const organizationName = authUser?.user?.organization?.name;

  useEffect(() => {
    document.title = `Giddaa - ${organizationName} - ${mainTitle}`;

    setNavInfo({
      mainTitle: mainTitle,
      subTitle: subTitle,
      isHome: isHome,
      isProp: isProp ?? false,
      hasBackButton: hasBackButton,
      nav: nav,
      showNav: showNav,
      leftButtonText: leftBtnTxt,
      rightButtonText: rightBtnTxt,
      leftBtnClick: leftBtnClick,
      rightBtnClick: rightBtnClick,
      checkBox: checkBox,
      leftButtonDelete: leftButtonDelete,
      leftBtnDelete: leftBtnDelete,
      houses: houses,
      estates: estates,
    });
  }, [
    mainTitle,
    isHome,
    isProp,
    hasBackButton,
    nav,
    showNav,
    subTitle,
    leftBtnTxt,
    rightBtnTxt,
    checkBox,
    leftButtonDelete,
    houses,
    estates,
  ]);
};
