import create from "./httpService";

const estate = create("/public/estate/get-all?PageNumber=1&PageSize=30");
const state = create("/public/state/get-all?PageNumber=1&PageSize=50");
const city = create("/public/city");
const house = create("/public/house/");
const organization = {
  bank: create("/public/organization/bank/get-all"),
  developer: create("/public/organization/developer/get-all"),
};
const customer = create("/public/customer/");
const account = create("/public/account/");
const optionType = create("/public/option-type/");
export {
  estate,
  state,
  city,
  house,
  organization,
  customer,
  account,
  optionType,
};
