import React from "react";
import { styled } from "styled-components";
import Icons from "../Icons";
const PropertyAddress = ({ text, name, element, elementImg }) => {
  const defaultImage =
  "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aG91c2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60";

  return (
    <div className="row">
      <div className="col-12 xc d-flex flex-row p-3">
        {element === "image" ? (
          <AvatarWrapper>
            <img src={elementImg ?? defaultImage} alt="property-avatar" />
          </AvatarWrapper>
        ) : (
          <Icons iconName={name} className="p-1" />
        )}
        <span className="align-items-center ps-3 p-1">{text}</span>
      </div>
    </div>
  );
};
const AvatarWrapper = styled.div`
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;
export default PropertyAddress;
