import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useUpdateNotificationConfiguration = (notificationConfigurationId) => {
  const { putRequest } = useAxiosBase();

  const updateNotificationConfiguration = useMutation({
    mutationKey: ["update-notification-configurationId", notificationConfigurationId],
    mutationFn: (data) =>
      putRequest({
        url: `developer/notification-configuration/${notificationConfigurationId}/update`,
        data: data,
      }),
      onSuccess: (res) => {
        if (res?.data?.statusCode === 200) {
          toast.success(res?.data?.message);
        } else {
          toast.error(res?.response?.data?.message || "An error occurred");
        }
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "An error occurred");
      },
  });

  return { updateNotificationConfiguration };
};

export default useUpdateNotificationConfiguration;
