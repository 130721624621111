import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../../../hooks/useAxiosBase";

const useGetConstructionUpdate = (houseId, page) => {
  const { getRequest } = useAxiosBase();
  return useQuery({
    queryKey: ["constructionUpdate", houseId],
    queryFn: () =>
      getRequest({ url: `/developer/house/${houseId}/construction-update/get-all?pageNumber=${page}` }).then(
        (res) => res.data.value?.value
      ),
    staleTime: 50000,
  });
};

export default useGetConstructionUpdate;
