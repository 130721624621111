import React, { useEffect, useState } from "react";
import { useGetAllPlans } from "./hooks/useGetAllPlans";
import Button from "../../Components/Common/Button";
import InfoCard from "../../Components/Common/InfoCard";
import CenteredText from "../../Components/CenteredText";
import InfoTable from "../../Components/Common/InfoTable";
import { plansColumns } from "./constant";
import usePermission from "../../hooks/usePermissions";
import Spinner from "../../Components/Spinner";
import { useNavigate } from "react-router-dom";
import { useNav } from "../../hooks/useNav";
import TableLoader from "../../Components/Common/TableLoader";
import SkeletonLoader from "../../Components/SkeletonLoader";

const AllPlans = () => {
  useNav({
    mainTitle: "Plans",
    subTitle:
      "This shows the different types of plans your company accepts from customers. You can also create custom plans solely for your development company.",
  });

  const navigate = useNavigate();

  const {
    accessViewPlans,
    accessCreatePlan,
    accessUpdatePlan,
    accessDeletePlan,
    setAccessDeletePlan,
    setAccessUpdatePlan,
  } = usePermission();

  const [pagination, setPagination] = useState(1);
  const [loadingTable, setLoadingTable] = useState(false);
  const {
    data: plans,
    isLoading,
    refetch,
    isRefetching,
  } = useGetAllPlans(pagination);

  useEffect(() => {
    setLoadingTable(isRefetching);
  }, [isRefetching]);

  useEffect(() => {
    if (pagination) {
      refetch();
    }
  }, [pagination]);

  return (
    <div className="px-2 mortgage-plans">
      {accessViewPlans?.value ? (
        <>
          <div className="">
            <InfoCard
              mainValue={
                isLoading || isRefetching ? (
                  <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
                ) : (
                  plans?.totalRecords ?? "0"
                )
              }
              description={
                isLoading || isRefetching ? (
                  <SkeletonLoader />
                ) : (
                  "Total Plans"
                )
              }
            />
          </div>

          <div className="mb-4">
            {isLoading ? (
              <div className="mt-4">
               <TableLoader />
              </div>
            ) : !plans?.data?.length ? (
              <div className="mt-5">
                <CenteredText title={"You have no Plans at this time"}>
                  {" "}
                  To begin accepting applications and selling your properties
                  online to customers, click the create plan button above to
                  create a purchase plan.
                </CenteredText>
              </div>
            ) : (
              <div className="mt-2">
                <InfoTable
                  columns={plansColumns(isRefetching)}
                  dataCollection={plans?.data}
                  pointerOnHover={true}
                  highlightOnHover={true}
                  loadingTable={loadingTable}
                  paginationTotalRows={plans?.totalRecords}
                  onChangePage={(page) => setPagination(page)}
                  onRowClicked={(row, event) => navigate(`/plans/${row.id}`)}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="mt-5">
          <CenteredText title={"Unauthorized Access"}>
            You don't have permission to view plans. Please contact the
            organization administrator for assistance.
          </CenteredText>
        </div>
      )}
    </div>
  );
};

export default AllPlans;
