import React from "react";
import CenteredText from "../../../Components/CenteredText";
import { arryEmpty } from "../../../utils/generalUtils";

const Payments = ({ application }) => {
  const transactions = application?.transactions;

  const pendingPayment = () => {
    return (
      <CenteredText title={"Pending Payment"} className="my-5 me-5">
        What specific revisions would you like Shirley West to make to this
        document? Please make revisions as specific as possible to address the
        issues you’ve identified.
      </CenteredText>
    );
  };

  return (
    <div className="">
      {/* {arryEmpty(transactions) ? pendingPayment() : ""} */}


      {/* <div className="row">
        <div className="col-7 p-4">
          <h4 className=" text-uppercase">Equity/Downpayment Validation</h4>
          <div className="card border-opacity-25 shadow">
            <div className="card-body">
              <div className="row">
                <p>
                  <small>
                    Payment made to and verified by <b>REMITA.</b>
                  </small>
                </p>
              </div>
              <div className="row">
                <div className="col-6 pt-3">
                  <span className="pe-2">
                    <b>Name:</b>
                  </span>
                  <span>John Simon</span>
                </div>
                <div className="col-6 pt-3">
                  <span className="pe-2">
                    <b>Amount:</b>
                  </span>
                  <span>N360,000</span>
                </div>
                <div className="col-6 pt-3">
                  <span className="pe-2">
                    <b>Date:</b>
                  </span>
                  <span>15th August 2023</span>
                </div>
                <div className="col-6 pt-3">
                  <span className="pe-2">
                    <b>RRR:</b>
                  </span>
                  <span className="pe-2">
                    <span>123456789</span>
                  </span>
                </div>
              </div>
              <div className="row mt-5">
                <div className="col-6 col-md-6">
                  <h4 className="text-uppercase">Payment Status:</h4>
                </div>
                <div className="col-6">
                  <div className="container">
                    <div className="row">
                      <div className="col-2">
                        <BsDatabaseFillCheck
                          color={colors.darkGreen}
                          size={25}
                        />
                      </div>
                      <div className="col-10">
                        <small className="medium-text-bold-success">
                          Successfuly Paid
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="row border"></div> */}
    </div>
  );
};

export default Payments;
