import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../hooks/useAuth";

const RequireAuth = () => {
  const { authUser } = useAuth();
  const location = useLocation();
  const unAthorized = (user) =>
    user ? (
      <Navigate to="/unauthorized" state={{ from: location }} replace />
    ) : (
      <Navigate to="/account/login" state={{ from: location }} replace />
    );

  return authUser?.user?.roleId ? <Outlet /> : unAthorized(authUser?.user);
  // return <Outlet />;
};
export default RequireAuth;
