import React, { useEffect, useState } from "react";
import usePermission from "../../hooks/usePermissions";
import { useNav } from "../../hooks/useNav";
import FormInput from "../../Components/Common/FormInput";
import { colors } from "../../utils/constants";
import InfoCard from "../../Components/Common/InfoCard";
import MultiInfoCard from "../../Components/Common/MultiInfoCard";
import InfoTable from "../../Components/Common/InfoTable";
import { customerColums, customerData } from "./constant";
import { useNavigate } from "react-router";
import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../hooks/useAxiosBase";
import Button from "../../Components/Common/Button";
import useGetApplicationSummary from "./hooks/useGetApplicationSummary";
import useGetApplicationSubscribers from "./hooks/useGetApplicationSubcribers";
import Spinner from "../../Components/Spinner";
import CenteredText from "../../Components/CenteredText";
import TabGroup from "../../Components/TabGroup";
import { mtAppcolumns } from "../../utils/constants";
import { formattedPrice } from "../../utils/constants";
import Dropdown from "../../Components/Common/Dropdown";
import TableLoader from "../../Components/Common/TableLoader";
import SkeletonLoader from "../../Components/SkeletonLoader";

const PaymentPlans = () => {
  const navigate = useNavigate();
  const { getRequest } = useAxiosBase();
  useNav({
    mainTitle: "Portfolio",
    subTitle: "This shows you all the subscribers to your payment plans.",
    rightBtnTxt: "Mortgage Plans",
    rightBtnClick: () => navigate("/mortgages/mortgage-plans"),
  });

  const { accessViewPortfolios } = usePermission();

  const {
    data: applicationSummary,
    isLoading,
    refetch,
    isRefetching,
  } = useGetApplicationSummary();

  const [pagination, setPagination] = useState(1);
  const [loadingTable, setLoadingTable] = useState(false);
  const {
    data: applicationSubscribers,
    isLoading: loadingSubscribers,
    refetch: refetchSubscribers,
    isRefetching: isRefetchingSubscribers,
  } = useGetApplicationSubscribers(pagination);

  const ActionMenu = [
    { id: "DOWNLOAD_FORMAT", name: "DOWNLOAD FORMAT" },
    { id: "UPLOAD_PORTFOLIO", name: "UPLOAD PORTFOLIO" },
  ];

  const handleRowClicked = (row) => {
    navigate(`/portfolio/details/${row.applicationId}`);
  };

  useEffect(() => {
    refetch();
  }, [applicationSummary]);

  useEffect(() => {
    setLoadingTable(isRefetchingSubscribers);
  }, [isRefetchingSubscribers]);

  useEffect(() => {
    if (pagination) {
      refetchSubscribers();
    }
  }, [pagination]);

  return (
    <div className="px-2 mb-4">
      {accessViewPortfolios?.value ? (
        <>
          <TabGroup>
            <div label="SUMMARY">
              <div className="mb-4">
                {isLoading || applicationSummary ? (
                  <div className="row">
                    <div className="col-sm-6 col-md-4 col-xxl-3 mb-3 ps-2 pd">
                      <InfoCard
                        width={"100%"}
                        mainValue={
                          isLoading || isRefetching ? (
                            <SkeletonLoader
                              height={"5rem"}
                              borderRadius={"5px"}
                            />
                          ) : (
                            `₦ ${formattedPrice(
                              applicationSummary?.totalPortolioAmount?.toFixed(
                                2
                              )
                            )}` ?? 0
                          )
                        }
                        description={
                          isLoading || isRefetching ? (
                            <SkeletonLoader />
                          ) : (
                            "Total Portfolio Value"
                          )
                        }
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-xxl-3 mb-3 ps-2 pd">
                      <InfoCard
                        width={"100%"}
                        mainValue={
                          isLoading || isRefetching ? (
                            <SkeletonLoader
                              height={"5rem"}
                              borderRadius={"5px"}
                            />
                          ) : (
                            `₦ ${formattedPrice(
                              applicationSummary?.totalAmountEarned?.toFixed(2)
                            )}` ?? 0
                          )
                        }
                        description={
                          isLoading || isRefetching ? (
                            <SkeletonLoader />
                          ) : (
                            "Amount Currently Earned"
                          )
                        }
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-xxl-3 mb-3 ps-2 pd">
                      <InfoCard
                        width={"100%"}
                        mainValue={
                          isLoading || isRefetching ? (
                            <SkeletonLoader
                              height={"5rem"}
                              borderRadius={"5px"}
                            />
                          ) : (
                            applicationSummary?.totalNumberOfDefaults ?? 0
                          )
                        }
                        description={
                          isLoading || isRefetching ? (
                            <SkeletonLoader />
                          ) : (
                            "Total Number of Defaults"
                          )
                        }
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-xxl-3 mb-3 ps-2 pd">
                      <InfoCard
                        width={"100%"}
                        mainValue={
                          isLoading || isRefetching ? (
                            <SkeletonLoader
                              height={"5rem"}
                              borderRadius={"5px"}
                            />
                          ) : (
                            `₦ ${formattedPrice(
                              applicationSummary?.totalValueOfDefaults?.toFixed(
                                2
                              )
                            )}` ?? 0
                          )
                        }
                        description={
                          isLoading || isRefetching ? (
                            <SkeletonLoader />
                          ) : (
                            "Total Value of Defaults"
                          )
                        }
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-xxl-4 mb-3 ps-2 pd">
                      <InfoCard
                        width={"100%"}
                        mainValue={
                          isLoading || isRefetching ? (
                            <SkeletonLoader
                              height={"5rem"}
                              borderRadius={"5px"}
                            />
                          ) : (
                            `₦ ${formattedPrice(
                              applicationSummary?.netPortfolioValue?.toFixed(2)
                            )}` ?? 0
                          )
                        }
                        description={
                          isLoading || isRefetching ? (
                            <SkeletonLoader />
                          ) : (
                            "Net Portfolio Value"
                          )
                        }
                      />
                    </div>
                    <div className="col-md-5 mb-3 ps-2 pd">
                      <MultiInfoCard
                        width={"100%"}
                        cardlin1
                        cardlin2
                        mainValue={
                          isLoading || isRefetching ? (
                            <SkeletonLoader
                              height={"5rem"}
                              borderRadius={"5px"}
                            />
                          ) : (
                            applicationSummary?.totalValueOfDefaults?.toFixed(
                              2
                            ) ?? 0
                          )
                        }
                        description={
                          isLoading || isRefetching ? (
                            <SkeletonLoader />
                          ) : (
                            "Total Subscribers"
                          )
                        }
                        mainValue2={
                          isLoading || isRefetching ? (
                            <SkeletonLoader
                              height={"5rem"}
                              borderRadius={"5px"}
                            />
                          ) : (
                            applicationSummary?.totalApplicationWithInNigeria ??
                            0
                          )
                        }
                        description2={
                          isLoading || isRefetching ? (
                            <SkeletonLoader />
                          ) : (
                            "Within Nigeria"
                          )
                        }
                        mainValue3={
                          isLoading || isRefetching ? (
                            <SkeletonLoader
                              height={"5rem"}
                              borderRadius={"5px"}
                            />
                          ) : (
                            applicationSummary?.totalApplicationOutsideNigeria ??
                            0
                          )
                        }
                        description3={
                          isLoading || isRefetching ? (
                            <SkeletonLoader />
                          ) : (
                            "Outside Nigeria"
                          )
                        }
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-xxl-3 mb-3 ps-2 pd">
                      <InfoCard
                        width={"100%"}
                        mainValue={
                          isLoading || isRefetching ? (
                            <SkeletonLoader
                              height={"5rem"}
                              borderRadius={"5px"}
                            />
                          ) : (
                            applicationSummary?.totalUnitsSold ?? 0
                          )
                        }
                        description={
                          isLoading || isRefetching ? (
                            <SkeletonLoader />
                          ) : (
                            "Total Units Sold"
                          )
                        }
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-xxl-3 mb-3 ps-2 pd">
                      <InfoCard
                        width={"100%"}
                        mainValue={
                          isLoading || isRefetching ? (
                            <SkeletonLoader
                              height={"5rem"}
                              borderRadius={"5px"}
                            />
                          ) : (
                            applicationSummary?.totalUniqueHouseSold ?? 0
                          )
                        }
                        description={
                          isLoading || isRefetching ? (
                            <SkeletonLoader />
                          ) : (
                            "Total Unique Properties Sold"
                          )
                        }
                      />
                    </div>
                    <div className="col-sm-6 col-md-4 col-xxl-4 mb-3 ps-2 pd">
                      <InfoCard
                        width={"100%"}
                        mainValue={
                          isLoading || isRefetching ? (
                            <SkeletonLoader
                              height={"5rem"}
                              borderRadius={"5px"}
                            />
                          ) : (
                            applicationSummary?.totalApplicationsBetween20To40 ??
                            0
                          )
                        }
                        description={
                          isLoading || isRefetching ? (
                            <SkeletonLoader />
                          ) : (
                            "Subscribers (20 - 40Yrs)"
                          )
                        }
                      />
                    </div>
                    <div className="col-md-5 mb-3 ps-2 pd">
                      <MultiInfoCard
                        width={"100%"}
                        cardlin1
                        cardlin2
                        mainValue={
                          isLoading || isRefetching ? (
                            <SkeletonLoader
                              height={"5rem"}
                              borderRadius={"5px"}
                            />
                          ) : (
                            applicationSummary?.totalApplicationsBetween20To40 ??
                            0
                          )
                        }
                        description={
                          isLoading || isRefetching ? (
                            <SkeletonLoader />
                          ) : (
                            "Subscribers (20 - 40Yrs)"
                          )
                        }
                        mainValue2={
                          isLoading || isRefetching ? (
                            <SkeletonLoader
                              height={"5rem"}
                              borderRadius={"5px"}
                            />
                          ) : (
                            applicationSummary?.totalApplicationsBetween40To60 ??
                            0
                          )
                        }
                        description2={
                          isLoading || isRefetching ? (
                            <SkeletonLoader />
                          ) : (
                            "Subscribers (40 - 60Yrs)"
                          )
                        }
                        mainValue3={
                          isLoading || isRefetching ? (
                            <SkeletonLoader
                              height={"5rem"}
                              borderRadius={"5px"}
                            />
                          ) : (
                            applicationSummary?.totalApplicationsOver60 ?? 0
                          )
                        }
                        description3={
                          isLoading || isRefetching ? (
                            <SkeletonLoader />
                          ) : (
                            "Subscribers (Over 60 Yrs)"
                          )
                        }
                      />
                    </div>
                  </div>
                ) : (
                  !applicationSummary && (
                    <div className="mt-5">
                      <CenteredText title={"No Applications"}>
                        There are no application subscribers at this time
                      </CenteredText>
                    </div>
                  )
                )}
              </div>
            </div>

            {/* SUBSCRIBERS TAB */}
            <div label="SUBSCRIBERS">
              <div className="mb-4">
                {loadingSubscribers ? (
                  <div className="mt-3">
                   <TableLoader />
                  </div>
                ) : !applicationSubscribers?.data?.length ? (
                  <div className="mt-5">
                    <CenteredText title={"No Applications"}>
                      There are no application subscribers at this time
                    </CenteredText>
                  </div>
                ) : (
                  <div className="">
                    <InfoTable
                      columns={customerColums(isRefetchingSubscribers)}
                      dataCollection={applicationSubscribers?.data}
                      rightTextAsButton={true}
                      pointerOnHover={true}
                      highlightOnHover={true}
                      loadingTable={loadingTable}
                      paginationTotalRows={applicationSubscribers?.totalRecords}
                      onChangePage={(page) => setPagination(page)}
                      onRowClicked={handleRowClicked}
                    />
                  </div>
                )}
              </div>
            </div>
          </TabGroup>
        </>
      ) : (
        <div className="mt-5">
          <CenteredText title={"Unauthorized Access"}>
            You don't have permission to view portfolio. Please contact the
            organization administrator for assistance.
          </CenteredText>
        </div>
      )}
    </div>
  );
};

export default PaymentPlans;
