import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";

export const useGetNotificationUsers = (page) => {
    const { getRequest } = useAxiosBase();
  
    return useQuery({
      queryKey: ["get-notification-users"],
      queryFn: () =>
        getRequest({ url: `developer/user/get-registered-users?pageNumber=${page}` }).then(
          (res) => res?.data?.value
        ),
      staleTime: 50000,
    });
  };
  