import React from "react";
import { CardWrapper } from "./cardStyle";

const DocumentAndFeeCard = ({ name, isRequired, pill, pillColor }) => {
  return (
    <CardWrapper>
      <p className="card_title m-0">{name}</p>
      <div className="isRequired">
        {isRequired && <p className={`m-0 ${pillColor ? pillColor : "redDangerPill"}`}>{pill}</p>}
      </div>
    </CardWrapper>
  );
};

export default DocumentAndFeeCard;
