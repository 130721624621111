import { styled } from "styled-components";
import { colors } from "../../../utils/constants";

export const PlanTypeWrapper = styled.div`
  border: 1px solid gray;
  border-radius: 15px;
  min-height: 17rem;
  margin-bottom: 2rem;
  cursor: pointer;
  overflow: hidden;

  .select_plan_type_header {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(51, 95, 50, 0.05);
    padding: 10px 3px;
  }

  .select_plan_type_header > span > span > svg {
    height: 2.5rem;
  }

  .plan_type_title {
    color: #335f32;
    font-size: 20px;
  }

  .select_plan_type_checkbox {
    position: absolute;
    top: 0.5rem;
    right: 0.8rem;
  }
`;
