import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useCreatePertnershipTerms = () => {
  const { postRequest } = useAxiosBase();

  const createPertnershipTerms = useMutation({
    mutationFn: (data) =>
      postRequest({
        url: `developer/partnership/create-terms`,
        data: data,
      }),
      onSuccess: (res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success(res?.data?.value?.message);
        } else {
          toast.error(res?.response?.data?.value?.message || "An error occurred");
        }
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message || "An error occurred");
      },
  });

  return { createPertnershipTerms };
};

export default useCreatePertnershipTerms;
