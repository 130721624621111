import React, { useState, useEffect, useRef } from "react";
import { colors } from "../../../utils/constants";
import CustomTextEditor from "../../../Components/Common/CustomRichTextEditor";
import Button from "../../../Components/Common/Button";
import CenteredText from "../../../Components/CenteredText";
// import Icons from "../../../Components/Common/Icons";

const RevissionChart = ({
  docName,
  comment,
  setComment,
  customerName,
  revisionDoc,
  btnIsLoading,
}) => {
  const chatEndRef = useRef(null);

  const [conversation, setConversation] = useState(revisionDoc);

  const handleSendMessage = () => {
    const newMessage = {
      comment: [
        {
          message: comment,
          userType: "DEVELOPER",
          dateCreated: new Date().toISOString(),
        },
      ],
      sending: true, // Flag to indicate if message is currently sending
    };

    setConversation((prevConversation) => [...prevConversation, newMessage]);
    setComment(""); // Clear the input field
  };

  const stripHtmlTags = (html) => {
    const div = document.createElement("div");
    div.innerHTML = html;
    return div.textContent || div.innerText || "";
  };

  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    if (btnIsLoading) {
      handleSendMessage();
    }
  }, [btnIsLoading]);

  useEffect(() => {
    scrollToBottom();
  }, [conversation, comment]);

  useEffect(() => {
    setConversation(revisionDoc);
  }, [revisionDoc]);

  return (
    <div className="message_container">
      {conversation?.length > 0 ? (
        conversation?.map((revision, index) => (
          <div
            className={`message ${
              revision?.comment[0]?.userType === "DEVELOPER"
                ? "agent_messages"
                : "customer_messages"
            }`}
            key={index}
          >
            <p className="m-0 mb-3">
              {" "}
              {stripHtmlTags(revision?.comment[0]?.message)}
            </p>
            <div className="d-flex align-items-center gap-1">
              <div
                className=""
                style={{
                  height: "1.5rem",
                  width: "1.5rem",
                  overflow: "hidden",
                  borderRadius: "50%",
                  border: "1px solid #D8D8D8",
                }}
              >
                <img
                  src="//s3.amazonaws.com/appforest_uf/f1675031132769x885606220423525500/GiddaaLogoGreen.svg"
                  className="w-100 h-100"
                />
              </div>
              <p className="m-0 fw-bold mt-1" style={{ fontSize: "0.6rem" }}>
                {revision?.comment[0]?.userType === "DEVELOPER"
                  ? "YOU"
                  : customerName}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p
                className="m-0 fw-semibold mt-1"
                style={{ fontSize: "0.6rem", color: colors?.green }}
              >
                Sent on{" "}
                {new Date(revision?.comment?.[0].dateCreated).toDateString()}
              </p>
              <p
                className="m-0 fw-semibold mt-1"
                style={{ fontSize: "0.6rem", color: colors?.green }}
              >
                {revision.sending && btnIsLoading ? "Sending..." : "Sent"}
              </p>
            </div>
          </div>
        ))
      ) : (
        <div className="pt-5">
          <CenteredText title={"This document has no revisions"}>
            If you are not satisfied with the <strong>{docName}</strong>{" "}
            provided by <strong>{customerName}</strong>, you can send a message detailing your
            requirements or expectations for the{" "}
            <strong>{docName}</strong>.
          </CenteredText>
        </div>
      )}
      <div className="flex justify-content-end mt-3">
        <CustomTextEditor
          height={"5rem"}
          value={comment}
          placeholder={"Type a message"}
          onChange={(e) => setComment(e)}
        />
        <div ref={chatEndRef}></div>
      </div>
    </div>
  );
};

export default RevissionChart;
