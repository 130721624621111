import React, { useEffect, useRef, useState } from "react";
import useCreateConstructionUpdate from "./hooks/useCreateConstructionUpdate";
import useGetConstructionUpdate from "./hooks/useGetConstructionUpdate";
import useDeleteConstructionUpdate from "./hooks/useDeleteConstructionUpdate";
import InfoCard from "../../../../Components/Common/InfoCard";
import TableLoader from "../../../../Components/Common/TableLoader";
import InfoTable from "../../../../Components/Common/InfoTable";
import CenteredText from "../../../../Components/CenteredText";
import SkeletonLoader from "../../../../Components/SkeletonLoader";

import { columns } from "./constant";
import Button from "../../../../Components/Common/Button";
import { useNav } from "../../../../hooks/useNav";
import Modal from "../../../../Components/Common/Modal";
import Icons from "../../../../Components/Common/Icons";
import { imageTypes } from "../../../../utils/mockData";
import { debounce } from "lodash";
import { toast } from "react-toastify";
import { fileExtension, getBase64Image } from "../../../../utils/imageUtils";
import { colors, convertToTitleCase } from "../../../../utils/constants";
import Dropdown from "../../../../Components/Common/Dropdown";
import FormInput from "../../../../Components/Common/FormInput";
import CustomTextEditor from "../../../../Components/Common/CustomRichTextEditor";
import { useLocation, useParams } from "react-router-dom";

const EstateConstructionUpdate = () => {
  useNav({
    hasBackButton: true,
    mainTitle: "Construction Updates",
  });
  const params = useParams();
  const estateId = params?.estateId;

  const location = useLocation();
  const action = new URLSearchParams(location.search).get("action");

  const [pagination, setPagination] = useState(1);
  const [loadingTable, setLoadingTable] = useState(false);
  const [constructionUpdateId, setConstructionUpdateId] = useState(null)
  const {
    data: constructionUpdate,
    isLoading,
    refetch,
    isRefetching,
  } = useGetConstructionUpdate(estateId, pagination);

  const { createConstructionUpdate } = useCreateConstructionUpdate(estateId);
  const { deleteConstructionUpdate } = useDeleteConstructionUpdate(estateId, constructionUpdateId);

  const [imagesToDisplay, setImagesToDisplay] = useState([]);
  const [imageObject, setImageObject] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [editableImageName, setEditableImageName] = useState("");
  const [editableImageType, setEditableImageType] = useState(imageTypes[0]?.id);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [showConstructionUpdateModal, setShowConstructionUpdateModal] =
    useState();
  const [
    showDeleteConstructionUpdateModal,
    setShowDeleteConstructionUpdateModal,
  ] = useState(false);

  const handleChange = async (event) => {
    const files = Array.from(event.target.files);
    const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];

    if (files.length !== 0 && files[0].length !== 0) {
      for (let file of files) {
        // Check if file is of allowed type
        if (!allowedTypes.includes(file.type)) {
          toast.error("Only .png, .jpeg, and .jpg files are allowed.");

          // Reset file input value
          event.target.value = null;
          return;
        }

        if (file.size > 2 * 1024 * 1024) {
          toast.error("Image size shouldn't be more than 2MB.");

          // Reset file input value
          event.target.value = null;
          return;
        }

        // Check if an image with the same name already exists
        if (imagesToDisplay.some((img) => img.name === file.name)) {
          toast.error(`An image with the name ${file.name} already exists.`);

          // Reset file input value
          event.target.value = null;
          return;
        }

        const displayImg = URL.createObjectURL(file);
        setShowImageModal(true);
        setSelectedImage({ image: displayImg, name: file.name });
        setEditableImageName(file.name);
        setImagesToDisplay((prevImages) => [
          { image: displayImg, name: file.name },
          ...prevImages,
        ]);
        URL.revokeObjectURL(file);
        const base64 = await getBase64Image(file);
        const newImage = {
          base64: base64,
          name: file.name,
          optionId: "CONSTRUCTION_UPDATE_IMAGE",
          description: "",
          extension: fileExtension(file),
        };
        setImageObject((prevImages) => [newImage, ...prevImages]);
      }

      // Reset file input value
      event.target.value = null;
      return;
    }
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setEditableImageName(image.name);
    setEditableImageType(image.type);
    setShowImageModal(true);
  };

  const hiddenFileInputRef = useRef(null);
  const handleClick = () => {
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.click();
    } else {
      console.error("The file input is not yet available.");
    }
  };

  const handleEditSubmit = () => {
    if (imageObject?.length <= 0) {
      toast.error("Please select at least one image.");
      return;
    }

    if (!name) {
      toast.error("Please enter a name for the image.");
      return;
    }

    if (!description) {
      toast.error("Please provide a description for the image.");
      return;
    }
    const formData = {
      name: name,
      description: description,
      documents: imageObject,
    };

    createConstructionUpdate.mutate(formData, {
      onSuccess: () => {
        refetch();
        setShowConstructionUpdateModal(false);
      },
    });
  };

  const handleAction = (rowId, action) => {
    setConstructionUpdateId(rowId)
    setShowDeleteConstructionUpdateModal(true)
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);
  const openDeleteModal = (image) => {
    setImageToDelete(image);
    setShowDeleteModal(true);
  };

  const handleDelete = () => {
    if (imageToDelete) {
      setImagesToDisplay((prevImages) =>
        prevImages.filter((img) => img !== imageToDelete)
      );

      setImageObject((prevImages) =>
        prevImages.filter((img) => img.name !== imageToDelete.name)
      );
    }
    setShowDeleteModal(false);
  };

  useEffect(() => {
    if (action === "create") {
      setShowConstructionUpdateModal(true);
    }
  }, []);

  useEffect(() => {
    setLoadingTable(isRefetching);
  }, [isRefetching]);

  useEffect(() => {
    if (pagination) {
      refetch();
    }
  }, [pagination]);

  return (
    <div className="px-2 mortgage-plans">
      <div className="pe-1 text-end">
        <Button
          btnText={"Create"}
          onBtnClick={() => setShowConstructionUpdateModal(true)}
        />
      </div>

      <div className="">
        <InfoCard
          mainValue={
            isLoading || isRefetching ? (
              <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
            ) : (
              constructionUpdate?.totalRecords ?? "0"
            )
          }
          description={
            isLoading || isRefetching ? <SkeletonLoader /> : "Total Construction Updates"
          }
        />
      </div>

      <div className="mb-4">
        {isLoading || isRefetching ? (
          <div className="mt-4">
            <TableLoader />
          </div>
        ) : !constructionUpdate?.data?.length ? (
          <div className="mt-5">
            <CenteredText
              title={"You have no created construction Update at this time"}
            >
              Click the create button above to add a new update.
            </CenteredText>
          </div>
        ) : (
          <div className="mt-2">
            <InfoTable
              columns={columns(
                (rowId, action) => handleAction(rowId, action),
                isRefetching
              )}
              dataCollection={constructionUpdate?.data}
              pointerOnHover={true}
              highlightOnHover={true}
              loadingTable={loadingTable}
              paginationTotalRows={constructionUpdate?.totalRecords}
              onChangePage={(page) => setPagination(page)}
            />
          </div>
        )}
      </div>

      <Modal
        show={showConstructionUpdateModal}
        closeModal={() => {
          setImagesToDisplay([]);
          setName("");
          setDescription("");
          setShowConstructionUpdateModal(false);
        }}
        title={"Create Construction Update"}
        confirmModal={() => handleEditSubmit()}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Create"}
        width={"100%"}
        height={"100%"}
        closeModalIcon
        titleFontSize={"30px"}
        isBtnLoading={createConstructionUpdate.isLoading}
        disabled={createConstructionUpdate.isLoading}
      >
        <div>
          {/* Main Images Display Section */}
          <div className="row ps-3">
            <div className="col">
              <div
                className="d-flex flex-column justify-content-center align-items-center"
                style={{
                  height: "220px",
                  borderRadius: "8px",
                  border: "1px solid #335f32",
                  cursor: "pointer",
                }}
                onClick={handleClick}
              >
                <span className="plus-icon d-flex justify-content-center align-items-center">
                  <Icons iconName={"plus"} />
                </span>
                <p className="fw-bold text-success uppercase mt-3">
                  ADD CONSTRUCTION UPDATE IMAGES
                </p>
              </div>
            </div>

            {/* Display First Three Images */}
            {imagesToDisplay?.slice(0, 3).map((image) => (
              <div
                className="col house_image_wrapper position-relative"
                key={image.name}
                style={{
                  backgroundImage: `url(${image.image})`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <div className="img_overlay position-absolute"></div>
                <div onClick={() => handleImageClick(image)}>
                  <p className="img_open">
                    <span className="img_open-icon">
                      <Icons iconName={"open"} className="" />
                    </span>
                    Open
                  </p>
                </div>
                <div className="img_footer position-absolute">
                  <div className="img_delete_icon d-flex justify-content-end w-100 mb-2">
                    <Button
                      btnText={<Icons iconName={"delete"} />}
                      bordercolor={colors.black}
                      borderHover={colors.red}
                      backgroundcolor={colors.white}
                      backgroundHover={colors.dangerLight}
                      colorHover={colors.danger}
                      color={colors.danger}
                      iconcolorHover={colors.danger}
                      padding={"0"}
                      bordersize={2}
                      btntextcolor={colors.red}
                      width={"40px"}
                      height={"43px"}
                      type={"button"}
                      onBtnClick={() => openDeleteModal(image)}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Thumbnails Section */}
          <div className="col h-25 thumbs-wrapper mt-3 mb-4 px-3">
            {imagesToDisplay.length > 3 &&
              imagesToDisplay.slice(3).map((image, index) => (
                <div className="thumb position-relative d-flex flex-column me-2">
                  <div
                    className="position-relative w-full h-full thumb_img"
                    style={{
                      backgroundImage: `url(${image.image})`,
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  >
                    <div className="thumb_img_overlay position-absolute"></div>
                    <p
                      className="thumb_open"
                      onClick={() => handleImageClick(image)}
                    >
                      <span className="thumb_open_icon">
                        <Icons iconName={"open"} className="" />
                      </span>
                      Open
                    </p>
                  </div>
                  <p className="delete_icon_thumbs">
                    <Button
                      btnText={<Icons iconName={"delete"} />}
                      bordercolor={colors.black}
                      borderHover={colors.red}
                      backgroundcolor={colors.white}
                      backgroundHover={colors.dangerLight}
                      colorHover={colors.danger}
                      color={colors.danger}
                      iconcolorHover={colors.danger}
                      padding={"0"}
                      bordersize={2}
                      btntextcolor={colors.red}
                      iconWidth={"15px"}
                      width={"25px"}
                      height={"29px"}
                      type={"button"}
                      onBtnClick={() => openDeleteModal(image)}
                    />
                  </p>
                  <p
                    className="my-0 text-success"
                    title={image.name}
                    style={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      cursor: "pointer",
                    }}
                  >
                    {image.name}
                  </p>
                </div>
              ))}
          </div>

          {/* File Input */}
          <input
            type="file"
            className="d-none"
            ref={hiddenFileInputRef}
            accept="image/*"
            onChange={handleChange}
            multiple
          />
        </div>

        <div className="px-3">
          <FormInput
            required
            labelName={"Name"}
            placeholder={"Enter update name"}
            value={name}
            onChange={(name, value) => setName(value)}
          />

          <div className="col-md-12 mt-3">
            <CustomTextEditor
              required
              height={"10rem"}
              labelName={"Description"}
              value={description}
              placeholder={"eg. Enter description"}
              onChange={(e) => setDescription(e)}
            />
          </div>
        </div>
      </Modal>

      <Modal
        show={showDeleteModal}
        closeModal={() => setShowDeleteModal(false)}
        title={"Delete Image"}
        cancelButtonLabel="Cancel"
        confirmButtonLabel="Delete"
        confirmModal={() => handleDelete()}
        cancelModal={() => setShowDeleteModal(false)}
      >
        <p className="text-center">
          Are you sure you want to delete this image?
        </p>
      </Modal>

      <Modal
        show={showDeleteConstructionUpdateModal}
        title={"Delete Construction Update"}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        closeModal={() => setShowDeleteConstructionUpdateModal(false)}
        confirmModal={() =>
          deleteConstructionUpdate.mutate(null, {
            onSuccess: () => {
              refetch();
              setShowDeleteConstructionUpdateModal(false);
            },
          })
        }
        isBtnLoading={deleteConstructionUpdate.isLoading}
        disabled={deleteConstructionUpdate.isLoading}
      >
        <div className="text-center">
          Are you sure you want to delete this Construction Update?.{" "}
          <strong className="text-danger">This action can't be undone</strong>
        </div>
      </Modal>
    </div>
  );
};

export default EstateConstructionUpdate;
