import React, { useState } from "react";
import { useNav } from "../../../../hooks/useNav";
import MortgagePlanForm from "./MortgagePlanForm";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import Spinner from "../../../../Components/Spinner";
import HeaderTitle from "../../../../Components/Common/HeaderTitle";
import Button from "../../../../Components/Common/Button";
import Icons from "../../../../Components/Common/Icons";
import { colors } from "../../../../utils/constants";
import Modal from "../../../../Components/Common/Modal";
import { MdCancel } from "react-icons/md";
import { toast } from "react-toastify";
import CenteredText from "../../../../Components/CenteredText";
import useDeletePlans from "./hooks/useDeletePlans";

const MortgagePlanDetails = () => {
  useNav({ hasBackButton: true, checkBox: true });
  
  const [showModal, setShowModal] = useState(false);
  const { deleteRequest, getRequest } = useAxiosBase();
  const navigate = useNavigate();
  const params = useParams();
  const houseId = params?.houseId;
  const planId = params?.id;

  const {
    data: plan,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["mortgage-plan", planId],
    queryFn: () =>
      getRequest({ url: `/developer/mortgage-plan/${planId}` }).then(
        (res) => res.data?.value?.value
      ),
  });

  const { deletePlans } = useDeletePlans(planId);
 
  if (isLoading) return <div style={{height: "70vh"}}> <Spinner loadingText={"Loading Plan Details..."} /> </div>;;
  return (
    <div className="container-fluid">
      {plan ? (
        <HeaderTitle
          title={`Plan - ${isLoading ? <Spinner /> : plan?.name ?? ""}`}
        >
          <div className="col-2 col-md-2 col-sm-2">
            <Button
              btnText={<Icons iconName={"delete"} />}
              bordercolor={colors.black}
              borderHover={colors.red}
              backgroundcolor={colors.white}
              backgroundHover={colors.dangerLight}
              colorHover={colors.danger}
              color={colors.danger}
              iconcolorHover={colors.danger}
              padding={"0"}
              bordersize={2}
              btntextcolor={colors.red}
              width={"40px"}
              height={"43px"}
              type={"button"}
              onBtnClick={() => setShowModal(true)}
            />
          </div>
        </HeaderTitle>
      ) : (
        <div className="mt-5">
          <CenteredText title="No available Plan at the moment">
            <p className="text-center">
              Please check your network connectiion and reload the page.
            </p>
          </CenteredText>
        </div>
      )}

      {plan && <MortgagePlanForm plan={plan} />}

      <Modal
        show={showModal}
        title={"Delete Plan"}
        subTitle={
          <>
            Are you sure you want to delete{" "} <strong>{plan?.name}.</strong>{" "}
            <strong className="text-danger">
              This action can't be undone.
            </strong>
          </>
        }
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Delete"}
        closeModal={() => setShowModal(false)}
        confirmModal={() =>
          deletePlans.mutate(null, {
            onSuccess: () => {
              refetch();
              setShowModal(false);
              navigate(`/properties/house/plans/${houseId}`);
            },
          })
        }
        isBtnLoading={deletePlans.isLoading}
        disabled={deletePlans.isLoading}
      />
    </div>
  );
};

export default MortgagePlanDetails;
