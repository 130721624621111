import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";

const useGetApplication = ({ applicationOwner, applicationId }) => {
  const { getRequest } = useAxiosBase();
  return useQuery({
    queryKey: ["mortgage-application", applicationOwner, applicationId],
    queryFn: () =>
      getRequest({ url: `/developer/${applicationOwner}/${applicationId}` }).then(
        (response) => response.data?.value?.value ?? null
      ),
    refetchOnWindowFocus: false,
  });
};

export default useGetApplication;
