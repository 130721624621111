import { styled } from "styled-components";
import { colors } from "../../../utils/constants";

export const SearchWrapper = styled.div`
position: relative;

  .custom-select__control { 
    font-size: 14px !important;
    height: ${(props) => (props.height ? `${props.height}px` : "50px")};
    width: ${(props) => (props.width ? `${props.width} !important` : "")};
    border: ${(props) =>
      props.bordercolor
        ? `2px solid ${props.bordercolor}`
        : "2px solid transparent"};
    border-radius: 100px;
    background-color: ${(props) =>
      props.backgroundcolor
        ? `${props.backgroundcolor} !important`
        : colors.grey};
    transition: all 0.25s ease;
    padding-right: 0.3rem;
    padding-left: 1rem;
    overflow: hidden;
    border: none !important;
    box-shadow: none !important;
  }

  .custom-select__control:hover {
    background-color: white;
    border: 1px solid ${colors.darkGreen} !important;
  }

  .custom-select__control--is-focused {
    border: none !important;
    box-shadow: none !important;
  }

  .custom-select__menu {
    font-size: 13px !important; /* Adjusted font size */
  }

  .custom-select__option {
    padding: 2px 18px !important; /* Adjusted padding for options */
  }

  .custom-select__indicator-separator {
    display: none;
  }

  .form-error {
    position: absolute;
    left: 0;
    bottom: -1.2rem;

    padding: 0 16px 0 8px;
    font-family: "Red Hat Display";
    font-size: 13px;
    font-weight: 500;
    color: ${colors.red};
  }
`;
