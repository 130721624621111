import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { toast } from "react-toastify";

const useInviteBanks = () => {
  const { postRequest } = useAxiosBase();

  const inviteBank = useMutation({
    mutationFn: (data) => 
      postRequest({
        url: `/developer/partnership/send-email-invite`,
        data: data,
      }).then((res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success("Bank invitation sent successfully!");
        } else {
          toast.error("Failed to send bank invitation. Please try again.");
        }
      }),
    onSuccess: (res) => {
      if (res?.data?.value?.statusCode === 200) {
        toast.success("Bank invitation sent successfully!");
      } else {
        toast.error("Failed to send bank invitation. Please try again.");
      }
    },
    onError: (error) => {
      console.error("Error sending bank invitation:", error);
      toast.error("An error occurred while sending the bank invitation.");
    },
  });

  return { inviteBank };
};

export default useInviteBanks;
