import React, { useState } from "react";
import FormInput from "../FormInput";
import Modal from "../Modal";
import { colors, iconz } from "../../../utils/constants";
import "../DynamicForm/index.css";
import Button from "../Button";
const DynamicForm = ({
  index,
  firstLabelName,
  firstFormName,
  secondLabelName,
  secondFormName,
  onChangeEvent,
  firstValue,
  secondValue,
  handleRemove,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(iconz[0].Icon);
  const handleIconClick = () => {
    setOpen(!open);
  };

  const handleSelect = (value, icon) => {
    onChangeEvent(secondFormName, value);
    setSelectedIcon(icon);
    setOpen(false);
  };
  return (
    <>
      <div className="col-lg-6 col-md-4 col-sm-2">
        <div className="row px-3">
          <div className="col-md-12 d-flex flex-row mb-3">
            <div className="col-md-8">
              <FormInput
                labelName={firstLabelName}
                name={firstFormName}
                backgroundcolor={colors.grey}
                bordercolor={colors.grey}
                required={true}
                type="text"
                value={firstValue}
                placeholder="What popular structure is close to this estate?"
                onChange={(name, value) => onChangeEvent(name, value, index)}
              />
            </div>
            <div className="col-md-3 d-flex flex-column carrd_wrapper mt-3 ms-4">
              <Button
                bordercolor={colors.red}
                backgroundcolor={colors.white}
                backgroundHover={colors.dangerLight}
                colorHover={colors.danger}
                iconcolorHover={colors.danger}
                lefticonname={"delete"}
                bordersize={2}
                width={"100%"}
                btnText={"Remove Feature"}
                btntextcolor={colors.red}
                padding={"8px 16px"}
                onBtnClick={handleRemove}
                type={"button"}
                iconcolor={colors.red}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal show={open} closeModal={setOpen} title={"Select an icon"}>
        <div className="row">
          {iconz.map((icon) => (
            <div className="col-2 p-2" key={icon.id}>
              <div
                className="d-flex flex-column align-items-center icon-selector-container"
                onClick={() => handleSelect(icon.id, icon.Icon)}
              >
                <span className="icon_">{icon.Icon}</span>
                <span className="icon-name">{icon.name}</span>
              </div>
            </div>
          ))}
        </div>
      </Modal>
    </>
  );
};

export default DynamicForm;
