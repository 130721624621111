import React, { useEffect, useState } from "react";
import image from "../../assets/images/signup.jpg";
import { ImageWrapper } from "../../Components/Common/StyledComponents/style";
import { ForgotPasswordContainer } from "../ForgotPassword/style";
import FormInput from "../../Components/Common/FormInput";
import Button from "../../Components/Common/Button";
import { colors } from "../../utils/constants";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import useResetPassword from "./hooks/useResetPassword";
import useLogin from "../../hooks/useLogin";
import ResetPasswordSchema from "./model/ResetPasswordSchema";
import { useNavigate, useLocation } from "react-router";
import Spinner from "../../Components/Spinner";
import useAuth from "../../hooks/useAuth";

const ResetPassword = () => {
  const { authUser } = useAuth();
  const organizationName = authUser?.user?.organization?.name;

  const { resetPassword } = useResetPassword();
  const location = useLocation();
  const key = new URLSearchParams(location.search).get("key");
  const code = new URLSearchParams(location.search).get("code");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(ResetPasswordSchema) });

  const onSubmit = async (data) => {
    data.key = key;
    data.code = code;

    await resetPassword.mutate(data);
  };

  const [isNewPassword, setIsNewPassword] = useState(false);
  const [isConfirmPassword, setIsConfirmPassword] = useState(false);

  const toggleNewPassword = () => {
    setIsNewPassword(!isNewPassword);
  };

  const toggleConfirmPassword = () => {
    setIsConfirmPassword(!isConfirmPassword);
  };

  useEffect(() => {
    document.title = `Giddaa - ${organizationName} - Reset Password`;
  }, []);

  return (
    <ForgotPasswordContainer>
      <div className="container-fluid op">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-1 ps-0 ms-0 d-none d-md-flex img-container">
            <ImageWrapper>
              <img className="img-fluid" src={image} alt="imaget" />
            </ImageWrapper>
          </div>

          <div className="postion-relative form-container col-md-6 d-flex align-items-center h-100 p-3 p-md-4">
            <img
              src="https://s3.amazonaws.com/appforest_uf/f1675031132769x885606220423525500/GiddaaLogoGreen.svg"
              alt="logo"
              width={90}
              height={70}
              className="position-absolute"
              style={{ top: "2.5rem", left: "1rem" }}
            />

            <div className="form-wrapper w-100">
              <h1 className="pt-3 pe-5 text-md-start text-sm-center">
                Reset Password
              </h1>
              <p className="py-2">
                Enter your new password below to reset your account password.
              </p>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <FormInput
                    labelName={"New Password"}
                    lefticonname={"password"}
                    type={isNewPassword ? "text" : "password"}
                    righticonname={isNewPassword ? "viewings" : "eyeoff"}
                    register={register("newPassword")}
                    error={errors?.newPassword?.message}
                    placeholder={"Paul123#"}
                    required
                    autoComplete="off"
                    onClick={() => toggleNewPassword()}
                  />
                </div>
                <div>
                  <FormInput
                    labelName={"Confirm Password "}
                    lefticonname={"password"}
                    type={isConfirmPassword ? "text" : "password"}
                    righticonname={isConfirmPassword ? "viewings" : "eyeoff"}
                    register={register("confirmPassword")}
                    error={errors?.confirmPassword?.message}
                    placeholder={"Paul123#"}
                    required
                    autoComplete="off"
                    onClick={() => toggleConfirmPassword()}
                  />
                </div>
                <div className="text-center pt-4 pt-md-4 pt-sm-5 d-flex flex-row justify-content-between align-items-center">
                  <hr />
                  <Button
                    btnText={"Save"}
                    isLoading={resetPassword.isLoading}
                    disabled={resetPassword.isLoading}
                  />
                  <hr />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ForgotPasswordContainer>
  );
};

export default ResetPassword;
