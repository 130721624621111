import Icons from "../Icons";

const DocumentDownload = ({ name, icon, textColor, onClick }) => {
  return (
    <div className="d-flex flex-row align-items-center">
      <div>
        <Icons iconName={icon} />
      </div>
      <small className="px-3 text-center mt-2 fw-bold" style={{ fontFamily: '"Red Hat Display", sans-serif' , color: textColor}}>{name}</small>
      <div className="mt-1" role="button" onClick={onClick}>
        <Icons iconName={"documentDownload"} />
      </div>
    </div>
  );
};

export default DocumentDownload;
