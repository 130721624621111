import * as yup from "yup";

const updateModelSchema = yup
  .object({
    name: yup.string().required("Company Name is required."),
    emailAddress: yup
      .string()
      .email("Invalid email format.")
      .required("Company Email is required."),
    phoneNumber: yup
      .string()
      .required("Phone Number is required.")
      .max(11, "Phone Number cannot exceed 11 characters."),
    bankName: yup.string().required("Bank Name is required."),
    bankCode: yup.string().required("Bank Code is required."),
    accountNumber: yup
      .number()
      .max(10, "Account Number cannot exceed 10 digits.")
      .required("Account Number is required."),
    address: yup.string().required("Company Address is required."),
    cityId: yup.string().required("City is required."),
    logo: yup
      .object()
      .shape({
        base64: yup.string().required("Logo base64 data is required."),
        ownerId: yup.string(),
        optionId: yup.string().required("Logo optionId is required."),
        extension: yup.string().required("Logo extension is required."),
        description: yup.string(),
        name: yup.string().required("Logo name is required."),
        extraProperties: yup.string(),
        revisionId: yup.string(),
      })
      .required("Logo is required."),
    featureImage: yup.object().shape({
      base64: yup.string().required("Feature Image base64 data is required."),
      ownerId: yup.string(),
      optionId: yup.string().required("Feature Image optionId is required."),
      extension: yup.string().required("Feature Image extension is required."),
      description: yup.string(),
      name: yup.string().required("Feature Image name is required."),
      extraProperties: yup.string(),
      revisionId: yup.string(),
    }),
    brandColorCode: yup.string().required("Brand Color Code is required."),
    description: yup
      .string()
      .required("Company Description is required.")
      .max(600, "Company Description cannot exceed 600 characters."),
    yearsInOperation: yup.number().required("Years in Operation is required."),
  })
  .required();

export default updateModelSchema;
