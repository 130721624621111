import React from "react";
import DetailCard from "../../../Components/Common/DetailCard";
import InfoTable from "../../../Components/Common/InfoTable";
import { columns } from "../../Applications/utils/constants";

const EmpVerification = ({application}) => {

  return (
    <div className="container text-center mt-5">
      {/* <div className="row justify-content-center d-flex">
        <div className="col-9">
          <DetailCard
            title={"Employment Date"}
            subtitle={empData?.employment_date ?? "NIL"}
          />
        </div>
        <div className="col-3 justify-content-end">
          <DetailCard
            title={"Job Role"}
            subtitle={empData?.job_role ?? "NIL"}
          />
        </div>
        <div className="col-9 mt-5">
          <DetailCard
            title={"Verification Details"}
            subtitle={
              "You can contact the following people to verify Ngutor John Ikpaahindi’s current employment status"
            }
          />
        </div>
        <div className="col-3 justify-content-end mt-5">
          <DetailCard title={"Salary"} subtitle={empData?.salary ?? "NIL"} />
        </div>

        <>
          <div className="col-12 mt-3">
            <InfoTable
              columns={columns}
              dataCollection={empData?.sources?.apollo_representative}
            />
          </div>
        </>
      </div> */}

      <p style={{fontSize: "16px", fontWeight: "bold"}}> Handling Staff ({`${application?.handler?.firstName} ${application?.handler?.lastName}`}) - Recommedation</p>
      <div
      style={{fontSize: "13px", width: "50%", margin: "auto"}}
      >
       {application?.handlingStaffRecommendation}
      </div>
    </div>
  );
};

export default EmpVerification;
