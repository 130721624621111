import { toast } from "react-toastify";
// import usePermission from "../hooks/usePermissions";
import Button from "../Components/Common/Button";
import { toApplicationNumber, yearRange } from "./generalUtils";
import {
  applicationStatus,
  applicationStatusIndex,
} from "../Pages/Applications/utils/applicationStatus";
import { HiDotsVertical } from "react-icons/hi";
import Dropdown from "react-bootstrap/Dropdown";
import Badges from "../Components/Common/Badges";
import { useNavigate } from "react-router-dom";
import CellSelector from "../Components/Common/CellSelector";

export const colors = {
  defaultWhite: "#F7F7F7",
  orange: "#FFF2CB",
  darkOrange: "#9D7404",
  successGreen: "#335F32",
  lightGreen: "#DFFFDE",
  darkGreen: "#335F32",
  green: "#0A7E32",
  red: "#D10808",
  white: "#FFFFFF",
  black: "#000000",
  dark: "#585858",
  lightGrey: "#00000041",
  lighterGrey: "#0000001A",
  grey: "#F0F0F0",
  gray: "#707070",
  yellow: "#FFEB00",
  yellowLight: "#FEFFE5",
  yellowdark: "#957E00",
  yellowDark: "#C3B40A",
  darkGray: "#919191",
  transparentWhite: "#ffffff99",

  anchorInactive: "#707070",
  anchorPressed: "#A35A05",
  primary: "#335F32",
  hover: "#007F25",
  danger: "#B90505",
  dangerLight: "#FFE0E0",
  btnPressed: "#345F32",

  secBtnActive: "#325F33",
  secBtnInactive: "#325F31",
  secBtnPressed: "#345F32",
};

export const isNullOrEmpty = (value) =>
  value === null || value === undefined || "";

export const formattedPrice = (value) => {
  let newValue = value?.toString()?.replace(/,/g, "");
  return newValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const removeUnderscore = (str) => {
  return str?.replace(/_/g, "");
};

export const convertToTitleCase = (str) => {
  str = String(str);
  if (!str) {
    return "";
  }
  return str
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b\w/g, (s) => s.toUpperCase());
};

export const stripHtmlTags = (html) => {
  const div = document.createElement("div");
  div.innerHTML = html;
  return div.textContent || div.innerText || "";
};

export const formatPercentage = (value) => {
  return `${value}%`;
};

export const formatToYears = (value) => {
  if (value > 1) {
    return `${value} Years`;
  }
  return `${value} Year`;
};

export const onlyNumbers = (str) => {
  return Boolean(str.match(/^(0|[1-9][0-9,]*)$/));
};

export const getNumber = (str) => String(str).replace(/[^0-9.]+/g, '').replace(/(\..*)\./g, '$1');

export const defaultCarousel = [
  {
    name: "Cosgrove estate",
    city: null,
    address: "Gombe",
    landmark: "Zoma rock",
    longitude: 0,
    latitude: 0,
    features: [],
    images: [],
    houses: [],
    id: "00000000-0000-0000-0000-000000000000",
    createdBy:
      '{"Id":"GIDDAA_SUPER_ADMIN_USER","Name":"Meck Aaron Tinum","Action":"Admin Create Estate"}',
    dateCreated: "2023-05-22T03:35:34.6800527",
    dateLastModified: null,
    lastModifiedBy: null,
  },
  {
    name: "Cosgrove estate",
    city: null,
    address: "Gombe",
    landmark: "Zoma rock",
    longitude: 0,
    latitude: 0,
    features: [],
    images: [],
    houses: [],
    id: "ac5674bc-8c26-48f0-8fe7-7e9ad7d63f3d",
    createdBy:
      '{"Id":"GIDDAA_SUPER_ADMIN_USER","Name":"Meck Aaron Tinum","Action":"Admin Create Estate"}',
    dateCreated: "2023-05-22T03:49:23.3551348",
    dateLastModified: null,
    lastModifiedBy: null,
  },
  {
    name: "Cosgrove estate",
    city: null,
    address: "Gombe",
    landmark: "Zoma rock",
    longitude: 0,
    latitude: 0,
    features: [],
    images: null,
    houses: [],
    id: "b1e96830-d5c8-426b-a4b1-258f4b5ff063",
    createdBy:
      '{"Id":"GIDDAA_SUPER_ADMIN_USER","Name":"Meck Aaron Tinum","Action":"Admin Create Estate"}',
    dateCreated: "2023-05-22T03:51:12.8502735",
    dateLastModified: null,
    lastModifiedBy: null,
  },
  {
    name: "Cosgrove estate",
    city: null,
    address: "Gombe",
    landmark: "Zoma rock",
    longitude: 0,
    latitude: 0,
    features: [
      {
        id: "00000000-0000-0000-0000-000000000000",
        name: "4 Rooms",
        icon: "<i className='fa-solid fa-bed'></i>",
      },
    ],
    images: [
      {
        id: "169b75ef-d61f-4f00-9699-ef2a1d4e560a",
        type: null,
        base64: "",
      },
    ],
    houses: [],
    id: "251325fe-b518-4bc5-be39-9aa64fd65306",
    createdBy:
      '{"Id":"GIDDAA_SUPER_ADMIN_USER","Name":"Meck Aaron Tinum","Action":"Admin Create Estate"}',
    dateCreated: "2023-05-22T03:57:03.7976895",
    dateLastModified: null,
    lastModifiedBy: null,
  },
  {
    name: "Cosgrove estate",
    city: null,
    address: "Gombe",
    landmark: "Zoma rock",
    longitude: 0,
    latitude: 0,
    features: [
      {
        id: "404f1815-4bb5-4112-bc6d-88b6cf131d44",
        name: "4 Toilet",
        icon: "<i className='fa-solid fa-bed'></i>",
      },
      {
        id: "be1db29b-dc1c-406e-9460-831d45871e1c",
        name: "4 Rooms",
        icon: "<i className='fa-solid fa-bed'></i>",
      },
    ],
    images: null,
    houses: [],
    id: "3dc056a8-ef59-4c9f-8c8a-a61c945794f6",
    createdBy:
      '{"Id":"GIDDAA_SUPER_ADMIN_USER","Name":"Meck Aaron Tinum","Action":"Admin Create Estate"}',
    dateCreated: "2023-05-22T03:58:14.7556338",
    dateLastModified: null,
    lastModifiedBy: null,
  },
  {
    name: "Cosgrove estate",
    city: null,
    address: "Gombe",
    landmark: "Zoma rock",
    longitude: 0,
    latitude: 0,
    features: [
      {
        id: "cd3ed00f-bced-4f6d-b38c-8d77cbff22b6",
        name: "4 Rooms",
        icon: "<i className='fa-solid fa-bed'></i>",
      },
      {
        id: "fef71fcd-eda9-4d6a-9441-fb982f151142",
        name: "4 Toilet",
        icon: "<i className='fa-solid fa-bed'></i>",
      },
    ],
    images: null,
    houses: [],
    id: "d6a30c9d-55d6-4398-838a-6514cca0830d",
    createdBy:
      '{"Id":"GIDDAA_SUPER_ADMIN_USER","Name":"Meck Aaron Tinum","Action":"Admin Create Estate"}',
    dateCreated: "2023-05-24T14:30:09.5177537",
    dateLastModified: null,
    lastModifiedBy: null,
  },
  {
    name: "Sanila",
    city: null,
    address: "Some fancy address",
    landmark: "A man holding a dog",
    longitude: 0,
    latitude: 0,
    features: [
      {
        id: "42a34cf9-3fdf-4adc-89a4-3f5a95c6220c",
        name: "5 rooms",
        icon: "apartments_and_flats",
      },
      {
        id: "838bd93a-332d-4a3a-88f5-630d0711d226",
        name: "2 bungalows",
        icon: "bungalow",
      },
    ],
    images: null,
    houses: [],
    id: "f2ef04e7-74b5-490c-8a2f-c837942365a8",
    createdBy:
      '{"Id":"GIDDAA_SUPER_ADMIN_USER","Name":"Meck Aaron Tinum","Action":"Admin Create Estate"}',
    dateCreated: "2023-05-24T15:33:40.2047818",
    dateLastModified: null,
    lastModifiedBy: null,
  },
  {
    name: "Sanila",
    city: null,
    address: "Some fancy address",
    landmark: "A man holding a dog",
    longitude: 0,
    latitude: 0,
    features: [
      {
        id: "90b2572b-7d56-41aa-8188-d25c5035f541",
        name: "2 bungalows",
        icon: "bungalow",
      },
      {
        id: "fc77a93e-7d39-44ca-bb7a-6b8af9a51c87",
        name: "5 rooms",
        icon: "apartments_and_flats",
      },
    ],
    images: null,
    houses: [],
    id: "75b71882-8ce2-4d83-8cdf-5f889d59f827",
    createdBy:
      '{"Id":"GIDDAA_SUPER_ADMIN_USER","Name":"Meck Aaron Tinum","Action":"Admin Create Estate"}',
    dateCreated: "2023-05-24T15:33:51.0243537",
    dateLastModified: null,
    lastModifiedBy: null,
  },
  {
    name: "Sanila",
    city: null,
    address: "Fancy address",
    landmark: "Statue",
    longitude: 0,
    latitude: 0,
    features: [
      {
        id: "9aa3dff5-ae74-466f-a544-1cbacbe548e4",
        name: "2 bungalows",
        icon: "bungalow",
      },
      {
        id: "e253e518-9bc9-4e10-ad00-4b428a7be415",
        name: "5 bedrooms",
        icon: "bedrooms",
      },
      {
        id: "fc0f0c1a-fa76-46b7-b5bd-38ac1572fa00",
        name: "3 bathrooms",
        icon: "bathrooms",
      },
    ],
    images: null,
    houses: [],
    id: "5bc81b8f-88ad-4019-82df-66d57125afbf",
    createdBy:
      '{"Id":"GIDDAA_SUPER_ADMIN_USER","Name":"Meck Aaron Tinum","Action":"Admin Create Estate"}',
    dateCreated: "2023-05-24T23:25:14.2175945",
    dateLastModified: null,
    lastModifiedBy: null,
  },
  {
    name: "Sanila",
    city: null,
    address: "Fancy address",
    landmark: "Statue",
    longitude: 0,
    latitude: 0,
    features: [
      {
        id: "0b9dae48-1932-455d-a5fe-209c7da75fec",
        name: "5 bedrooms",
        icon: "bedrooms",
      },
      {
        id: "95333f0c-c680-4d1b-b873-710c9ee7b791",
        name: "2 bungalows",
        icon: "bungalow",
      },
      {
        id: "f88e8275-a2ab-4cf1-8bcd-f285a410bfdc",
        name: "3 bathrooms",
        icon: "bathrooms",
      },
    ],
    images: null,
    houses: [],
    id: "97c3523d-b486-4f63-9adc-3ec713860d40",
    createdBy:
      '{"Id":"GIDDAA_SUPER_ADMIN_USER","Name":"Meck Aaron Tinum","Action":"Admin Create Estate"}',
    dateCreated: "2023-05-29T05:23:36.5735747",
    dateLastModified: null,
    lastModifiedBy: null,
  },
];

const defaultImage =
  "https://images.unsplash.com/photo-1600585154340-be6161a56a0c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8aG91c2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60";

export const columns = (loading) => [
  {
    name: "",
    grow: 0,
    selector: (row) => (
      <CellSelector loading={loading}>
        <img
          data-tag="allowRowEvents"
          height="60px"
          width="70px"
          alt={row?.images?.length ? row?.images[0]?.name : "alt image"}
          src={
            row?.images?.length > 0 ? row?.images[0]?.document : defaultImage
          }
          style={{ borderRadius: "18px", padding: "8px" }}
        />
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PROPERTIES
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row.name ?? "---"}</CellSelector>
    ),
    wrap: true,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PRICE
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        <>₦ {row.price?.toLocaleString()}</>
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        LOCATON
      </p>
    ),
    sortable: true,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row.address}</CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        UNITS
      </p>
    ),
    sortable: true,
    grow: 0.3,
    // center: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row.units}</CellSelector>
    ),
  },
];

export const houseColumns = [
  {
    name: "",
    grow: 0,
    cell: (row) => (
      <img
        data-tag="allowRowEvents"
        height="60px"
        width="70px"
        alt={row?.images?.length ? row?.images[0]?.name : "alt image"}
        src={row?.images?.length > 0 ? row?.images[0]?.document : defaultImage}
        style={{ borderRadius: "18px", padding: "8px" }}
      />
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        ADDRESS
      </p>
    ),
    sortable: true,
    selector: (row) => row?.address,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PRICE
      </p>
    ),
    sortable: true,
    selector: (row) => row.price?.toLocaleString(),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        ESTATE
      </p>
    ),
    sortable: true,
    selector: (row) => row.estate?.name ?? "None Selected",
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        LOCATION
      </p>
    ),
    sortable: true,
    selector: (row) => row.landmark,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        HOUSE TYPE
      </p>
    ),
    sortable: true,
    selector: (row) => row.type,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        UNITS
      </p>
    ),
    sortable: true,
    grow: 0.6,
    center: true,
    selector: (row) => row.units,
  },
];

export const data = [
  {
    id: 1,
    image:
      "https://images.unsplash.com/photo-1600585154526-990dced4db0d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fEhvdXNlc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    address: "No 23 James Adon street, Mabushi, Abuja",
    price: "N25,000,000",
    estate: "Paradise",
    location: "Abuja, Nigeria",
    houseType: "Apartment/Flat",
    unitsLeft: 12,
  },
  {
    id: 2,
    image:
      "https://images.unsplash.com/photo-1600585154526-990dced4db0d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fEhvdXNlc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    address: "No 23 Christy Way, Asokor, Abuja.",
    price: "N55,000,000",
    estate: "",
    location: "Abuja, Nigeria",
    houseType: "Bungalow",
    unitsLeft: 13,
  },

  {
    id: 3,
    image:
      "https://images.unsplash.com/photo-1600585154526-990dced4db0d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fEhvdXNlc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    address: "No 23 James Adon street, Mabushi, Abuja",
    price: "N250,000,000",
    estate: "",
    location: "Cross River, Nigeria",
    houseType: "Duplex",
    unitsLeft: 1,
  },
  {
    id: 4,
    image:
      "https://images.unsplash.com/photo-1600585154526-990dced4db0d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fEhvdXNlc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    address: "No 23 James Adon street, Mabushi, Abuja",
    price: "N15,000,000",
    estate: "New Majestic Luxury",
    location: "Abuja, Nigeria",
    houseType: "Terrace",
    unitsLeft: 3,
  },
  {
    id: 5,
    image:
      "https://images.unsplash.com/photo-1600585154526-990dced4db0d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fEhvdXNlc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    address: "No 23 James Adon street, Mabushi, Abuja",
    price: "N25,000,000",
    estate: "Paradise Gardens",
    location: "Lagos, Nigeria",
    houseType: "Semi-Detached Duplex",
    unitsLeft: 45,
  },
  {
    id: 6,
    image:
      "https://images.unsplash.com/photo-1600585154526-990dced4db0d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fEhvdXNlc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    address: "No 23 James Adon street, Mabushi, Abuja",
    price: "N10, 000, 000",
    estate: "Paradise Ensuites",
    location: "Abuja, Nigeria",
    houseType: "Apartment/Flat",
    unitsLeft: 23,
  },
  {
    id: 7,
    image:
      "https://images.unsplash.com/photo-1600585154526-990dced4db0d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fEhvdXNlc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    address: "No 23 James Adon street, Mabushi, Abuja",
    price: "N25,000,000",
    estate: "Oceanic Homes",
    location: "Abuja, Nigeria",
    houseType: "Bungalow",
    unitsLeft: 25,
  },
  {
    id: 8,
    image:
      "https://images.unsplash.com/photo-1600585154526-990dced4db0d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fEhvdXNlc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    address: "No 23 James Adon street, Mabushi, Abuja",
    price: "N55,000,000",
    estate: "Sunnyville",
    location: "Abuja, Nigeria",
    houseType: "Bungalow",
    unitsLeft: 15,
  },
  {
    id: 9,
    image:
      "https://images.unsplash.com/photo-1600585154526-990dced4db0d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fEhvdXNlc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    address: "No 23 James Adon street, Mabushi, Abuja",
    price: "N25,000,000",
    estate: "Sunrise",
    location: "Abuja, Nigeria",
    houseType: "Terrace",
    unitsLeft: 22,
  },
  {
    id: 10,
    image:
      "https://images.unsplash.com/photo-1600585154526-990dced4db0d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTd8fEhvdXNlc3xlbnwwfHwwfHw%3D&auto=format&fit=crop&w=500&q=60",
    address: "No 23 James Adon street, Mabushi, Abuja",
    price: "N55,000,000",
    estate: "Hill Top",
    location: "Abuja, Nigeria",
    houseType: "Terrace",
    unitsLeft: 120,
  },
];

export const mtAppcolumns = (
  handleOmit,
  handleButtonClick,
  handleViewDetails,
  loading
) => [
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        CUSTOMER
      </p>
    ),
    sortable: true,
    grow: 0.7,
    wrap: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        <>
          {(row.applicationStatus === "PENDING" ||
            row.applicationStatus === "REVIEW_APPROVED") && (
            <div className="">
              <span
                className="badge btn-sm py-1 mb-1"
                style={{
                  fontSize: "0.5rem",
                  backgroundColor: colors.dangerLight,
                  color: colors.danger,
                  borderRadius: "20px",
                  display: "inline-block",
                  padding: "3px 5px",
                  display: "inline-block",
                }}
              >
                ACTION REQUIRED
              </span>
            </div>
          )}
          <>
            {row.customer?.firstName} {row.customer?.lastName}
          </>
        </>
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PROPERTY
      </p>
    ),
    sortable: true,
    grow: 1.5,
    selector: (row) => (
      <CellSelector loading={loading}>{row.house?.name ?? "---"}</CellSelector>
    ),
    wrap: true,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        HANDLING STAFF
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {row?.handler?.handlerId
          ? `${row?.handler?.firstName} ${row?.handler?.lastName}`
          : "No Handler Assigned"}
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        APPLICATION DATE
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {new Date(row.dateCreated).toDateString()}
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        STATUS
      </p>
    ),
    grow: 1,
    sortable: true,
    // selector: (row) => row.applicationStatus,
    cell: (row) => (
      <CellSelector loading={loading}>
        {(() => {
          let displayText, tooltipText;
          let borderColor, bgColor, textColor;
          switch (row.applicationStatus) {
            case "IN_COMPLETE":
              displayText = "Application Created";
              tooltipText = "Your application has been successfully created";
              borderColor = colors.darkGray;
              bgColor = colors.grey;
              textColor = colors.darkGray;
              break;
            case "VIEWING_BOOKED":
              displayText = "Viewing Booked";
              tooltipText = `You have successfully booked a viewing for ${convertToTitleCase(
                row.house?.type
              )}. ${
                row.house?.address
              }. However, you have yet to acknowledge viewing the property. Once you view the property, you’ll need to acknowledge having viewed it then you can proceed with your application. Only proceed once you have viewed the property. Click the acknowledge viewing button to proceed.`;
              borderColor = colors.darkGray;
              bgColor = colors.grey;
              textColor = colors.darkGray;
              break;
            case "BOOKING_VIEWED":
              displayText = "Property Viewed";
              tooltipText = `You have successfully acknowledged having viewed this property. Now submit the required documents to ${row.organization?.name} to process. If there are no required documents, simply submit the application by clicking the submit button of the document submission page of the application process`;
              borderColor = colors.darkGray;
              bgColor = colors.grey;
              textColor = colors.darkGray;
              break;
            case "LOAN_TERM_OFFER":
              displayText = "Terms Sent";
              tooltipText = `You have sent the high-level terms of purchase. If the terms are accepted, you are to upload their offer letter. If they’re rejected, this will mark the end of your interaction with the customer.`;
              borderColor = colors.darkGray;
              bgColor = colors.grey;
              textColor = colors.darkGray;
              break;
            case "LOAN_TERM_ACCEPTED":
              displayText = "Terms Sent";
              tooltipText = `[Customer Name] accepted the terms you sent to them. Kindly upload their offer letter for them to view and acknowledge. Ensure all terms are spelled out in the offer letter.`;
              borderColor = colors.darkGray;
              bgColor = colors.grey;
              textColor = colors.darkGray;
              break;
            case "OFFER_LETTER_UPLOADED":
              displayText = "Offer Letter Uploaded";
              tooltipText = `The offer letter for [property address] on [plan name] has been submitted to [customer name]. You’ll be notified if they accept or want some revisions to the terms you submitted to them. You can preview the submitted terms by clicking the Upload Terms Status and downloading the document you submitted.`;
              borderColor = colors.darkGray;
              bgColor = colors.grey;
              textColor = colors.darkGray;
              break;
            case "PENDING":
              displayText = "Document Validation";
              tooltipText =
                "Validate each of the customer’s documents. You can ask the customer to revise a document if there is an error with it. After this, you can send the application for review by clicking the “Send for Review” button above. ";
              borderColor = colors.darkOrange;
              bgColor = colors.yellowLight;
              textColor = colors.darkOrange;
              break;
            case "PENDING_MANDATE":
              displayText = "Mandate Creation";
              tooltipText = "";
              borderColor = colors.darkOrange;
              bgColor = colors.yellowLight;
              textColor = colors.darkOrange;
              break;
            case "UNDER_REVIEW":
              displayText = "Under Review";
              tooltipText =
                "The application has been sent for review but hasn’t been accepted or rejected yet. Reach out to someone with the ability to review applications to review this application.";
              borderColor = colors.darkGray;
              bgColor = colors.grey;
              textColor = colors.darkGray;
              break;
            case "REVIEW_APPROVED":
              displayText = "Review Approved";
              tooltipText = "The customer’s application has been approved";
              borderColor = colors.darkGreen;
              textColor = colors.darkGreen;
              break;
            case "REVIEW_REJECTED":
              displayText = "Review Rejected";
              tooltipText = "The customer’s application was rejected";
              borderColor = colors.danger;
              bgColor = colors.dangerLight;
              textColor = colors.danger;
              break;
            case "LOAN_TERM_REJECTED":
              displayText = "Terms Rejected";
              tooltipText = ` [Customer Name] rejected the terms you sent to them. This marks the end of your interaction with them for the purchase of [House Type - House Address] on [Plan Name].`;
              borderColor = colors.danger;
              bgColor = colors.dangerLight;
              textColor = colors.danger;
              break;
            case "MORTGAGE_TERMS_UPLOADED":
              displayText = "Offer Letter Uploaded";
              tooltipText =
                "Terms for the payment plan have been submitted to the customer. You’ll be notified if they accept the terms you submitted to them. You can preview the submitted terms by clicking the Upload Terms Status and downloading the document you submitted.";
              borderColor = colors.darkGray;
              bgColor = colors.grey;
              textColor = colors.darkGray;
              break;
            case "PENDING_FEE_PAYMENT":
              displayText = "Pending Fee Payment";
              tooltipText = "The customer is yet to pay the required fees";
              borderColor = colors.darkOrange;
              bgColor = colors.yellowLight;
              textColor = colors.darkOrange;
              break;
            case "PENDING_EQUITY_PAYMENT":
              displayText = "Pending Equity Payment";
              tooltipText =
                "The customer is yet to pay their equity/downpayment";
              borderColor = colors.darkOrange;
              bgColor = colors.yellowLight;
              textColor = colors.darkOrange;
              break;
            case "MANDATE_CREATION":
              displayText = "Mandate Creation";
              tooltipText = "...";
              break;
            case "COMPLETED":
              displayText = "Completed";
              tooltipText =
                "All necessary steps have been met, marking the completion of the transaction.";
              break;
            default:
              displayText = convertToTitleCase(row.applicationStatus); // Fallback for any other applicationStatus
              tooltipText = convertToTitleCase(row.applicationStatus);
              break;
          }

          return (
            <Badges
              text={displayText}
              title={tooltipText}
              backgroundcolor={bgColor}
              bordercolor={borderColor}
              color={textColor}
            />
          );
        })()}
      </CellSelector>
    ),
    wrap: true,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        ACTION
      </p>
    ),
    sortable: false,
    grow: 0.5,
    center: true,
    cell: (row) => (
      <Dropdown>
        <Dropdown.Toggle id={`dropdown-${row.id}`}>
          <HiDotsVertical className="fw-bold text-dark" />
        </Dropdown.Toggle>
        {!loading && (
          <Dropdown.Menu>
            {row.handler?.handlerId ? (
              <Dropdown.Item
                onClick={() => handleViewDetails(row.applicationId)}
              >
                View Application
              </Dropdown.Item>
            ) : (
              <Dropdown.Item
                onClick={() => handleButtonClick(row.applicationId)}
              >
                Handle Application
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        )}
      </Dropdown>
    ),
  },
];

export const bankAppcolumns = (loading) => [
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        CUSTOMER
      </p>
    ),
    sortable: true,
    grow: 0.7,
    selector: (row) => (
      <CellSelector loading={loading}>
        <>
          {row.applicationStatus === "OFFER_LETTER_REQUEST" && (
            <div className="">
              <span
                className="badge btn-sm py-1 mb-1"
                style={{
                  fontSize: "0.5rem",
                  backgroundColor: colors.dangerLight,
                  color: colors.danger,
                  borderRadius: "20px",
                  display: "inline-block",
                  padding: "3px 5px",
                  display: "inline-block",
                }}
              >
                ACTION REQUIRED
              </span>
            </div>
          )}
          <>
            {row.customer?.firstName} {row.customer?.lastName}
          </>
        </>
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        PROPERTIES
      </p>
    ),
    sortable: true,
    grow: 1.7,
    selector: (row) => (
      <CellSelector loading={loading}>{row.house?.name ?? "---"}</CellSelector>
    ),
    wrap: true,
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        BANK
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>{row.house?.name ?? "---"}</CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        APPLICATION DATE
      </p>
    ),
    sortable: true,
    selector: (row) => (
      <CellSelector loading={loading}>
        {new Date(row.dateCreated).toDateString()}
      </CellSelector>
    ),
  },
  {
    name: (
      <p className="fw-bold p-0 m-0" style={{ fontSize: "12px" }}>
        STATUS
      </p>
    ),
    grow: 1,
    sortable: true,
    cell: (row) => (
      <CellSelector loading={loading}>
        {(() => {
          let displayText, tooltipText;
          let borderColor, bgColor, textColor;
          switch (row.applicationStatus) {
            case "IN_COMPLETE":
              displayText = "Application Created";
              tooltipText = "Your application has been successfully created";
              borderColor = colors.darkGray;
              bgColor = colors.grey;
              textColor = colors.darkGray;
              break;
            case "VIEWING_BOOKED":
              displayText = "Viewing Booked";
              tooltipText = `You have successfully booked a viewing for ${convertToTitleCase(
                row.house?.type
              )}. ${
                row.house?.address
              }. However, you have yet to acknowledge viewing the property. Once you view the property, you’ll need to acknowledge having viewed it then you can proceed with your application. Only proceed once you have viewed the property. Click the acknowledge viewing button to proceed.`;
              borderColor = colors.darkGray;
              bgColor = colors.grey;
              textColor = colors.darkGray;
              break;
            case "BOOKING_VIEWED":
              displayText = "Property Viewed";
              tooltipText = `You have successfully acknowledged having viewed this property. Now submit the required documents to ${row.organization?.name} to process. If there are no required documents, simply submit the application by clicking the submit button of the document submission page of the application process`;
              borderColor = colors.darkGray;
              bgColor = colors.grey;
              textColor = colors.darkGray;
              break;
            case "LOAN_TERM_OFFER":
              displayText = "Terms Sent";
              tooltipText = `You have sent the high-level terms of purchase. If the terms are accepted, you are to upload their offer letter. If they’re rejected, this will mark the end of your interaction with the customer.`;
              borderColor = colors.darkGray;
              bgColor = colors.grey;
              textColor = colors.darkGray;
              break;
            case "IDENTITY_VERIFICATION":
              displayText = "Identity Verification";
              tooltipText = "";
              borderColor = colors.darkGray;
              bgColor = colors.grey;
              textColor = colors.darkGray;
              break;
            case "DOCUMENT_SUBMISSION":
              displayText = "Document Submission";
              tooltipText = "";
              borderColor = colors.darkGray;
              bgColor = colors.grey;
              textColor = colors.darkGray;
              break;
            case "LEGAL_SEARCH":
              displayText = "Legal Search";
              tooltipText = "";
              borderColor = colors.darkGray;
              bgColor = colors.grey;
              textColor = colors.darkGray;
              break;
            case "OFFER_LETTER_REQUEST":
              displayText = "Offer Letter Request";
              tooltipText = "";
              borderColor = colors.darkGray;
              bgColor = colors.grey;
              textColor = colors.darkGray;
              break;
            case "LOAN_TERM_ACCEPTED":
              displayText = "Terms Sent";
              tooltipText = `[Customer Name] accepted the terms you sent to them. Kindly upload their offer letter for them to view and acknowledge. Ensure all terms are spelled out in the offer letter.`;
              borderColor = colors.darkGray;
              bgColor = colors.grey;
              textColor = colors.darkGray;
              break;
            case "OFFER_LETTER_UPLOADED":
              displayText = "Offer Letter Uploaded";
              tooltipText = `The offer letter for [property address] on [plan name] has been submitted to [customer name]. You’ll be notified if they accept or want some revisions to the terms you submitted to them. You can preview the submitted terms by clicking the Upload Terms Status and downloading the document you submitted.`;
              borderColor = colors.darkGray;
              bgColor = colors.grey;
              textColor = colors.darkGray;
              break;
            case "PENDING":
              displayText = "Document Validation";
              tooltipText =
                "Validate each of the customer’s documents. You can ask the customer to revise a document if there is an error with it. After this, you can send the application for review by clicking the “Send for Review” button above. ";
              borderColor = colors.darkOrange;
              bgColor = colors.yellowLight;
              textColor = colors.darkOrange;
              break;
            case "PENDING_MANDATE":
              displayText = "Mandate Creation";
              tooltipText = "";
              borderColor = colors.darkOrange;
              bgColor = colors.yellowLight;
              textColor = colors.darkOrange;
              break;
            case "UNDER_REVIEW":
              displayText = "Under Review";
              tooltipText =
                "The application has been sent for review but hasn’t been accepted or rejected yet. Reach out to someone with the ability to review applications to review this application.";
              borderColor = colors.darkGray;
              bgColor = colors.grey;
              textColor = colors.darkGray;
              break;
            case "REVIEW_APPROVED":
              displayText = "Review Approved";
              tooltipText = "The customer’s application has been approved";
              borderColor = colors.darkGreen;
              textColor = colors.darkGreen;
              break;
            case "REVIEW_REJECTED":
              displayText = "Review Rejected";
              tooltipText = "The customer’s application was rejected";
              borderColor = colors.danger;
              bgColor = colors.dangerLight;
              textColor = colors.danger;
              break;
            case "LOAN_TERM_REJECTED":
              displayText = "Terms Rejected";
              tooltipText = ` [Customer Name] rejected the terms you sent to them. This marks the end of your interaction with them for the purchase of [House Type - House Address] on [Plan Name].`;
              borderColor = colors.danger;
              bgColor = colors.dangerLight;
              textColor = colors.danger;
              break;
            case "MORTGAGE_TERMS_UPLOADED":
              displayText = "Offer Letter Uploaded";
              tooltipText =
                "Terms for the payment plan have been submitted to the customer. You’ll be notified if they accept the terms you submitted to them. You can preview the submitted terms by clicking the Upload Terms Status and downloading the document you submitted.";
              borderColor = colors.darkGray;
              bgColor = colors.grey;
              textColor = colors.darkGray;
              break;
            case "PENDING_FEE_PAYMENT":
              displayText = "Pending Fee Payment";
              tooltipText = "The customer is yet to pay the required fees";
              borderColor = colors.darkOrange;
              bgColor = colors.yellowLight;
              textColor = colors.darkOrange;
              break;
            case "PENDING_EQUITY_PAYMENT":
              displayText = "Pending Equity Payment";
              tooltipText =
                "The customer is yet to pay their equity/downpayment";
              borderColor = colors.darkOrange;
              bgColor = colors.yellowLight;
              textColor = colors.darkOrange;
              break;
            case "MANDATE_CREATION":
              displayText = "Mandate Creation";
              tooltipText = "...";
              break;
            case "COMPLETED":
              displayText = "Completed";
              tooltipText =
                "All necessary steps have been met, marking the completion of the transaction.";
              break;
            default:
              displayText = row.applicationStatus; // Fallback for any other applicationStatus
              tooltipText = row.applicationStatus;
              break;
          }

          return (
            <Badges
              text={displayText}
              title={tooltipText}
              backgroundcolor={bgColor}
              bordercolor={borderColor}
              color={textColor}
            />
          );
        })()}
      </CellSelector>
    ),
    wrap: true,
  },
];

export const mtAppdata = [
  {
    nhfNumber: "12345678909",
    mortgagePlanId: "84b236cb-d598-4e2b-a814-ec702dbcd410",
    mortgageBanks: "Premium Mortgage Bank",
    mortgagePlan: {
      name: "NHF",
      description: "An awesome plan",
      type: "NHF",
      mortgagePeriod: 12,
      access: "Anybody",
      maxLoanAmount: 50000.0,
      interestRate: 12.0,
      loanTenor: 12,
      equityPercentage: 0.0,
      organizationId: "c7ecc866-ca52-42a6-b84e-9281877a789c",
      organization: null,
      processingPeriod: 30,
      amortization: 0.0,
      applicableFees: [],
      requiredDocuments: [],
      repaymentMethods: [],
      id: "84b236cb-d598-4e2b-a814-ec702dbcd410",
      createdBy:
        '{"Id":"8750c76e-36ce-44ff-bb0c-13a10c2354ee","Name":" ","Action":"Admin Create Mortgage Plan"}',
      dateCreated: "2023-08-02T23:55:22.922434+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    contactNumber: null,
    houseId: "ad563919-e42e-45c3-b7ef-21e9c4e492f2",
    house: {
      city: null,
      address: "St Marvin Street",
      landmark: "The popes statue",
      units: 5,
      estate: null,
      price: 35000000.0,
      longitude: 0.0,
      latitude: 0.0,
      description: "An awesome house",
      features: [],
      images: null,
      organizationId: "c7ecc866-ca52-42a6-b84e-9281877a789c",
      organization: null,
      houseMortgagePlans: [],
      id: "ad563919-e42e-45c3-b7ef-21e9c4e492f2",
      createdBy:
        '{"Id":"8750c76e-36ce-44ff-bb0c-13a10c2354ee","Name":" ","Action":"Admin Create House"}',
      dateCreated: "2023-08-02T23:49:45.312126+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    customerId: "ceb75dff-94db-4459-b4d7-a010597c6258",
    customer: {
      name: "Lionel Messi",
      firstName: "Lionel",
      lastName: "Messi",
      email: "test2@gmail.com",
      phoneNumber: "08106884376",
      address: null,
      organizationId: "giddaa_organization",
      organization: null,
      verificationCode: "isdpPUh6OIvYosF",
      roleId: "CUSTOMER_ROLE",
      applicationRole: null,
      profilePicture:
        "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80",
      withInNigeria: true,
      age: null,
      income: null,
      cityId: null,
      city: null,
      affordability: null,
      lastLoginDate: "2023-07-23T15:09:16.433835+00:00",
      isActive: true,
      isDeleted: false,
      id: "ceb75dff-94db-4459-b4d7-a010597c6258",
      createdBy:
        '{"Id":"system","Name":"SYSTEM","Action":"Customer registration"}',
      dateCreated: "2023-07-23T15:09:16.433807+00:00",
      dateLastModified: "0001-01-01T00:00:00",
      lastModifiedBy: null,
    },
    houseReview: {
      applicationUserId: "ceb75dff-94db-4459-b4d7-a010597c6258",
      applicationUser: null,
      applicationId: "aa0ab1da-f5f3-47ee-b7e4-55a8d694ceb2",
      houseId: "ad563919-e42e-45c3-b7ef-21e9c4e492f2",
      house: null,
      star: 5,
      comment: "I loved the whole house.",
      id: "97d9c304-6828-4333-87dd-155d43319c36",
      createdBy:
        '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Creates Review"}',
      dateCreated: "2023-08-03T02:12:41.956345+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    viewingComplete: false,
    proofOfPaymentReferenceNumber: null,
    downPayment: null,
    loanAmount: null,
    withInNigeria: true,
    countryId: "NIGERIA",
    houseViewing: {
      applicationId: "aa0ab1da-f5f3-47ee-b7e4-55a8d694ceb2",
      contactNumber: "090230219321",
      agentId: "489c6da4-1c7c-432d-ab0a-212ce007d9b3",
      agent: {
        name: "Kayne West",
        firstName: "Kayne",
        lastName: "West",
        email: "kayne.west@gmail.com",
        phoneNumber: "+234090129012",
        address: null,
        organizationId: "giddaa_organization",
        organization: null,
        verificationCode: "WoDzcKX4eM7XqSB",
        roleId: "VIEWING_AGENT_ROLE",
        applicationRole: null,
        profilePicture: defaultImage,
        withInNigeria: true,
        age: null,
        income: null,
        cityId: null,
        city: null,
        affordability: null,
        lastLoginDate: "2023-08-03T00:49:47.530228+00:00",
        isActive: true,
        isDeleted: false,
        id: "489c6da4-1c7c-432d-ab0a-212ce007d9b3",
        createdBy:
          '{"Id":"8750c76e-36ce-44ff-bb0c-13a10c2354ee","Name":"James Bond","Action":"Create Admin User"}',
        dateCreated: "2023-08-03T00:49:47.526006+00:00",
        dateLastModified: "0001-01-01T00:00:00",
        lastModifiedBy: null,
      },
      viewingDate: "2023-07-27T11:06:27.556377+00:00",
      customerId: "ceb75dff-94db-4459-b4d7-a010597c6258",
      customer: null,
      houseId: "ad563919-e42e-45c3-b7ef-21e9c4e492f2",
      house: null,
      status: 0,
      id: "ece7eeb2-9bf8-47ab-92b1-35bfd4264f2f",
      createdBy:
        '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Creates House Viewing"}',
      dateCreated: "2023-08-03T01:50:45.669459+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    interestPercentage: 0,
    documents: [],
    readyToPay: null,
    status: "InComplete",
    stage: 1,
    interestDate: null,
    dateViewed: "0001-01-01T00:00:00",
    alternateViewerName: "Meck Aaron",
    alternateViewerEmail: "meck@gmail.com",
    alternateViewerPhoneNumber: "0329023",
    id: "aa0ab1da-f5f3-47ee-b7e4-55a8d694ceb2",
    createdBy:
      '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Creates An Application"}',
    dateCreated: "2023-08-03T02:44:01.684133+00:00",
    dateLastModified: "2023-08-03T01:46:47.268438+00:00",
    lastModifiedBy:
      '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Updates Application"}',
  },
  {
    nhfNumber: "123456789092",
    mortgagePlanId: "84b236bb-d598-4e2b-a814-ec702dbcd410",
    mortgageBanks: "Premium Mortgage Bank",
    mortgagePlan: {
      name: "Plan A",
      description: "An awesome plan",
      type: "DEVELOPER_PLAN",
      mortgagePeriod: 12,
      access: "Anybody",
      maxLoanAmount: 50000.0,
      interestRate: 12.0,
      loanTenor: 12,
      equityPercentage: 0.0,
      organizationId: "c7ecc866-ca52-42a6-b84e-9281877a789c",
      organization: null,
      processingPeriod: 30,
      amortization: 0.0,
      applicableFees: [],
      requiredDocuments: [],
      repaymentMethods: [],
      id: "84b236cb-d598-4e2b-a814-ec702dbcd410",
      createdBy:
        '{"Id":"8750c76e-36ce-44ff-bb0c-13a10c2354ee","Name":" ","Action":"Admin Create Mortgage Plan"}',
      dateCreated: "2023-08-02T23:55:22.922434+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    contactNumber: null,
    houseId: "ad563919-f42e-45c3-b7ef-21e9c4e492f2",
    house: {
      city: null,
      address: "St Marvin Street",
      landmark: "The popes statue",
      units: 5,
      estate: null,
      price: 35000000.0,
      longitude: 0.0,
      latitude: 0.0,
      description: "An awesome house",
      features: [],
      images: null,
      organizationId: "c7ecc866-ca52-42a6-b84e-9281877a789c",
      organization: null,
      houseMortgagePlans: [],
      id: "ad563919-e42e-45c3-b7ef-21e9c4e492f2",
      createdBy:
        '{"Id":"8750c76e-36ce-44ff-bb0c-13a10c2354ee","Name":" ","Action":"Admin Create House"}',
      dateCreated: "2023-08-02T23:49:45.312126+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    customerId: "ceb75dff-94db-4459-b4d7-a010597c6258",
    customer: {
      name: "Sam Curr",
      firstName: "Lionel",
      lastName: "Messi",
      email: "test2@gmail.com",
      phoneNumber: "08106884376",
      address: null,
      organizationId: "giddaa_organization",
      organization: null,
      verificationCode: "isdpPUh6OIvYosF",
      roleId: "CUSTOMER_ROLE",
      applicationRole: null,
      profilePicture:
        "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80",
      withInNigeria: true,
      age: null,
      income: null,
      cityId: null,
      city: null,
      affordability: null,
      lastLoginDate: "2023-07-23T15:09:16.433835+00:00",
      isActive: true,
      isDeleted: false,
      id: "ceb75dff-94db-4459-b4d7-a010597c6258",
      createdBy:
        '{"Id":"system","Name":"SYSTEM","Action":"Customer registration"}',
      dateCreated: "2023-07-23T15:09:16.433807+00:00",
      dateLastModified: "0001-01-01T00:00:00",
      lastModifiedBy: null,
    },
    houseReview: {
      applicationUserId: "ceb75dff-94db-4459-b4d7-a010597c6258",
      applicationUser: null,
      applicationId: "aa0ab1da-f5f3-47ee-b7e4-55a8d694ceb2",
      houseId: "ad563919-e42e-45c3-b7ef-21e9c4e492f2",
      house: null,
      star: 5,
      comment: "I loved the whole house.",
      id: "97d9c304-6828-4333-87dd-155d43319c36",
      createdBy:
        '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Creates Review"}',
      dateCreated: "2023-08-03T02:12:41.956345+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    viewingComplete: false,
    proofOfPaymentReferenceNumber: null,
    downPayment: null,
    loanAmount: null,
    withInNigeria: true,
    countryId: "NIGERIA",
    houseViewing: {
      applicationId: "ab1ab1da-f5f3-47ee-b7e4-55a8d694ceb2",
      contactNumber: "090230219321",
      agentId: "489c6da4-1c7c-432d-ab0a-212ce007d9b3",
      agent: {
        name: "Kayne West",
        firstName: "Kayne",
        lastName: "West",
        email: "kayne.west@gmail.com",
        phoneNumber: "+234090129012",
        address: null,
        organizationId: "giddaa_organization",
        organization: null,
        verificationCode: "WoDzcKX4eM7XqSB",
        roleId: "VIEWING_AGENT_ROLE",
        applicationRole: null,
        profilePicture: defaultImage,
        withInNigeria: true,
        age: null,
        income: null,
        cityId: null,
        city: null,
        affordability: null,
        lastLoginDate: "2023-08-03T00:49:47.530228+00:00",
        isActive: true,
        isDeleted: false,
        id: "489c6da4-1c7c-432d-ab0a-212ce007d9b3",
        createdBy:
          '{"Id":"8750c76e-36ce-44ff-bb0c-13a10c2354ee","Name":"James Bond","Action":"Create Admin User"}',
        dateCreated: "2023-08-03T00:49:47.526006+00:00",
        dateLastModified: "0001-01-01T00:00:00",
        lastModifiedBy: null,
      },
      viewingDate: "2023-07-27T11:06:27.556377+00:00",
      customerId: "ceb75dff-94db-4459-b4d7-a010597c6258",
      customer: null,
      houseId: "ad563919-e42e-45c3-b7ef-21e9c4e492f2",
      house: null,
      status: 0,
      id: "ece7eeb2-9bf8-47ab-92b1-35bfd4264f2f",
      createdBy:
        '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Creates House Viewing"}',
      dateCreated: "2023-08-03T01:50:45.669459+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    interestPercentage: 0,
    documents: [],
    readyToPay: null,
    status: "InComplete",
    stage: 2,
    interestDate: null,
    dateViewed: "0001-01-01T00:00:00",
    alternateViewerName: "Meck Aaron",
    alternateViewerEmail: "meck@gmail.com",
    alternateViewerPhoneNumber: "0329023",
    id: "ab1ab1da-f5f3-47ee-b7e4-55a8d694ceb2",
    createdBy:
      '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Creates An Application"}',
    dateCreated: "2023-08-03T02:44:01.684133+00:00",
    dateLastModified: "2023-08-03T01:46:47.268438+00:00",
    lastModifiedBy:
      '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Updates Application"}',
  },
  {
    nhfNumber: "123456789093",
    mortgagePlanId: "84b236bb-d598-4e2b-a814-ec702dbcd529",
    mortgageBanks: "Premium Mortgage Bank",
    mortgagePlan: {
      name: "Plan A",
      description: "An awesome plan",
      type: "BANK_PLAN",
      mortgagePeriod: 12,
      access: "Anybody",
      maxLoanAmount: 50000.0,
      interestRate: 12.0,
      loanTenor: 12,
      equityPercentage: 0.0,
      organizationId: "c7ecc866-ca52-42a6-b84e-9281877a789c",
      organization: null,
      processingPeriod: 30,
      amortization: 0.0,
      applicableFees: [],
      requiredDocuments: [],
      repaymentMethods: [],
      id: "93b236cb-d598-4e2b-a814-ec702dbcd410",
      createdBy:
        '{"Id":"8750c76e-36ce-44ff-bb0c-13a10c2354ee","Name":" ","Action":"Admin Create Mortgage Plan"}',
      dateCreated: "2023-08-02T23:55:22.922434+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    contactNumber: null,
    houseId: "ao573919-f42e-45c3-b7ef-21e9c4e492f2",
    house: {
      city: null,
      address: "St Marvin Street",
      landmark: "The popes statue",
      units: 5,
      estate: null,
      price: 35000000.0,
      longitude: 0.0,
      latitude: 0.0,
      description: "An awesome house",
      features: [],
      images: null,
      organizationId: "c7ecc866-ca52-42a6-b84e-9281877a789c",
      organization: null,
      houseMortgagePlans: [],
      id: "ad563919-e42e-45c3-b7ef-21e9c4e492f2",
      createdBy:
        '{"Id":"8750c76e-36ce-44ff-bb0c-13a10c2354ee","Name":" ","Action":"Admin Create House"}',
      dateCreated: "2023-08-02T23:49:45.312126+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    customerId: "ceb75dff-94db-4459-b4d7-a010597c6258",
    customer: {
      name: "Ethan Hawke",
      firstName: "Ethan",
      lastName: "Hawke",
      email: "test2@gmail.com",
      phoneNumber: "08106884376",
      address: null,
      organizationId: "giddaa_organization",
      organization: null,
      verificationCode: "isdpPUh6OIvYosF",
      roleId: "CUSTOMER_ROLE",
      applicationRole: null,
      profilePicture:
        "https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80",
      withInNigeria: true,
      age: null,
      income: null,
      cityId: null,
      city: null,
      affordability: null,
      lastLoginDate: "2023-07-23T15:09:16.433835+00:00",
      isActive: true,
      isDeleted: false,
      id: "pob75dff-94db-4459-b4d7-a010597c6258",
      createdBy:
        '{"Id":"system","Name":"SYSTEM","Action":"Customer registration"}',
      dateCreated: "2023-07-23T15:09:16.433807+00:00",
      dateLastModified: "0001-01-01T00:00:00",
      lastModifiedBy: null,
    },
    houseReview: {
      applicationUserId: "ceb75dff-94db-4459-b4d7-a010597c6258",
      applicationUser: null,
      applicationId: "aa0ab1da-f5f3-47ee-b7e4-55a8d694ceb2",
      houseId: "ad563919-e42e-45c3-b7ef-21e9c4e492f2",
      house: null,
      star: 5,
      comment: "I loved the whole house.",
      id: "97d9c304-6828-4333-87dd-155d43319c36",
      createdBy:
        '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Creates Review"}',
      dateCreated: "2023-08-03T02:12:41.956345+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    viewingComplete: false,
    proofOfPaymentReferenceNumber: null,
    downPayment: null,
    loanAmount: null,
    withInNigeria: true,
    countryId: "NIGERIA",
    houseViewing: {
      applicationId: "yy0ab1da-f5f3-47ee-b7e4-55a8d694ceb2",
      contactNumber: "090230219321",
      agentId: "489c6da4-1c7c-432d-ab0a-212ce007d9b3",
      agent: {
        name: "Kayne West",
        firstName: "Kayne",
        lastName: "West",
        email: "kayne.west@gmail.com",
        phoneNumber: "+234090129012",
        address: null,
        organizationId: "giddaa_organization",
        organization: null,
        verificationCode: "WoDzcKX4eM7XqSB",
        roleId: "VIEWING_AGENT_ROLE",
        applicationRole: null,
        profilePicture: defaultImage,
        withInNigeria: true,
        age: null,
        income: null,
        cityId: null,
        city: null,
        affordability: null,
        lastLoginDate: "2023-08-03T00:49:47.530228+00:00",
        isActive: true,
        isDeleted: false,
        id: "489c6da4-1c7c-432d-ab0a-212ce007d9b3",
        createdBy:
          '{"Id":"8750c76e-36ce-44ff-bb0c-13a10c2354ee","Name":"James Bond","Action":"Create Admin User"}',
        dateCreated: "2023-08-03T00:49:47.526006+00:00",
        dateLastModified: "0001-01-01T00:00:00",
        lastModifiedBy: null,
      },
      viewingDate: "2023-07-27T11:06:27.556377+00:00",
      customerId: "ceb75dff-94db-4459-b4d7-a010597c6258",
      customer: null,
      houseId: "ad563919-e42e-45c3-b7ef-21e9c4e492f2",
      house: null,
      status: 0,
      id: "ece7eeb2-9bf8-47ab-92b1-35bfd4264f2f",
      createdBy:
        '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Creates House Viewing"}',
      dateCreated: "2023-08-03T01:50:45.669459+00:00",
      dateLastModified: null,
      lastModifiedBy: null,
    },
    interestPercentage: 0,
    documents: [],
    readyToPay: null,
    status: "Rejected",
    stage: 2,
    interestDate: null,
    dateViewed: "0001-01-01T00:00:00",
    alternateViewerName: "Meck Aaron",
    alternateViewerEmail: "meck@gmail.com",
    alternateViewerPhoneNumber: "0329023",
    id: "yy0ab1da-f5f3-47ee-b7e4-55a8d694ceb2",
    createdBy:
      '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Creates An Application"}',
    dateCreated: "2023-08-03T02:44:01.684133+00:00",
    dateLastModified: "2023-08-03T01:46:47.268438+00:00",
    lastModifiedBy:
      '{"Id":"ceb75dff-94db-4459-b4d7-a010597c6258","Name":"Lionel Messi","Action":"Lionel Messi Updates Application"}',
  },
];

export const options = [
  {
    value: "1",
    label: "Number of bedrooms",
  },
  {
    value: "2",
    label: "Number of bedrooms",
  },
  {
    value: "3",
    label: "Number of bedrooms",
  },
  {
    value: "4",
    label: "Number of bedrooms",
  },
  {
    value: "5",
    label: "Number of bedrooms",
  },
];

export const iconz = [
  {
    id: "ac",
    name: "Air Conditioner",
    Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="35.945"
        height="25.228"
        viewBox="0 0 35.945 25.228"
      >
        <g id="Group_176" data-name="Group 176" transform="translate(0 0)">
          <path
            id="Path_60"
            data-name="Path 60"
            d="M38.948,13H7a2.035,2.035,0,0,0-2,2.13V25.514a2.035,2.035,0,0,0,2,2.13H38.948a2.035,2.035,0,0,0,2-2.13V15.13A2.035,2.035,0,0,0,38.948,13ZM37.617,26.313H8.328v-.4c0-.466.266-.8.666-.8H36.951c.4,0,.666.333.666.8Zm2-.8c0,.466-.266.8-.666.8v-.4a2.035,2.035,0,0,0-2-2.13H8.994a2.035,2.035,0,0,0-2,2.13v.4c-.4,0-.666-.333-.666-.8V15.13c0-.466.266-.8.666-.8H38.948c.4,0,.666.333.666.8Z"
            transform="translate(-5 -13)"
          />
          <path
            id="Path_61"
            data-name="Path 61"
            d="M29.982,25H24.657a.666.666,0,1,0,0,1.331h5.325a.666.666,0,1,0,0-1.331Zm-2.8,11.649a1.927,1.927,0,0,1,.533-1.2.661.661,0,1,0-.865-1,2.913,2.913,0,0,0-1,2.13,3.044,3.044,0,0,0,.932,2.13,1.741,1.741,0,0,1,.6,1.2,3.644,3.644,0,0,1-.6,1.265.651.651,0,0,0,.133.932.946.946,0,0,0,.4.133.605.605,0,0,0,.533-.266,4.258,4.258,0,0,0,.932-2.064,3.044,3.044,0,0,0-.932-2.13C27.452,37.448,27.186,37.248,27.186,36.649Zm-7.855,0a1.927,1.927,0,0,1,.533-1.2.661.661,0,1,0-.865-1,2.913,2.913,0,0,0-1,2.13,3.044,3.044,0,0,0,.932,2.13,1.741,1.741,0,0,1,.6,1.2,3.644,3.644,0,0,1-.6,1.265.651.651,0,0,0,.133.932.946.946,0,0,0,.4.133A.605.605,0,0,0,20,41.974a4.258,4.258,0,0,0,.932-2.064A3.044,3.044,0,0,0,20,37.781C19.6,37.448,19.331,37.248,19.331,36.649Zm15.976,0a2.18,2.18,0,0,1,.533-1.2.661.661,0,0,0-.865-1,2.913,2.913,0,0,0-1,2.13,3.044,3.044,0,0,0,.932,2.13,1.741,1.741,0,0,1,.6,1.2,3.644,3.644,0,0,1-.6,1.265.651.651,0,0,0,.133.932.946.946,0,0,0,.4.133.605.605,0,0,0,.533-.266,4.258,4.258,0,0,0,.932-2.064,3.044,3.044,0,0,0-.932-2.13C35.573,37.448,35.307,37.248,35.307,36.649Z"
            transform="translate(-9.346 -17.012)"
          />
        </g>
      </svg>
    ),
  },
  {
    id: "apartments_and_flats",
    name: "Apartments and Flats",
    Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="27.263"
        height="30.293"
        viewBox="0 0 27.263 30.293"
      >
        <g id="Group_717" data-name="Group 717" transform="translate(-1)">
          <path
            id="Path_255"
            data-name="Path 255"
            d="M22.2,6.059V0H7.059V6.059H1V30.293H28.263V6.059Zm3.029,21.2H16.146V24.234H13.117v3.029H4.029V9.088h6.059V3.029h9.088V9.088h6.059Z"
            transform="translate(0)"
          />
          <path
            id="Path_256"
            data-name="Path 256"
            d="M11.059,4h3.029V7.029H11.059ZM5,10.059H8.029v3.029H5Zm6.059,0h3.029v3.029H11.059Zm6.059,0h3.029v3.029H17.117ZM5,16.117H8.029v3.029H5Zm6.059,0h3.029v3.029H11.059Zm6.059,0h3.029v3.029H17.117Z"
            transform="translate(2.059 2.059)"
          />
        </g>
      </svg>
    ),
  },
  {
    id: "bathrooms",
    name: "Bathrooms",
    Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="23.461"
        height="21.319"
        viewBox="0 0 23.461 21.319"
      >
        <path
          id="Path_14"
          data-name="Path 14"
          d="M23.394,12.663H5.266V6.7A2.106,2.106,0,0,1,6.375,4.826,2.127,2.127,0,0,1,7.6,4.581a3.723,3.723,0,0,0,.893,3.79L9.622,9.5a1.066,1.066,0,0,0,1.509,0l3.77-3.77a1.066,1.066,0,0,0,0-1.508l-1.13-1.13a3.726,3.726,0,0,0-4.831-.366A4.257,4.257,0,0,0,3.133,6.7v5.961H2.066a1.066,1.066,0,0,0,0,2.133H3.133V18a3.194,3.194,0,0,0,2.133,3v1.263a1.066,1.066,0,1,0,2.133,0V21.194H18.062v1.066a1.066,1.066,0,1,0,2.133,0V21a3.194,3.194,0,0,0,2.133-3V14.8h1.066a1.066,1.066,0,0,0,0-2.133ZM10,4.6a1.6,1.6,0,0,1,2.261,0l.378.376L10.377,7.24,10,6.863A1.6,1.6,0,0,1,10,4.6ZM20.195,18a1.066,1.066,0,0,1-1.066,1.066H6.332A1.066,1.066,0,0,1,5.266,18V14.8H20.195Z"
          transform="translate(-1 -2.008)"
        />
      </svg>
    ),
  },
  {
    id: "bedrooms",
    name: "Bedrooms",
    Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="26.66"
        height="20.261"
        viewBox="0 0 26.66 20.261"
      >
        <path
          id="Path_12"
          data-name="Path 12"
          d="M27.593,23.761H3.066A1.066,1.066,0,0,1,2,22.695V16.3A1.066,1.066,0,0,1,3.066,15.23H27.593A1.066,1.066,0,0,1,28.66,16.3v6.4A1.066,1.066,0,0,1,27.593,23.761ZM4.133,21.629H26.527V17.363H4.133Zm12.8-8.523h-3.2a1.066,1.066,0,0,1-1.066-1.066V10.965h-3.2v1.066A1.066,1.066,0,0,1,8.4,13.1H5.2a1.066,1.066,0,0,1-1.066-1.066V6.7a3.2,3.2,0,0,1,3.2-3.2h16a3.2,3.2,0,0,1,3.2,3.2v5.332A1.066,1.066,0,0,1,25.461,13.1h-3.2a1.066,1.066,0,0,1-1.066-1.066V10.965H18V12.04A1.066,1.066,0,0,1,16.929,13.106ZM14.8,10.973l1.066-.009A2.135,2.135,0,0,1,18,8.832h3.2a2.135,2.135,0,0,1,2.133,2.133h1.066V6.7a1.068,1.068,0,0,0-1.066-1.066h-16A1.068,1.068,0,0,0,6.266,6.7v4.266H7.332A2.135,2.135,0,0,1,9.465,8.832h3.2A2.135,2.135,0,0,1,14.8,10.965Z"
          transform="translate(-2 -3.5)"
        />
      </svg>
    ),
  },
  {
    id: "bungalow",
    name: "Bungalow",
    Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="32.527"
        height="26.982"
        viewBox="0 0 32.527 26.982"
      >
        <g
          id="Group_721"
          data-name="Group 721"
          transform="translate(-1.39 -6.7)"
        >
          <path
            id="Path_257"
            data-name="Path 257"
            d="M33.167,17.929c-.373-.266-.745-.585-1.118-.851-.585-.479-1.171-.9-1.756-1.33l-5.322-4.1-1.064-.8-4.79-3.672a1.844,1.844,0,0,0-.851-.426h-.053c-.106,0-.16-.053-.266-.053h-.639c-.106,0-.16.053-.266.053h-.053a4.221,4.221,0,0,0-.851.426c-1.969,1.49-3.885,2.98-5.854,4.47V10a.761.761,0,0,0-.745-.745H6.771A.728.728,0,0,0,6.026,10v4.417a1.337,1.337,0,0,1-.319.745c-.692.639-1.543,1.277-2.395,1.916-.373.266-.745.585-1.118.851a1.669,1.669,0,0,0-.8,1.384,1.252,1.252,0,0,0,.266.851,1.867,1.867,0,0,0,2.129.692c.16-.053.373-.16.532-.213V31.66H3.152v2.022H32.209V31.66H31.038V20.643a2.224,2.224,0,0,0,.532.213,1.952,1.952,0,0,0,2.076-.692,1.6,1.6,0,0,0-.479-2.235ZM15.392,31.66a.276.276,0,0,0,.106-.213V24a3.035,3.035,0,0,0-.053-.692,3.137,3.137,0,0,0-3.832-2.5,3.264,3.264,0,0,0-2.448,3.246v7.344c0,.16,0,.213.053.266H6.664V18.887c3.406-2.608,6.865-5.215,10.271-7.876l.373-.266a1.391,1.391,0,0,1,.426-.16.575.575,0,0,1,.373.16l.373.266c3.406,2.608,6.812,5.269,10.271,7.876V31.713H15.392Z"
            transform="translate(0)"
          />
          <path
            id="Path_258"
            data-name="Path 258"
            d="M33.2,22.6v4.47h2.5V22.6Z"
            transform="translate(-14.881 -7.438)"
          />
          <path
            id="Path_259"
            data-name="Path 259"
            d="M26.1,22.6v4.47h2.5V22.6Z"
            transform="translate(-11.559 -7.438)"
          />
          <path
            id="Path_260"
            data-name="Path 260"
            d="M50.133,19.4l-5.322-4.1L43.8,14.5"
            transform="translate(-19.84 -3.649)"
          />
        </g>
      </svg>
    ),
  },
  {
    id: "gated_compound_gated_community",
    name: "Geted compound & community",
    Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34.507"
        height="23.527"
        viewBox="0 0 34.507 23.527"
      >
        <path
          id="Layer_32"
          data-name="Layer 32"
          d="M35.722,9H31.017a.784.784,0,0,0-.784.784v3.137a.784.784,0,0,0,.784.784H31.8v3.137h-.784V15.274a.784.784,0,0,0-.62-.784c-.22,0-10.924-2.353-11.144-2.353S8.344,14.49,8.109,14.49a.784.784,0,0,0-.62.784v1.568H6.705V13.705H7.49a.784.784,0,0,0,.784-.784V9.784A.784.784,0,0,0,7.49,9H2.784A.784.784,0,0,0,2,9.784V31.743a.784.784,0,0,0,.784.784H5.921a.784.784,0,0,0,.784-.784V29.39H7.49v1.568a.784.784,0,0,0,.784.784H30.233a.784.784,0,0,0,.784-.784V29.39H31.8v2.353a.784.784,0,0,0,.784.784h3.137a.784.784,0,0,0,.784-.784V9.784A.784.784,0,0,0,35.722,9Zm-.784,18.822H33.37V26.253h1.568Zm0-6.274H33.37V19.979h1.568ZM33.37,18.411V16.842h1.568v1.568Zm0,6.274V23.116h1.568v1.568ZM31.8,10.568h3.137v1.568H31.8Zm3.137,3.137v1.568H33.37V13.705ZM14.548,26.253V19.979h3.921v6.274Zm3.921,1.568v2.353H14.548V27.822ZM9.058,19.979h3.921v6.274H9.058Zm10.979,0h3.921v6.274H20.037Zm0-1.568V13.894l3.921.839v3.678Zm5.49,1.568h3.921v6.274H25.527Zm3.921-1.568H25.527V15.07l3.921.839Zm-10.979,0H14.548V14.733l3.921-.839Zm-9.411-2.5,3.921-.839v3.341H9.058Zm-5.49,4.07H5.137v1.568H3.568Zm1.568-1.568H3.568V16.842H5.137ZM3.568,23.116H5.137v1.568H3.568Zm0,3.137H5.137v1.568H3.568ZM5.137,15.274H3.568V13.705H5.137ZM3.568,10.568H6.705v1.568H3.568Zm0,20.39V29.39H5.137v1.568Zm3.137-3.137V18.411H7.49v9.411Zm2.353,0h3.921v2.353H9.058Zm10.979,0h3.921v2.353H20.037Zm9.411,2.353H25.527V27.822h3.921Zm1.568-11.764H31.8v9.411h-.784ZM33.37,30.959V29.39h1.568v1.568Z"
          transform="translate(-2 -9)"
        />
      </svg>
    ),
  },
  {
    id: "houses",
    name: "House",
    Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="28.094"
        height="24.411"
        viewBox="0 0 28.094 24.411"
      >
        <g id="Group_236" data-name="Group 236" transform="translate(0)">
          <path
            id="Path_82"
            data-name="Path 82"
            d="M28.232,14.267a.939.939,0,0,1-.545-.178l-12.6-8.994-12.6,8.994A.939.939,0,0,1,1.4,12.567L14.543,3.178a.939.939,0,0,1,1.089,0l13.144,9.389a.939.939,0,0,1-.545,1.7ZM4.76,8.633a.939.939,0,0,1-.939-.939V3.939A.939.939,0,0,1,4.76,3H8.515a.939.939,0,1,1,0,1.878H5.7V7.694A.939.939,0,0,1,4.76,8.633Z"
            transform="translate(-1.07 -3)"
          />
          <path
            id="Path_83"
            data-name="Path 83"
            d="M23.716,28.144H19.022a.939.939,0,0,1-.939-.939V20.633H12.45v6.572a.939.939,0,0,1-.939.939H6.817A2.817,2.817,0,0,1,4,25.328V15.939a.939.939,0,1,1,1.878,0v9.389a.939.939,0,0,0,.939.939h3.756V19.694a.939.939,0,0,1,.939-.939h7.511a.939.939,0,0,1,.939.939v6.572h3.756a.939.939,0,0,0,.939-.939V15.939a.939.939,0,1,1,1.878,0v9.389a2.817,2.817,0,0,1-2.817,2.817Z"
            transform="translate(-1.249 -3.733)"
          />
        </g>
      </svg>
    ),
  },
  {
    id: "semi_detached_bungalow",
    name: "Semi detached bungalow",
    Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="41.317"
        height="28.802"
        viewBox="0 0 41.317 28.802"
      >
        <g id="Group_726" data-name="Group 726" transform="translate(-1.9 0.1)">
          <g
            id="Group_726-2"
            data-name="Group 726"
            transform="translate(-1.186 -17.843)"
          >
            <path
              id="Path_261"
              data-name="Path 261"
              d="M43.3,19.483a.64.64,0,0,0-.64-.64H4.826a.64.64,0,0,0-.64.64V30.531a.453.453,0,0,1,0,.179V44.8a.64.64,0,0,0,.64.64H42.662a.64.64,0,0,0,.64-.64V30.644c0-.019,0-.038-.006-.056s.006-.037.006-.056V19.483Zm-17.55.64H42.022v9.767H36.89L33.8,24.68a.641.641,0,0,0-1.1,0L29.6,29.891H24.469V20.123h1.284Zm-20.286,0H23.189v9.767h-5.3L14.793,24.68a.64.64,0,0,0-1.1,0L10.6,29.891H5.467V20.123Zm0,11.048h5.5a.64.64,0,0,0,.551-.313l2.728-4.6,2.729,4.6a.641.641,0,0,0,.551.313h5.666V44.165H5.467Zm19,12.993V31.172h5.5a.64.64,0,0,0,.551-.313l2.729-4.6,2.728,4.6a.641.641,0,0,0,.551.313h5.5V44.165Z"
              transform="translate(0 0)"
              strokeWidth="2.2"
            />
            <path
              id="Path_262"
              data-name="Path 262"
              d="M45.206,56.235H40.567a.64.64,0,0,0-.64.64v4.884a.64.64,0,0,0,.64.64h4.639a.64.64,0,0,0,.64-.64V56.875A.64.64,0,0,0,45.206,56.235Zm-.64,4.885H41.207v-3.6h3.358v3.6ZM26.288,56.234h-4.64a.64.64,0,0,0-.64.64v4.885a.64.64,0,0,0,.64.64h4.64a.64.64,0,0,0,.64-.64V56.874A.64.64,0,0,0,26.288,56.234Zm-.64,4.885h-3.36v-3.6h3.36Z"
              transform="translate(-9.64 -21.428)"
              strokeWidth="2.2"
            />
          </g>
        </g>
      </svg>
    ),
  },
  {
    id: "semi_detached_duplex",
    name: "Semi detached duplex",
    Icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="34.141"
        height="29.246"
        viewBox="0 0 34.141 29.246"
      >
        <path
          id="Path_263"
          data-name="Path 263"
          d="M20.916,54.466,37.97,65.888a.485.485,0,0,0,.688-.158.536.536,0,0,0-.151-.72L21.453,53.587a.485.485,0,0,0-.688.158.536.536,0,0,0,.151.721Zm18.67,25.5a2.125,2.125,0,0,1-.461,1.015.491.491,0,0,1-.842-.176.536.536,0,0,1,.089-.52,1.207,1.207,0,0,0,.26-.81A1.538,1.538,0,0,0,37.125,78a1.357,1.357,0,0,0-.849.314V81.7h-.906V65.3a.527.527,0,0,0-.238-.445L21.445,56.073a.479.479,0,0,0-.5-.011.526.526,0,0,0-.255.455v1.965l-.027.016L6.349,68.254a.536.536,0,0,0-.146.722.5.5,0,0,0,.315.226.48.48,0,0,0,.375-.073l13.793-9.4V72.22l-.014,2.111a.51.51,0,0,1-.5.522H12a.522.522,0,0,1,0-1.043h7.677v-12.3L9.718,68.176a.528.528,0,0,0-.23.439V81.7H7.43a.522.522,0,0,0,0,1.043H19.454a.477.477,0,0,0,.063,0H34.872a.477.477,0,0,0,.063,0h1.341v0h3.476a1.96,1.96,0,0,0,.511-1.325,1.908,1.908,0,0,0-.677-1.463ZM14.238,68.638h3.1v3.236h-3.1ZM19.019,81.7H13.494V76.048h5.525Zm4.75-13.065h3.1v3.236h-3.1ZM27.7,81.7h-3.1V76.048h3.1Zm3.767,0h-3.1V76.048h3.1Zm.8-9.829h-3.1V68.638h3.1Z"
          transform="translate(-6.122 -53.505)"
        />
      </svg>
    ),
  },
  {
    id: "apartments",
    name: "Apartments",
    Icon: (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
      >
        <path
          className="st0"
          d="M35.8,9.7V0H12.2v9.7H2.4V48h43.1V9.7H35.8z M41.4,43.8H26.1v-4.2h-4.2v4.2H6.6V13.9h9.7V4.2h15.3v9.7h9.7V43.8
       z"
        />
        <rect x="21.9" y="9.7" className="st0" width="4.2" height="4.2" />
        <rect x="21.9" y="28.9" className="st0" width="4.2" height="4.2" />
        <rect x="12.2" y="28.9" className="st0" width="4.2" height="4.2" />
        <rect x="31.7" y="28.9" className="st0" width="4.2" height="4.2" />
        <rect x="21.9" y="19.3" className="st0" width="4.2" height="4.2" />
        <rect x="12.2" y="19.3" className="st0" width="4.2" height="4.2" />
        <rect x="31.7" y="19.3" className="st0" width="4.2" height="4.2" />
      </svg>
    ),
  },
  {
    id: "bungalow_alt",
    name: "Bungalow Alt",
    Icon: (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
      >
        <path
          className="st0"
          d="M24,1.3L0.5,18.6v3.1c0,0.9,1,1.4,1.7,0.8L6,19.8v24H0.5V48h47v-4.2H42v-24l3.8,2.8c0.7,0.5,1.7,0,1.7-0.8v-3.1
       L24,1.3z M37.8,43.8h-15v-4.2v-4.2v-4.2h-4.2h-4.2v4.2v4.2v4.2h-4.2V16.7L24,6.5l13.8,10.2V43.8z"
        />
        <polygon
          className="st0"
          points="19.8,18.6 19.8,22.8 19.8,27 24,27 24,22.8 24,18.6 "
        />
        <polygon
          className="st0"
          points="28.2,18.6 28.2,22.8 28.2,27 32.4,27 32.4,22.8 32.4,18.6 "
        />
      </svg>
    ),
  },
  {
    id: "duplex_alt",
    name: "Duplex alt",
    Icon: (
      <svg
        version="1.1"
        id="Group_711"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
      >
        <polygon
          className="st0"
          points="13.1,24 13.1,28 13.1,32 17.1,32 17.1,28 17.1,24 "
        />
        <polygon
          className="st0"
          points="30.9,24 30.9,28 30.9,32 34.9,32 34.9,28 34.9,24 "
        />
        <path
          className="st0"
          d="M47.7,17.9L42.2,5.5h-2.6v-4h-4v4H5.8L0.3,17.9c-0.1,0.3,0.1,0.7,0.5,0.7h4.3V48h37.9V18.6h4.3
       C47.6,18.6,47.8,18.2,47.7,17.9z M8.4,9.5h31.3l2.2,5.1H6.1L8.4,9.5z M38.9,44h-4v-4v-4h-4h-4v4v4h-5.9v-4v-4h-4h-4v4v4h-4V18.6
       h29.9V44z"
        />
      </svg>
    ),
  },
  {
    id: "semiDetachedBungalow",
    name: "Semi detached bungalow alt",
    Icon: (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
      >
        <path
          className="st0"
          d="M45.8,22.6c0.7,0.5,1.7,0,1.7-0.8v-3.1L24,1.3L0.5,18.6v3.1c0,0.9,1,1.4,1.7,0.8L6,19.8v24H0.5V48h47v-4.2H42
            v-24L45.8,22.6z M24,43.8H10.2V16.7L24,6.5V43.8z M33.6,39.6v4.2h-4.2v-4.2v-4.2h4.2V39.6z M33.6,24.9h-4.2v-4.2h4.2V24.9z"
        />
        <rect x="14.4" y="20.7" className="st0" width="4.2" height="4.2" />
        <polygon
          className="st0"
          points="14.4,35.4 14.4,39.6 14.4,43.8 18.6,43.8 18.6,39.6 18.6,35.4 "
        />
      </svg>
    ),
  },
  {
    id: "semiDetachedDuplex",
    name: "Semi detached duplex alt",
    Icon: (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
      >
        <rect x="12.5" y="25.8" className="st0" width="4" height="4" />
        <path
          className="st0"
          d="M41.1,41.8V16.7L21.2,4c-0.3-0.2-0.8,0-0.8,0.4v37.4h-4v-8h-4v8H6.7V22.4l9.7-6.5V12c0-0.4-0.4-0.6-0.8-0.4
       l-13,8.7v21.5H0v4h48v-4H41.1z M24.8,17.9h4v4h-4V17.9z M24.8,25.8h4v4h-4V25.8z M34.8,37.8v4h-4h-4v-4v-4h4h4V37.8z M36.8,29.8h-4
       v-4h4V29.8z"
        />
      </svg>
    ),
  },
  {
    id: "terrace",
    name: "Terrace",
    Icon: (
      <svg
        version="1.1"
        id="Group_173"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width="48px"
        height="48px"
        viewBox="0 0 48 48"
      >
        <path
          className="st0"
          d="M47.3,12.8V8.6L18.2,0L17,3.9l3.5,1v8.2L3.7,18.8V44H1.3v4h45.5v-4h-3.1V11.7L47.3,12.8z M20.5,44H7.8V21.7
       l12.8-4.3V44z M39.6,44h-5.5v-4v-4h-4v4v4h-5.5V6.1l15,4.4V44z"
        />
        <rect x="30.1" y="23.8" className="st0" width="4" height="4" />
        <rect x="30.1" y="15.7" className="st0" width="4" height="4" />
        <rect x="12.1" y="23.8" className="st0" width="4" height="4" />
        <rect x="12.1" y="31.8" className="st0" width="4" height="4" />
      </svg>
    ),
  },
];

export const ccTabs = [
  {
    title: "CRC",
    label: "CRC",
    element: {},
  },
  {
    title: "First Central",
    label: "FIRST_CENTRAL",
    element: {},
  },
  {
    title: "Credit Registry",
    label: "CREDIT_REGISTRY",
    element: {},
  },
];

export const months = [
  { label: "1", value: "January" },
  { label: "2", value: "February" },
  { label: "3", value: "March" },
  { label: "4", value: "April" },
  { label: "5", value: "May" },
  { label: "6", value: "June" },
  { label: "7", value: "July" },
  { label: "8", value: "August" },
  { label: "9", value: "September" },
  { label: "10", value: "October" },
  { label: "11", value: "November" },
  { label: "12", value: "December" },
];

export const years = () => {
  const currentYear = new Date().getFullYear();
  const f = yearRange(currentYear, currentYear + 5, +1);
  return f.map((x) => ({ label: x, value: x }));
};

export const dum = [
  {
    value: {
      statusCode: 200,
      message: "Success",
      value: {
        name: "Custom Plan D",
        description: "The most awesome plan ever",
        type: "",
        mortgagePeriod: 50,
        access: "Anybody can access this plan",
        maxLoanAmount: 56000.0,
        interestRate: 12.0,
        loanTenor: 12,
        equityPercentage: 0.0,
        organizationId: "9c74e05a-a881-4f80-8eff-07d0527539fa",
        organization: null,
        processingPeriod: 20,
        applicableFees: [
          {
            name: "Sorting fee",
            amount: 6500.0,
            id: "553a8226-04bc-419f-8052-1e958bd75699",
            createdBy:
              '{"Id":"98e35a41-c8ba-4a4e-a145-1cc546844062","Name":"Tom Riddle","Action":"Admin Create Mortgage Plan"}',
            dateCreated: "2023-07-09T18:40:18.7106275",
            dateLastModified: null,
            lastModifiedBy: null,
          },
          {
            name: "Security Fee",
            amount: 5600.0,
            id: "5e4944ad-5f02-4a33-ad6b-21e2ff06bbf4",
            createdBy:
              '{"Id":"98e35a41-c8ba-4a4e-a145-1cc546844062","Name":"Tom Riddle","Action":"Admin Create Mortgage Plan"}',
            dateCreated: "2023-07-09T18:40:17.9120909",
            dateLastModified: null,
            lastModifiedBy: null,
          },
        ],
        requiredDocuments: [
          {
            id: "1184c33f-8875-44ec-8f40-db474cb92af9",
            optionId: "624a0565-0ef1-4b5d-af15-972b5d3ab4b9",
            option: {
              id: "624a0565-0ef1-4b5d-af15-972b5d3ab4b9",
              name: "Drivers License",
              optionTypeId: "MORTGAGE_PLAN_DOCUMENT_TYPE_UPLOAD",
              optionType: null,
              description: "Drivers License",
              identifier: "",
              createdBy:
                '{"Id":"98e35a41-c8ba-4a4e-a145-1cc546844062","Name":"Tom Riddle","Action":"Admin Create Mortgage Plan"}',
              dateCreated: "2023-07-09T18:40:23.9103573",
            },
            mortgagePlanId: "4ce723a3-2e7b-455c-8278-79bf20206e58",
          },
          {
            id: "5c200762-7b7a-438b-a8ab-de1b5c27c8c6",
            optionId: "31838992-e71b-4ae9-b470-f8ccb0c0bbe1",
            option: {
              id: "31838992-e71b-4ae9-b470-f8ccb0c0bbe1",
              name: "International Passport",
              optionTypeId: "MORTGAGE_PLAN_DOCUMENT_TYPE_UPLOAD",
              optionType: null,
              description: "International Passport",
              identifier: "",
              createdBy:
                '{"Id":"98e35a41-c8ba-4a4e-a145-1cc546844062","Name":"Tom Riddle","Action":"Admin Create Mortgage Plan"}',
              dateCreated: "2023-07-09T18:40:22.464293",
            },
            mortgagePlanId: "4ce723a3-2e7b-455c-8278-79bf20206e58",
          },
          {
            id: "84fb3308-4b18-426c-8933-0ec771a69482",
            optionId: "ce9f9a03-0bf6-48fc-897d-af7a7f2da40b",
            option: {
              id: "ce9f9a03-0bf6-48fc-897d-af7a7f2da40b",
              name: "Employment Letter",
              optionTypeId: "MORTGAGE_PLAN_DOCUMENT_TYPE_UPLOAD",
              optionType: null,
              description: "Your employment letter",
              identifier: "",
              createdBy:
                '{"Id":"98e35a41-c8ba-4a4e-a145-1cc546844062","Name":"Tom Riddle","Action":"Admin Create Mortgage Plan"}',
              dateCreated: "2023-07-09T18:40:19.4462533",
            },
            mortgagePlanId: "4ce723a3-2e7b-455c-8278-79bf20206e58",
          },
          {
            id: "961bdabf-9c44-46ab-af51-d9b32da214e5",
            optionId: "206f7ab4-9eac-4075-bd1a-aae43c9ac0cd",
            option: {
              id: "206f7ab4-9eac-4075-bd1a-aae43c9ac0cd",
              name: "National Identity Card/Slip",
              optionTypeId: "MORTGAGE_PLAN_DOCUMENT_TYPE_UPLOAD",
              optionType: null,
              description: "National Identity Card/Slip",
              identifier: "",
              createdBy:
                '{"Id":"98e35a41-c8ba-4a4e-a145-1cc546844062","Name":"Tom Riddle","Action":"Admin Create Mortgage Plan"}',
              dateCreated: "2023-07-09T18:40:25.3774225",
            },
            mortgagePlanId: "4ce723a3-2e7b-455c-8278-79bf20206e58",
          },
          {
            id: "dd999f22-64b2-4a3d-a8d3-ac071411fa13",
            optionId: "cda16ca8-1a76-44df-9ac5-53db8a1df519",
            option: {
              id: "cda16ca8-1a76-44df-9ac5-53db8a1df519",
              name: "Tax Verification document",
              optionTypeId: "MORTGAGE_PLAN_DOCUMENT_TYPE_UPLOAD",
              optionType: null,
              description: "Your Tax clarence letter",
              identifier: "",
              createdBy:
                '{"Id":"98e35a41-c8ba-4a4e-a145-1cc546844062","Name":"Tom Riddle","Action":"Admin Create Mortgage Plan"}',
              dateCreated: "2023-07-09T18:40:21.0088281",
            },
            mortgagePlanId: "4ce723a3-2e7b-455c-8278-79bf20206e58",
          },
        ],
        repaymentMethods: [
          {
            id: "19275ac9-77e3-41e8-8a25-49fce6649ad4",
            optionId: "DIRECT_DEBIT",
            option: {
              id: "DIRECT_DEBIT",
              name: "Direct Debit",
              optionTypeId: "REPAYMENT",
              optionType: null,
              description: "Direct Debit",
              identifier: "",
              createdBy:
                '{"Id":"system","Name":"SYSTEM","Action":"Create Option"}',
              dateCreated: "2023-06-28T02:46:01.5852437",
            },
            mortgagePlanId: "4ce723a3-2e7b-455c-8278-79bf20206e58",
          },
          {
            id: "f10c8881-de49-4597-95f9-1121fb240ef8",
            optionId: "PAYMENT_GATEWAY",
            option: {
              id: "PAYMENT_GATEWAY",
              name: "Payment Gateway",
              optionTypeId: "REPAYMENT",
              optionType: null,
              description: "Payment Gateway",
              identifier: "",
              createdBy:
                '{"Id":"system","Name":"SYSTEM","Action":"Create Option"}',
              dateCreated: "2023-06-28T02:46:01.6050983",
            },
            mortgagePlanId: "4ce723a3-2e7b-455c-8278-79bf20206e58",
          },
        ],
        id: "4ce723a3-2e7b-455c-8278-79bf20206e58",
        createdBy:
          '{"Id":"98e35a41-c8ba-4a4e-a145-1cc546844062","Name":"Tom Riddle","Action":"Admin Create Mortgage Plan"}',
        dateCreated: "2023-07-09T18:40:16.5000826",
        dateLastModified: null,
        lastModifiedBy: null,
      },
    },
    formatters: [],
    contentTypes: [],
    declaredType: null,
    statusCode: 200,
  },
];

export const mortgagePlanTypes = [
  { name: "NHF", type: "NHF" },
  { name: "DEVELOPER_CUSTOM_PLAN", type: "DEVELOPER_PLAN" },
  { name: "BANK_CUSTOM_PLAN", type: "BANK_PLAN" },
];

export const ERROR_CODE = "ERR_NETWORK";
