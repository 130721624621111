import React from "react";
import { useNav } from "../../../../hooks/useNav";
import MortgagePlanForm from "./MortgagePlanForm";

const CreateMortagagePlan = () => {
  useNav({ mainTitle: "Create a Plan", hasBackButton: true });
  return (
    <div className="container-fluid">
      <MortgagePlanForm />
    </div>
  );
};

export default CreateMortagagePlan;
