import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";
import useAuth from "../../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

const useChangePassword = (estateId) => {
  const { postRequest } = useAxiosBase();
  const { setAuthUser } = useAuth();
  const navigate = useNavigate();

  const changePassword = useMutation({
    mutationFn: (data) =>
      postRequest({
        url: `/account/change-password`,
        data: data,
      }),
    onSuccess: (res) => {
      if (res?.data?.value?.statusCode === 200) {
        toast.success("Password Successfully Changed!");
        sessionStorage.removeItem("user");
        setAuthUser(null);
        navigate("/account/login");
      } else {
        toast.error(res?.response?.data?.value?.message || "An error occurred");
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message || "An error occurred");
    },
  });

  return { changePassword };
};

export default useChangePassword;
