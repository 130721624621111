import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../../hooks/useAxiosBase";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


const useUpdateRolePermissions = (roleId) => {
  const { putRequest } = useAxiosBase();
  const navigate = useNavigate();

  const updateRolePermissions = useMutation({
    mutationKey: ["update-role", roleId],
    mutationFn: (data) =>
      putRequest({
        url: `/developer/role/${roleId}/update`,
        data: data,
      }).then((res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success("Role has be updated Successfully");
          navigate("/settings/permission-and-roles", { state: { refresh: true } });
        }
        if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
          toast.error(res?.data?.value?.message);
        }
      }),
    onSuccess: (res, res1) => {
      if (res?.data?.value?.value?.statusCode === 200) {
        toast.success("Role has be updated Successfully");
      }
      if ([400, 404, 500].includes(res?.data?.value?.value?.statusCode)) {
        toast.error(res?.data?.value?.value?.message);
      }
    },
    onError: (error) => {
      toast.error(error);
    },
  });

  return { updateRolePermissions };
};

export default useUpdateRolePermissions;
