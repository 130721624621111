import baseAxios from "../api/baseAxios";

class SampleService {
  endpoint = "";
  constructor(endpoint) {
    this.endpoint = endpoint;
  }
  getAll = () => {
    const controller = new AbortController();
    const request = baseAxios.get(this.endpoint, {
      signal: controller.signal,
    });
    return { request, cancel: () => controller.abort() };
  };

  get = (id) => {
    return baseAxios.get(`${this.endpoint}/${id}`).then((res) => res.data);
  };

  create = (entity) => {
    return baseAxios.post(this.endpoint, entity).then((res) => res.data);
  };

  update = (entity) => {
    return baseAxios.put(this.endpoint + entity?.id, entity);
  };

  delete = (id) => {
    return baseAxios.delete(this.endpoint + id);
  };
}

export default SampleService;
