import React, { useState, useRef } from "react";
import { months, mtAppcolumns, mtAppdata, years } from "../../utils/constants";
import { useNav } from "../../hooks/useNav";
import CustomDropdown from "../../Components/Common/CustomDropdown";
import InfoCard from "../../Components/Common/InfoCard";
import InfoTable from "../../Components/Common/InfoTable";
import Modal from "../../Components/Common/Modal/index";
import { useNavigate } from "react-router-dom";
import { OfferLetterColumns, OfferLetterColumnsData } from "./constants";
import UploadDocument from "../../Components/Common/UploadDocument";
import useSaveSingleImage from "../../hooks/useSaveSingleImage";
import useGetOfferLetter from "./hooks/useGetOfferLetter";
import useUploadOfferLetter from "./hooks/useUploadOfferLetter";
import { Dropdown, Table } from "react-bootstrap";
import CenteredText from "../../Components/CenteredText";
import Spinner from "../../Components/Spinner";

const OfferLetterList = () => {
  useNav({
    hasBackButton: true,
    mainTitle: "Offer Letters",
    subTitle:
      "This shows a list of all offer letter requests in your organization made by prospective customers for your different plans and your partner bank plans.",
  });

  const { data: offerLetters, isLoading } = useGetOfferLetter();

  const [requestId, setRequestId] = useState("");
  const { uploadOfferLetter } = useUploadOfferLetter(requestId);

  const [omit, setOmit] = useState(true);
  const [customerData, setCustomerData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const [spanText, setSpanText] = useState("Show All Fields");
  const [rowAction, setRowAction] = useState("");

  // const handleSpanClick = () => {
  //   setSpanText(!omit ? "Show All Fields" : "Hide Fields");
  //   setOmit(!omit);
  // };

  const handleApplicationBtnClick = (rowId) => {
    setRequestId(rowId);
    setShowModal(true);
  };

  // const docRef = useRef();
  // const { imageToDisplay, imageObject, handleChange } = useSaveSingleImage({
  //   optionsId: "OFFER_LETTER",
  //   description: "Uploading offer letter",
  // });

  // const handleRowClicked = (row, e) => {
  //   const status = row.application.status;
  //   setRequestId(row.id);
  //   setRowAction(status);
  //   setCustomerData(row);

  //   if (status === "Sent" || status === "NotSent") {
  //     setShowModal(true);
  //   }
  // };

  // const handleUpload = async () => {
  //   if (rowAction === "NotSent") {
  //     await uploadOfferLetter.mutate(imageObject[0]);
  //   }
  // };

  // const handleDownload = async () => {};

  return (
    <div className="container-fluid mb-3">
      {/* <div className="d-flex justify-content-end gap-3 pt-2 pb-3">
        <div>
          <CustomDropdown
            listItem={months}
            defaultItem={months[new Date().getMonth()].value}
            width={150}
          />
        </div>
        <div>
          <CustomDropdown
            listItem={years()}
            defaultItem={years()[0].value}
            width={140}
          />
        </div>
      </div> */}
      <div className="mb-4">
        <div className="col-lg-3 col-md-3 col-sm-4">
          <InfoCard
            // positive={true}
            // topValue={"15%"}
            // percentage={true}
            mainValue={"0"}
            description={"Total number of requests"}
          />
        </div>
      </div>

      {isLoading ? (
        <Spinner />
      ) : offerLetters?.length > 0 ? (
        <div className="row mt-2">
          <div className="col-12">
            <InfoTable
              columns={OfferLetterColumns(omit, handleApplicationBtnClick)}
              dataCollection={offerLetters}
              rightTextAsButton={true}
              spanText={spanText}
              // handleSpanTextClick={handleSpanClick}
              pointerOnHover={true}
              highlightOnHover={true}
              // onRowClicked={(row, e) => handleRowClicked(row, e)}
            />
          </div>
        </div>
      ) : (
        <>
          <CenteredText title={"You have no requests at this time"}>
            No application has reached the level where an offer letter has been
            requested. When an application reaches the point where an offer
            letter is requested, the request will show up here. We will also
            send you emails to inform you about the request.
          </CenteredText>
        </>
      )}

      {/* <Modal
        show={showModal}
        title={
          rowAction === "NotSent"
            ? "Send Offer Letter"
            : "Download Offer Letter"
        }
        subTitle={
          rowAction === "NotSent"
            ? `You are about to send ${customerData?.customer?.name}'s Offer Letter for Bungalow - No 12 ${customerData?.house?.address}, Abuja.`
            : `You are about to download ${customerData?.customer?.name}'s Offer Letter for Bungalow - No 12 ${customerData?.house?.address}, Abuja.`
        }
        confirmButtonLabel={rowAction === "NotSent" ? "Send" : "Download"}
        closeModal={() => setShowModal(false)}
        confirmModal={rowAction === "NotSent" ? handleUpload : handleDownload}
        isBtnLoading={uploadOfferLetter.isLoading}
        disabled={uploadOfferLetter.isLoading || !imageObject}
      >
        {rowAction === "NotSent" ? (
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-6">
                <UploadDocument
                  title={"Upload Offer Letter"}
                  docRef={docRef}
                  docObject={imageObject}
                  handleChange={handleChange}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-6">
                <UploadDocument
                  title={"Download Offer Letter"}
                  variant={"download"}
                  // docRef={docRef}
                  // docObject={imageObject}
                  // handleChange={handleChange}
                />
              </div>
            </div>
          </div>
        )}
      </Modal> */}
    </div>
  );
};

export default OfferLetterList;
