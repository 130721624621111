import { styled } from "styled-components";

export const CardWrapper = styled.div`
position: relative;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
gap: 0.6rem;
 border-radius: 10px;
  min-height: ${(props) => (props.height ? `${props.height} !important` : "7rem")};
  box-shadow: 0px 3px 6px #0000001a !important;
  overflow: hidden;

  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 0.5rem;
  padding-bottom: 2rem;

  .card_title {
    text-align: center;
    font-size: 1rem;
  }

  .isRequired {
    position: absolute;
    left: 0.7rem;
    bottom: 0.7rem;
    width: 100%;
  }
`;