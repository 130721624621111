import React, { useEffect, useRef, useState } from "react";
import usePermission from "../../../hooks/usePermissions";
import "../Profile/profile.css";
import { useNav } from "../../../hooks/useNav";
import FormInput from "../../../Components/Common/FormInput";
import { colors } from "../../../utils/constants";
import Button from "../../../Components/Common/Button";
import FooterButtons from "../../../Components/Common/FooterButtons";
import { useForm } from "react-hook-form";
import useAuth from "../../../hooks/useAuth";
import useEditProfile from "./hooks/useEditProfile";
import CenteredText from "../../../Components/CenteredText";
import Icons from "../../../Components/Common/Icons";
import { fileExtension, getBase64Image } from "../../../utils/imageUtils";
import { toast } from "react-toastify";
import Dropdown from "../../../Components/Common/Dropdown";
import { jobTitles, titleList } from "../../../utils/mockData";
import MultitextInput from "../../../Components/Common/MultitextInput";

const Profile = () => {
  useNav({ mainTitle: "My Profile" });

  const imageRef = useRef();

  const { authUser } = useAuth();
  const appUserId = authUser?.user?.id;

  const [profilePicture, setProfilePicture] = useState();
  const [gender, setGender] = useState();
  const [title, setTitle] = useState();
  const [titleName, setTitleName] = useState();
  const [jobTtile, setJobTtile] = useState();
  const [jobTitleName, setJobTitleName] = useState();
  const [phoneNumber, setPhoneNumber] = useState(authUser?.user?.phoneNumber);
  const [previousProfilePicture, setPreviousProfilePicture] = useState(
    authUser?.user?.profilePicture
  );

  const { register, handleSubmit, setValue, reset } = useForm();
  const { update } = useEditProfile({ appUserId });

  const onSubmit = (data) => {
    data.profilePicture = profilePicture ?? null;

    // Calculate age from date of birth
    const dob = new Date(data.dateOfBirth);
    const today = new Date();
    let age = today.getFullYear() - dob.getFullYear();
    const monthDiff = today.getMonth() - dob.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--;
    }

    const profileData = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      bio: data.bio,
      title: title,
      phoneNumber: phoneNumber,
      age: age,
      dateOfBirth: data.dateOfBirth,
      gender: gender,
      profilePicture: profilePicture ?? null,
      designation: jobTtile,
    };

    // console.log("profileData", profileData);
    update.mutate(profileData);
  };

  const handleAddProfile = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        // Check if the file size is greater than 2MB
        toast.error("Image size should be less than 2MB");
        return;
      }
      if (!["image/jpg", "image/jpeg", "image/png"].includes(file.type)) {
        // Check if the file type is jpeg or png
        toast.error("Please upload a JPG, JPEG or PNG image");
        return;
      }
      const base64 = await getBase64Image(file);
      let newImage = {
        base64: base64,
        name: file.name,
        optionId: "USER_PROFILE_IMAGE",
        extension: fileExtension(file),
      };

      setProfilePicture(newImage);
    }
  };

  const genderDropdown = [
    { id: "Male", name: "Male" },
    { id: "Female", name: "Female" },
  ];

  const handleChange = (name, value) => {
    // Filter out non-numeric characters
    const numericValue = value.replace(/\D/g, "");

    // Update the state with the filtered value
    setPhoneNumber(numericValue);
  };

  useEffect(() => {
    if (authUser?.user) {
      reset(authUser?.user);
    }

    if (authUser?.user?.dateOfBirth) {
      const datePart = authUser?.user?.dateOfBirth.split("T")[0];
      setValue("dateOfBirth", datePart);
    }

    if (authUser?.user?.profilePicture) {
      setPreviousProfilePicture(authUser?.user?.profilePicture);
    }

    const jobTitleName = jobTitles.find(
      (x) => x.id === authUser?.user?.designation
    )?.name;
    setJobTitleName(jobTitleName);
    setJobTtile(authUser?.user?.designation);

    const titleName = titleList?.find(
      (x) => x.id === authUser?.user?.title
    )?.name;
    setTitleName(titleName);
    setTitle(authUser?.user?.title);
  }, [authUser?.user]);

  return (
    <div className="container-fluid mt-3">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="profile row m-0">
          <div className="col-md-12 d-flex justify-content-center flex-column align-items-center mb-2 mt-5">
            <div
              className="company_profile"
              onClick={() => imageRef?.current.click()}
            >
              {profilePicture?.base64 ? (
                <img
                  src={`data:image/${profilePicture.extension};base64,${profilePicture.base64}`}
                  alt=""
                  className="w-100 h-100"
                />
              ) : previousProfilePicture ? (
                <img
                  src={previousProfilePicture}
                  alt=""
                  className="w-100 h-100"
                />
              ) : (
                <Icons iconName="profile" />
              )}
              <input
                id="company_profile"
                type="file"
                ref={imageRef}
                accept="image/*"
                onChange={(e) => {
                  handleAddProfile(e);
                }}
              />
            </div>
            <label
              htmlFor="company_profile"
              className="my-2 mb-3 fw-bold"
              style={{ color: colors.green, cursor: "pointer" }}
            >
              Upload Photo
            </label>
          </div>
          <div className="col-md-6 col-lg-4">
            <Dropdown
              labelName={"Title"}
              lefticonname="user"
              name="designation"
              options={titleList}
              value={titleName}
              placeholder={"Select"}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </div>
          <div className="col-md-6 col-lg-4">
            <FormInput
              labelName={"First Name"}
              required
              lefticonname="user"
              name="firstName"
              register={register("firstName")}
            />
          </div>
          <div className="col-md-6 col-lg-4">
            <FormInput
              labelName={"Last Name"}
              required
              lefticonname="user"
              name="lastName"
              register={register("lastName")}
            />
          </div>
          <div className="col-md-6 col-lg-4">
            <FormInput
              labelName={"Email"}
              type={"email"}
              required
              lefticonname="envolope"
              name="email"
              register={register("email")}
              disabled
            />
          </div>
          <div className="col-md-6 col-lg-4">
            <FormInput
              labelName={"Date of birth"}
              type={"date"}
              name="dateOfBirth"
              placeholder="Select your date of birth"
              register={register("dateOfBirth")}
            />
          </div>
          <div className="col-md-6 col-lg-4">
            <Dropdown
              labelName={"Gender"}
              name="gender"
              options={genderDropdown}
              placeholder={
                authUser?.user?.gender ? authUser?.user?.gender : "Select"
              }
              onChange={(e) => {
                setGender(e.target.value);
              }}
            />
          </div>
          <div className="col-md-6 col-lg-4">
            <FormInput
              labelName={"Phone Number"}
              type={"tel"}
              maxlength="11"
              lefticonname={"phone"}
              placeholder={"e.g., 09065718291"}
              name="phoneNumber"
              // register={register("phoneNumber")}
              value={phoneNumber}
              onChange={handleChange}
            />
          </div>
          <div className="col-md-6 col-lg-4">
            <Dropdown
              labelName={"Job Title"}
              lefticonname="user"
              name="designation"
              options={jobTitles}
              value={jobTitleName}
              placeholder={"Select"}
              onChange={(e) => {
                setJobTtile(e.target.value);
              }}
            />
          </div>
          <div className="col-md-9">
            <MultitextInput
              label={"Bio"}
              name="bio"
              placeholder="Enter your bio"
              register={register("bio")}
            />
          </div>
        </div>

        <FooterButtons loading={update.isLoading} disabled={update.isLoading} />
      </form>
    </div>
  );
};

export default Profile;
