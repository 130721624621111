import { useQuery } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";

const useViewing = (page) => {
  const { getRequest } = useAxiosBase();
  return useQuery({
    queryKey: ["viewing-request"],
    queryFn: () =>
      getRequest({
        url: `/developer/house-viewing/get-all?pageNumber=${page}`,
      }).then((res) => res.data?.value?.value),
    staleTime: 50000,
  });
};
export default useViewing;
