import React, { useState, useMemo, useEffect } from "react";
import usePermission from "../../hooks/usePermissions";
import { useNav } from "../../hooks/useNav";
import InfoCard from "../../Components/Common/InfoCard";
import InfoTable from "../../Components/Common/InfoTable";
import { defaultImage } from "./constants";
import CenteredText from "../../Components/CenteredText";
import Spinner from "../../Components/Spinner";
import Modal from "../../Components/Common/Modal/index";
import FormInput from "../../Components/Common/FormInput";
import Button from "../../Components/Common/Button";
import { colors } from "../../utils/constants";
import Icons from "../../Components/Common/Icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { invitOrganisation } from "../../models/InviteOrganisation";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import useAxiosBase from "../../hooks/useAxiosBase";
import { getSingleImage } from "../../utils/generalUtils";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useGetBankCollections from "./hooks/useGetBankCollection";
import useGetBankPartnership from "./hooks/useGetBanksPartnerships";
// import { bankColums } from "./constants";
import useInviteBanks from "./hooks/useInviteBanks";
import { HiDotsVertical } from "react-icons/hi";
import Dropdown from "react-bootstrap/Dropdown";
import Badges from "../../Components/Common/Badges";
import defaultHouse from "../../house.svg";
import CellSelector from "../../Components/Common/CellSelector";
import EstateCards from "../../Components/Common/EstateCards";
import TableLoader from "../../Components/Common/TableLoader";
import SkeletonLoader from "../../Components/SkeletonLoader";

const MortgageBanks = () => {
  const navigate = useNavigate();
  useNav({
    mainTitle: "Banks",
    subTitle: "Your partner banks and banks available for you to partner with.",
  });

  const {
    accessViewPartners,
    accessInvitePartner,
    accessAddPartner,
    accessAcceptPartnerRequest,
    accessDeclinePartnerRequest,
    accessRevokePartner,
    accessDeletePartner,
  } = usePermission();

  const { authUser } = useAuth();
  const orgID = authUser?.user?.organizationId;

  const [pagination, setPagination] = useState(1);

  const { data: getBankCollections, isLoading: loading } =
    useGetBankCollections();
  const {
    data: banks,
    isLoading,
    refetch,
    isRefetching,
  } = useGetBankPartnership(pagination);
  const { inviteBank } = useInviteBanks();

  const [bankCollections, setBankCollections] = useState([]);
  const [partneredBanks, setPartneredBanks] = useState([]);
  const [slectedDeveloperName, setSelectedDeveloperName] = useState();

  const [requesterID, setRequesterID] = useState("");
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [showBankModal, setShowBankModal] = useState(false);
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showDeclineModal, setShowDeclineModal] = useState(false);
  const [showUnpartnerModal, setShowUnpartnerModal] = useState(false);
  const [showRevokeModal, setShowRevokeModal] = useState(false);
  const [pending, setPending] = useState(false);
  const [search, setSearch] = useState("");

  const { getRequest } = useAxiosBase();

  // INVITE BANK
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver: yupResolver(invitOrganisation) });

  const handleSubmitInvite = (data) => {
    // inviteBank.mutate(data);
  };

  const bankCollection = () => {
    if (!search) return bankCollections;
    if (search.length >= 1 && bankCollections?.length > 0) {
      return bankCollections?.filter((x) => {
        return x.name.toLocaleLowerCase().includes(search.toLocaleLowerCase());
      });
    }

    return bankCollections;
  };

  const showPartnerShipModal = (requesterId, status) => {
    setRequesterID(requesterId);

    const selectedDeveloper = banks?.data?.find(
      (dev) => dev.partner?.id === requesterId
    );

    if (selectedDeveloper) {
      setSelectedDeveloperName(selectedDeveloper?.partner?.name);
    }

    if (status === "PENDING") {
      navigate(`/mortgage-banks/${requesterId}`);
    } else if (status === "ACCEPTED") {
      setShowAcceptModal(true);
    } else if (status === "REJECTED") {
      setShowDeclineModal(true);
    } else if (status === "UNPARTNER") {
      setShowUnpartnerModal(true);
    } else if (status === "REVOKED") {
      setShowRevokeModal(true);
    }
  };

  const handlePartnerShipRequest = (requesterId, status) => {
    getRequest({
      url: `developer/partnership/${requesterId}/respond-to-request/${status}`,
    })
      .then((res) => {
        const responseData = res.data?.value;
        if (responseData.statusCode === 200) {
          toast.success(responseData?.message);
          refetch();
          setShowAcceptModal(false);
          setShowDeclineModal(false);
          setShowUnpartnerModal(false);
          setShowRevokeModal(false);
          setPending(false);
        } else {
          toast.error(responseData?.message);
          setPending(false);
        }
        setPending(false);
      })
      .catch((error) => {
        toast.error("An error occurred while processing your request.");
      });
  };

  // BANK COLUMS TO DISPLAY IN THE TABLE STARTS
  const bankColums = (handlePartnership, loading) => [
    {
      name: <p className="fw-bold p-0 m-0">BANKS</p>,
      grow: 0,
      cell: (row) => (
        <CellSelector loading={loading}>
          <img
            data-tag="allowRowEvents"
            height="60px"
            width="60px"
            alt={
              row.sender?.id === orgID ? row?.partner?.name : row?.sender?.name
            }
            src={
              row.sender?.id === orgID ? row?.partner?.logo : row.sender?.logo
            }
            style={{ borderRadius: "18px", padding: "8px 0" }}
          />
        </CellSelector>
      ),
    },
    {
      name: "",
      sortable: true,
      selector: (row) => {
        const displayName =
          row.sender?.id === orgID ? row.partner?.name : row.sender?.name;
        return <CellSelector loading={loading}>{displayName}</CellSelector>;
      },
    },
    {
      name: <p className="fw-bold p-0 m-0">OPERATIONS & CERTIFICATES</p>,
      sortable: true,
      grow: 2,
      selector: (row) => {
        const isSender = row.sender?.id === orgID;
        const yearsInOperation = isSender
          ? row?.partner?.yearsInOperation
          : row?.sender?.yearsInOperation;
        const documentCount = isSender
          ? row?.partner?.documentCount
          : row?.sender?.documentCount;

        return (
          <CellSelector loading={loading}>
            <p className="m-0">
              {yearsInOperation ?? 0} Years in Operation{" "}
              <span className="circle_bar"></span> {documentCount}{" "}
              {documentCount <= 1 ? "Certificate" : "Certificates"}
            </p>
          </CellSelector>
        );
      },
    },
    {
      name: <p className="fw-bold p-0 m-0">STATUS</p>,
      sortable: true,
      compact: true,
      cell: (row) => {
        let displayText, tooltipText;
        let borderColor, bgColor, textColor;
        switch (row.status) {
          case "ACCEPTED":
            displayText = "Partnered";
            tooltipText = "The customer’s application has been approved";
            borderColor = colors.darkGreen;
            textColor = colors.darkGreen;
            break;
          case "PENDING":
            displayText = "Pending";
            tooltipText =
              "All necessary steps have been met, marking the completion of the transaction.";
            borderColor = colors.darkOrange;
            bgColor = colors.yellowLight;
            textColor = colors.darkOrange;
            break;
          case "UNPARTNER":
            displayText = "Unpartnered";
            tooltipText =
              "All necessary steps have been met, marking the completion of the transaction.";
            borderColor = colors.darkGray;
            bgColor = colors.grey;
            textColor = colors.darkGray;
            break;
          case "REVOKED":
            displayText = "Revoked";
            tooltipText =
              "All necessary steps have been met, marking the completion of the transaction.";
            borderColor = colors.darkGray;
            bgColor = colors.grey;
            textColor = colors.darkGray;
            break;
          default:
            displayText = row.status;
            tooltipText = row.status;
            break;
        }

        return (
          <CellSelector loading={loading}>
            <Badges
              text={
                displayText?.length > 17
                  ? `${displayText.substring(0, 13)}..`
                  : displayText
              }
              title={tooltipText}
              backgroundcolor={bgColor}
              bordercolor={borderColor}
              color={textColor}
            />
          </CellSelector>
        );
      },
      wrap: true,
    },
    {
      name: <p className="fw-bold p-0 m-0"> ACTION </p>,
      sortable: false,
      grow: 0.5,
      center: true,
      cell: (row) => (
        <Dropdown>
          <Dropdown.Toggle id={`dropdown-${row.id}`}>
            <HiDotsVertical className="fw-bold text-dark" />
          </Dropdown.Toggle>
          {!loading && (
            <Dropdown.Menu>
              {row.status === "REVOKED" ? (
                <Dropdown.Item
                  onClick={() =>
                    navigate(
                      `/banks/${
                        row.sender?.id === orgID
                          ? row.partner.id
                          : row.sender?.id
                      }`
                    )
                  }
                >
                  View Details
                </Dropdown.Item>
              ) : row.status === "UNPARTNER" ? (
                <Dropdown.Item
                  onClick={() => handlePartnership(row.id, "ACCEPTED")}
                >
                  Partner
                </Dropdown.Item>
              ) : row?.status === "ACCEPTED" ? (
                <Dropdown.Item
                  onClick={() => handlePartnership(row.id, "UNPARTNER")}
                >
                  Unpartner
                </Dropdown.Item>
              ) : row?.status === "PENDING" && row?.sender?.id !== orgID ? (
                <>
                  {accessAcceptPartnerRequest?.value && (
                    <Dropdown.Item
                      onClick={() => handlePartnership(row.id, "ACCEPTED")}
                    >
                      Accept
                    </Dropdown.Item>
                  )}
                  {accessDeclinePartnerRequest?.value && (
                    <Dropdown.Item
                      onClick={() => handlePartnership(row.id, "REJECTED")}
                    >
                      Decline
                    </Dropdown.Item>
                  )}
                </>
              ) : row?.status === "PENDING" && row?.sender?.id === orgID ? (
                <>
                  {accessRevokePartner?.value && (
                    <Dropdown.Item
                      onClick={() => handlePartnership(row.id, "REVOKED")}
                    >
                      Revoke Partnership
                    </Dropdown.Item>
                  )}
                </>
              ) : row?.status === "NULL" ? (
                <Dropdown.Item
                  onClick={() => handlePartnership(row.id, "PENDING")}
                >
                  Request Partnership
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  onClick={() =>
                    navigate(
                      `/banks/${
                        row.sender?.id === orgID
                          ? row.partner.id
                          : row.sender?.id
                      }`
                    )
                  }
                >
                  View Details
                </Dropdown.Item>
              )}
            </Dropdown.Menu>
          )}
        </Dropdown>
      ),
    },
  ];
  // BANK COLUMS TO DISPLAY IN THE TABLE ENDS

  useEffect(() => {
    if (getBankCollections) {
      setBankCollections(getBankCollections);

      const acceptedBanks = banks?.data?.filter(
        (bank) => bank.status === "ACCEPTED"
      );
      setPartneredBanks(acceptedBanks);
    }
  }, [getBankCollections, banks?.data]);

  useEffect(() => {
    if (pagination) {
      refetch();
    }
  }, [pagination]);

  return (
    <div className="container-fluid mortgage-banks">
      {accessViewPartners?.value ? (
        <>
          <div className="d-flex justify-content-end gap-3 pt-2 pb-3">
            {accessInvitePartner?.value && (
              <div>
                <Button
                  btnText={"Invite Banks"}
                  iconcolor={colors.primary}
                  btntextcolor={colors.primary}
                  backgroundcolor={colors.white}
                  iconcolorHover={colors.hover}
                  colorHover={colors.hover}
                  borderHover={colors.hover}
                  backgroundHover={colors.white}
                  onBtnClick={() => setShowInviteModal(true)}
                />
              </div>
            )}
            {accessAddPartner?.value && (
              <div>
                <Button
                  btnText={"Add Banks"}
                  disabled={!getBankCollections}
                  isLoading={!getBankCollections}
                  onBtnClick={() => {
                    bankCollection();
                    setShowBankModal(true);
                  }}
                />
              </div>
            )}
          </div>

          <div className="d-flex gap-4 mb-4 mt-3">
            <div className="">
              <InfoCard
                mainValue={
                  isLoading || isRefetching ? (
                    <SkeletonLoader height={"5rem"} borderRadius={"5px"} />
                  ) : (
                    partneredBanks?.length ?? 0
                  )
                }
                description={
                  isLoading || isRefetching ? (
                    <SkeletonLoader />
                  ) : (
                    "Patner Banks"
                  )
                }
              />
            </div>
          </div>

          {isLoading ? (
            <div className="mt-4">
              <TableLoader />
            </div>
          ) : banks?.data?.length > 0 ? (
            <div className="mt-2">
              <InfoTable
                mainTitle="Manage Bank Relationships"
                columns={bankColums(
                  (rowId, status) => showPartnerShipModal(rowId, status),
                  isRefetching
                )}
                dataCollection={banks?.data}
                pointerOnHover={true}
                highlightOnHover={true}
                loadingTable={isRefetching}
                paginationTotalRows={banks?.totalRecords}
                onChangePage={(page) => setPagination(page)}
                onRowClicked={(row, event) =>
                  navigate(
                    `/banks/${
                      row.sender?.id === orgID ? row.partner.id : row.sender?.id
                    }`
                  )
                }
              />
            </div>
          ) : (
            <div className="mt-5">
              <CenteredText title="You are not partnered with any Bank">
                Click the “add Banks" button above to create a partnership with
                a Bank.
              </CenteredText>
            </div>
          )}
        </>
      ) : (
        <div className="mt-5">
          <CenteredText title={"Unauthorized Access"}>
            You don't have permission to view partnership. Please contact the
            organization administrator for assistance.
          </CenteredText>
        </div>
      )}

      {/* INVITE BANK */}
      <Modal
        title="Invite Bank"
        show={showInviteModal}
        cancelButtonLabel={"Cancel"}
        confirmButtonLabel={"Invite"}
        confirmModal={handleSubmit(handleSubmitInvite)}
        closeModal={() => setShowInviteModal(false)}
        isBtnLoading={pending}
      >
        <form className="row d-flex justify-content-center">
          <div className="col-12">
            <FormInput
              labelName={"Bank Name"}
              required
              register={register("organisationName")}
              error={errors?.organisationName?.message}
            />
            <FormInput
              labelName={"Email"}
              required
              register={register("organisationEmail")}
              error={errors?.organisationEmail?.message}
            />
            <FormInput
              labelName={"Phone No"}
              required
              register={register("organisationPhone")}
              error={errors?.organisationPhone?.message}
            />
          </div>
        </form>
      </Modal>

      {/* ADD BANKS */}
      <Modal
        show={showBankModal}
        title={`Banks on Gidda-(${getBankCollections?.length})`}
        closeModal={setShowBankModal}
        width={"100%"}
        height={"100%"}
        crossLine={true}
        closeModalIcon={true}
        titleFont={"Millik"}
        titleFontSize={"30px"}
      >
        {/* <div className="row mt-3 mb-3">
          <FormInput
            placeholder="Search for Banks"
            lefticonname={"search"}
            value={search}
            onChange={(name, value) => setSearch(value)}
          />
        </div> */}

        <div className="row">
          {loading ? (
            <Spinner />
          ) : bankCollections?.length > 0 ? (
            bankCollections.map((bank) => (
              <div className="custom_grid mb-5" key={bank.id}>
                <EstateCards
                  estate={bank}
                  image={bank?.coverImage}
                  houseCount={bank?.houseCount}
                  key={bank.id}
                  gotoEstate={() => navigate(`/banks/${bank.id}`)}
                />
              </div>
              // <div
              //   className="col-sm-6 col-md-4 col-xl-2 my-4"
              //   key={bank.id}
              //   onClick={() => navigate(`/banks/${bank.id}`)}
              // >
              //   <div className="card bank-card">
              //     <div className="bank-img">
              //       <img
              //         src={bank?.logo}
              //         alt={bank.name}
              //         className="img-fluid"
              //       />
              //     </div>
              //     <div className="bank-details px-2">
              //       <div className="row bank-title" title={bank.name}>
              //         <h6>
              //           {bank.name?.length > 18
              //             ? `${bank.name?.substring(0, 18)}...`
              //             : bank.name}
              //         </h6>
              //       </div>
              //       <div className="row bank-desc" title={bank.address}>
              //         <p className="p-0">
              //           {bank.address?.length > 30
              //             ? `${bank.address?.substring(0, 30)}...`
              //             : bank.address}
              //         </p>
              //       </div>
              //       <div className="row bank-hou">
              //         <span>
              //           {bank.estateCount}{" "}
              //           {bank.estateCount > 0 ? "Estates" : "Estate"}.{" "}
              //           {bank.houseCount}{" "}
              //           {bank.houseCount > 0 ? "Houses" : "House"}
              //         </span>
              //       </div>
              //     </div>
              //   </div>
              // </div>
            ))
          ) : (
            <div className="mt-5">
              <CenteredText title={"There are currently no banks on Giddaa"}>
                Check back later as this information is updated regularly.
              </CenteredText>
            </div>
          )}
        </div>
      </Modal>

      {/* ACCEPT PARTNERSHIP */}
      <Modal
        title="Accept Bank Partnership Request"
        show={showAcceptModal}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        confirmModal={() => {
          setPending(true);
          handlePartnerShipRequest(requesterID, "ACCEPTED");
        }}
        closeModal={() => {
          setPending(false);
          setShowAcceptModal(false);
        }}
        isBtnLoading={pending}
        disabled={pending}
      >
        <p className="text-center">
          Are you sure you want to accept the request to partner with{" "}
          {slectedDeveloperName}?{" "}
          <strong>
            This will give you access to sell their properties through your
            plans.
          </strong>
        </p>
      </Modal>

      {/* DECLINE PARTNERSHIP */}
      <Modal
        title="Reject Bank Partnership Request"
        show={showDeclineModal}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        confirmModal={() => {
          setPending(true);
          handlePartnerShipRequest(requesterID, "REJECTED");
        }}
        closeModal={() => {
          setPending(false);
          setShowDeclineModal(false);
        }}
        isBtnLoading={pending}
        disabled={pending}
      >
        <p className="text-center">
          Are you sure you want to decline the request to partner with{" "}
          {slectedDeveloperName}?{" "}
          <strong>This will give them access to sell your properties.</strong>
        </p>
      </Modal>

      {/* UNPARTNER DEVELOPER */}
      <Modal
        title="Unpartner with Bank"
        show={showUnpartnerModal}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        confirmModal={() => {
          setPending(true);
          handlePartnerShipRequest(requesterID, "UNPARTNER");
        }}
        closeModal={() => {
          setPending(false);
          setShowUnpartnerModal(false);
        }}
        isBtnLoading={pending}
        disabled={pending}
      >
        <p className="text-center">
          Are you sure you want to unpartner with {slectedDeveloperName}?{" "}
          <strong>
            This will mean you won’t be able to access any of their properties
            anymmore.
          </strong>
        </p>
      </Modal>

      {/* REVOKE DEVELOPER */}
      <Modal
        title="Revoke partnership with this Bank"
        show={showRevokeModal}
        cancelButtonLabel={"No"}
        confirmButtonLabel={"Yes"}
        confirmModal={() => {
          setPending(true);
          handlePartnerShipRequest(requesterID, "REVOKED");
        }}
        closeModal={() => {
          setPending(false);
          setShowRevokeModal(false);
        }}
        isBtnLoading={pending}
        disabled={pending}
      >
        <p className="text-center">
          Are you sure you want to revoke your partnership request with{" "}
          {slectedDeveloperName}?{" "}
          <strong>
            This will mean you won’t be able to access any of their properties
            anymore.
          </strong>
        </p>
      </Modal>
    </div>
  );
};

export default MortgageBanks;
