import React from "react";
import { TbHomeSearch } from "react-icons/tb";
import { FaCircleCheck } from "react-icons/fa6";
import { FiMail, FiInstagram, FiFileText } from "react-icons/fi";
import { IoNewspaperOutline } from "react-icons/io5";
import { MdOutlineMarkEmailUnread } from "react-icons/md";
import { MdOutlinePayments } from "react-icons/md";
import { BsQuestionCircleFill } from "react-icons/bs";
import { FaDotCircle } from "react-icons/fa";
import "./mortgageApplication.css";
import Icons from "../../Components/Common/Icons";
import { colors } from "../../utils/constants";

const Tabs = ({ tabs }) => {
  const getIconStyle = (status) => {
    switch (status) {
      case "completed":
        return { height: "30px", stroke: "#707070", fill: "#707070", opacity: "0.8"  };
      case "pending":
        return { height: "30px", stroke: "#C6C6C6", fill: "#C6C6C6" };
      case "active":
        return { height: "30px", stroke: colors.green };
      default:
        return {}; // Default empty style
    }
  };


  return (
    <>
      <div className="carousel mt-3">
        <div className="d-md-flex justify-content-between w-100">
          {tabs &&
            tabs?.tabs?.map((tab, index) => (
              <div className="w-100 text-uppercase g-0 m-0">
                {tabs?.flow === "tabFlow1" ? (
                  <div className="row g-0 text-center pe-1">
                    <span className="pe-3">
                      {index === 0 ? (
                        <Icons
                          iconName={"legalSearch"}
                          className="tab-icon"
                          style={{
                            ...getIconStyle(tab.status),
                            height: "20px",
                          }}
                        />
                      ) : index === 1 ? (
                        <Icons
                          iconName={"terms"}
                          className="tab-icon"
                          style={{
                            ...getIconStyle(tab.status),
                            height: "20px",
                          }}
                        />
                      ) : index === 2 ? (
                        <Icons
                          iconName={"applications"}
                          className="tab-icon"
                          style={{
                            ...getIconStyle(tab.status),
                            height: "20px",
                          }}
                        />
                      ) : index === 3 ? (
                        <Icons
                          iconName={"documentValidation"}
                          className="tab-icon"
                          style={{
                            ...getIconStyle(tab.status),
                            height: "20px",
                          }}
                        />
                      ) : index === 4 ? (
                        <Icons
                          iconName={"application_review"}
                          className="tab-icon"
                          style={{
                            ...getIconStyle(tab.status),
                            height: "20px",
                          }}
                        />
                      ) : index === 5 ? (
                        <Icons
                          iconName={"offerLetter"}
                          className="tab-icon"
                          style={{
                            ...getIconStyle(tab.status),
                            height: "20px",
                          }}
                        />
                      ) : index === 6 ? (
                        <Icons
                          iconName={"payments"}
                          className="tab-icon"
                          style={{
                            ...getIconStyle(tab.status),
                            height: "20px",
                          }}
                        />
                      ) : index === 7 ? (
                        <Icons
                          iconName={"completed"}
                          className="tab-icon"
                          style={{ ...getIconStyle(tab.status) }}
                        />
                      ) : (
                        <Icons
                          iconName={"terms"}
                          className="tab-icon"
                          style={{ ...getIconStyle(tab.status) }}
                        />
                      )}
                    </span>
                  </div>
                ) : tabs?.flow === "tabFlow2" ? (
                  <div className="row g-0 text-center pe-1">
                    <span className="pe-3">
                      {index === 0 ? (
                        <Icons
                          iconName={"legalSearch"}
                          className="tab-icon"
                          style={{
                            ...getIconStyle(tab.status),
                            height: "20px",
                          }}
                        />
                      ): index === 1 ? (
                        <Icons
                          iconName={"terms"}
                          className="tab-icon"
                          style={{
                            ...getIconStyle(tab.status),
                            height: "20px",
                          }}
                        />
                      ) : index === 2 ? (
                        <Icons
                          iconName={"documentValidation"}
                          className="tab-icon"
                          style={{
                            ...getIconStyle(tab.status),
                            height: "20px",
                          }}
                        />
                      ) : index === 3 ? (
                        <Icons
                          iconName={"application_review"}
                          className="tab-icon"
                          style={{
                            ...getIconStyle(tab.status),
                            height: "20px",
                          }}
                        />
                      ) : index === 4 ? (
                        <Icons
                          iconName={"offerLetter"}
                          className="tab-icon"
                          style={{
                            ...getIconStyle(tab.status),
                            height: "20px",
                          }}
                        />
                      ) : index === 5 ? (
                        <Icons
                          iconName={"bankApproval"}
                          className="tab-icon"
                          style={{
                            ...getIconStyle(tab.status),
                            height: "20px",
                          }}
                        />
                      ) : index === 6 ? (
                        <Icons
                          iconName={"payments"}
                          className="tab-icon"
                          style={{ ...getIconStyle(tab.status) }}
                        />
                      ) : index === 7 ? (
                        <Icons
                          iconName={"completed"}
                          className="tab-icon"
                          style={{ ...getIconStyle(tab.status) }}
                        />
                      ) : (
                        <Icons
                          iconName={"terms"}
                          className="tab-icon"
                          style={{ ...getIconStyle(tab.status) }}
                        />
                      )}
                    </span>
                  </div>
                ) : (
                  <div className="row g-0 text-center pe-1">
                    <span className="pe-3">
                      {index === 0 ? (
                        <Icons
                          iconName={"legalSearch"}
                          className="tab-icon"
                          style={{
                            ...getIconStyle(tab.status),
                            height: "20px",
                          }}
                        />
                      ) : index === 1 ? (
                        <Icons
                          iconName={"applications"}
                          className="tab-icon"
                          style={{
                            ...getIconStyle(tab.status),
                            height: "20px",
                          }}
                        />
                      ) : index === 2 ? (
                        <Icons
                          iconName={"documentValidation"}
                          className="tab-icon"
                          style={{
                            ...getIconStyle(tab.status),
                            height: "20px",
                          }}
                        />
                      ) : index === 3 ? (
                        <Icons
                          iconName={"application_review"}
                          className="tab-icon"
                          style={{
                            ...getIconStyle(tab.status),
                            height: "20px",
                          }}
                        />
                      ) : index === 4 ? (
                        <Icons
                          iconName={"offerLetter"}
                          className="tab-icon"
                          style={{
                            ...getIconStyle(tab.status),
                            height: "20px",
                          }}
                        />
                      ) : index === 5 ? (
                        <Icons
                          iconName={"payments"}
                          className="tab-icon"
                          style={{
                            ...getIconStyle(tab.status),
                            height: "20px",
                          }}
                        />
                      ) : index === 6 ? (
                        <Icons
                          iconName={"completed"}
                          className="tab-icon"
                          style={{ ...getIconStyle(tab.status) }}
                        />
                      ) : (
                        <Icons
                          iconName={"terms"}
                          className="tab-icon"
                          style={{ ...getIconStyle(tab.status) }}
                        />
                      )}
                    </span>
                  </div>
                )}

                <div className="row">
                  <div
                    className="d-flex align-items-center p-0 offset-1 position-relative"
                    style={{ width: "100%" }}
                  >
                    <span className="rounded-circle position-absolute">
                      {tab.status === "completed" ? (
                        <FaCircleCheck
                          size={"1em"}
                          z={500}
                          color={colors.gray}
                        />
                      ) : tab.status === "active" ? (
                        <BsQuestionCircleFill
                          color={colors.green}
                          size={"1em"}
                        />
                      ) : (
                        <FaDotCircle color={colors.lightGrey} size={"1em"} />
                      )}
                    </span>
                    <hr
                      className="flex-grow-1 m-0 p-0 g-2 g-md-1 g-sm-1 mt-3 mb-2"
                      style={{
                        borderTop: `3px solid ${
                          tab.status === "completed"
                            ? "#0a8640"
                            : tab.status === "pending"
                            ? colors.lightGrey
                            : colors.gray
                        }`,
                        borderRadius: "8px",
                        width: "100%",
                      }}
                    />
                  </div>
                </div>

                <div className="row text-center">
                  <span
                    style={{
                      fontSize: "0.7em",
                      fontFamily: '"Red Hat Display", sans-serif',
                      marginBottom: "3px",
                    }}
                    className={
                      tab.status === "active"
                        ? "tab-active"
                        : tab.status === "pending"
                        ? "tab-pending"
                        : ""
                    }
                  >
                    {tab.name}
                  </span>
                </div>
              </div>
            ))}
        </div>
      </div>

      <hr style={{ color: "gray" }} />
    </>
  );
};

export default Tabs;
