import { useMutation } from "@tanstack/react-query";
import useAxiosBase from "../../../hooks/useAxiosBase";
import { toast } from "react-toastify";
import useAuth from "../../../hooks/useAuth";

const useUploadOfferLetter = (requestId) => {
  const { postRequest } = useAxiosBase();

  const uploadOfferLetter = useMutation({
    mutationFn: (data) =>
      postRequest({
        url: `/developer/offer-letter-request/${requestId}/upload-document`,
        data: data,
      }).then((res) => {
        if (res?.data?.value?.statusCode === 200) {
          toast.success(res?.data?.value?.message);
        }
        if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
          toast.error(res?.data?.value?.message);
        }
      }),
    onSuccess: (res, res1) => {
      if (res?.data?.value?.statusCode === 200) {
        toast.success(res?.data?.value?.message);
      }

      if ([400, 404, 500].includes(res?.data?.value?.statusCode)) {
        toast.error(res?.data?.value?.message);
      }
    },
    onError: (error) => {
      toast.error("ERRROR", error);
    },
  });

  return { uploadOfferLetter };
};

export default useUploadOfferLetter;
