import React, { useEffect } from "react";
import image from "../../assets/images/signup.jpg";
import { ImageWrapper } from "../../Components/Common/StyledComponents/style";
import { ForgotPasswordContainer } from "./style";
import FormInput from "../../Components/Common/FormInput";
import Button from "../../Components/Common/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import forgotPasswordSchema from "./model/forgotPasswordSchema";
import useForgotPassword from "./hooks/useForgotPassword";
import { useNavigate } from "react-router";
import useAuth from "../../hooks/useAuth";

const ForogtPassword = () => {
  const { authUser } = useAuth();
  const orgName = authUser?.user?.organization?.name;

  const navigate = useNavigate();
  const { forgotPassword } = useForgotPassword();

  const handleLogin = () => navigate("/account/login");

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: error },
  } = useForm({ resolver: yupResolver(forgotPasswordSchema) });

  const onSubmitForgotPassword = async (data) => {

    await forgotPassword.mutate(data);
  };

  useEffect(() => {
    document.title = `Giddaa - ${orgName} - Forgot Password`;
  }, []);

  return (
    <ForgotPasswordContainer>
      <div className="container-fluid op">
        <div className="row">
          <div className="col-md-6 d-none d-md-flex img-container p-0">
            <ImageWrapper>
              <img className="img-fluid" src={image} alt="imaget" />
            </ImageWrapper>
          </div>

          <div className="postion-relative form-container col-md-6 d-flex align-items-center h-100 p-3 p-md-4">
            <div className="">
              <img
                src="https://s3.amazonaws.com/appforest_uf/f1675031132769x885606220423525500/GiddaaLogoGreen.svg"
                alt="logo"
                width={90}
                height={70}
                className="position-absolute"
                style={{ top: "2rem", left: "1rem" }}
              />
            </div>

            <div className="form-wrapper w-100">
              <h1 className="pt-3 pe-5 text-md-start text-sm-center">
                Forgot Password
              </h1>
              <p classfffName="py-2">
                Enter your Giddaa registered email address below, and a password
                reset link will be sent to your email.
              </p>
              <form onSubmit={handleSubmit2(onSubmitForgotPassword)}>
                <div>
                  <FormInput
                    labelName={"Enter your email address"}
                    lefticonname={"envolope"}
                    register={register2("email")}
                    error={error?.email?.message}
                    placeholder={"Enter email address"}
                    required
                    autoComplete="off"
                  />
                  <div className="text-center pt-4 pt-md-4 pt-sm-5 d-flex flex-row justify-content-between align-items-center">
                    <hr />
                    <Button
                      btnText={"Continue"}
                      isLoading={forgotPassword.isLoading}
                      disabled={forgotPassword.isLoading}
                    />
                    <hr />
                  </div>
                  <div className="mt-3 text-center">
                    <p>
                      Already have an account?{" "}
                      <strong
                        className="anchor"
                        role="button"
                        onClick={handleLogin}
                      >
                        Login
                      </strong>
                    </p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </ForgotPasswordContainer>
  );
};

export default ForogtPassword;
