import { createBrowserRouter, useSearchParams } from "react-router-dom";
import Dashboard from "./Pages/Dashboard";
import Properties from "./Pages/Properties";
import Transactions from "./Pages/Transactions";
import BankApplication from "./Pages/Applications/BankApplications";
import MyApplication from "./Pages/Applications/MyApplications";
import MortgageBanks from "./Pages/Banks";
import CreateHouse from "./Pages/Properties/House/createHouse";
import CreateEstate from "./Pages/Properties/Estate/createEstate";
import Estate from "./Pages/Properties/Estate";
import PaymentPlans from "./Pages/PaymentPlans";
import MortgagePlans from "./Pages/Properties/House/HousePlan";
import NHF from "./Pages/Properties/House/HousePlan/NHF";
import MortgagePlanDetails from "./Pages/Properties/House/HousePlan/MortgagePlanDetails";
import CreateMortagagePlan from "./Pages/Properties/House/HousePlan/CreateMortagagePlan";
import App from "./App";
import Login from "./Pages/Login";
import ForogtPassword from "./Pages/ForgotPassword";
import RequireAuth from "./Pages/RequireAuth";
import BankDetails from "./Pages/Banks/BankDetails";
import Prospect from "./Pages/Prospect";
import ViewingRequests from "./Pages/ViewingRequests";
import EditViewing from "./Pages/ViewingRequests/Edit";
import Agents from "./Pages/ViewingRequests/Agents";
import House from "./Pages/Properties/House/index";
import ApplicationDetails from "./Pages/Applications/ApplicationDetails";
import OfferLetterAndTitleDoc from "./Pages/PropertyDocuments";
import OfferLetterList from "./Pages/PropertyDocuments/offerLetterList";
import TitleDocumentList from "./Pages/PropertyDocuments/TitleDocumentList";
import Company from "./Pages/Settings/Company";
import Profile from "./Pages/Settings/Profile";
import Account from "./Pages/Settings/Account";
import Team from "./Pages/Settings/Team";
import Documents from "./Pages/Settings/Documents";
import PermissionsAndRoles from "./Pages/Settings/PermissionsAndRoles";
import ApplicationReview from "./Pages/ApplicationReview";
import Applications from "./Pages/Applications/Applications";
import Signup from "./Pages/Signup";
import Wizzard from "./Pages/Signup/wizzard";
import ResetPassword from "./Pages/ResetPassword";
import Permissions from "./Pages/Settings/PermissionsAndRoles/Permissions";
import InviteTeamMember from "./Pages/InviteTeamMember";
import PortfolioDetails from "./Pages/PaymentPlans/PortfolioDetails";
import SinglePlanDetails from "./Pages/Plans/SinglePlanDetails";
import AllPlans from "./Pages/Plans";
import Payments from "./Pages/Settings/Payments";
import PartnershipTerms from "./Pages/Settings/PartnershipTerms";
import NotificationConfiguration from "./Pages/Settings/NotificationConfiguration";
import ConstructionUpdate from "./Pages/Properties/House/HouseConstructionUpdate";
import EstateConstructionUpdate from "./Pages/Properties/Estate/EstateConstructionUpdate";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RequireAuth />,
    children: [
      {
        path: "/",
        element: <App />,
        children: [
          {
            element: <Dashboard />,
            path: "",
            titleName: "Dashboard",
          },
          {
            path: "/properties",
            children: [
              { path: "", element: <Properties />, titleName: "Properties" },
              {
                path: "/properties/estate/:id",
                element: <Estate />,
              },
              {
                path: "/properties/house/:id",
                element: <House />,
              },
              {
                path: "/properties/estate/create-estate",
                element: <CreateEstate />,
                titleName: "Create Estate",
              },
              {
                path: "/properties/house/create-house/:estateId?",
                element: <CreateHouse />,
                titleName: "Create House",
              },
              {
                path: "/properties/house/plans/:houseId",
                element: <MortgagePlans />,
              },
              {
                path: "/properties/house/plans/:houseId/:id",
                element: <MortgagePlanDetails />,
              },
              {
                path: "/properties/house/plans/create/:houseId",
                element: <CreateMortagagePlan />,
              },
              {
                path: "/properties/house/:houseId/construction-update",
                element: <ConstructionUpdate />,
              },
              {
                path: "/properties/estate/:estateId/construction-update",
                element: <EstateConstructionUpdate />,
              },
              {
                path: "/properties/house/plans/:houseId/NHF",
                element: <NHF />,
              },
            ],
          },
          {
            path: "/plans",
            children: [
              {
                path: "",
                element: <AllPlans />,
              },
              {
                path: "/plans/:id",
                element: <SinglePlanDetails />,
              },
            ],
            titleName: "Plans",
          },
          {
            path: "estate/:id",
            element: <Estate />,
            titleName: "Estate",
          },
          {
            path: "/application",
            children: [
              {
                path: "/application/my-applications",
                element: <MyApplication />,
                titleName: "Mortgage Application",
              },
              {
                path: "/application/bank-applications",
                element: <BankApplication />,
                titleName: "Mortgage Application",
              },
              {
                path: "/application/my-applications/:mortagePlanId?",
                element: <Applications />,
                titleName: "Application",
              },
              {
                path: "/application/my-applications/:owner/:id",
                element: <ApplicationDetails />,
              },
              {
                path: "/application/bank-applications/:owner/:id",
                element: <ApplicationDetails />,
              },
            ],
          },
          {
            path: "/banks",
            children: [
              {
                path: "",
                element: <MortgageBanks />,
                titleName: "Banks",
              },
              {
                path: "/banks/:id",
                element: <BankDetails />,
                titleName: "Bank Details",
              },
            ],
          },
          {
            element: <ApplicationReview />,
            path: "/application-review",
            titleName: "Application Review",
          },
          {
            element: <Transactions />,
            path: "/transactions-earnings",
            titleName: "Transactions & Earnings",
          },
          {
            path: "/portfolio",
            children: [
              { path: "", element: <PaymentPlans /> },
              { path: "/portfolio/details/:id", element: <PortfolioDetails /> },
            ],
            titleName: "Offer Letter & Title Documents",
          },
          {
            path: "/prospects",
            element: <Prospect />,
            titleName: "Prospect",
          },
          {
            path: "/offer-letter",
            children: [
              { path: "", element: <OfferLetterAndTitleDoc /> },
              { path: "/offer-letter/list", element: <OfferLetterList /> },
              {
                path: "/offer-letter/property-documents",
                element: <TitleDocumentList />,
              },
            ],
            titleName: "Offer Letter & Property Documents",
          },
          {
            path: "/house-inspections",
            children: [
              { path: "", element: <ViewingRequests /> },
              { path: "/house-inspections/agents", element: <Agents /> },
              {
                path: "/house-inspections/edit",
                element: <EditViewing />,
                titleName: "Edit House Inspections",
              },
            ],
            titleName: "House Inspections",
          },
          {
            path: "/settings/company",
            element: <Company />,
          },
          {
            path: "/settings/profile",
            element: <Profile />,
          },
          {
            path: "/settings/payments",
            element: <Payments />,
          },
          {
            path: "/settings/account",
            element: <Account />,
          },
          {
            path: "/settings/team",
            element: <Team />,
          },
          {
            path: "/settings/partnership-terms",
            element: <PartnershipTerms />,
          },
          {
            path: "/settings/documents",
            element: <Documents />,
          },
          {
            path: "/settings/notification-configuration",
            element: <NotificationConfiguration />,
          },
          {
            path: "/settings/permission-and-roles",
            children: [
              {
                path: "",
                element: <PermissionsAndRoles />,
                titleName: "Organization Roles",
              },
              {
                path: "/settings/permission-and-roles/:id",
                element: <Permissions />,
                titleName: "Organization Permissions",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: "/account/login",
    element: <Login />,
  },
  {
    path: "/account/forgot-password",
    element: <ForogtPassword />,
  },
  {
    path: "/account/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/account/signup",
    element: <Signup />,
  },
  {
    path: "/account/complete-registration",
    element: <InviteTeamMember />,
  },
  {
    path: "/account/onboarding",
    element: <Wizzard />,
  },
]);

export default router;
