const houseModel = {
  mortgagePlans: [],
  estateId: "",
  price: "",
  cityId: "",
  address: "",
  units: 0,
  landmark: "",
  description: "",
  images: [],
  organizationId: "",
};
export default houseModel;
