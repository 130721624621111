import axios, { CanceledError } from "axios";

export default axios.create({
  baseURL: process.env.REACT_APP_BASE_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
  },
});

const authClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_ENDPOINT,
  headers: {
    "Content-Type": "application/json",
    Authorization:
      "eyJhbGciOiJodHRwOi8vd3d3LnczLm9yZy8yMDAxLzA0L3htbGRzaWctbW9yZSNobWFjLXNoYTI1NiIsInR5cCI6IkpXVCJ9.eyJJZCI6IkdJRERBQV9TVVBFUl9BRE1JTl9VU0VSIiwiTmFtZSI6Ik1lY2sgQWFyb24gVGludW0iLCJSb2xlSWQiOiJTVVBFUl9BRE1JTl9ST0xFIiwiRW1haWwiOiJtZWNrQGdpZGRhYS5jb20iLCJleHAiOjE2ODY5MzcwNzYsImlzcyI6Imh0dHA6Ly9sb2NhbGhvc3Q6NzE5MCIsImF1ZCI6Imh0dHA6Ly9sb2NhbGhvc3Q6NzE5MCJ9.NbM5bH7Imtge547muyO3ThscheGUIcibzVv0zvWsxj4",
  },
});

export { CanceledError, authClient };
